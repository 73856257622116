import * as React from 'react'

import {Option, PrimaryText, SecondarySelect} from '@settleindex/react'

import {CapType} from '../../graphQLTypes'
import {capTypeNames} from '../capTypeNames'

interface Props {
  defaultValue: CapType
  onChange: (value: string) => void
  readonly: boolean
  testId?: string
  types: CapType[]
}

export const TypeSelect: React.FC<Props> = ({defaultValue, onChange, readonly, testId, types}) => {
  return readonly ? (
    <PrimaryText>{capTypeNames.get(defaultValue)}</PrimaryText>
  ) : (
    <SecondarySelect
      data-test-id={testId}
      defaultValue={defaultValue}
      dropdownStyle={{minWidth: 350}}
      onChange={onChange}
      style={{width: '100%'}}
    >
      {types.map((capType) => (
        <Option key={capType} value={capType}>
          {capTypeNames.get(capType)}
        </Option>
      ))}
    </SecondarySelect>
  )
}
