import styled from 'styled-components'

export const ReadOnlyWrapper = styled.div`
  // 100% height so the content can also be 100% high
  height: 100%;

  .casebot-readonly {
    & .ant-form-item-control-input *,
    & .ant-select-selector,
    & button,
    & input {
      pointer-events: none;
    }

    & .ant-select-selector,
    & button {
      opacity: 0.5;
    }

    // Some buttons need to remain usable
    & [data-readonly='skip'] * {
      pointer-events: auto;
      opacity: 1;
    }

    & .ant-select-clear,
    & .ant-input-number-handler-wrap:hover,
    & .ant-input-number-handler-wrap {
      opacity: 0;
      visibility: hidden;
    }

    & .ant-input-number-affix-wrapper,
    & input,
    & .ant-input-number {
      background-color: white !important;
      border-color: white;
    }
  }
`
