import * as React from 'react'

import {DeleteIcon as BaseDeleteIcon} from '@settleindex/react'

interface Props {
  onClick: () => void
}

export const DeleteIcon: React.FC<Props> = ({onClick}) => {
  return (
    <a onClick={onClick} style={{fontSize: 12, cursor: 'pointer'}}>
      <BaseDeleteIcon />
    </a>
  )
}
