import * as React from 'react'

import {mulDecimals, percentToStr} from '@settleindex/domain'
import {cond} from '@settleindex/fp'
import {
  LeftRight,
  PrimaryText,
  SecondaryButton2,
  SecondaryText,
  Table,
  TableColumn,
  TableRow,
  VSpaceMedium,
} from '@settleindex/react'

import {CounterclaimTag} from '../../../claim/CounterClaimTag'
import {DebugLabel} from '../../../debug'
import {VersionFragment} from '../../../graphQLTypes'
import {Percent} from '../../../percent/Percent'
import {SumText} from '../../../sum'
import {AppOutcome} from '../../AppOutcome'
import {useNodes} from '../../context/useNodes'
import {useVersionVariablesDrawer} from '../../versionVariablesDrawer/useVersionVariablesDrawer'
import {PayReceiveTag} from '../payReceiveTag/makePayReceiveTag'
import {AwardTag} from './AwardTag'

type ClaimGroupRecord = 'name' | 'amount' | 'adjustment' | 'adjusted'

const columns: TableColumn<ClaimGroupRecord>[] = [
  {
    label: 'Claim',
    id: 'name',
    align: 'left',
    width: 475,
  },
  {
    label: 'Original Amount',
    id: 'amount',
    align: 'right',
    width: 120,
  },
  {
    label: 'Award',
    id: 'adjustment',
    align: 'right',
  },
  {
    label: 'Total',
    id: 'adjusted',
    align: 'right',
  },
]

interface Props {
  outcome: AppOutcome
  payReceiveTag: PayReceiveTag
  readonly?: boolean
  version: VersionFragment
}

export const ClaimGroupsTable: React.FC<Props> = ({outcome, payReceiveTag, readonly, version}) => {
  const {editClaims} = useVersionVariablesDrawer()
  const {claimGroups} = version
  const {isPerspective: perspectiveIsClaimant} = useNodes()

  const claimGroupRows: TableRow<ClaimGroupRecord>[] = React.useMemo(() => {
    return claimGroups.map((claimGroup) => {
      const groupModifier = outcome.claimGroupModifiers.find((m) => m.id === claimGroup.id)?.modifier
      const groupModified = outcome.claimGroupsModified.find((m) => m.id === claimGroup.id)

      // Rows for each claim
      const subTableRows: TableRow<ClaimGroupRecord>[] = claimGroup.claims.map((claim) => {
        const claimModifier = cond([
          [() => claim.type === 'claim' && groupModified?.award === 'won', () => groupModifier],
          [() => claim.type === 'counterclaim' && groupModified?.award === 'won', () => 0],
          [() => claim.type === 'claim' && groupModified?.award === 'lost', () => 0],
          [() => claim.type === 'counterclaim' && groupModified?.award === 'lost', () => groupModifier],
          [() => groupModified?.award === 'ignored', () => 0],
        ])()

        const claimTotal = mulDecimals([claim.amountForType, claimModifier])

        return {
          id: `${claim.id}/children`,
          cells: {
            name: (
              <LeftRight
                left={<PrimaryText>{claim.name}</PrimaryText>}
                right={claim.type === 'counterclaim' ? <CounterclaimTag style={{marginLeft: 10, width: 95}} /> : <></>}
                rowStyle={{width: '100%'}}
              />
            ),
            amount: (
              <DebugLabel title="version.claimGroups[].claims[].amountForType">
                <SumText sum={claim.amountForType} />
              </DebugLabel>
            ),
            adjustment: <PrimaryText>{percentToStr(claimModifier)}</PrimaryText>,
            adjusted: <SumText sum={claimTotal} />,
          },
        }
      })

      return {
        id: claimGroup.id,
        subTable: {
          rows: subTableRows,
          indentAmount: 'parentAlign',
        },
        expandable: true,
        cells: {
          name: (
            <>
              <LeftRight
                left={<PrimaryText semibold>{claimGroup.name}</PrimaryText>}
                right={<AwardTag award={groupModified?.award} perspectiveIsClaimant={perspectiveIsClaimant} />}
                rowStyle={{width: '100%'}}
              />
            </>
          ),
          amount: (
            <DebugLabel title="version.claimGroups[].totalClaimsWithInterest">
              <SumText sum={claimGroup.totalClaimsWithInterest} />
            </DebugLabel>
          ),
          adjusted: (
            <DebugLabel title="outcome.claimGroupsModified[].sum">
              <SumText semibold sum={groupModified?.sum} />
            </DebugLabel>
          ),
        },
      }
    })
  }, [claimGroups, outcome.claimGroupModifiers, outcome.claimGroupsModified, perspectiveIsClaimant])

  const totalRow: TableRow<ClaimGroupRecord>[] = [
    {
      id: 'space',
      cells: {},
    },
    {
      id: 'interest',
      cells: {
        name: <PrimaryText>Adjust All</PrimaryText>,
        adjustment: (
          <DebugLabel title="outcome.interestDecimal">
            <Percent percent={outcome.interestDecimal} />
          </DebugLabel>
        ),
      },
    },
    {
      id: 'total',
      cells: {
        name: <PrimaryText semibold>Claims Award</PrimaryText>,
        adjustment: payReceiveTag(outcome.claimsWithInterest),
        adjusted: (
          <DebugLabel title="outcome.claimsWithInterest">
            <SumText semibold sum={outcome.claimsWithInterest} />
          </DebugLabel>
        ),
      },
    },
  ]

  const rows = [...claimGroupRows, ...totalRow]

  return (
    <>
      <LeftRight
        left={<SecondaryText medium>Damages payable or receivable in this outcome</SecondaryText>}
        right={!readonly && <SecondaryButton2 onClick={editClaims}>Manage Values</SecondaryButton2>}
      />

      <VSpaceMedium />

      <Table columns={columns} rows={rows} stateId={`estimate/${outcome.id}/claimGroups`} />
    </>
  )
}
