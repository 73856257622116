import 'react-cmdk/dist/cmdk.css'

import React, {useEffect, useState} from 'react'
import CommandPalette, {filterItems, getItemIndex, JsonStructureItem} from 'react-cmdk'
import useLocalStorage from 'use-local-storage'

import {useDebugLabels} from '../debug/useDebugLabels'
import {useDebugPanel} from '../debug/useDebugPanel'
import {useDispute} from '../dispute/context/useDispute'
import {useVersionVariablesDrawer} from '../nodes/versionVariablesDrawer/useVersionVariablesDrawer'
import {paths, useHistory} from '../routing'
import {useVersion} from '../version/context/useVersion'

export const CommandK = () => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useLocalStorage('commandKSearchString', '')

  const {history} = useHistory()
  const {toggleDebugLabels} = useDebugLabels()
  const {toggleDebugPanel} = useDebugPanel()
  const {dispute} = useDispute()
  const {version} = useVersion()
  const {editCaps, editClaims, editCosts} = useVersionVariablesDrawer()

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e: any) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen((o) => !o)

        // !open means the dialog was closed, i.e. it's open now
        if (!open) {
          window.setTimeout(() => {
            const input = document.querySelector('#command-palette-search-input') as HTMLInputElement

            input?.focus()
          }, 555)
        }
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [open])

  const filteredItems = React.useMemo(() => {
    const disputeItems = []
    let disputeHeading = 'Case'
    const versionList: JsonStructureItem[] = []
    const versionItems: JsonStructureItem[] = []

    if (dispute) {
      disputeHeading = dispute.title
      disputeItems.push({
        id: 'dashboard',
        children: 'Dashboard',
        onClick: () => history.push(paths.dispute(dispute.id)),
      })

      dispute.versions.forEach((v) => {
        versionList.push({
          id: v.id,
          children: `Model: ${v.title}`,
          onClick: () => history.push(paths.version(dispute.id, v.id)),
        })
      })

      disputeItems.push(
        {id: 'history', children: 'History', onClick: () => history.push(paths.disputeMetrics(dispute.id))},
        {id: 'compare', children: 'Compare', onClick: () => history.push(paths.versionCompare(dispute.id))},
        {id: 'events', children: 'Offers', onClick: () => history.push(paths.disputeEvents(dispute.id))},
        {id: 'team', children: 'Team', onClick: () => history.push(paths.disputeSharingList(dispute.id))},
        {id: 'settings', children: 'Settings', onClick: () => history.push(paths.disputeSettings(dispute.id))},
      )
    }

    if (version) {
      versionItems.push(
        {id: 'claims', children: 'Claims', onClick: editClaims},
        {id: 'costs', children: 'Costs', onClick: () => editCosts()},
        {id: 'caps', children: 'Caps', onClick: editCaps},
      )
    }

    return filterItems(
      [
        {
          heading: disputeHeading,
          id: 'case',
          items: disputeItems,
        },
        {
          heading: 'Versions',
          id: 'versions',
          items: versionList,
        },
        {
          heading: 'Version',
          id: 'version',
          items: versionItems,
        },
        {
          heading: 'Navigate',
          id: 'nav',
          items: [
            {id: 'home', children: 'Home', onClick: () => history.push(paths.home())},
            {id: 'newDispute', children: 'New Case', onClick: () => history.push(paths.newDisputePattern)},
          ],
        },

        {
          heading: 'Admin',
          id: 'admin',
          items: [
            {id: 'labels', children: 'Toggle labels', onClick: toggleDebugLabels},
            {id: 'debug', children: 'Debug case values', onClick: toggleDebugPanel},
            {id: 'admin', children: 'User Admin', onClick: () => history.push(paths.admin())},
          ],
        },
      ],
      search,
    )
  }, [dispute, editCaps, editClaims, editCosts, history, search, toggleDebugLabels, toggleDebugPanel, version])

  return (
    <CommandPalette isOpen={open} onChangeOpen={setOpen} onChangeSearch={setSearch} page={'root'} search={search}>
      <CommandPalette.Page id="root">
        {filteredItems.map((list) => (
          <CommandPalette.List heading={list.heading} key={list.id}>
            {list.items &&
              list.items.map(({id, ...rest}) => (
                <CommandPalette.ListItem index={getItemIndex(filteredItems, id)} key={id} {...rest} />
              ))}
          </CommandPalette.List>
        ))}
      </CommandPalette.Page>
    </CommandPalette>
  )
}
