import {AxisProps} from '@nivo/axes'
import {ResponsiveMarimekko} from '@nivo/marimekko'
import * as React from 'react'

import {percentToStr} from '@settleindex/domain'
import {head, tail} from '@settleindex/fp'
import {hex2rgba} from '@settleindex/react'

import {theme} from '../../chart/theme'
import {leftTicks} from '../../chart/tick/leftTicks'
import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {opponentColor, perspectiveColor} from '../../party/perspectiveAwareColors'
import {useNodes} from '../context/useNodes'
import {AnalyseChartTooltip} from './AnalyseChartTooltip'
import {Data} from './Data'
import {getPossibleYValues} from './getPossibleYValues'
import {makeChartData} from './makeChartData'

interface Props {
  outcomeVisibleThreshold: number
}

const AnalyseChartComponent: React.FC<Props> = ({outcomeVisibleThreshold}) => {
  const {
    dispute: {currency},
  } = useSafeDispute()
  const {
    cumulativeWeights,
    isPerspective,
    selectedPartyNodes,
    selectedPartyOutcomes,
    valueToDisplay: principalValue,
  } = useNodes()

  const dimensions: Array<{id: string; value: keyof Data}> = React.useMemo(() => [{id: 'value', value: 'value'}], [])

  const chartData = React.useMemo(
    () =>
      makeChartData({
        currency,
        displayValue: principalValue,
        partyNodes: selectedPartyNodes,
        partyOutcomes: selectedPartyOutcomes,
        outcomeVisibleThreshold,
        cumulativeWeights,
      }),
    [currency, principalValue, selectedPartyNodes, selectedPartyOutcomes, outcomeVisibleThreshold, cumulativeWeights],
  )

  const y = React.useMemo(() => {
    const possibleYValues = getPossibleYValues(selectedPartyOutcomes)
    const yMin = Math.min(0, head(possibleYValues))
    const yMax = tail(possibleYValues)
    const yValues = leftTicks({min: yMin, max: yMax, numberOfTicksRequired: 10})

    return {
      yMin,
      yValues,
    }
  }, [selectedPartyOutcomes])

  const colors = React.useCallback(
    (p: any) => {
      const barColor = isPerspective ? perspectiveColor : opponentColor
      const opacity = p.datum.data.originalValueIsZero ? 0.15 : 1

      return hex2rgba(barColor, opacity)
    },
    [isPerspective],
  )

  const margin = React.useMemo(() => ({top: 30, right: 10, bottom: 65, left: 75}), [])

  const axisBottom = React.useMemo<AxisProps>(
    () => ({
      format: (value: number) => percentToStr(value),
      tickSize: 0,
      tickPadding: 20,
      legend: 'Cumulative Chance',
      legendOffset: 55,
      legendPosition: 'middle',
    }),
    [],
  )

  const axisLeft = React.useMemo<AxisProps>(
    () => ({
      format: '(.2~s',
      legend: 'Outcome Value',
      legendOffset: -70,
      legendPosition: 'middle',
      tickPadding: 20,
      tickSize: 0,
    }),
    [],
  )

  return (
    <ResponsiveMarimekko
      axisBottom={axisBottom}
      axisLeft={axisLeft}
      colors={colors}
      data={chartData}
      dimensions={dimensions}
      enableGridY
      gridYValues={y.yValues}
      id={'id'}
      isInteractive
      margin={margin}
      theme={theme}
      tooltip={AnalyseChartTooltip}
      // value selects which property defines the width of a bar
      value="absoluteWeight"
    />
  )
}

export const AnalyseChart = React.memo(AnalyseChartComponent)
