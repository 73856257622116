import * as React from 'react'

import {H2, LeftRight, Table, TableRow, Tag, VSpaceLarge} from '@settleindex/react'

import {SumFormat} from '../../../sum'
import {AppOutcome} from '../../AppOutcome'
import {PayReceiveModeTag, PayReceiveTag} from '../payReceiveTag/makePayReceiveTag'
import {columns} from './columns'
import {makeRow} from './makeRow'
import {makeRowFromProperty} from './makeRowFromProperty'
import {OutcomeTotalsRecord} from './OutcomeTotalsRecord'

interface Props {
  outcome: AppOutcome
  payReceiveTag: PayReceiveTag
}

export const OutcomeTotalsTable: React.FC<Props> = ({outcome, payReceiveTag}) => {
  const costSpent = React.useMemo(
    () => <SumFormat sum={outcome.costPartyTotalsSelected.spent} />,
    [outcome.costPartyTotalsSelected.spent],
  )
  const costForecast = React.useMemo(
    () => <SumFormat sum={outcome.costPartyTotalsSelected.forecast} />,
    [outcome.costPartyTotalsSelected.forecast],
  )

  const rows: TableRow<OutcomeTotalsRecord>[] = React.useMemo(() => {
    const propertyRow = makeRowFromProperty(payReceiveTag, outcome)

    return [
      propertyRow(
        'valueGross',
        {},
        {
          expandable: true,
          subTable: {
            indentAmount: 'parentAlign',
            rows: [
              propertyRow('claimsWithInterest', {isLabelBold: false}),
              propertyRow('costRecoveryNet', {isLabelBold: false}),
            ],
          },
        },
      ),
      makeRow({
        debugLabelTitle: 'outcome.costPartyTotals.budget',
        description: 'Costs spent in this outcome',
        idSuffix: 'ownCosts',
        label: <span style={{paddingLeft: 25}}>Own Costs</span>,
        payReceive: <PayReceiveModeTag mode={'pay'} />,
        sum: outcome.costPartyTotalsSelected.budget,
      }),
      propertyRow(
        'valueNet',
        {},
        {
          expandable: true,
          subTable: {
            indentAmount: 'parentAlign',
            rows: [
              propertyRow('valuePresent', {
                isLabelBold: false,
                description: <>Adjusted for {costSpent} spend to date</>,
              }),
              propertyRow('valueForecast', {
                isLabelBold: false,
                description: <>Adjusted for {costForecast} forecast costs</>,
              }),
            ],
          },
        },
      ),
    ]
  }, [costForecast, costSpent, payReceiveTag, outcome])

  return (
    <>
      <LeftRight left={<H2>Summary</H2>} right={outcome.capId ? <Tag color={'light'}>Cap applies</Tag> : <></>} />
      <Table columns={columns} rows={rows} stateId="OutcomeTotalsTable" />
      <VSpaceLarge />
    </>
  )
}
