import * as React from 'react'

import {DotDotDot, Link, Popconfirm} from '@settleindex/react'

interface Props {
  editPath: string
  email: string
  onDelete: () => void
}

export const UserListMenu: React.FC<Props> = ({editPath, email, onDelete}) => {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false)

  const menu = {
    items: [
      {
        key: 'edit',
        label: <Link to={editPath}>Edit</Link>,
      },
      {
        key: 'delete',
        label: (
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onCancel={() => {
              setMenuOpen(false)
            }}
            onConfirm={() => {
              onDelete()
              setMenuOpen(false)
            }}
            title={`Are you sure you want to delete ${email}?`}
          >
            <Link to="#">Delete</Link>
          </Popconfirm>
        ),
      },
    ],
  }

  return <DotDotDot menu={menu} onOpenChange={setMenuOpen} open={menuOpen} />
}
