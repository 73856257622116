import * as React from 'react'

import {PrimaryText, Table, TableRow} from '@settleindex/react'

import {DebugLabel} from '../../../debug/DebugLabel'
import {useSafeDispute} from '../../../dispute/context/useSafeDispute'
import {SumText} from '../../../sum'
import {useSafeVersion} from '../../../version/context/useSafeVersion'
import {useSettlementAnalysis} from '../useSettlementAnalysis'
import {CostsTableRecord} from './CostsTableRecord'
import {makeColumns} from './makeColumns'

export const CostsTable: React.FC = () => {
  const {
    dispute: {perspective},
  } = useSafeDispute()
  const {
    version: {costTotals, perspectiveCost},
  } = useSafeVersion()

  const {settlementOpponent} = useSettlementAnalysis()

  const opponentCosts = React.useMemo(
    () => costTotals.find((t) => t.partyId === settlementOpponent.id),
    [costTotals, settlementOpponent.id],
  )

  const columns = React.useMemo(
    () => makeColumns(perspective.label, settlementOpponent.label),
    [perspective.label, settlementOpponent.label],
  )

  const rows: TableRow<CostsTableRecord>[] = React.useMemo(
    () => [
      {
        id: 'amount',
        cells: {
          value: <PrimaryText medium>Potential Costs</PrimaryText>,
          perspective: (
            <DebugLabel title="version.perspectiveCost.budget">
              <SumText sum={perspectiveCost.budget} />
            </DebugLabel>
          ),
          opponent: (
            <DebugLabel title="version.costTotals[].budget">
              <SumText sum={opponentCosts?.budget} />
            </DebugLabel>
          ),
        },
      },
      {
        id: 'recoverable',
        cells: {
          value: <PrimaryText medium>Potentially Recoverable</PrimaryText>,
          perspective: (
            <DebugLabel title="version.perspectiveCost.recoverable">
              <SumText sum={perspectiveCost.recoverable} />
            </DebugLabel>
          ),
          opponent: (
            <DebugLabel title="version.costTotals[].recoverable">
              <SumText sum={opponentCosts?.recoverable} />
            </DebugLabel>
          ),
        },
      },
      {
        id: 'spent',
        cells: {
          value: <PrimaryText medium>Spent to Date</PrimaryText>,
          perspective: (
            <DebugLabel title="version.perspectiveCost.spent">
              <SumText sum={perspectiveCost.spent} />
            </DebugLabel>
          ),
          opponent: (
            <DebugLabel title="version.costTotals[].spent">
              <SumText sum={opponentCosts?.spent} />
            </DebugLabel>
          ),
        },
      },
      {
        id: 'forecast',
        cells: {
          value: <PrimaryText medium>Forecast Spend</PrimaryText>,
          perspective: (
            <DebugLabel title="version.perspectiveCost.forecast">
              <SumText sum={perspectiveCost.forecast} />
            </DebugLabel>
          ),
          opponent: (
            <DebugLabel title="version.costTotals[].forecast">
              <SumText sum={opponentCosts?.forecast} />
            </DebugLabel>
          ),
        },
      },
    ],
    [opponentCosts, perspectiveCost],
  )

  return <Table columns={columns} rows={rows} stateId="CostsTable" />
}
