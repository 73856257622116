import * as React from 'react'

import {FormItem, TextInput} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {partyFieldNames} from './partyFieldNames'

export const PartyLabel: React.FC<{}> = () => {
  React.useEffect(() => {
    window.setTimeout(() => {
      document.querySelector<HTMLInputElement>('#party_label')?.focus()
    }, 100)
  }, [])

  return (
    <FormItem
      label="Label"
      name={partyFieldNames.label}
      rules={[
        {
          required: true,
          message: 'Please provide a label for this party',
        },
      ]}
    >
      <TextInput autoComplete="off" data-test-id={disputeFormTestIds.editPartyLabel} placeholder="e.g. Retailer" />
    </FormItem>
  )
}
