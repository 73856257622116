import * as React from 'react'
import styled from 'styled-components'

import {Link} from '@settleindex/react'
import {logoTestId} from '@settleindex/testids/src/testIds'

import {paths} from '../routing/paths'
import logoPng from './logo_white.png'

const LogoImg = styled.img`
  height: 25px;
  width: 25px;
  margin-bottom: 40px;
  margin-left: 18px;
`

export const Logo: React.FC<{}> = () => (
  <Link data-test-id={logoTestId} to={paths.disputes()}>
    <LogoImg src={logoPng} />
  </Link>
)
