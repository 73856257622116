import * as React from 'react'

import {Option, TertiarySelect} from '@settleindex/react'

import {useNodes} from '../../nodes/context/useNodes'
import {versionValueLabels} from '../../nodes/valueLabels'

export const ValueToDisplaySelect: React.FC = () => {
  const {setValueToDisplay, valueToDisplay} = useNodes()
  const style = React.useMemo(() => ({fontWeight: 500, minWidth: 150}), [])

  return (
    <TertiarySelect defaultValue={valueToDisplay} onChange={setValueToDisplay} style={style}>
      <Option value="valueGross">{versionValueLabels.valueGross}</Option>
      <Option value="valueNet">{versionValueLabels.valueNet}</Option>
      <Option value="valuePresent">{versionValueLabels.valuePresent}</Option>
      <Option value="valueForecast">{versionValueLabels.valueForecast}</Option>
    </TertiarySelect>
  )
}
