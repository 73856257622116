import * as React from 'react'

import {ClaimGroupModified} from '@settleindex/domain'
import {Tag} from '@settleindex/react'

interface AwardTagProps {
  award?: ClaimGroupModified['award']
  perspectiveIsClaimant: boolean
}

export const AwardTag: React.FC<AwardTagProps> = ({award, perspectiveIsClaimant}) => {
  if (!award) {
    return null
  }

  const claimantWinColor = perspectiveIsClaimant ? 'green' : 'red'
  const defendantWinColor = perspectiveIsClaimant ? 'red' : 'green'

  const awardTagColor = award === 'won' ? claimantWinColor : award === 'lost' ? defendantWinColor : 'purpleLight'
  const awardTagStyle = {width: 95, marginLeft: 10}

  const awardTagTextLookup = {
    won: 'Claimant Win',
    lost: 'Claimant Lose',
    ignored: 'Not attached',
  }
  return (
    <Tag color={awardTagColor} style={awardTagStyle}>
      {awardTagTextLookup[award]}
    </Tag>
  )
}
