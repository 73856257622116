import * as React from 'react'

import {DisputeFragment} from '../graphQLTypes'
import {
  disputeBreadcrumbs,
  disputeEventsListLink,
  disputeHistoryListLink,
  disputeSettingsLink,
  disputeTeamLink,
  versionCompareLink,
} from '../navigation'
import {Content} from '../page/'

type SectionKey = 'compare' | 'dashboard' | 'events' | 'metrics' | 'settings' | 'team'

interface Props {
  activeKey: SectionKey
  dispute: DisputeFragment
  initialLoading?: boolean
  loading?: boolean
}

export const Frame: React.FC<React.PropsWithChildren<Props>> = ({activeKey, children, dispute, loading}) => {
  const sectionKeyToBreadcrumbMap = new Map<SectionKey, React.ReactElement>([
    ['compare', versionCompareLink(dispute.id)],
    ['events', disputeEventsListLink(dispute.id)],
    ['metrics', disputeHistoryListLink(dispute.id)],
    ['settings', disputeSettingsLink(dispute.id)],
    ['team', disputeTeamLink(dispute.id)],
  ])

  const disputeTabBreadcrumb = sectionKeyToBreadcrumbMap.get(activeKey)
  const breadcrumbs = disputeBreadcrumbs({dispute, disputeTab: disputeTabBreadcrumb})

  return (
    <>
      <Content breadcrumbs={breadcrumbs} loading={loading}>
        {children}
      </Content>
    </>
  )
}
