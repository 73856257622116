import * as React from 'react'

import {Col, Row} from '@settleindex/react'

import {userEditFields} from '../../../user/userEditFields'

interface Props {
  fields: ReturnType<typeof userEditFields>
  showNotify?: boolean
}

export const FormLayout: React.FC<Props> = ({fields, showNotify}) => {
  return (
    <>
      <Row gutter={20}>
        <Col span={6}>{fields.firstName}</Col>
        <Col span={6}>{fields.lastName}</Col>
      </Row>
      <Row gutter={20}>
        <Col span={6}>{fields.email}</Col>
        <Col span={6}>{fields.phone}</Col>
      </Row>
      <Row>
        <Col span={12}>{fields.company}</Col>
      </Row>
      {showNotify && (
        <Row>
          <Col span={12}>{fields.notify}</Col>
        </Row>
      )}
      <Row>
        <Col span={12}>{fields.submit}</Col>
      </Row>
    </>
  )
}
