import * as React from 'react'

import {isDefined} from '@settleindex/fp'
import {LoadingData} from '@settleindex/react'

import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {useVersionContainer} from '../../version/context/useVersionContainer'
import {useVersionGraph} from '../../version/graph/useVersionGraph'
import {SettlementTargetsPage} from './SettlementTargetsPage'

interface Props {
  disputeId: string
  versionId: string
}

export const SettlementTargetsContainer: React.FC<Props> = ({disputeId, versionId}) => {
  const {dispute: graphDispute, setSettlementStrategy, version: graphVersion} = useVersionGraph({disputeId, versionId})

  useDisputeContainer({requiredId: disputeId, setDisputeFrom: graphDispute})
  const {version: versionInContext} = useVersionContainer({requiredId: versionId, setVersionFrom: graphVersion})

  return (
    <LoadingData
      state={{
        available: isDefined(versionInContext),
      }}
      whenAvailable={<SettlementTargetsPage setSettlementStrategy={setSettlementStrategy} />}
    />
  )
}
