import * as React from 'react'

import {H3, PrimaryText, SecondaryText, Table, TableRow, VSpaceSmall} from '@settleindex/react'

import {DebugLabel} from '../../../debug/DebugLabel'
import {SettlementStrategyInput, SettlementStrategyType} from '../../../graphQLTypes'
import {SumText} from '../../../sum'
import {SumInput} from '../../../sum/sumInput'
import {useSafeVersion} from '../../../version/context/useSafeVersion'
import {useSettlementAnalysis} from '../useSettlementAnalysis'
import {columns} from './columns'
import {SettlementValuesRecord} from './SettlementValuesRecord'

interface Props {
  setSettlementStrategy: (strategy: SettlementStrategyInput) => void
}

export const SettlementValuesTable: React.FC<Props> = ({setSettlementStrategy}) => {
  const {
    version: {readonly, settlementStrategy},
  } = useSafeVersion()

  const {settlementOpponent, settlementOpponentValues} = useSettlementAnalysis()

  const customSettlementTarget = React.useMemo(
    () =>
      settlementStrategy.find(
        (s) => s.partyId === settlementOpponent.id && s.type === SettlementStrategyType.customTarget,
      )?.amount ?? undefined,
    [settlementOpponent.id, settlementStrategy],
  )

  const rows: TableRow<SettlementValuesRecord>[] = React.useMemo(
    () => [
      {
        id: 'modelSettlement',
        cells: {
          methodology: <PrimaryText medium>Model Settlement</PrimaryText>,
          description: <SecondaryText medium>Including Costs Award</SecondaryText>,
          value: (
            <DebugLabel title="version.opponentValues.commonSettlementValue">
              <SumText bold sum={settlementOpponentValues?.commonSettlementValue} />
            </DebugLabel>
          ),
          currentGainLoss: (
            <DebugLabel title="version.opponentValues.currentGainLoss">
              <SumText sum={settlementOpponentValues?.currentGainLoss} />
            </DebugLabel>
          ),
          expectedGainLoss: (
            <DebugLabel title="version.opponentValues.expectedGainLoss">
              <SumText sum={settlementOpponentValues?.expectedGainLoss} />
            </DebugLabel>
          ),
        },
      },
      {
        id: 'custom',
        cells: {
          methodology: <PrimaryText>Custom Settlement</PrimaryText>,
          value: (
            <DebugLabel title="settlementStrategy[] / customTarget">
              <SumInput
                defaultValue={customSettlementTarget}
                disabled={readonly}
                key={settlementOpponent.id}
                onSave={(amount: number) =>
                  setSettlementStrategy({
                    partyId: settlementOpponent.id,
                    type: SettlementStrategyType.customTarget,
                    amount,
                  })
                }
                placeholder={`0`}
                style={{maxWidth: 145}}
              />
            </DebugLabel>
          ),
          currentGainLoss: (
            <DebugLabel placement="left" title="settlementOpponentValues.customTargetCurrentGainLoss">
              <SumText sum={settlementOpponentValues?.customTargetCurrentGainLoss} />
            </DebugLabel>
          ),
          expectedGainLoss: (
            <DebugLabel placement="left" title="settlementOpponentValues.customTargetExpectedGainLoss">
              <SumText sum={settlementOpponentValues?.customTargetExpectedGainLoss} />
            </DebugLabel>
          ),
        },
      },
    ],
    [
      customSettlementTarget,
      readonly,
      setSettlementStrategy,
      settlementOpponent.id,
      settlementOpponentValues?.commonSettlementValue,
      settlementOpponentValues?.currentGainLoss,
      settlementOpponentValues?.customTargetCurrentGainLoss,
      settlementOpponentValues?.customTargetExpectedGainLoss,
      settlementOpponentValues?.expectedGainLoss,
    ],
  )

  return (
    <>
      <H3>Settlements</H3>
      <VSpaceSmall />

      <Table columns={columns} rows={rows} stateId="SettlementValuesTable" />
    </>
  )
}
