import * as React from 'react'
import {Redirect, useParams} from 'react-router-dom'

import {disputeClassifications} from '@settleindex/domain'
import {anyTrue, isDefined} from '@settleindex/fp'
import {Ambulance, Forbidden, LoadingData} from '@settleindex/react'
import {accessRightTestIds} from '@settleindex/testids/src/testIds'

import {
  DisputeFragment,
  DisputePatchInput,
  useAllDisputesLazyQuery,
  useArchiveDisputeMutation,
  useDisputeQuery,
  usePatchDisputeMutation,
} from '../../graphQLTypes'
import {LoadingIndicatorWithNav} from '../../page'
import {GoToHome, useHistory} from '../../routing'
import {paths} from '../../routing/paths'
import {useDisputeContainer} from '../context/useDisputeContainer'
import {DisputeNotFound} from '../DisputeNotFound'
import {disputeNotFoundGraphError} from '../graph/disputeErrorGuards'
import {DisputeEditPage} from './DisputeEditPage'
import {disputeToEditFormValues} from './disputeToEditFormValues'

interface Match {
  id: string
}

const DisputeEditContainer: React.FC = () => {
  const {gotoTop} = useHistory()
  const {id: disputeId} = useParams<Match>()
  const {
    data: disputeData,
    error: disputeError,
    loading: disputeLoading,
  } = useDisputeQuery({variables: {id: disputeId}})
  const [patchMutation, {called: patchCalled, client: patchClient, error: patchError, loading: patchLoading}] =
    usePatchDisputeMutation()
  const [archiveMutation, {error: archiveError, loading: archiveLoading}] = useArchiveDisputeMutation()
  const [refetchDisputes, {called: refetchCalled, data: refetchData, loading: refetchLoading}] =
    useAllDisputesLazyQuery({fetchPolicy: 'network-only'})

  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: disputeData?.dispute})

  const isAnyDataLoading = anyTrue(disputeLoading, patchLoading, archiveLoading, refetchLoading)
  const isDisputeForbidden = disputeInContext?.readonly === true
  const isAnyDataError = anyTrue(disputeError, patchError, archiveError, isDisputeForbidden)

  if (patchCalled && !patchLoading) {
    return <Redirect to={paths.dispute(disputeId)} />
  }

  if (refetchCalled && !refetchLoading) {
    return <GoToHome />
  }

  if (refetchCalled && refetchData) {
    return <Redirect to="/" />
  }

  const whenAvailable = () => {
    const dispute = disputeInContext as DisputeFragment

    const onCancel = () => gotoTop(paths.dispute(disputeId))

    const initialValues = disputeToEditFormValues(dispute)

    const patch = (disputePatch: DisputePatchInput) => {
      const {cache} = patchClient

      dispute?.versionIds.forEach((versionId) => {
        const id = cache.identify({id: versionId, __typename: 'VersionResponse'})

        cache.evict({id})
      })

      cache.gc()

      patchMutation({
        variables: {
          id: disputeId,
          patch: disputePatch,
        },
      })
    }

    const archiveDispute = async () => {
      await archiveMutation({
        variables: {
          id: disputeId,
        },
      })

      refetchDisputes()
    }

    return (
      <DisputeEditPage
        dispute={dispute}
        disputeClassifications={disputeClassifications}
        initialValues={initialValues}
        loading={isAnyDataLoading}
        onArchive={archiveDispute}
        onCancel={onCancel}
        patch={patch}
      />
    )
  }

  const whenError = () =>
    isDisputeForbidden ? <Forbidden data-test-id={accessRightTestIds.accessForbidden} /> : <Ambulance />

  return (
    <LoadingData
      state={{
        available: isDefined(disputeInContext),
        empty: disputeNotFoundGraphError(disputeError),
        error: isAnyDataError,
        loading: disputeLoading,
      }}
      whenAvailable={whenAvailable}
      whenEmpty={<DisputeNotFound />}
      whenError={whenError}
      whenLoading={<LoadingIndicatorWithNav />}
    />
  )
}

export default DisputeEditContainer
