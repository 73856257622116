import styled from 'styled-components'

import {colors, spaceMedium} from '@settleindex/react'

export const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  min-height: 60px;
  background-color: ${colors.base10};
  padding: 0 ${spaceMedium}px;
`
