import * as React from 'react'
import {useIdleTimer} from 'react-idle-timer'

import {hourToMillisecond, minuteToMillisecond} from '@settleindex/domain'
import {Notification, PrimaryButton2} from '@settleindex/react'

import {useUser} from '../useUser/useUser'
import {useAutoLogout} from './useAutoLogout'

const inactiveTimeoutMs = hourToMillisecond(1)
const promptTimeoutMs = minuteToMillisecond(10)

const warningPromptKey = 'promptNotificationKey'
const gotLoggedOutMessageKey = 'gotLoggedOutMessageKey'

const hideGotLoggedOutMessage = () => {
  Notification.close(gotLoggedOutMessageKey)
}

export const AutoLogout: React.FC = () => {
  const user = useUser()
  const [isPromptNotificationVisible, setIsPromptNotificationVisible] = React.useState<boolean>(false)

  // Hide the "You have been logged out" message when the user logs in
  React.useEffect(() => {
    hideGotLoggedOutMessage()
  }, [])

  const onIdle = React.useCallback(() => {
    hideGotLoggedOutMessage()
    Notification.close(warningPromptKey)
    Notification.info({
      duration: 0,
      message: 'You have been logged out due to inactivity',
      key: gotLoggedOutMessageKey,
    })
    user.logout()
  }, [user])

  const {checkIdleAfterSleep} = useAutoLogout({inactiveTimeoutMs, onIdle})

  const {activate, isPrompted} = useIdleTimer({
    crossTab: true,
    onAction: checkIdleAfterSleep,
    onIdle,
    promptBeforeIdle: promptTimeoutMs,
    stopOnIdle: true,
    throttle: 5000,
    timeout: inactiveTimeoutMs,
  })

  const handleStayLoggedIn = React.useCallback(() => {
    Notification.close(warningPromptKey)
    setIsPromptNotificationVisible(false)
    activate()
  }, [activate])

  React.useEffect(() => {
    const checkPromptedInterval = window.setInterval(() => {
      if (!isPrompted() || isPromptNotificationVisible) {
        return
      }

      Notification.warn({
        btn: (
          <PrimaryButton2 onClick={handleStayLoggedIn} size="medium">
            Stay logged in
          </PrimaryButton2>
        ),
        onClose: handleStayLoggedIn,
        description: `For your security you will be logged out if you're inactive.`,
        duration: 0,
        key: warningPromptKey,
        message: 'You are inactive',
        placement: 'top',
      })
      setIsPromptNotificationVisible(true)
    }, 1000)

    return () => window.clearInterval(checkPromptedInterval)
  }, [activate, handleStayLoggedIn, isPromptNotificationVisible, isPrompted])

  return <></>
}
