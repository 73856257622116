import * as React from 'react'
import styled from 'styled-components'

import {DrawerFrame} from '../drawer/DrawerFrame'
import {useVersionVariablesDrawer} from './useVersionVariablesDrawer'

export const VersionVariablesDrawer: React.FC = () => {
  const {content, editNone, selectedVersionVariable} = useVersionVariablesDrawer()

  return (
    <DrawerFrame
      headerTitle={'Values'}
      isDrawerVisible={!!selectedVersionVariable}
      mask={false}
      onClose={editNone}
      placement="left"
      width={900}
    >
      <Padding>{content}</Padding>
    </DrawerFrame>
  )
}

const Padding = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`
