import * as React from 'react'
import {EdgeTypes, NodeTypes} from 'react-flow-renderer'

import {EditableEdge, editableEdgeType} from '../edge/EditableEdge'
import {OutcomeNode, outcomeNodeType} from '../outcome/OutcomeNode'
import {StartNode, startNodeType} from '../start/StartNode'

export const useNodeTypes = () => {
  const nodeTypes = React.useMemo<NodeTypes>(
    () => ({
      [outcomeNodeType]: OutcomeNode,
      [startNodeType]: StartNode,
    }),
    [],
  )

  const edgeTypes = React.useMemo<EdgeTypes>(
    () => ({
      [editableEdgeType]: EditableEdge,
    }),
    [],
  )

  return {
    nodeTypes,
    edgeTypes,
  }
}
