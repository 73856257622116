import {useAtom} from 'jotai/index'
import * as React from 'react'
import styled from 'styled-components'

import {Accordion, H1, H2, LeftRightAlignCenter, SecondaryText, VSpaceMedium, VSpaceSmall} from '@settleindex/react'

import {AccordionPanel} from '../../../casebot/AccordionPanel'
import {DecisionTreePage} from '../../../decisionTree/DecisionTreePage'
import {NoOutcomes} from '../../../decisionTree/NoOutcomes'
import {useDecisionTree} from '../../../decisionTree/useDecisionTree'
import {useSafeDispute} from '../../../dispute/context/useSafeDispute'
import {HelpTitleWithTooltip} from '../../../help/HelpTitleWithTooltip'
import {AnalyseContainer} from '../../../nodes/analyse/AnalyseContainer'
import {SettlementAnalysisContainer} from '../../../settlement/analysis/SettlementAnalysisContainer'
import {SettlementTargetsContainer} from '../../../settlement/targets/SettlementTargetsContainer'
import {useSafeVersion} from '../../context/useSafeVersion'
import {VersionSummaryBar} from '../../summaryBar/VersionSummaryBar'
import {reportsAccordionOpenAtom} from './reportsAccordionOpenAtom'

interface VersionReportsProps {}

export const VersionReports: React.FC<VersionReportsProps> = () => {
  const {dispute} = useSafeDispute()
  const {version} = useSafeVersion()
  const [reportsAccordionOpen, setReportsAccordionOpen] = useAtom(reportsAccordionOpenAtom)
  const {isLargeView} = useDecisionTree()

  const sections = [
    {label: 'Key Data', key: 'keyData', children: <VersionSummaryBar />, right: 'Position, Targets, KPIs'},
    {
      label: 'Settlement Positions',
      right: 'Position Analysis and Settlement Ranges',
      key: 'settle',
      children: <SettlementAnalysisContainer disputeId={dispute.id} versionId={version.id} />,
    },
    {
      label: 'Outcome Analysis',
      right: 'Potential Outcomes, Probabilities and Values',
      key: 'analyse',
      children: (
        <>
          <AnalyseContainer />
        </>
      ),
    },
    {
      label: 'Opponent Targets',
      right: 'Party Specific Targets and Metrics',
      key: 'targets',
      children: <SettlementTargetsContainer disputeId={dispute.id} versionId={version.id} />,
    },
    {
      label: 'Map',
      right: 'Decision Tree Analysis',
      key: 'map',
      children: !isLargeView ? (
        <>
          <HelpTitleWithTooltip
            id="help_for_map"
            left="This Report shows all the permutations of Outcome that flow from the User Inputs."
            leftId="help_for_map_title"
          />

          <DecisionTreePage noData={<NoOutcomes />} />
        </>
      ) : (
        <></>
      ),
    },
  ]

  return (
    <Section>
      <VSpaceMedium />
      <H1 style={{textAlign: 'center'}}>Reports</H1>
      <VSpaceSmall />
      <Accordion
        accordion
        activeKey={reportsAccordionOpen}
        boundless
        defaultActiveKey={['settle', 'analyse']}
        onChange={(key) => setReportsAccordionOpen(key as string[])}
      >
        {sections.map((item) => (
          <AccordionPanel
            header={
              <LeftRightAlignCenter left={<H2>{item.label}</H2>} right={<SecondaryText>{item.right}</SecondaryText>} />
            }
            key={item.key}
          >
            {item.children}
          </AccordionPanel>
        ))}
      </Accordion>

      {isLargeView ? (
        <FullScreenModal>
          <DecisionTreePage noData={<NoOutcomes />} />
        </FullScreenModal>
      ) : null}
    </Section>
  )
}

const FullScreenModal = styled.div`
  background-color: white;
  position: fixed;
  top: 0;
  left: 60px;
  right: 10px;
  bottom: 0;
  z-index: 1000;
`

const Section = styled.div`
  padding: 0 60px 80px;
`
