import * as React from 'react'

import {Role} from '@settleindex/domain'
import {LeftRight, StateToggleButtons, VSpaceLarge, VSpaceMedium} from '@settleindex/react'
import {disputeTestIds} from '@settleindex/testids/src/testIds'

import {useDebugPanel} from '../../../debug/useDebugPanel'
import {usePageTitle} from '../../../pageTitle/usePageTitle'
import {paths} from '../../../routing/paths'
import {useSafeDispute} from '../../context/useSafeDispute'
import {DashboardNoHistory} from '../../dashboard/DashboardNoHistory'
import {DisputeHistoryChartSection} from '../../dashboard/DisputeHistoryChartSection'
import {LatestVersionChartSection} from '../../dashboard/LatestVersionChartSection'
import {Frame} from '../../Frame'
import {TitleAndReference} from '../../TitleAndReference'
import {rejectDraftVersion} from '../disputeHistoryFilters'
import {DisputeHistoryMetricsSelector} from '../disputeHistoryMetricsSelector/DisputeHistoryMetricsSelector'
import {useMetricsSelector} from '../metricsSelector/useMetricsSelector'

type Section = 'latestModel' | 'caseHistory'

interface Props {
  addEmptyVersion: () => void
}

export const DisputeHistoryPage: React.FC<Props> = ({addEmptyVersion}) => {
  const {dispute} = useSafeDispute()
  usePageTitle(dispute.title)
  const [section, setSection] = React.useState<Section>('latestModel')
  const {setDebugPanelContent} = useDebugPanel()
  React.useEffect(() => {
    setDebugPanelContent(dispute)

    return () => setDebugPanelContent(undefined)
  }, [dispute, dispute.history, setDebugPanelContent])

  const metricsStorageKey = React.useMemo(() => `DisputeHistoryPageMetrics-${dispute.id}`, [dispute.id])
  const {checkedMetrics, onCheckboxClick} = useMetricsSelector({
    storageKey: metricsStorageKey,
  })

  const disputeHistoryWithoutDrafts = React.useMemo(() => dispute.history.filter(rejectDraftVersion), [dispute.history])

  const versionListLink = paths.dispute(dispute.id)

  const sectionToggle = disputeHistoryWithoutDrafts.length ? (
    <StateToggleButtons<Section>
      buttons={[
        {title: 'Latest Model', valueToSet: 'latestModel'},
        {title: 'Case History', valueToSet: 'caseHistory'},
      ]}
      canEmpty={false}
      currentValue={section}
      setter={setSection}
    />
  ) : (
    <></>
  )

  const chartSection =
    section === 'latestModel' ? (
      <LatestVersionChartSection
        dispute={dispute}
        historyPoints={disputeHistoryWithoutDrafts}
        metricsOnChart={checkedMetrics}
      />
    ) : (
      <>
        <DisputeHistoryChartSection
          currency={dispute.currency}
          disputeHistoryWithoutDrafts={disputeHistoryWithoutDrafts}
          metricsOnChart={checkedMetrics}
          versionListLink={versionListLink}
        />
        <VSpaceMedium />
      </>
    )

  const metricsSelector = (
    <DisputeHistoryMetricsSelector
      checkedMetrics={checkedMetrics}
      history={disputeHistoryWithoutDrafts}
      nonInteractive={!history.length}
      onCheckboxClick={onCheckboxClick}
      perspectiveRole={dispute.perspectiveRole as Role}
    />
  )

  const content = disputeHistoryWithoutDrafts.length ? (
    <>
      {chartSection} {metricsSelector}
    </>
  ) : (
    <DashboardNoHistory addEmptyVersion={addEmptyVersion} versionListLink={versionListLink} />
  )

  return (
    <>
      <Frame activeKey="metrics" dispute={dispute}>
        <LeftRight
          left={<TitleAndReference dispute={dispute} />}
          right={sectionToggle}
          rowStyle={{alignItems: 'flex-end'}}
        />
        <VSpaceLarge />

        <div data-test-id={disputeTestIds.disputeHistoryContent}>{content}</div>
        <VSpaceMedium />
      </Frame>
    </>
  )
}
