import * as React from 'react'

import {AppContext} from '../appContext/AppContext'

const knowledgeBaseHomeUrl = 'https://settleindex.com/support/'

export interface UseKnowledgeBase {
  hide: () => void
  homeUrl: string
  isOpen: boolean
  show: (url: string) => void
  url?: string
}

export const useKnowledgeBase = (): UseKnowledgeBase => {
  const {knowledgeBase} = React.useContext(AppContext)

  const show = (url: string) => {
    knowledgeBase.setUrl(url)
    knowledgeBase.setOpen(true)
  }

  const hide = () => {
    knowledgeBase.setUrl(undefined)
    knowledgeBase.setOpen(false)
  }

  return {
    hide,
    homeUrl: knowledgeBaseHomeUrl,
    isOpen: knowledgeBase.isOpen,
    show,
    url: knowledgeBase.url,
  }
}
