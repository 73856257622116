import * as React from 'react'

import {ContentLayout, Sticky} from '@settleindex/react'

import {SecondaryNav} from '../navigation'

interface Props {
  breadcrumbs?: React.ReactElement[]
  loading?: boolean
  padTop?: number
  testId?: string
}

/**
 * Container for page content that adds navigation and restricts width to
 * `theme/contentWidth`.
 * Not to be used for Version pages as they implement a slightly different
 * layout solution.
 */
export const Content: React.FC<React.PropsWithChildren<Props>> = ({
  breadcrumbs,
  children,
  loading,
  padTop = 60,
  testId,
}) => (
  <>
    <Sticky>
      <SecondaryNav breadcrumbs={breadcrumbs} loading={loading} />
    </Sticky>
    <ContentLayout style={{paddingTop: padTop}} testId={testId}>
      {children}
    </ContentLayout>
  </>
)

Content.displayName = 'PageLayout'
