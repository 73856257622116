import * as React from 'react'
import {useParams} from 'react-router-dom'

import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData} from '@settleindex/react'

import {useDisputeQuery} from '../../graphQLTypes'
import {LoadingIndicatorWithNav} from '../../page'
import {useDisputeContainer} from '../context/useDisputeContainer'
import {DisputeNotFound} from '../DisputeNotFound'
import {disputeNotFoundGraphError} from '../graph/disputeErrorGuards'
import {VersionComparePage} from './VersionComparePage'

const VersionCompareContainer: React.FC = () => {
  const {disputeId} = useParams<{disputeId: string}>()

  const {
    data,
    error: fetchError,
    loading: fetchLoading,
  } = useDisputeQuery({
    variables: {id: disputeId},
  })

  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: data?.dispute})

  const whenAvailable = () => {
    return <VersionComparePage />
  }

  return (
    <LoadingData
      state={{
        available: isDefined(disputeInContext),
        empty: disputeNotFoundGraphError(fetchError),
        error: fetchError && disputeNotFoundGraphError(fetchError),
        loading: fetchLoading,
      }}
      whenAvailable={whenAvailable}
      whenEmpty={<DisputeNotFound />}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicatorWithNav />}
    />
  )
}

// Default export for React.lazy
export default VersionCompareContainer
