import * as React from 'react'
import {atom, useRecoilState} from 'recoil'

import {cond} from '@settleindex/fp'

import {CapListContainer} from '../../cap/list/CapListContainer'
import {ClaimsContainer} from '../../claim/ClaimsContainer'
import {CostsContainer} from '../../cost/costsTable/CostsContainer'
import {VersionVariable} from './VersionVariable'

const selectedVersionVariableState = atom<VersionVariable | null>({
  key: 'selectedVersionVariableState',
  default: null,
})

const defaultCostPartyIdState = atom<string | undefined>({
  key: 'defaultCostPartyIdState',
  default: undefined,
})

export const useVersionVariablesDrawer = () => {
  const [selectedVersionVariable, setSelectedVersionVariable] = useRecoilState(selectedVersionVariableState)
  const [defaultCostPartyId, setDefaultCostPartyId] = useRecoilState(defaultCostPartyIdState)

  const editCosts = (defaultPartyId?: string) => {
    setSelectedVersionVariable('costs')

    if (typeof defaultPartyId === 'string') {
      setDefaultCostPartyId(defaultPartyId)
    } else {
      setDefaultCostPartyId(undefined)
    }
  }
  const editClaims = () => setSelectedVersionVariable('claims')
  const editCaps = () => setSelectedVersionVariable('caps')
  const editNone = () => setSelectedVersionVariable(null)

  const content = React.useMemo(
    () =>
      cond([
        [
          () => selectedVersionVariable === 'costs',
          () => <CostsContainer defaultPartyId={defaultCostPartyId} hasTabPadding={false} />,
        ],
        [() => selectedVersionVariable === 'claims', () => <ClaimsContainer />],
        [() => selectedVersionVariable === 'caps', () => <CapListContainer />],
      ])(),
    [selectedVersionVariable, defaultCostPartyId],
  )

  return {
    content,
    editCaps,
    editClaims,
    editCosts,
    editNone,
    selectedVersionVariable,
    setSelectedVersionVariable,
  }
}
