import styled from 'styled-components'

import {boxShadow1, theme} from '@settleindex/react'

export const Box = styled.div`
  min-width: 244px;
  padding: 16px 22px;
  background-color: ${theme.chart.tooltip.bg};
  color: black;
  box-shadow: ${boxShadow1};
`
