import * as React from 'react'

import {isDefined} from '@settleindex/fp'
import {LoadingData} from '@settleindex/react'

import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {useVersionContainer} from '../../version/context/useVersionContainer'
import {useVersionGraph} from '../../version/graph/useVersionGraph'
import {SettlementAnalysisPage} from './SettlementAnalysisPage'

interface Props {
  disputeId: string
  versionId: string
}

export const SettlementAnalysisContainer: React.FC<Props> = ({disputeId, versionId}) => {
  const {dispute: graphDispute, setSettlementStrategy, version: graphVersion} = useVersionGraph({disputeId, versionId})

  useDisputeContainer({requiredId: disputeId, setDisputeFrom: graphDispute})
  const {version: versionInContext} = useVersionContainer({requiredId: versionId, setVersionFrom: graphVersion})

  const state = React.useMemo(
    () => ({
      available: isDefined(versionInContext),
    }),
    [versionInContext],
  )

  return (
    <LoadingData
      state={state}
      whenAvailable={<SettlementAnalysisPage setSettlementStrategy={setSettlementStrategy} />}
    />
  )
}
