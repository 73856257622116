import dayjs from 'dayjs'

import {IsoDate, Nullable} from '@settleindex/domain'

import {date} from '../../date/date'

export const disputeOpenedDateString = (isoDate: Nullable<IsoDate>): string | undefined => {
  if (!isoDate) {
    return
  }

  const difference = dayjs().diff(dayjs(isoDate), 'day')

  return `${date(isoDate)} (${difference} Days)`
}
