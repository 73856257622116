import {atom} from 'jotai'

const storageKey = 'outcomeDetailsSelectedTabAtom'

const tabAtom = atom(localStorage.getItem(storageKey) ?? 'claims')

export const outcomeDetailsSelectedTabAtom = atom(
  (get) => get(tabAtom),
  (_, set, newKey: string) => {
    set(tabAtom, newKey)
    localStorage.setItem(storageKey, newKey)
  },
)
