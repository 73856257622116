import * as React from 'react'

import {getPath, percentToStr} from '@settleindex/domain'
import {Column, PrimaryText, SecondaryText, Table, TableRow, VSpaceSmall} from '@settleindex/react'
import {outcomesAnalyseTestIds} from '@settleindex/testids/src/testIds'

import {DebugLabel} from '../../debug'
import {SumFormat, SumText} from '../../sum'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {useNodes} from '../context/useNodes'
import {DimmedText} from '../DimmedText'
import {useOutcomeDrawer} from '../drawer/useOutcomeDrawer'
import {nodeNamePrefix} from '../nodeNamePrefix'
import {AnalyseColumn, makeColumns} from './columns'
import {sortOutcomeResultByPresentValue} from './sortOutcomeResultByPresentValue'

interface Props {
  outcomeVisibleThreshold: number
}

export const AnalyseTable: React.FC<Props> = ({outcomeVisibleThreshold}) => {
  const {
    caseValueLabel,
    caseValueSum,
    nodeValueLabel,
    selectedNodePartyId,
    selectedPartyNodes,
    selectedPartyOutcomes,
    valueToDisplay,
  } = useNodes()
  const {version} = useSafeVersion()
  const {openDrawer, setDrawerOutcomeId} = useOutcomeDrawer()

  const nonZeroWeightOutcomes = React.useMemo(
    () => selectedPartyOutcomes.filter((o) => o.absoluteWeight > outcomeVisibleThreshold),
    [outcomeVisibleThreshold, selectedPartyOutcomes],
  )

  const hiddenOutcomesCount = React.useMemo(
    () => selectedPartyOutcomes.length - nonZeroWeightOutcomes.length,
    [nonZeroWeightOutcomes.length, selectedPartyOutcomes.length],
  )

  // Legacy outcomes have no cumulative weights
  const hasCumulativeWeights = version?.cumulativeWeights?.length > 0

  const outcomeRows: TableRow<AnalyseColumn>[] = React.useMemo(
    () =>
      nonZeroWeightOutcomes.sort(sortOutcomeResultByPresentValue).map<TableRow<AnalyseColumn>>((outcome) => {
        const path = getPath({partyNodes: selectedPartyNodes, leafId: outcome.id, prefix: nodeNamePrefix})
        const onEditClick = () => {
          setDrawerOutcomeId(outcome.id)
          openDrawer()
        }

        const cumulativeWeight = !hasCumulativeWeights
          ? null
          : version?.cumulativeWeights?.find((cw) => cw.outcomeId === outcome.id && cw.partyId === selectedNodePartyId)
              ?.cumulativeWeight

        return {
          id: outcome.id,
          cells: {
            name: (
              <Column onClick={onEditClick} style={{marginTop: 8, marginBottom: 8, cursor: 'pointer'}}>
                {path && <DimmedText>{path.join(' / ')}</DimmedText>}
                <div>
                  <PrimaryText semibold>{outcome.name}</PrimaryText>
                </div>
              </Column>
            ),
            absoluteWeight: (
              <DebugLabel title="outcome.absoluteWeight">
                <PrimaryText medium>{percentToStr(outcome.absoluteWeight as number)}</PrimaryText>
              </DebugLabel>
            ),
            cumulativeWeight: (
              <DebugLabel title="version.cumulativeWeights [Graph only]">
                {cumulativeWeight && <PrimaryText medium>{percentToStr(cumulativeWeight)}</PrimaryText>}
              </DebugLabel>
            ),
            value: (
              <DebugLabel title={`outcome.${valueToDisplay}`}>
                <PrimaryText medium>{<SumFormat sum={outcome[valueToDisplay]} />}</PrimaryText>
              </DebugLabel>
            ),
            riskWeightedValue: (
              <DebugLabel title={`outcome.${valueToDisplay}Weighted`}>
                <PrimaryText medium>
                  <SumFormat sum={outcome[`${valueToDisplay}Weighted`]} />
                </PrimaryText>
              </DebugLabel>
            ),
          },
        }
      }),
    [
      nonZeroWeightOutcomes,
      selectedPartyNodes,
      hasCumulativeWeights,
      version,
      valueToDisplay,
      setDrawerOutcomeId,
      openDrawer,
      selectedNodePartyId,
    ],
  )

  const totalRows = React.useMemo<TableRow<AnalyseColumn>[]>(
    () => [
      {
        id: 'total',
        cells: {
          name: <PrimaryText semibold>{caseValueLabel}</PrimaryText>,
          riskWeightedValue: (
            <DebugLabel
              title={`version.case${
                // uppercase first letter
                valueToDisplay.charAt(0).toUpperCase() + valueToDisplay.slice(1)
              } (perspective) or version.opponentValues.${valueToDisplay} (opponent)`}
            >
              <SumText semibold sum={caseValueSum} />
            </DebugLabel>
          ),
        },
      },
    ],
    [caseValueLabel, caseValueSum, valueToDisplay],
  )

  const rows = [...outcomeRows, ...totalRows]

  const columns = React.useMemo(
    () => makeColumns({valueLabel: nodeValueLabel, hasCumulativeWeights}),
    [hasCumulativeWeights, nodeValueLabel],
  )

  return (
    <>
      <Table<AnalyseColumn>
        columns={columns}
        rows={rows}
        stateId="AnalyseLeafOutcomesTable"
        testId={outcomesAnalyseTestIds.analyseTable}
      />
      <VSpaceSmall />
      {hiddenOutcomesCount > 0 ? (
        <SecondaryText>
          <small>{hiddenOutcomesCount} outcomes having ~0% chance hidden </small>
        </SecondaryText>
      ) : (
        <></>
      )}
    </>
  )
}
