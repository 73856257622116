import React from 'react'

import {Label, TableColumn} from '@settleindex/react'

import {Tip} from '../../help'
import {ClaimRecord} from './ClaimRecord'

export const claimColumnWidth = 305
export const typeColumnWidth = 90

export const columns: TableColumn<ClaimRecord>[] = [
  {
    label: (
      <Label>
        <Tip id="Claims.Claim">Claim</Tip>
      </Label>
    ),
    id: 'name',
    align: 'left',
    width: 320,
  },
  {
    id: 'type',
    align: 'center',
  },
  {
    label: 'Amount',
    id: 'amount',
    align: 'right',
  },
  {
    label: (
      <Label>
        <Tip id="Claims.Adjust">Adjust</Tip>
      </Label>
    ),
    id: 'interest',
    align: 'right',
  },
  {
    label: 'Total',
    id: 'adjusted',
    align: 'right',
  },
  {
    align: 'center',
    id: 'actions',
    width: 32,
  },
]
