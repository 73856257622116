import * as React from 'react'

import {isDefined} from '@settleindex/fp'
import {LoadingData, message} from '@settleindex/react'

import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {useDisputeTeam} from '../../disputeTeam/state/useDisputeTeam'
import {
  DisputeFragment,
  useArchiveVersionMutation,
  useDuplicateVersionMutation,
  useSetVersionTitleMutation,
  useVersionOfDisputeQuery,
  VersionPatchInput,
} from '../../graphQLTypes'
import {paths, useHistory} from '../../routing'
import {useAuthenticatedUser} from '../../user/useUser/useAuthenticatedUser'
import {useVersionContainer} from '../context/useVersionContainer'
import {usePatchVersion} from '../graph/usePatchVersion'
import {VersionSettings} from './VersionSettings'

interface Props {
  disputeId: string
  versionId: string
}

export const VersionSettingsContainer: React.FC<Props> = ({disputeId, versionId}) => {
  const {gotoTop, history} = useHistory()

  const {runAsync} = useAppIsLoading()

  const {
    user: {id: userId},
  } = useAuthenticatedUser()

  const {data, refetch} = useVersionOfDisputeQuery({
    variables: {disputeId, versionId},
  })
  const [versionTitleMutation] = useSetVersionTitleMutation()
  const [duplicateVersionMutation] = useDuplicateVersionMutation()
  const [archiveVersionMutation] = useArchiveVersionMutation()
  const patchVersion = usePatchVersion()

  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: data?.dispute})
  const {version: versionInContext} = useVersionContainer({
    requiredId: versionId,
    setVersionFrom: data?.dispute?.version,
  })
  useDisputeTeam({setDisputeTeamFrom: data?.disputeTeam})

  const whenAvailable = () => {
    const dispute = disputeInContext as DisputeFragment

    const onPatchVersion = async (_: string, patch: VersionPatchInput) => {
      await patchVersion(versionId, patch)
      return refetch && runAsync(() => refetch())
    }

    const archiveThenGoToDispute = async () => {
      try {
        await runAsync(() => archiveVersionMutation({variables: {disputeId, versionId}}))
        history.push(paths.dispute(disputeId))
      } catch (archiveError) {
        message.error('Please try again')

        throw archiveError
      }
    }

    const duplicateVersion = async () => {
      try {
        await runAsync(async () => {
          const mutationResponse = await duplicateVersionMutation({variables: {disputeId, versionId}})
          message.success('Model Duplicated')

          const newVersion = mutationResponse.data?.duplicateVersion.versions[0]
          newVersion && gotoTop(paths.version(disputeId, newVersion.id))
        })
      } catch (duplicateError) {
        message.error('Please try again')

        throw duplicateError
      }
    }

    const setVersionTitle = (title: string) => runAsync(() => versionTitleMutation({variables: {versionId, title}}))

    return (
      <VersionSettings
        archiveVersion={archiveThenGoToDispute}
        duplicateVersion={duplicateVersion}
        patchVersion={onPatchVersion}
        perspectiveRole={dispute.perspectiveRole}
        setVersionTitle={setVersionTitle}
        userId={userId}
      />
    )
  }

  return (
    <LoadingData
      state={{
        available: isDefined(versionInContext),
      }}
      whenAvailable={whenAvailable}
    />
  )
}

VersionSettingsContainer.displayName = 'VersionSettingsContainer'
