import {colors} from '@settleindex/react'

import {allValueKeys} from './MetricData'

export const chartBarColors = {
  [allValueKeys[0]]: colors.green,
  [allValueKeys[1]]: colors.grape,
  [allValueKeys[2]]: colors.flower,
  [allValueKeys[3]]: colors.raspberry,
}
