import * as React from 'react'

import {Col, PrimaryText, Row} from '@settleindex/react'

import {gutter, nameSpan, weightSpan} from '../columnWidths'

interface ExtraLabelProps {
  label: string
  span?: number
  spanBefore?: number
}

/**
 * ExtraLabel is a second line of text above normal form labels. Can be used to
 * add a common label above "our view" and "their view" columns
 */
export const ExtraLabel: React.FC<ExtraLabelProps> = ({label, span = weightSpan * 2, spanBefore = nameSpan}) => {
  return (
    <Row align="top" gutter={gutter}>
      <Col span={spanBefore} />
      <Col span={span} style={{textAlign: 'left'}}>
        <PrimaryText>{label}</PrimaryText>
      </Col>
    </Row>
  )
}
