import * as React from 'react'

import {NoData, PrimaryButton} from '@settleindex/react'

interface Props {
  onAddEvent: () => void
}

export const NoEvents: React.FC<Props> = ({onAddEvent}) => (
  <NoData
    button={
      <PrimaryButton data-test-id={`add-event`} medium onClick={onAddEvent}>
        Add Offer
      </PrimaryButton>
    }
    heading={'No Offers'}
    text={'Record offers made and recieved here.'}
  />
)
