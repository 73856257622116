import React from 'react'

import {cond} from '@settleindex/fp'
import {Tag} from '@settleindex/react/lib/src'

/**
 * Shows a warning label if the isValid is false, a normal label if it is true
 */
export const warnLabel = (labelText: string, isValid: boolean, index: number) =>
  cond([
    [() => index > 0, () => ''],
    [() => !isValid, () => <Tag color={'amber'}>{labelText}</Tag>],
    [() => isValid, () => labelText],
  ])()
