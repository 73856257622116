import {useAtom} from 'jotai'
import * as React from 'react'
import styled from 'styled-components'

import {notCombinedDefendantParty, partyPerspectiveFirst} from '@settleindex/domain'
import {getPath} from '@settleindex/domain/lib/src/nodes/tree/getPath'
import {noop} from '@settleindex/fp'
import {ContentWidth, H2, TabPane, Tabs, VSpace, VSpaceLarge, VSpaceMedium, VSpaceSmall} from '@settleindex/react'

import {CapDropdown} from '../../cap/select/CapDropdown'
import {CapsEmpty} from '../../cap/select/CapsEmpty'
import {DisputeFragment, VersionFragment} from '../../graphQLTypes'
import {AppOutcome} from '../AppOutcome'
import {useNodes} from '../context/useNodes'
import {DimmedText} from '../DimmedText'
import {nodeNamePrefix} from '../nodeNamePrefix'
import {ClaimGroupsTable} from './claims/ClaimGroupsTable'
import {ClaimGroupsTableEmpty} from './claims/ClaimGroupsTableEmpty'
import {CostRecoveryTable} from './costs/CostRecoveryTable'
import {CostRecoveryTableEmpty} from './costs/CostRecoveryTableEmpty'
import {outcomeDetailsSelectedTabAtom} from './outcomeDetailsSelectedTabAtom'
import {Padded} from './Padded'
import {makePayReceiveTag} from './payReceiveTag/makePayReceiveTag'
import {Footer} from './totals/Footer'
import {OutcomeTotalsTable} from './totals/OutcomeTotalsTable'

interface Props {
  dispute: DisputeFragment
  outcome: AppOutcome
  readonly: boolean
  version: VersionFragment
}

/**
 * Outcome details rendered in the sidebar/drawer
 */
export const OutcomeDetails: React.FC<Props> = ({dispute, outcome, readonly, version}) => {
  const [selectedTab, setSelectedTab] = useAtom(outcomeDetailsSelectedTabAtom)
  const {selectedPartyNodes, selectedPartyRole} = useNodes()
  const payReceiveTag = React.useMemo(() => makePayReceiveTag(selectedPartyRole), [selectedPartyRole])
  const {costGroups} = version
  const partiesWithoutCombinedDefendant = React.useMemo(
    () => dispute.parties.filter(notCombinedDefendantParty),
    [dispute.parties],
  )
  const sortedParties = React.useMemo(
    () => [...partiesWithoutCombinedDefendant].sort(partyPerspectiveFirst(dispute.perspectiveId)),
    [partiesWithoutCombinedDefendant, dispute.perspectiveId],
  )

  const path = getPath({partyNodes: selectedPartyNodes, leafId: outcome.id, prefix: nodeNamePrefix})

  return (
    <Page>
      <Padded>
        {path && (
          <>
            <DimmedText>{path.join(' / ')}</DimmedText>
            <VSpaceSmall />
          </>
        )}

        <H2>{outcome.name}</H2>
        <VSpace space={10} />
      </Padded>

      <VSpaceSmall />

      {
        <>
          <Content>
            <Tabs defaultActiveKey={selectedTab} onChange={setSelectedTab} tabBarStyle={{marginLeft: 40}}>
              <TabPane key="claims" tab="Claims Award">
                <VSpaceSmall />
                <ContentWidth>
                  {version.claimGroups.length ? (
                    <ClaimGroupsTable
                      outcome={outcome}
                      payReceiveTag={payReceiveTag}
                      readonly={readonly}
                      version={version}
                    />
                  ) : (
                    <ClaimGroupsTableEmpty readonly={readonly} />
                  )}
                </ContentWidth>
              </TabPane>

              <TabPane key="costRecovery" tab="Costs Award">
                <VSpaceSmall />
                <ContentWidth>
                  <>
                    {costGroups.length ? (
                      <CostRecoveryTable
                        outcome={outcome}
                        parties={sortedParties}
                        payReceiveTag={payReceiveTag}
                        readonly={readonly}
                      />
                    ) : (
                      <CostRecoveryTableEmpty readonly={readonly} />
                    )}
                  </>
                </ContentWidth>
              </TabPane>

              <TabPane key="caps" tab="Caps/Limits">
                <VSpaceSmall />
                <ContentWidth>
                  {version.caps.length ? (
                    <CapDropdown
                      caps={version.caps}
                      defaultValue={outcome.capId ?? ''}
                      onChange={noop}
                      parties={partiesWithoutCombinedDefendant}
                      readonly
                    />
                  ) : (
                    <CapsEmpty readonly={readonly} />
                  )}
                </ContentWidth>
              </TabPane>
            </Tabs>

            <VSpaceMedium />
          </Content>

          <Footer>
            <VSpaceMedium />
            <Padded>
              <OutcomeTotalsTable outcome={outcome} payReceiveTag={payReceiveTag} />
            </Padded>
            <VSpaceLarge />
          </Footer>
        </>
      }
    </Page>
  )
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const Content = styled.div`
  flex: 1;
`
