import styled from 'styled-components'

import {Tag as BaseTag} from '@settleindex/react'

export const Tag = styled(BaseTag)`
  font-size: 11px;
  width: 90px;

  & svg {
    margin-right: 10px;
  }
`
