import * as React from 'react'
import {Handle, Position} from 'react-flow-renderer'
import styled from 'styled-components'

import {colors} from '@settleindex/react'

export const startNodeWidth = 22
export const startNodeHeight = 22
export const startNodeType = 'startNodeType'

const Layout = styled.div`
  width: ${startNodeWidth}px;
  height: ${startNodeHeight}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Circle = styled.div`
  width: ${22}px;
  height: ${22}px;
  background-color: ${colors.base6};
  border-radius: 50%;
`

export const StartNode: React.FC = () => {
  return (
    <Layout>
      <Circle>
        <Handle position={Position.Right} style={{}} type="source" />
      </Circle>
    </Layout>
  )
}
