import * as React from 'react'

import {defaultTo} from '@settleindex/fp'
import {KeyValue, KeyValueBox} from '@settleindex/react'

import {DisputeFragment} from '../../graphQLTypes'
import {disputeOpenedDateString} from './disputeOpenedDateString'

interface Props {
  dispute: DisputeFragment
}

const defaultToDash = defaultTo('-')

export const CaseInformationBox: React.FC<Props> = ({dispute}) => {
  const caseInformationBoxData: KeyValue[] = [
    {label: 'Client/Perspective', value: defaultToDash(dispute.perspective.label)},
    {label: 'Law Firm', value: defaultToDash(dispute.perspective.lawFirm)},
    {label: 'Lead', value: defaultToDash(dispute.perspective.leadSolicitor)},
    {label: 'Opened', value: defaultToDash(disputeOpenedDateString(dispute.disputeStartedOn))},
    {
      label: 'Description',
      value: defaultToDash(dispute.description?.length ? dispute.description : undefined),
      longValue: !!dispute.description,
    },
  ]

  return <KeyValueBox data={caseInformationBoxData} grow span={12} title="Case Information" />
}
