import * as React from 'react'

import {Drawer} from '@settleindex/react'

import {DrawerBody} from './DrawerBody'
import {DrawerHeader} from './DrawerHeader'
import {DrawerInfo} from './DrawerInfo'

const defaultWidth = 980

interface Props {
  headerTitle?: string
  infoLine?: string
  isDrawerVisible: boolean
  /**
   * Whether the mask is visible or not? It is always used so users can click outside the drawer to close it.
   * But it's not always masking the content behind it.
   */
  mask?: boolean
  onClose: () => void
  placement?: 'left' | 'right'
  showRoleTag?: boolean
  width?: number
}

export const DrawerFrame: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  headerTitle,
  infoLine,
  isDrawerVisible,
  mask = true,
  onClose,
  placement = 'right',
  showRoleTag,
  width = defaultWidth,
}) => {
  const maskStyle = React.useMemo(() => ({opacity: mask ? 1 : 0}), [mask])

  return (
    <Drawer
      bodyStyle={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 999,
      }}
      closable={false}
      destroyOnClose
      maskClosable
      maskStyle={maskStyle}
      onClose={onClose}
      open={isDrawerVisible}
      placement={placement}
      width={width}
    >
      <>
        <DrawerHeader onDone={onClose} showRoleTag={showRoleTag} title={headerTitle} />
        {infoLine && <DrawerInfo info={infoLine} />}
        <DrawerBody>{children}</DrawerBody>
      </>
    </Drawer>
  )
}
