import React from 'react'

import {cond} from '@settleindex/fp'
import {Popconfirm, TertiaryButton} from '@settleindex/react'
import {versionSettingsTestIds} from '@settleindex/testids/src/testIds'

import {AccessKind, Lifecycle, VersionOverviewFragment, VersionPatchInput} from '../../graphQLTypes'
import {Tip} from '../../help/Tip'
import {DuplicateButton} from '../widgets/DuplicateButton'

interface Args {
  deleteVersion: (versionId: string) => void
  duplicateVersion: (versionId: string) => void
  patchVersion: (versionId: string, patch: VersionPatchInput) => void
  userDisputeTeamAccessKind?: AccessKind
  userIsVersionOwnerOrAdminMember: boolean
  version: VersionOverviewFragment
}

export const getActionButtons = ({
  deleteVersion,
  duplicateVersion,
  patchVersion,
  userDisputeTeamAccessKind,
  userIsVersionOwnerOrAdminMember,
  version,
}: Args) => {
  const makeReportButton = (
    <Tip id="Model.PublishButton">
      <TertiaryButton
        data-test-id={versionSettingsTestIds.makeReportButton}
        onClick={() => patchVersion(version.id, {lifecycle: Lifecycle.Report})}
        style={{marginLeft: 10}}
      >
        Publish
      </TertiaryButton>
    </Tip>
  )

  const makeDraftButton = (
    <Tip id="Model.DraftButton">
      <TertiaryButton
        data-test-id={versionSettingsTestIds.makeDraftButton}
        onClick={() => patchVersion(version.id, {lifecycle: Lifecycle.Draft})}
        style={{marginLeft: 10}}
      >
        Unpublish
      </TertiaryButton>
    </Tip>
  )

  const deleteButton = (
    <Tip id="Model.ArchiveButton" placement="topLeft">
      <Popconfirm
        cancelText="No"
        okText="Yes"
        onConfirm={() => deleteVersion(version.id)}
        placement="left"
        title="Are you sure you want to delete this model?"
      >
        <TertiaryButton data-test-id={versionSettingsTestIds.deleteButton} style={{marginLeft: 10}}>
          Delete
        </TertiaryButton>
      </Popconfirm>
    </Tip>
  )

  const duplicateButton = (
    <DuplicateButton onClick={() => duplicateVersion(version.id)} testId={versionSettingsTestIds.duplicateButton} />
  )

  if (version.variant === 'PRE_CASEBOT') {
    return [duplicateButton]
  }

  return cond([
    [
      () => version.variant === 'PRE_CASEBOT',
      () => (
        <>
          {duplicateButton} {deleteButton}
        </>
      ),
    ],
    [() => !userDisputeTeamAccessKind || userDisputeTeamAccessKind === AccessKind.EXTERNAL_TEAM_MEMBER, () => <></>],
    [() => !userIsVersionOwnerOrAdminMember, () => <>{duplicateButton}</>],
    [
      () => !version.readonly,
      () => (
        <>
          {makeReportButton} {duplicateButton} {deleteButton}
        </>
      ),
    ],
    [
      () => version.readonly,
      () => (
        <>
          {makeDraftButton} {duplicateButton} {deleteButton}
        </>
      ),
    ],
  ])()
}
