import {BarTooltipProps} from '@nivo/bar'
import * as React from 'react'
import styled from 'styled-components'

import {boxShadow1, Circle, HSpace, PrimaryText} from '@settleindex/react'

import {SumText} from '../../../sum'
import {chartBarColors} from './chartBarColors'
import {MetricData} from './MetricData'

export const Tooltip: React.FC<BarTooltipProps<MetricData>> = (props) => {
  const {data, id, value} = props

  const versionTitle = data[`${id}Title`] ?? 'Weighted Model'

  return (
    <Bar>
      <Circle color={chartBarColors[id]} />
      <HSpace space={10} />
      <PrimaryText>{versionTitle}:</PrimaryText>
      <HSpace space={8} />
      <SumText bold sum={value} />
    </Bar>
  )
}

const Bar = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  background-color: white;
  padding: 8px 10px;
  box-shadow: ${boxShadow1};
`
