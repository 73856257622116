import styled from 'styled-components'

import {primaryTextStyle, semiBoldWeight} from '@settleindex/react'

import {opponentColor, perspectiveColor} from '../party/perspectiveAwareColors'
import {Input} from './edge/Input'
import {MetricsSummaryLayout} from './MetricsSummary'

const toolsBottomMargin = 40
const controlsLeftMargin = 60
const metricsSummaryLeftMargin = controlsLeftMargin + 60

interface DecisionTreeStyleProps {
  edgeColor: string
  isPerspective: boolean
}

export const DecisionTreeStyle = styled.div<DecisionTreeStyleProps>`
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // !!! Height must be set in style prop to avoid 0px high component!
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  & .react-flow__node {
    cursor: default;
  }

  & .react-flow__handle {
    background: ${(p: DecisionTreeStyleProps) => p.edgeColor};
    border: none;
    height: 1px;
    width: 4px;
  }

  & .react-flow__edge-text {
    ${primaryTextStyle};
    font-weight: ${semiBoldWeight};
  }

  & .react-flow__edge-path {
    stroke: ${(p: DecisionTreeStyleProps) => p.edgeColor};
  }

  & .react-flow__controls {
    left: ${controlsLeftMargin}px;
    bottom: ${toolsBottomMargin}px;
    box-shadow: none;
  }

  & .react-flow__controls-button {
    width: 32px;
    height: 32px;

    padding: 0;
    margin-top: 10px;

    box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
  }

  & ${MetricsSummaryLayout} {
    position: absolute;
    left: ${metricsSummaryLeftMargin}px;
    bottom: ${toolsBottomMargin}px;
  }

  & {
    ${Input} {
      border-color: ${(p: DecisionTreeStyleProps) => (p.isPerspective ? perspectiveColor : opponentColor)}!important;

      * {
        color: ${(p: DecisionTreeStyleProps) => (p.isPerspective ? perspectiveColor : opponentColor)}!important;
      }
    }
  }
`
