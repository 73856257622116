import * as React from 'react'
import styled from 'styled-components'

import {colors, HSpaceSmall, InfoIcon, SecondaryText} from '@settleindex/react'

import {HeaderStyle} from './HeaderStyle'

interface Props {
  info?: string
}

export const DrawerInfo: React.FC<Props> = ({info}) => (
  <Bar>
    <InfoIcon /> <HSpaceSmall />{' '}
    <SecondaryText medium style={{color: colors.raspberry}}>
      {info}{' '}
    </SecondaryText>
  </Bar>
)

const Bar = styled(HeaderStyle)`
  color: ${colors.raspberry};
  background-color: ${colors.redLight};
`
