import * as React from 'react'

import {HistoryMetrics} from '@settleindex/domain'
import {filterArray, path, tail} from '@settleindex/fp'
import {PrimaryText, TableColumn} from '@settleindex/react'

import {DisputeHistoryPointFragment, Role} from '../../../graphQLTypes'
import {PercentChange} from '../../../percent/PercentChange'
import {SumFormat} from '../../../sum'
import {isVersionHistoryPoint} from '../disputeHistoryFilters'
import {MetricsSelector} from '../metricsSelector/MetricsSelector'
import {MetricVisuals, metricVisuals} from '../metricVisuals'
import {getChangeColorCoding} from './getChangeColorCoding'

type Cols = 'latestValue' | 'changeFromPrevious' | 'changeFromFirst'

interface Props {
  checkedMetrics: HistoryMetrics[]
  history: DisputeHistoryPointFragment[]
  nonInteractive: boolean
  onCheckboxClick: (metricPath: HistoryMetrics, isChecked: boolean) => void
  perspectiveRole: Role
}

export const DisputeHistoryMetricsSelector: React.FC<Props> = ({
  checkedMetrics = [],
  history,
  nonInteractive,
  onCheckboxClick,
  perspectiveRole,
}) => {
  const extraColumns: TableColumn<Cols>[] = React.useMemo(
    () => [
      {id: 'latestValue', align: 'right', label: 'Value', width: 150},
      {id: 'changeFromPrevious', align: 'right', label: 'From Last', width: 100},
      {id: 'changeFromFirst', align: 'right', label: 'From First', width: 100},
    ],
    [],
  )

  const latestVersionHistoryPoint = React.useMemo(() => tail(filterArray(isVersionHistoryPoint, history)), [history])
  const comparisonList = latestVersionHistoryPoint?.versionComparison

  const makeExtraCells = React.useCallback(
    (metricPath: HistoryMetrics, visualConfig: MetricVisuals) => {
      const latestValue = path<DisputeHistoryPointFragment, number>(metricPath, latestVersionHistoryPoint)
      const change = comparisonList?.find((c) => c.path === metricPath)
      const fromPreviousColor = visualConfig.coding
        ? getChangeColorCoding(visualConfig.coding, perspectiveRole, change?.changeFromPrevious)
        : ''
      const fromFirstColor = visualConfig.coding
        ? getChangeColorCoding(visualConfig.coding, perspectiveRole, change?.changeFromFirst)
        : ''
      return visualConfig.showValues
        ? {
            latestValue: (
              <PrimaryText semibold>
                <SumFormat placeholder="-" sum={latestValue} />
              </PrimaryText>
            ),
            changeFromPrevious: <PercentChange color={fromPreviousColor} percent={change?.changeFromPrevious} />,
            changeFromFirst: <PercentChange color={fromFirstColor} percent={change?.changeFromFirst} />,
          }
        : {}
    },
    [latestVersionHistoryPoint, comparisonList, perspectiveRole],
  )

  return (
    <MetricsSelector
      checkedMetrics={checkedMetrics}
      dataTableStateId="DisputeHistoryMetricsSelector"
      extraColumns={extraColumns}
      makeExtraCells={makeExtraCells}
      metricsToShow={metricVisuals}
      nonInteractive={nonInteractive}
      onCheckboxClick={onCheckboxClick}
    />
  )
}
