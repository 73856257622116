import {atom} from 'recoil'

import {Nullable} from '@settleindex/domain'

import {DisputeTeamFragment} from '../../graphQLTypes'

export const disputeTeamAtom = atom<Nullable<DisputeTeamFragment>>({
  key: 'disputeTeamAtom',
  default: undefined,
})
