import styled from 'styled-components'

import {collapsedPrimaryNavWidth, expandedPrimaryNavWidth, theme, usualTransition} from '@settleindex/react'

import {LabelCol} from './MenuSection'

interface Props {
  expanded: boolean
}

export const PrimaryNavLayout = styled.div<Props>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;

  width: ${(props: Props) => (props.expanded ? expandedPrimaryNavWidth : collapsedPrimaryNavWidth)}px;
  height: 100vh;
  padding-top: 21px;

  z-index: 10;
  overflow: hidden;
  transition: width ${usualTransition};

  background-color: ${theme.primaryNav.bgColor};

  & ${LabelCol} {
    opacity: ${(props: Props) => (props.expanded ? 1 : 0)};
    transition: opacity ${usualTransition};
  }

  & a,
  & span {
    color: ${theme.primaryNav.color};
  }

  & a:hover {
    color: ${theme.primaryNav.hover};
  }
`
