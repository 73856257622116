import * as React from 'react'

import {FormItem, TextInput} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {partyFieldNames} from './partyFieldNames'

export const PartyLawFirm: React.FC<{}> = () => (
  <FormItem label="Representing Law Firm" markAs="optional" name={partyFieldNames.lawFirm} rules={[{required: false}]}>
    <TextInput data-test-id={disputeFormTestIds.editPartyLawFirm} placeholder="" />
  </FormItem>
)
