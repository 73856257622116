import * as React from 'react'
import styled from 'styled-components'

import {CloseIcon, colors, HSpace, PrimaryText, usualTransition, VSpace} from '@settleindex/react'

import {DebugLabel} from '../debug'
import {HelpTooltip} from './HelpTooltip'
import {useHelp} from './useHelp'

interface Props {
  id: string
  /**
   * The main title: a text or element to display to the left of the help icon.
   * If a string, then it is rendered as PrimaryText
   **/
  left?: string | React.ReactElement
  /**
   * When leftId is provided, it will be looked up from the help system and used as the "left" title.
   * In this case, the `left` property is used as a fallback / default value for the title (while the help is being
   * fetched).
   * Rendered as PrimaryText.
   */
  leftId?: string
  margin?: {
    bottom?: string | number
    left?: string | number
    right?: string | number
    top?: string | number
  }
}

export const HelpTitleWithTooltip: React.FC<Props> = ({id, left, leftId, margin = {bottom: 20}}) => {
  const [boxOpen, setBoxOpen] = React.useState<boolean>(false)

  const {helpFor} = useHelp()
  const allHelp = React.useMemo(() => helpFor(id), [helpFor, id])
  const hasHelp = React.useMemo(() => allHelp && allHelp.length > 0, [allHelp])

  const openBox = React.useCallback(() => setBoxOpen(true), [])
  const closeBox = React.useCallback(() => setBoxOpen(false), [])
  const toggleBox = React.useCallback(() => setBoxOpen(!boxOpen), [boxOpen])

  const fullText = helpFor(id)?.map((t, i) => (
    <p key={i}>
      <PrimaryText>{t}</PrimaryText>
    </p>
  ))

  const tooltip = <HelpTooltip id={id} onOpenHelp={openBox} onToggleHelp={toggleBox} />

  const titleToShow = React.useMemo(() => {
    const titleById = leftId ? helpFor(leftId) : undefined
    if (titleById) {
      return <PrimaryText>{titleById}</PrimaryText>
    }

    return typeof left === 'string' ? <PrimaryText>{left}</PrimaryText> : left
  }, [helpFor, left, leftId])

  return (
    <>
      <div style={{display: 'flex'}}>
        <DebugLabel fallbackDebugContent={id} placement="bottomRight" title={id}>
          {titleToShow}
        </DebugLabel>
        {hasHelp ? (
          <>
            <HSpace inline space={10} />
            {tooltip}
          </>
        ) : (
          <></>
        )}
      </div>
      {boxOpen ? <VSpace space={10} /> : <></>}
      <FullTextBox
        open={boxOpen}
        style={{
          marginTop: boxOpen && margin?.top,
          marginBottom: boxOpen && margin.bottom,
          marginLeft: boxOpen && margin?.left,
          marginRight: boxOpen && margin?.right,
        }}
      >
        {fullText}
        <CloseBox onClick={closeBox}>
          <CloseIcon />
        </CloseBox>
      </FullTextBox>
    </>
  )
}

interface FullTextBoxProps {
  open: boolean
}

const FullTextBox = styled.div<FullTextBoxProps>`
  position: relative;
  border: 1px solid ${colors.portage};
  border-radius: 4px;
  background-color: ${colors.base10};
  padding: 10px 30px 0 10px;

  opacity: ${({open}: FullTextBoxProps) => (open ? 1 : 0)};
  transition: ${({open}: FullTextBoxProps) => (open ? usualTransition : 'none')};
  height: ${({open}: FullTextBoxProps) => (open ? 'auto' : 0)};
  overflow: hidden;
`

const CloseBox = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 11px;
  color: ${colors.portage};
  /* Make top and right padding look the same */
  padding-right: 3px;
`
