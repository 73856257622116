import * as React from 'react'

import {HistoryMetrics} from '@settleindex/domain'
import {adjust, defaultTo, ifDefined, lensPath, path, set, sum} from '@settleindex/fp'

import {DisputeHistoryPointFragment} from '../../graphQLTypes'
import {useSafeDispute} from '../context/useSafeDispute'
import {isVersionHistoryPoint} from '../history/disputeHistoryFilters'
import {CompareMode} from './CompareMode'
import {MaybeHistoryPoint, MaybeHistoryPoints} from './MaybeHistoryPoint'
import {ThreeNumbers} from './ThreeNumbers'

const defaultTo0 = defaultTo(0)

export const useVersionCompare = () => {
  const {dispute} = useSafeDispute()
  const {history} = dispute
  const allHistoryPoints: DisputeHistoryPointFragment[] = history

  const [compareMode, setCompareMode] = React.useState<CompareMode>('absolute')

  const [isWeightEnabled, setIsWeightEnabled] = React.useState<boolean>(false)

  const versionHistoryPoints = React.useMemo(() => allHistoryPoints.filter(isVersionHistoryPoint), [allHistoryPoints])

  const [selectedHistoryIndexes, setSelectedHistoryIndexes] = React.useState<ThreeNumbers>([
    ifDefined(versionHistoryPoints[0], 0, null),
    ifDefined(versionHistoryPoints[1], 1, null),
    ifDefined(versionHistoryPoints[2], 2, null),
  ])

  const selectedVersionHistoryPoints: MaybeHistoryPoints = React.useMemo(
    () => selectedHistoryIndexes.map((index) => ifDefined(index, versionHistoryPoints[index as number], undefined)),
    [selectedHistoryIndexes, versionHistoryPoints],
  )

  const [modelWeights, setModelWeights] = React.useState<number[]>([0.34, 0.33, 0.33])
  const totalWeight = React.useMemo(() => sum(modelWeights), [modelWeights])

  const onModelWeightChange = React.useCallback(
    (index: number, value: number) => {
      setModelWeights(adjust(index, () => value, modelWeights))
    },
    [modelWeights],
  )

  const weightedVersionHistoryPoints: MaybeHistoryPoints = React.useMemo(
    () =>
      selectedVersionHistoryPoints.map((versionHistoryPoint, index) => {
        if (!versionHistoryPoint) {
          return
        }

        const weight = isWeightEnabled ? modelWeights[index] : 1

        let weightedHistoryPoint = {...versionHistoryPoint}

        Object.keys(HistoryMetrics).forEach((metricPath) => {
          const originalValue = path<MaybeHistoryPoint, number>(metricPath, versionHistoryPoint)
          const weightedValue = (originalValue ?? 0) * weight
          weightedHistoryPoint = set(lensPath(metricPath), weightedValue, weightedHistoryPoint)
        })

        return weightedHistoryPoint
      }),
    [isWeightEnabled, modelWeights, selectedVersionHistoryPoints],
  )

  const differenceHistoryPoints: MaybeHistoryPoints = React.useMemo(
    () =>
      versionHistoryPoints.map((point, index) => {
        if (index === 0) {
          return point
        }

        let differencePoint = {...point} as DisputeHistoryPointFragment

        Object.keys(HistoryMetrics).forEach((metricPath) => {
          const difference =
            defaultTo0(path(metricPath, versionHistoryPoints?.[index])) -
            defaultTo0(path(metricPath, versionHistoryPoints?.[0]))

          differencePoint = set(lensPath(metricPath), difference, differencePoint)
        })

        return differencePoint
      }),
    [versionHistoryPoints],
  )

  const weightedTotalHistoryPoint: DisputeHistoryPointFragment = React.useMemo(() => {
    let weightedTotal = {...selectedVersionHistoryPoints[0]} as DisputeHistoryPointFragment

    Object.keys(HistoryMetrics).forEach((metricPath) => {
      const weightedValue =
        defaultTo0(path(metricPath, weightedVersionHistoryPoints?.[0])) +
        defaultTo0(path(metricPath, weightedVersionHistoryPoints?.[1])) +
        defaultTo0(path(metricPath, weightedVersionHistoryPoints?.[2]))

      weightedTotal = set(lensPath(metricPath), weightedValue, weightedTotal)
    })

    return weightedTotal
  }, [selectedVersionHistoryPoints, weightedVersionHistoryPoints])

  const weightedDifferenceHistoryPoint: DisputeHistoryPointFragment = React.useMemo(() => {
    let differencePoint = {...weightedTotalHistoryPoint}

    Object.keys(HistoryMetrics).forEach((metricPath) => {
      const difference =
        defaultTo0(path(metricPath, weightedTotalHistoryPoint)) - defaultTo0(path(metricPath, versionHistoryPoints[0]))

      differencePoint = set(lensPath(metricPath), difference, differencePoint)
    })

    return differencePoint
  }, [versionHistoryPoints, weightedTotalHistoryPoint])

  return {
    compareMode,
    differenceHistoryPoints,
    isWeightEnabled,
    modelWeights,
    onModelWeightChange,
    selectedHistoryIndexes,
    selectedVersionHistoryPoints,
    setCompareMode,
    setIsWeightEnabled,
    setSelectedHistoryIndexes,
    totalWeight,
    versionHistoryPoints,
    weightedDifferenceHistoryPoint,
    weightedTotalHistoryPoint,
  }
}
