import * as React from 'react'

import {CaseBotDamage, percentToStr} from '@settleindex/domain'
import {
  CenterLeftLine,
  Col,
  Form,
  FormItem,
  H3,
  HSpace,
  PercentFormInput,
  PrimaryText,
  Row,
  Switch,
  Tag,
  TertiaryButton,
  Tooltip,
  VSpaceLarge,
  VSpaceSmall,
} from '@settleindex/react'

import {DebugFormItem} from '../debug'
import {Hint} from '../help'
import {HelpTitleWithTooltip} from '../help/HelpTitleWithTooltip'
import {useVersionVariablesDrawer} from '../nodes/versionVariablesDrawer/useVersionVariablesDrawer'
import {PerspectiveAwarePercentInput} from '../party/perspectiveAwareColors/PerspectiveAwarePercentInput'
import {PerspectiveAwareText} from '../party/perspectiveAwareColors/PerspectiveAwareText'
import {useSafeVersion} from '../version/context/useSafeVersion'
import {gutter, nameSpan, weightSpan} from './columnWidths'
import {CaseBotFormValues} from './form/CaseBotFormValues'
import {fakeValueToForceFormChange} from './graph/convertCasebotToGraph'
import {useCaseBot} from './useCaseBot'

interface ClaimDamageProportionTableProps {}

/**
 * Awards Range Refinements
 */
export const ClaimDamageProportionTable: React.FC<ClaimDamageProportionTableProps> = () => {
  const form = Form.useFormInstance()
  const damages: CaseBotDamage[] = Form.useWatch('damages', form)

  const {version} = useSafeVersion()
  const {editClaims} = useVersionVariablesDrawer()
  const {caseBotFormValues, cumulativeClaimDamageProportions, setCaseBotFormValues} = useCaseBot()

  // We're not proud of this one, but it works
  const onSwitch = React.useCallback(
    (claimGroupId: string) => (checked: boolean) => {
      let newValue: any

      if (!checked) {
        newValue = null
      } else {
        newValue = damages.map((damage) => ({
          damageId: damage.id,
          itemId: claimGroupId,
          proportion: damage.proportion,
        }))
      }

      const newCDP = {
        ...(caseBotFormValues?.claimDamageProportionsByItemId ?? {}),
        [claimGroupId]: newValue,
      }

      setCaseBotFormValues({
        ...(caseBotFormValues as CaseBotFormValues),
        claimDamageProportionsByItemId: newCDP,
      })

      form.setFieldValue(['claimDamageProportionsByItemId', claimGroupId], newValue)
    },
    [caseBotFormValues, damages, form, setCaseBotFormValues],
  )

  const claimGroups = React.useMemo(
    () =>
      version.claimGroups.map((claimGroup, index) => {
        const hasCustomProportions = form.getFieldValue(['claimDamageProportionsByItemId', claimGroup.id])?.length > 0

        return (
          <span key={claimGroup.id}>
            {index > 0 ? <VSpaceSmall /> : <></>}
            <div key={claimGroup.id}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {/* Using a fake value path to ensure the value change cycle kicks in and the remote service is called*/}
                <Form.Item
                  label="Switch"
                  name={[fakeValueToForceFormChange, 'customDamage', claimGroup.id]}
                  noStyle
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={hasCustomProportions}
                    onChange={onSwitch(claimGroup.id)}
                    style={{marginRight: 15}}
                  />
                </Form.Item>
                <H3 noMargin style={{display: 'inline-block', marginRight: 10}}>
                  {claimGroup.name}
                </H3>
              </div>
            </div>

            <Form.List name={['claimDamageProportionsByItemId', claimGroup.id]}>
              {(claimDamageProportions) => {
                const spacer = claimDamageProportions.length ? <VSpaceSmall /> : <></>

                return (
                  <>
                    {spacer}
                    {claimDamageProportions.map((cdpField, cdpIndex) => {
                      return (
                        <Row align="bottom" gutter={gutter} key={cdpField.key}>
                          <Col span={nameSpan}>
                            <FormItem label={cdpIndex === 0 ? 'Label' : ''}>
                              <div>
                                <PrimaryText>{damages?.[cdpIndex]?.name}</PrimaryText>
                              </div>
                            </FormItem>
                          </Col>

                          {/*The read-only chances*/}
                          <Col span={weightSpan}>
                            <FormItem title={'Chances are inherited and are not customizable'}>
                              <PerspectiveAwarePercentInput
                                disabled
                                isPerspective
                                value={damages?.[cdpIndex]?.myWeight}
                              />
                            </FormItem>
                          </Col>
                          <Col span={weightSpan}>
                            <FormItem title={'Chances are inherited and are not customizable'}>
                              <PerspectiveAwarePercentInput
                                disabled
                                isPerspective={false}
                                value={damages?.[cdpIndex]?.theirWeight}
                              />
                            </FormItem>
                          </Col>

                          <Col span={weightSpan}>
                            <DebugFormItem
                              label={cdpIndex === 0 ? 'Proportion' : ''}
                              name={[cdpField.name, 'proportion']}
                              title={`caseBot.claimDamageProportions[].proportion`}
                            >
                              <PercentFormInput />
                            </DebugFormItem>
                          </Col>
                        </Row>
                      )
                    })}

                    {claimDamageProportions.length ? (
                      <>
                        <Row>
                          <Col span={nameSpan}>
                            <Tooltip title={'Weighted Proportion of the Award Ranges in this Claim Group'}>
                              <Hint>
                                <Tag color="purpleLight" style={{width: 160}}>
                                  Weighted Proportion
                                </Tag>
                              </Hint>
                            </Tooltip>
                          </Col>
                          <Col span={weightSpan}>
                            <CenterLeftLine>
                              <HSpace space={10} />
                              <PerspectiveAwareText isPerspective semibold>
                                {percentToStr(cumulativeClaimDamageProportions[index]?.myWeight)}
                              </PerspectiveAwareText>
                            </CenterLeftLine>
                          </Col>
                          <Col span={weightSpan}>
                            <CenterLeftLine>
                              <HSpace space={10} />
                              <PerspectiveAwareText semibold>
                                {percentToStr(cumulativeClaimDamageProportions[index]?.theirWeight)}
                              </PerspectiveAwareText>
                            </CenterLeftLine>
                          </Col>
                        </Row>
                        <VSpaceSmall />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )
              }}
            </Form.List>
          </span>
        )
      }),
    [cumulativeClaimDamageProportions, damages, form, onSwitch, version.claimGroups],
  )

  return (
    <>
      <HelpTitleWithTooltip
        id="casebot_damage_refinement_overview"
        left="Awards Range Refinements for Claim Groups"
        leftId="casebot_damage_refinement_title"
      />
      <VSpaceSmall />

      {claimGroups}

      <VSpaceLarge />
      {!claimGroups.length && (
        <>
          <PrimaryText>There are no claim groups defined yet</PrimaryText>
          <VSpaceSmall />
        </>
      )}
      <TertiaryButton
        medium
        onClick={(e: any) => {
          e.preventDefault()
          editClaims()
        }}
      >
        Edit claim groups
      </TertiaryButton>
    </>
  )
}
