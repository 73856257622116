import * as React from 'react'
import {Redirect} from 'react-router'

import {cond, T} from '@settleindex/fp'
import {
  CenterLeftLine,
  H1,
  HelpIcon,
  HSpace,
  PrimaryButton,
  PrimaryText,
  SecondaryButton,
  Table,
  TableRow,
  VSpaceMedium,
} from '@settleindex/react'
import {disputeTeamTestIds} from '@settleindex/testids/src/testIds'

import {Frame} from '../../dispute/Frame'
import {AccessKind, DisputeFragment, ResolvedUserResponse} from '../../graphQLTypes'
import {Tip} from '../../help'
import {usePageTitle} from '../../pageTitle/usePageTitle'
import {paths} from '../../routing'
import {useAuthenticatedUser} from '../../user/useUser/useAuthenticatedUser'
import {accessKindLabels} from '../access/accessKindLabels'
import {MemberAvatar} from '../avatar/MemberAvatar'
import {InviteModal, InviteModalProps} from './InviteModal'
import {ManageModal, ManageModalProps} from './ManageModal'
import {teamTableColumns} from './teamTableColumns'
import {TeamTableRows} from './TeamTableRows'

interface TeamPageProps {
  dispute: DisputeFragment
  loading: boolean
  members: ResolvedUserResponse[]
  onChangeAccess: ManageModalProps['onChangeAccess']
  onInvite: InviteModalProps['onInvite']
  onRemoveShare: ManageModalProps['onRemoveShare']
}

export const TeamPage: React.FC<TeamPageProps> = ({
  dispute,
  loading,
  members,
  onChangeAccess,
  onInvite,
  onRemoveShare,
}) => {
  usePageTitle(dispute.title)
  const {
    user: {email, id: loggedInUserId},
  } = useAuthenticatedUser()

  const [isInviteOpen, setIsInviteOpen] = React.useState<boolean>(false)
  const openInvite = () => setIsInviteOpen(true)
  const closeInvite = () => setIsInviteOpen(false)

  const [isManageOpen, setIsManageOpen] = React.useState<boolean>(false)
  const [memberToManage, setMemberToManage] = React.useState<ResolvedUserResponse | undefined>()
  const openManage = (member: ResolvedUserResponse) => {
    setMemberToManage(member)
    setIsManageOpen(true)
  }
  const closeManage = () => {
    setMemberToManage(undefined)
    setIsManageOpen(false)
  }

  const thereIsOnlyOneActiveAdmin = React.useMemo(
    () => members.filter((m) => m.inviteAccepted === true && m.accessKind === AccessKind.DISPUTE_CREATOR).length < 2,
    [members],
  )

  const isCurrentUserAdmin = React.useMemo(
    () => members.find((m) => m.user.id === loggedInUserId)?.accessKind === AccessKind.DISPUTE_CREATOR,
    [members, loggedInUserId],
  )

  const currentUserHasAccess = React.useMemo(
    () => members.some((m) => m.user.id === loggedInUserId),
    [members, loggedInUserId],
  )

  const rows: TableRow<TeamTableRows>[] = React.useMemo(
    () =>
      members.map((member) => {
        const isCurrentUser = email === member.user.email
        const isLastActiveAdmin =
          member.inviteAccepted === true &&
          member.accessKind === AccessKind.DISPUTE_CREATOR &&
          thereIsOnlyOneActiveAdmin
        const actions = cond([
          [() => !isCurrentUserAdmin, () => <></>],
          [() => isLastActiveAdmin, () => <DisabledManage />],
          [
            T,
            () => (
              <SecondaryButton
                data-test-id={disputeTeamTestIds.mangeButton(member.user.email)}
                onClick={() => openManage(member)}
              >
                Manage
              </SecondaryButton>
            ),
          ],
        ])()

        const displayName = cond([
          [() => member.inviteAccepted === false, () => '(Invitation Pending)'],
          [T, () => `${member.user.firstName} ${member.user.lastName}`],
        ])()

        return {
          id: member.user.id,
          cells: {
            name: (
              <CenterLeftLine>
                <MemberAvatar pending={member.inviteAccepted === false} user={member.user} />
                <HSpace space={16} />
                <PrimaryText bold={isCurrentUser}>{displayName}</PrimaryText>
              </CenterLeftLine>
            ),
            email: <PrimaryText>{member.user.email}</PrimaryText>,
            access: <PrimaryText>{accessKindLabels.get(member.accessKind)}</PrimaryText>,
            actions,
          },
        }
      }),
    [email, isCurrentUserAdmin, members, thereIsOnlyOneActiveAdmin],
  )

  if (!currentUserHasAccess) {
    return <Redirect to={paths.home()} />
  }

  if (!isCurrentUserAdmin) {
    return <Redirect to={paths.dispute(dispute.id)} />
  }

  return (
    <>
      <Frame activeKey="team" dispute={dispute} loading={loading}>
        <H1 noMargin>Access</H1>
        <VSpaceMedium />
        <Table columns={teamTableColumns} rows={rows} stateId="AccessListPage/AccessList" testId="sharing-list" />
        <VSpaceMedium />
        {isCurrentUserAdmin ? (
          <PrimaryButton medium onClick={openInvite}>
            Invite
          </PrimaryButton>
        ) : (
          <></>
        )}
        <InviteModal disputeTitle={dispute.title} onCancel={closeInvite} onInvite={onInvite} open={isInviteOpen} />
        {memberToManage && (
          <ManageModal
            memberToManage={memberToManage}
            onCancel={closeManage}
            onChangeAccess={onChangeAccess}
            onRemoveShare={onRemoveShare}
            open={isManageOpen}
          />
        )}
      </Frame>
    </>
  )
}

const DisabledManage = () => (
  <Tip title="The last administrator can not be removed">
    <HelpIcon style={{opacity: 0.7}} />
  </Tip>
)
