import * as React from 'react'

import {H2, LeftRight, PrimaryButton, PrimaryText, Table, TableRow, VSpaceLarge, VSpaceMedium} from '@settleindex/react'
import {claimsTableTestIds} from '@settleindex/testids/src/testIds'

import {DebugLabel} from '../../debug'
import {HelpTitleWithTooltip} from '../../help/HelpTitleWithTooltip'
import {SumText} from '../../sum'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {ClaimRecord} from './ClaimRecord'
import {columns} from './columns'
import {NoClaimGroups} from './NoClaimGroups'
import {Args as ToClaimGroupsTableDataArgs, toClaimGroupRows} from './toClaimGroupRows'

interface Props {
  claimGroupClassifications: ToClaimGroupsTableDataArgs['claimGroupClassifications']
  onAddClaim: ToClaimGroupsTableDataArgs['onAddClaim']
  onAddClaimGroup: () => void
  onAddCounterclaim: ToClaimGroupsTableDataArgs['onAddCounterclaim']
  onClaimChange: ToClaimGroupsTableDataArgs['onClaimChange']
  onClaimGroupChange: ToClaimGroupsTableDataArgs['onClaimGroupChange']
  onDeleteClaim: ToClaimGroupsTableDataArgs['onDeleteClaim']
  onDeleteClaimGroup: ToClaimGroupsTableDataArgs['onDeleteClaimGroup']
}

export const ClaimGroupsTable: React.FC<Props> = ({
  claimGroupClassifications,
  onAddClaim,
  onAddClaimGroup: addClaimGroup,
  onAddCounterclaim,
  onClaimChange,
  onClaimGroupChange,
  onDeleteClaim,
  onDeleteClaimGroup,
}) => {
  const {version} = useSafeVersion()

  const tableStateId = `version/${version.id}/claimGroups`

  const hasDataRows = version.claimGroups.length > 0

  const onAddClaimGroup = () => {
    addClaimGroup()
  }

  if (!hasDataRows) {
    return <NoClaimGroups addClaimGroup={onAddClaimGroup} disabled={version.readonly} />
  }

  const claimRows = toClaimGroupRows({
    claimGroupClassifications,
    claimGroups: version.claimGroups,
    onAddClaim,
    onAddCounterclaim,
    onClaimChange,
    onClaimGroupChange,
    onDeleteClaim,
    onDeleteClaimGroup,
    readonly: version.readonly,
  })

  const totalRow: TableRow<ClaimRecord> = {
    id: `${tableStateId}/totals`,
    cells: {
      adjusted: (
        <DebugLabel title="version.totalClaimsWithInterest">
          <SumText semibold sum={version.totalClaimsWithInterest} />
        </DebugLabel>
      ),
      amount: (
        <DebugLabel title="version.totalClaims">
          <SumText semibold sum={version.totalClaims} />
        </DebugLabel>
      ),
      name: <PrimaryText semibold>Total</PrimaryText>,
    },
  }

  const rows: TableRow<ClaimRecord>[] = [...claimRows, totalRow]

  return (
    <>
      <HelpTitleWithTooltip
        id="help_for_claims_page"
        left="Enter the Claims in Groups "
        leftId="help_for_claims_page_title"
      />
      <LeftRight
        left={<H2 noMargin>Claims</H2>}
        right={
          version.readonly ? undefined : (
            <PrimaryButton data-test-id={claimsTableTestIds.createClaimGroupButton} medium onClick={onAddClaimGroup}>
              Add Claim Group
            </PrimaryButton>
          )
        }
      />
      <VSpaceMedium />
      <Table<ClaimRecord>
        columns={columns}
        rows={rows}
        stateId={tableStateId}
        testId={claimsTableTestIds.claimGroupsTable()}
      />
      <VSpaceLarge />
    </>
  )
}

ClaimGroupsTable.displayName = 'ClaimGroupsTable'
