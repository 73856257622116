import * as React from 'react'

import {NoData, PrimaryButton2} from '@settleindex/react'

import {useVersionVariablesDrawer} from '../../nodes/versionVariablesDrawer/useVersionVariablesDrawer'

interface Props {
  readonly?: boolean
}

export const CapsEmpty: React.FC<Props> = ({readonly}) => {
  const {editCaps} = useVersionVariablesDrawer()

  return (
    <NoData
      button={
        !readonly && (
          <PrimaryButton2 onClick={editCaps} size="medium" weight="medium">
            Manage Caps/Limits
          </PrimaryButton2>
        )
      }
      heading="No Caps/Limits"
      text="To model caps or limits in this outcome,
      you must first add some caps or limits as variables. Caps and limits are
      managed centrally and can then be applied in each outcome to see the
      impact they have."
    />
  )
}
