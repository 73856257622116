import {atom} from 'jotai'

import {getAllCaseBotIssues} from '@settleindex/domain'

import {CaseBotFormValues} from '../form/CaseBotFormValues'
import {caseBotChangedAtom} from './caseBotChangedAtom'

const caseBotFormValuesBaseAtom = atom<CaseBotFormValues | undefined>(undefined)

export const caseBotFormValuesAtom = atom(
  (get) => get(caseBotFormValuesBaseAtom),
  (_, set, update: CaseBotFormValues) => {
    set(caseBotChangedAtom, true)

    // caseBot.costDefaults.costIrrecoverablePercentage can be edited in an accordion where the only form
    // field is the costIrrecoverablePercentage (discount on assessment).
    // Without the deep merge, the whole caseBot.costDefaults object would be overwritten with just the discount
    // itself, resulting in `{costIrrecoverablePercentage: 0}`, instead of the whole object with the other values in
    // place.
    // See useCaseBot on why the accordion is a problem.
    set(caseBotFormValuesBaseAtom, (prev) => {
      const next: CaseBotFormValues = {
        ...prev,
        ...update,
        costDefaults: {
          ...prev?.costDefaults,
          ...update.costDefaults,
        },
      }

      const allIssueIds = getAllCaseBotIssues(next).map((i) => i.id)

      // Sometimes the form sends us issues in the form of `{id: undefined}`.
      // These are filtered out here.
      const loseFinals = next.loseFinals.filter((i) => i.id)
      const winFinals = next.winFinals.filter((i) => i.id)
      const others = next.others.filter((i) => i.id)

      // Remove keys from the customCosts object where the key refers to an issue that no longer exists
      const customCosts = Object.keys(next.customCosts).reduce((acc, key) => {
        return allIssueIds.includes(key) ? {...acc, [key]: next.customCosts[key]} : acc
      }, {})

      // Remove cap blocker issues that refer to issues that no longer exist
      const capBlockerIssues = next.capBlockerIssues.filter((i) => allIssueIds.includes(i.issueId))

      return {
        ...next,
        capBlockerIssues,
        customCosts,
        loseFinals,
        others,
        winFinals,
      }
    })
  },
)
