import * as React from 'react'

import {noop} from '@settleindex/fp'
import {Col, Form, FormItem, H3, Input, PrimaryText, Row, Switch, VSpaceSmall} from '@settleindex/react'

import {DebugFormItem} from '../debug'
import {useSafeDispute} from '../dispute/context/useSafeDispute'
import {HelpTitleWithTooltip} from '../help/HelpTitleWithTooltip'
import {PerspectiveAwareInput} from '../party/perspectiveAwareColors/PerspectiveAwareInput'
import {PerspectiveAwareSumInput} from '../party/perspectiveAwareColors/PerspectiveAwareSumInput'
import {gutter, nameSpan, sumSpan, weightSpan} from './columnWidths'
import {useCaseBot} from './useCaseBot'

export const CustomCostsTable: React.FC = () => {
  const {finalIssues} = useCaseBot()
  const {currencySymbol, dispute} = useSafeDispute()
  const perspectiveIndex = React.useMemo(
    () => dispute.parties.map((p) => p.id).indexOf(dispute.perspectiveId),
    [dispute.parties, dispute.perspectiveId],
  )

  return (
    <>
      <HelpTitleWithTooltip
        id={'casebot_custom_cost_overview'}
        left={'Custom Costs'}
        leftId={'casebot_custom_cost_title'}
      />

      <VSpaceSmall />
      {finalIssues.length === 0 && (
        <>
          <PrimaryText>You haven't defined any Lose or Win issues yet</PrimaryText>
          <VSpaceSmall />
        </>
      )}
      {finalIssues.map((issue) => (
        <span key={issue.id}>
          <H3>{issue.name}</H3>
          <VSpaceSmall />
          <Form.List name={['customCosts', issue.id]}>
            {(fields) =>
              fields.map((field, index) => (
                <span key={field.key}>
                  <Row align="bottom" gutter={gutter}>
                    <Col span={nameSpan}>
                      <FormItem hidden name={[field.name, 'issueId']} noStyle>
                        <Input />
                      </FormItem>

                      <FormItem label={index === 0 ? 'Party' : ''} name={[field.name, 'partyLabel']}>
                        <PerspectiveAwareInput isPerspective={perspectiveIndex === index} readonly />
                      </FormItem>
                    </Col>
                    <Col span={weightSpan}>
                      <FormItem
                        label={index === 0 ? 'Enabled' : ''}
                        name={[field.name, 'enabled']}
                        rules={[{required: false}]}
                        valuePropName="checked"
                      >
                        <Switch />
                      </FormItem>
                    </Col>
                    <Col span={sumSpan}>
                      <DebugFormItem
                        label={index === 0 ? 'Recoverable' : ''}
                        name={[field.name, 'recoverableCost']}
                        rules={[{required: false}]}
                        title={`caseBot.customCosts[].recoverableCost`}
                      >
                        <PerspectiveAwareSumInput
                          isPerspective={perspectiveIndex === index}
                          prefix={currencySymbol}
                          style={{paddingRight: 10}}
                        />
                      </DebugFormItem>
                    </Col>
                    <Col span={sumSpan}>
                      <DebugFormItem
                        label={index === 0 ? 'Non-recoverable' : ''}
                        name={[field.name, 'irrecoverableCost']}
                        rules={[{required: false}]}
                        title={`caseBot.customCosts[].irrecoverableCost`}
                      >
                        <PerspectiveAwareSumInput
                          isPerspective={perspectiveIndex === index}
                          onSaveNull={noop}
                          prefix={currencySymbol}
                          style={{paddingRight: 10}}
                        />
                      </DebugFormItem>
                    </Col>
                  </Row>
                  <VSpaceSmall />
                </span>
              ))
            }
          </Form.List>
        </span>
      ))}
    </>
  )
}
