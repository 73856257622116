import * as React from 'react'
import styled from 'styled-components'

import {decimalToPercent} from '@settleindex/domain'
import {isDefined} from '@settleindex/fp'
import {BaseInput, InputProps, mediumWeight, semiBoldWeight, theme, useNumberInput} from '@settleindex/react'

import {Percent} from './Percent'

interface StyleProps {
  $bold?: boolean
  $color?: string
}

const PercentInputStyle = styled(BaseInput)<StyleProps>`
  color: ${(props: StyleProps) => props.$color ?? theme.input.color};

  & .ant-input {
    text-align: center;
    font-size: ${theme.input.fontSize}px;
    color: ${(props: StyleProps) => props.$color ?? theme.input.color};
    font-weight: ${(props: StyleProps) => (props.$bold ? semiBoldWeight : mediumWeight)};
  }
`

type PercentInputProps = Omit<InputProps, 'onSave' | 'defaultValue'> & {
  defaultValue?: number
  onSave: (value: number) => void
  readonly?: boolean
}

export const PercentInput: React.FC<PercentInputProps> = (props) => {
  const {bold, color, defaultValue, onSave: onSavePercent, ...rest} = props
  const defaultValueAsPercent = isDefined(defaultValue) ? decimalToPercent(defaultValue) : 0
  const percentToDecimal = (p: number) => onSavePercent(p / 100)
  const {onChange, sendChange} = useNumberInput({
    defaultValue: defaultValueAsPercent,
    onChange: props.onChange,
    onSave: percentToDecimal,
  })
  const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    sendChange()
    props.onBlur && props.onBlur(event)
  }

  if (props.readonly) {
    return <Percent percent={props.defaultValue} />
  }

  return (
    <PercentInputStyle
      {...rest}
      $bold={bold}
      $color={color}
      defaultValue={defaultValueAsPercent}
      onBlur={onBlur}
      onChange={onChange}
      onPressEnter={sendChange}
      style={{width: 70, ...props.style}}
      suffix={'%'}
    />
  )
}
