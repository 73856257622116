import * as React from 'react'
import {RouteComponentProps, useHistory} from 'react-router-dom'

import {Right} from '@settleindex/domain'
import {message} from '@settleindex/react'

import {captureException} from '../../../captureException'
import {PatchUserMutationVariables, usePatchUserMutation, useUserQuery} from '../../../graphQLTypes'
import {paths} from '../../../routing/paths'
import {requiresRight} from '../../requiresRight'
import {UserEditPage} from './UserEditPage'

interface Match {
  id: string
}

const UserEditPageContainer_: React.FC<RouteComponentProps<Match>> = ({match}) => {
  const history = useHistory()
  const userQueryStatus = useUserQuery({variables: {id: match.params.id}})
  const [patchUserMutation, patchStatus] = usePatchUserMutation()
  const error = userQueryStatus.error || patchStatus.error
  const loading = userQueryStatus.loading || patchStatus.loading
  const user = userQueryStatus?.data?.user

  const patchUser = async (variables: PatchUserMutationVariables) => {
    await patchUserMutation({variables})

    message.success('User updated')
    history.push(paths.userAdmin())
  }

  if (error) {
    message.error(error.message, 10)
    captureException(error)
  }

  return <UserEditPage loading={loading} onSubmit={patchUser} user={user} />
}

export default requiresRight(Right.AdminUsers, UserEditPageContainer_)
