import {noLayoutPosition} from '../layout/noLayoutPosition'
import {startId} from './startId'
import {startNodeHeight, startNodeType, startNodeWidth} from './StartNode'

export const startElement = {
  data: {
    width: startNodeWidth,
    height: startNodeHeight,
  },
  id: startId,
  position: noLayoutPosition,
  type: startNodeType,
}
