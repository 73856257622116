import * as React from 'react'
import styled from 'styled-components'

import {Box, LeftRight, VSpaceSmall} from '@settleindex/react'

const ChartHeight = styled.div`
  height: 290px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 0px;
`

interface Props {
  chart: React.ReactElement
  headerLeft?: React.ReactElement
  headerRight?: React.ReactElement
}

export const DashboardChartLayout: React.FC<React.PropsWithChildren<Props>> = ({chart, headerLeft, headerRight}) => (
  <Box style={{backgroundColor: 'white'}}>
    <LeftRight left={headerLeft} right={headerRight} />
    <VSpaceSmall />
    <ChartHeight>{chart}</ChartHeight>
  </Box>
)
