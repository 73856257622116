import * as React from 'react'
import styled from 'styled-components'

import {DisputeClassification, Nullable} from '@settleindex/domain'
import {H1, LeftRight} from '@settleindex/react'

import {NewDisputeInput} from '../../graphQLTypes'
import {disputeBreadcrumbs} from '../../navigation/disputeBreadcrumbs'
import {Content} from '../../page/'
import {NewDisputeForm} from './NewDisputeForm'

interface Props {
  disputeClassifications: Nullable<DisputeClassification[]>
  loading?: boolean
  onCancel: () => void
  onSubmit: (newDispute: NewDisputeInput) => void
}

const Container = styled.div`
  background-color: white;
  padding: 0px 0px;
`

export const NewDisputePage: React.FC<Props> = ({disputeClassifications, loading = false, onCancel, onSubmit}) => {
  return (
    <>
      <Content breadcrumbs={[...disputeBreadcrumbs(), <>New Case</>]} loading={loading}>
        <Container>
          <LeftRight left={<H1>New Case</H1>} />
          <NewDisputeForm
            disputeClassifications={disputeClassifications ?? []}
            onCancel={onCancel}
            onFinish={onSubmit}
          />
        </Container>
      </Content>
    </>
  )
}
