import * as React from 'react'

import {defaultTo} from '@settleindex/fp'
import {KeyValue, KeyValueBox, PrimaryText} from '@settleindex/react'

import {DisputeFragment} from '../../graphQLTypes'
import {SumFormat} from '../../sum'

interface Props {
  dispute: DisputeFragment
}

const defaultToDash = defaultTo('-')

export const InsuranceInformationBox: React.FC<Props> = ({dispute}) => {
  const insuranceBoxData: KeyValue[] = [
    {label: 'Insured', value: defaultToDash(dispute?.insurance?.insured)},
    {
      label: 'Insurer',
      value: defaultToDash(dispute?.insurance?.insurer),
    },
    {label: 'Broker', value: defaultToDash(dispute?.insurance?.broker)},
    {label: 'Claim reference', value: defaultToDash(dispute?.insurance?.claimReference)},
    {label: 'Policy number', value: defaultToDash(dispute?.insurance?.policyNumber)},
    {label: 'Policy type', value: defaultToDash(dispute?.insurance?.policyType)},
    {
      label: 'Indemnity limit',
      value: (
        <PrimaryText semibold>
          <SumFormat sum={dispute?.insurance?.indemnityLimit} />
        </PrimaryText>
      ),
    },
    {
      label: 'Deductible',
      value: (
        <PrimaryText semibold>
          <SumFormat sum={dispute?.insurance?.deductible} />
        </PrimaryText>
      ),
    },
    {
      label: 'Excess',
      value: (
        <PrimaryText semibold>
          <SumFormat sum={dispute?.insurance?.excess} />
        </PrimaryText>
      ),
    },
  ]

  return <KeyValueBox data={insuranceBoxData} grow span={12} title="Insurance" />
}
