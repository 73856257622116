import * as React from 'react'

import {Accordion, AccordionPanel, Col, FormItem, H2, Tag, TextInput} from '@settleindex/react'

import {SumInput} from '../../../sum/sumInput'
import {Row} from '../Row'
import {fieldNames} from './fieldNames'

interface Props {}

export const InsuranceSection: React.FC<Props> = () => {
  const accordionHeader = (
    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
      <H2 noMargin style={{display: 'inline-block'}}>
        Insurance Information
      </H2>

      <Tag color="purpleLight" style={{maxWidth: 100, marginLeft: 30}}>
        Optional
      </Tag>
    </div>
  )

  return (
    <Accordion boundless>
      <AccordionPanel header={accordionHeader} key="1">
        <Row>
          <Col span={8}>
            <FormItem label="Insured" name={fieldNames.insured} rules={[{required: false}]}>
              <TextInput data-test-id="insurance-insured" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Insurer" name={fieldNames.insurer} rules={[{required: false}]}>
              <TextInput data-test-id="insurance-insurer" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Broker" name={fieldNames.broker} rules={[{required: false}]}>
              <TextInput data-test-id="insurance-broker" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <FormItem label="Claim Reference" name={fieldNames.claimReference} rules={[{required: false}]}>
              <TextInput data-test-id="insurance-claimReference" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Policy Number" name={fieldNames.policyNumber} rules={[{required: false}]}>
              <TextInput data-test-id="insurance-policyNumber" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Policy Type" name={fieldNames.policyType} rules={[{required: false}]}>
              <TextInput data-test-id="insurance-policyType" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <FormItem label="Indemnity Limit" name={fieldNames.indemnityLimit} rules={[{required: false}]}>
              <SumInput data-test-id="insurance-indemnityLimit" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Deductible" name={fieldNames.deductible} rules={[{required: false}]}>
              <SumInput data-test-id="insurance-deductible" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Excess" name={fieldNames.excess} rules={[{required: false}]}>
              <SumInput data-test-id="insurance-excess" />
            </FormItem>
          </Col>
        </Row>
      </AccordionPanel>
    </Accordion>
  )
}
