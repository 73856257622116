import React from 'react'

import {colors, HelpIcon, Tooltip} from '@settleindex/react/lib/src'

import {DebugLabel} from '../debug'
import {useHelp} from './useHelp'

export const HelpTooltip: React.FC<{
  id: string
  onOpenHelp?: () => void
  onToggleHelp?: () => void
}> = (props) => {
  const {helpFor} = useHelp()
  const allHelp = React.useMemo(() => helpFor(props.id), [helpFor, props.id])

  const tooltipTitle = React.useMemo(() => {
    if (!allHelp) {
      return ''
    }

    if (allHelp.length === 1) {
      return allHelp[0]
    }

    return (
      <>
        <span>{allHelp[0]}</span>

        <span
          onClick={props.onOpenHelp}
          style={{color: colors.portage, cursor: 'pointer', paddingLeft: 5, textTransform: 'uppercase', fontSize: 11}}
        >
          <strong>Read more</strong>
        </span>
      </>
    )
  }, [allHelp, props.onOpenHelp])

  return (
    <Tooltip overlayInnerStyle={{textAlign: 'left'}} title={tooltipTitle}>
      <div
        onClick={props.onToggleHelp}
        style={{
          // Force stuff into a single line
          display: 'flex',
          alignItems: 'center',
          color: colors.portage,
          cursor: 'pointer',
        }}
      >
        <div style={{display: 'flex', marginRight: 5}}>
          <HelpIcon />
        </div>
        <DebugLabel placement="bottomRight" title={props.id}>
          <div style={{display: 'flex', textTransform: 'uppercase', fontSize: 11}}>Help</div>
        </DebugLabel>
      </div>
    </Tooltip>
  )
}
