import * as React from 'react'
import {useRecoilState} from 'recoil'

import {Nullable} from '@settleindex/domain'

import {DisputeTeamFragment} from '../../graphQLTypes'
import {disputeTeamAtom} from './disputeTeamAtom'

interface Args {
  /**
   * The hook will set the DisputeTeam to this value when it is defined. Can
   * be used for passing `data?.disputeTeam` from an Apollo hook result.
   */
  setDisputeTeamFrom?: Nullable<DisputeTeamFragment>
}

export const useDisputeTeam = (args: Args = {}) => {
  const [disputeTeam, setDisputeTeam] = useRecoilState(disputeTeamAtom)

  React.useEffect(() => {
    args?.setDisputeTeamFrom && setDisputeTeam(args.setDisputeTeamFrom)
  }, [args.setDisputeTeamFrom, setDisputeTeam])

  return {
    disputeTeam,
    setDisputeTeam,
  }
}
