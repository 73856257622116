/**
 * @deprecated
 */
export const helpTexts: Record<string, string> = {
  'dispute/access': `Invite people to join the case. They will be able to view all models, but not edit them`,
  'outcome/details': 'For this outcome, model the expected claims and cost recovery',
  'outcome/details/caps': 'Select any caps or limits that may apply',
  'version/outcomes':
    "From this party's perspective, model the potential outcomes and assess the likelihood of them occurring",
  'version/analyseOutcomes': 'Outcome Analysis',
  'version/costs': 'Provide cost estimates for all parties in the case',
  'version/claims': 'Specify the claims in dispute, organise them by group, and apply interest if applicable',
  'version/settlement': 'Model Settlements are where opponent positions ranges intersect with yours',
  'version/caps':
    'Optionally, predefine any potential caps or limits for each party. You can then apply these in different outcomes',
  'version/report': 'Review your case valuation and set targets with opponents to finalise your report',
  'event/list': 'Record offers made and received here',
  'event/list-empty': 'Record offers made and received here',
}
