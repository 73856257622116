import {LineSvgProps} from '@nivo/line'

import {mergeDeepRight} from '@settleindex/fp'

const axisBottom = {
  format: '%d %b %Y',
  tickPadding: 20,
  tickSize: 0,
  tickValues: 5,
}

const axisLeft = {
  format: '(.2~s',
  tickPadding: 20,
  tickSize: 0,
}

const xScale = {
  tickValues: 5,
  format: '%Y-%m-%dT%H:%M:%S.%LZ',
  precision: 'day',
  type: 'time',
  useUTC: false,
}

const yScale = {
  stacked: false,
  type: 'linear' as 'linear',
}

export const historyChartProps = mergeDeepRight<LineSvgProps>({
  axisBottom,
  axisLeft,
  enableGridX: false,
  enableSlices: 'x' as 'x',
  isInteractive: true,
  margin: {top: 5, right: 20, bottom: 35, left: 50},
  pointBorderWidth: 2,
  pointColor: 'white',
  pointSize: 7,
  xFormat: 'time:%Y-%m-%d',
  xScale,
  yScale,
})
