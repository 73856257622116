import * as React from 'react'

import {Option, PrimaryText, SecondaryText, XlOption} from '@settleindex/react'

import {AccessKind} from '../../graphQLTypes'
import {accessKindDescriptions} from '../access/accessKindDescriptions'
import {accessKindLabels} from '../access/accessKindLabels'

export const getAccessKindOptions = () => {
  return [AccessKind.DISPUTE_CREATOR, AccessKind.DISPUTE_TEAM_MEMBER, AccessKind.EXTERNAL_TEAM_MEMBER].map((kind) => (
    <Option key={kind} value={kind}>
      <XlOption>
        <PrimaryText bold>{accessKindLabels.get(kind)}</PrimaryText>
        <SecondaryText>{accessKindDescriptions.get(kind)}</SecondaryText>
      </XlOption>
    </Option>
  ))
}
