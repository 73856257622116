import * as React from 'react'

import {ChevronDownIcon, DatePicker, FormItem} from '@settleindex/react'

import {fieldNames} from './fieldNames'

export const DisputeStartedOnDateInput: React.FC<{}> = () => {
  return (
    <FormItem label="Date Opened" name={fieldNames.disputeStartedOn} rules={[{required: false}]}>
      <DatePicker
        allowClear={false}
        bordered
        format="D MMM YYYY"
        placeholder="Select..."
        style={{width: '100%'}}
        suffixIcon={<ChevronDownIcon />}
      />
    </FormItem>
  )
}
