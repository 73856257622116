import {CaseBotDamage} from '@settleindex/domain'
import {FormInstance} from '@settleindex/react'

import {ItemIdToClaimDamageProportion} from './CaseBotFormValues'

/**
 * onRemoveDamage removes the deleted damage from the
 * claimDamageProportionsByItemId form field values, so that all
 * `claimDamageProportionsByItemId.$CLAIM_GROUP_ID` arrays remain valid,
 * i.e. they do not point to a non-existent damage ID.
 *
 * Must be called ***AFTER*** the form's remove function so that the
 * `remainingDamageIds` reflects the true state.
 */
export const onRemoveDamage = (form: FormInstance) => {
  const remainingDamageIds = form.getFieldValue('damages').map((damage: CaseBotDamage) => damage.id)

  const currentClaimDamageProportionsByItemId: ItemIdToClaimDamageProportion = form.getFieldValue(
    'claimDamageProportionsByItemId',
  )

  const newEntries = Object.entries(currentClaimDamageProportionsByItemId).map(([id, cdps]) => {
    const newClaimDamageProportions = cdps.filter((cdp) => remainingDamageIds.includes(cdp.damageId))

    return [id, newClaimDamageProportions]
  })

  const newClaimDamageProportionsByItemId = Object.fromEntries(newEntries)

  form.setFieldValue(['claimDamageProportionsByItemId'], newClaimDamageProportionsByItemId)
}
