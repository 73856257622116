import {notCombinedDefendantParty, partyPerspectiveFirst} from '@settleindex/domain'

import {IndexedPartyFragment, Role} from '../graphQLTypes'

interface Args {
  parties: IndexedPartyFragment[]
  perspectiveId: string
  perspectiveRole: Role
}

export const getPartiesToShow = ({parties, perspectiveId, perspectiveRole}: Args) => {
  const partiesToShow = perspectiveRole === Role.Claimant ? parties : parties.filter(notCombinedDefendantParty)

  return partiesToShow
    .map((party) => ({
      id: party.id,
      label: party.label,
    }))
    .sort(partyPerspectiveFirst(perspectiveId))
}
