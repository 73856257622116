import {HistoryMetrics} from '@settleindex/domain'
import {isDefined, path} from '@settleindex/fp'

import {metricVisuals} from '../../dispute/history/metricVisuals'
import {DisputeHistoryPointFragment} from '../../graphQLTypes'
import {MetricData} from './MetricData'

interface Args {
  historyPoints: DisputeHistoryPointFragment[]
  metricsToShow: HistoryMetrics[]
  valueKeys: string[]
}

export const getBarValuesForEnabledMetrics = ({historyPoints, metricsToShow, valueKeys}: Args): MetricData[] =>
  [...metricVisuals.entries()]
    .map(([metricPath, visuals]) => {
      if (!metricsToShow.includes(metricPath)) {
        return undefined
      }

      return {
        color: visuals.color,
        key: metricPath,
        numberOfVersions: historyPoints.length,
        title: metricVisuals.get(metricPath)?.title ?? '',
        version1: path<number>(metricPath, historyPoints?.[0]) ?? 0,
        version2: path<number>(metricPath, historyPoints?.[1]) ?? 0,
        version3: path<number>(metricPath, historyPoints?.[2]) ?? 0,
      }
    })
    .filter(isDefined)
    .filter(removeEmptyMetricData(valueKeys))

const removeEmptyMetricData =
  (valueKeys: string[]) =>
  (data: MetricData): boolean =>
    valueKeys.reduce<boolean>((acc, key) => Boolean(acc && data[key]), true)
