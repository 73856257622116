import * as React from 'react'

import {PrimaryText} from '@settleindex/react/lib/src'

import {opponentColor, perspectiveColor} from './index'

export const PerspectiveAwareText = ({
  isPerspective = false,
  ...props
}: React.PropsWithChildren<{isPerspective?: boolean; semibold?: boolean}>) => {
  const color = isPerspective ? perspectiveColor : opponentColor

  return <PrimaryText {...props} style={{color}} />
}
