import React from 'react'

import {EyeIcon} from '@settleindex/react'

import {Tip} from '../../help/Tip'
import {Tag} from '../tags/Tag'

export const PreCaseBotTag = () => <Tag color="amber">Legacy</Tag>
export const DraftTag = () => <Tag color="purpleLight">Draft</Tag>
export const PublishedTag = () => <Tag color="purpleDark">Published</Tag>
export const ReadonlyTag = () => (
  <Tip id="Model.ReadOnly">
    <Tag color="purpleLight">
      <span style={{marginTop: 3}}>
        <EyeIcon />
      </span>
      Read Only
    </Tag>
  </Tip>
)
