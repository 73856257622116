import {BarTooltipProps} from '@nivo/bar'
import * as React from 'react'
import styled from 'styled-components'

import {boxShadow1, Circle, HSpace, PrimaryText} from '@settleindex/react'

import {Role} from '../../graphQLTypes'
import {SumText} from '../../sum'
import {ChartData} from './ChartData'
import {getBarColors} from './getBarColors'

export const tooltip =
  (perspectiveRole: Role): React.FC<BarTooltipProps<ChartData>> =>
  ({data, value}) => {
    const color = React.useMemo(() => {
      return getBarColors(data.key, perspectiveRole)
    }, [data])

    return (
      <Bar>
        <Circle color={color} />
        <HSpace space={10} />
        <PrimaryText>{data.label}</PrimaryText>
        <HSpace space={8} />
        <SumText bold sum={value} />
      </Bar>
    )
  }

const Bar = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  background-color: white;
  padding: 8px 10px;
  box-shadow: ${boxShadow1};
`
