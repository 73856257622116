import * as React from 'react'

import {H1, VSpaceMedium} from '@settleindex/react'

import {Frame} from '../../dispute/Frame'
import {DisputeFragment} from '../../graphQLTypes'
import {usePageTitle} from '../../pageTitle/usePageTitle'
import {EventList, EventListProps} from './EventList'
import {NoEvents} from './NoEvents'

interface Props {
  dispute: DisputeFragment
  events: EventListProps['events']
  loading: boolean
  onAddEvent: () => void
  onDeleteEvent: EventListProps['onDeleteEvent']
  onEditEvent: EventListProps['onEditEvent']
  onViewEvent: EventListProps['onViewEvent']
}

export const EventListPage: React.FC<Props> = ({
  dispute,
  events,
  loading,
  onAddEvent,
  onDeleteEvent,
  onEditEvent,
  onViewEvent,
}) => {
  usePageTitle(dispute.title)

  const content =
    !events || !events.length ? (
      <NoEvents onAddEvent={onAddEvent} />
    ) : (
      <EventList
        events={events}
        onAddEvent={onAddEvent}
        onDeleteEvent={onDeleteEvent}
        onEditEvent={onEditEvent}
        onViewEvent={onViewEvent}
        parties={dispute.parties}
      />
    )

  return (
    <Frame activeKey="events" dispute={dispute} loading={loading}>
      <H1 noMargin>Offers</H1>
      <VSpaceMedium />
      {content}
    </Frame>
  )
}
