import * as React from 'react'

import {Option, PrimaryText, SecondarySelect} from '@settleindex/react'

import {Maybe, PartyFragment} from '../../graphQLTypes'

interface Props {
  defaultValue?: Maybe<string>
  onChange: (value: string) => void
  parties: PartyFragment[]
  readonly: boolean
  testId?: string
}

export const PartySelect: React.FC<Props> = ({defaultValue, onChange, parties, readonly, testId}) => {
  return readonly ? (
    <PrimaryText>{parties.find((p) => p.id === defaultValue)?.label}</PrimaryText>
  ) : (
    <SecondarySelect data-test-id={testId} defaultValue={defaultValue} onChange={onChange} style={{width: '100%'}}>
      {parties.map((party) => (
        <Option key={party.id} value={party.id}>
          {party.label}
        </Option>
      ))}
    </SecondarySelect>
  )
}
