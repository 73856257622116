import * as React from 'react'
import useKeypress from 'react-use-keypress'

import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {OutcomeDetails} from '../details/OutcomeDetails'
import {DrawerFrame} from './DrawerFrame'
import {useOutcomeDrawer} from './useOutcomeDrawer'

/**
 * Use this component in conjunction with `useOutcomeDrawer`. When setting the
 * outcome to edit using the hook, this component will be able to display the
 * Outcome edit/view drawer automatically, without any passed props.
 */
export const OutcomeDetailsDrawer: React.FC = () => {
  const {dispute} = useSafeDispute()
  const {version} = useSafeVersion()
  const {closeDrawer, isDrawerOpen: drawerIsOpen, outcome} = useOutcomeDrawer()
  useKeypress('Escape', closeDrawer)

  return (
    <DrawerFrame headerTitle="Outcome Details" isDrawerVisible={drawerIsOpen} onClose={closeDrawer} showRoleTag>
      <>
        {outcome && (
          <OutcomeDetails dispute={dispute} outcome={outcome} readonly={version.readonly} version={version} />
        )}
      </>
    </DrawerFrame>
  )
}
