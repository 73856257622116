import * as React from 'react'

import {pickByOverflowIndex} from '@settleindex/fp'
import {Avatar, colors, hex2rgba, HSpace} from '@settleindex/react'

import {Tip} from '../../help/Tip'

interface Props {
  pending?: boolean
  user: {
    firstName: string
    id: string
    lastName: string
  }
}

export const MemberAvatar: React.FC<Props> = ({pending, user}) => {
  const initials = makeInitials(user.firstName, user.lastName)
  const textColor = colors.white
  const bgColor = hex2rgba(pending ? colors.base8 : colorByInitials(initials), 0.7)

  return (
    <span key={user.id}>
      <Tip title={`${user.firstName} ${user.lastName}`}>
        <Avatar bgColor={bgColor} textColor={textColor}>
          <span>{initials}</span>
        </Avatar>
      </Tip>
      <HSpace space={25} />
    </span>
  )
}

const makeInitials = (firstName?: string, lastName?: string) => `${firstName?.[0] ?? ' '}${lastName?.[0] ?? ' '}`

const avatarColors = [
  colors.field,
  colors.grape,
  colors.green,
  colors.lime,
  colors.hollyhock,
  colors.magenta,
  colors.raspberry,
  colors.violet,
  colors.portage,
]

const colorByInitials = (initials: string) => {
  const indexFromInitials = Math.ceil(initials.charCodeAt(0) + initials.charCodeAt(1))

  return pickByOverflowIndex(avatarColors, indexFromInitials)
}
