import * as React from 'react'

import {
  colors,
  EyeIcon,
  HelpIcon,
  LinkOut,
  ReportIcon,
  SettingsIcon,
  SignOutIcon,
  Tooltip,
  UserIcon,
} from '@settleindex/react'
import {primaryNavigationTestIds} from '@settleindex/testids/src/testIds'

import {UseKnowledgeBase} from '../../knowledgeBase/useKnowledgeBase'
import {paths} from '../../routing'
import {MenuText} from './MenuText'
import {PrimaryMenuItem} from './PrimaryMenuItem'

interface Args {
  debugLabelsEnabled: boolean
  isAdmin: boolean
  isDebugPanelVisible: boolean
  knowledgeBase: UseKnowledgeBase
  logout: () => void
  setDebugLabelsEnabled: (value: boolean) => void
  setIsDebugPanelVisible: (value: boolean) => void
  showDebugPanelMenuItem: boolean
  username: string | undefined
}

export const getBottomMenuItems = ({
  debugLabelsEnabled,
  isAdmin,
  isDebugPanelVisible,
  knowledgeBase,
  logout,
  setDebugLabelsEnabled,
  setIsDebugPanelVisible,
  showDebugPanelMenuItem,
  username,
}: Args): PrimaryMenuItem[] => {
  const helpLinkProps = {
    href: knowledgeBase.homeUrl,
    onClick: (e: React.MouseEvent) => {
      e.preventDefault()
      knowledgeBase.show(knowledgeBase.homeUrl)
    },
  }

  const logoutLinkProps = {
    onClick: (e: React.MouseEvent) => {
      e.preventDefault()
      logout()
    },
  }

  const adminLabelStyle = {color: colors.base6, cursor: 'pointer', fontSize: 13}
  const adminLabelIconStyle = {...adminLabelStyle, display: 'block', marginTop: 3}
  const debugLabelStyle = {color: debugLabelsEnabled ? colors.portage : colors.base6, cursor: 'pointer', fontSize: 13}
  const debugLabelIconStyle = {...debugLabelStyle, display: 'block', marginTop: 3}
  const debugLabelToggle = () => setDebugLabelsEnabled(!debugLabelsEnabled)

  const debugPanelStyle = {cursor: 'pointer', fontSize: 13, color: colors.base6}
  const debugPanelIconStyle = {...debugPanelStyle, display: 'block', marginTop: 3}
  const debugPanelToggle = () => setIsDebugPanelVisible(!isDebugPanelVisible)

  return [
    {
      key: primaryNavigationTestIds.help,
      icon: (
        <LinkOut {...helpLinkProps} style={{paddingTop: 4}}>
          <HelpIcon />
        </LinkOut>
      ),
      label: (
        <LinkOut {...helpLinkProps}>
          <MenuText>Support</MenuText>
        </LinkOut>
      ),
    },
    {isGap: true},
    isAdmin
      ? {
          icon: (
            <span style={adminLabelIconStyle}>
              <SettingsIcon />
            </span>
          ),
          key: primaryNavigationTestIds.admin,
          label: <span style={adminLabelStyle}>Admin</span>,
          linkPath: paths.admin(),
        }
      : null,
    isAdmin
      ? {
          icon: (
            <span onClick={debugLabelToggle} style={debugLabelIconStyle}>
              <EyeIcon />
            </span>
          ),
          key: primaryNavigationTestIds.debugLabelsToggle,
          label: (
            <span onClick={debugLabelToggle} style={debugLabelStyle}>
              Labels
            </span>
          ),
        }
      : null,
    isAdmin && showDebugPanelMenuItem
      ? {
          icon: (
            <span onClick={debugPanelToggle} style={debugPanelIconStyle}>
              <ReportIcon />
            </span>
          ),
          key: primaryNavigationTestIds.debugPanelToggle,
          label: (
            <span onClick={debugPanelToggle} style={debugPanelStyle}>
              Debug
            </span>
          ),
        }
      : null,
    {
      key: primaryNavigationTestIds.account,
      icon: <UserIcon />,
      label: (
        <Tooltip placement="right" title={username}>
          <MenuText>Account</MenuText>
        </Tooltip>
      ),
    },
    {
      key: primaryNavigationTestIds.logout,
      icon: (
        <a {...logoutLinkProps} style={{paddingTop: 4}}>
          <SignOutIcon />
        </a>
      ),
      label: (
        <a {...logoutLinkProps}>
          <MenuText noMargin>Sign Out</MenuText>
        </a>
      ),
    },
  ]
}
