import {cond, equals} from '@settleindex/fp'

import {ChartDataKey} from './ChartData'

export interface GetLabelsArgs {
  key: ChartDataKey
  opponentLabel: string
  perspectiveLabel: string
}

export const getLabels = ({key, opponentLabel, perspectiveLabel}: GetLabelsArgs) =>
  cond<ChartDataKey, string>([
    [equals<ChartDataKey>('claimantCurrent'), () => `${perspectiveLabel} Position`],
    [equals<ChartDataKey>('customSettlement'), () => `Custom Settlement`],
    [equals<ChartDataKey>('modelSettlement'), () => `Model Settlement`],
    [equals<ChartDataKey>('opponentCurrent'), () => `${opponentLabel} Position`],
  ])(key)
