import * as React from 'react'

import {FormItem, TextArea} from '@settleindex/react'

import {fieldNames} from './fieldNames'

export const Description = () => (
  <FormItem
    label="Case Description"
    markAs="optional"
    name={fieldNames.description}
    rules={[
      {
        required: false,
      },
    ]}
  >
    <TextArea style={{minHeight: 95}} />
  </FormItem>
)
