import {Edge, Node} from 'react-flow-renderer'

import {outcomeListToTree} from '@settleindex/domain'

import {AppNode} from '../../nodes/AppNode'
import {NodeTree} from '../../nodes/tree/NodeTree'
import {startElement} from '../start/startElement'
import {NodeData} from './NodeData'
import {nodeTreeToElements} from './nodeTreeToElements'
import {WeightById} from './WeightById'

interface Args {
  outcomes: AppNode[]
  readonly: boolean
}

export const outcomesToElements = ({outcomes, readonly}: Args): [Node<NodeData>[], Edge<NodeData>[]] => {
  const weightById: WeightById = new Map(outcomes.map((o) => [o.id, o.weight]))
  const nodes: Node[] = [startElement]
  const edges: Edge[] = []
  const nodeTree = outcomeListToTree<AppNode, NodeTree>(outcomes)

  return nodeTreeToElements({
    edges,
    nodes,
    nodeTree,
    readonly,
    weightById,
  })
}
