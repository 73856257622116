import {TableColumn} from '@settleindex/react'

export type UserRecord = 'name' | 'company' | 'email' | 'phone' | 'mfaEnabled' | 'actions'

export const userListColumns: TableColumn<UserRecord>[] = [
  {
    label: 'Name',
    id: 'name',
    align: 'left',
  },
  {
    label: 'Company',
    id: 'company',
    align: 'left',
  },
  {
    label: 'Email',
    id: 'email',
    align: 'left',
  },
  {
    label: 'Phone',
    id: 'phone',
    align: 'left',
  },
  {
    label: 'MFA',
    id: 'mfaEnabled',
    align: 'left',
  },
  {
    id: 'actions',
    align: 'right',
  },
]
