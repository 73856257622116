import * as React from 'react'

import {ClaimClassification} from '@settleindex/domain'
import {findById} from '@settleindex/fp'
import {Autocomplete, PrimaryText} from '@settleindex/react'

import {ClaimGroupFragment, UpdateClaimGroupInput} from '../../graphQLTypes'

export interface ClassificationSelectProps {
  claimGroup: ClaimGroupFragment
  claimGroupClassifications: ClaimClassification[]
  onClaimGroupChange: (id: string, update: UpdateClaimGroupInput) => void
  readonly?: boolean
}

export const ClassificationSelect: React.FC<ClassificationSelectProps> = ({
  claimGroup,
  claimGroupClassifications,
  onClaimGroupChange,
  readonly,
}) => {
  const claimClassificationOptions = React.useMemo(
    () => claimGroupClassifications.map(({title}) => ({value: title, label: title})),
    [claimGroupClassifications],
  )

  const maybeSelectedClassification = claimGroup.classificationId
    ? findById(claimGroupClassifications)(claimGroup?.classificationId)
    : undefined

  const defaultValue = maybeSelectedClassification ? maybeSelectedClassification.title : claimGroup.name

  if (readonly) {
    return <PrimaryText semibold>{defaultValue}</PrimaryText>
  }

  const onFinish = (value?: string) => {
    const maybeClassification = claimGroupClassifications.find((c) => c.title === value)
    const update = maybeClassification
      ? {classificationId: maybeClassification.id, name: value}
      : {classificationId: null, name: value}

    onClaimGroupChange(claimGroup.id, update)
  }

  return (
    <Autocomplete
      allowClear
      bold
      defaultValue={defaultValue}
      onFinish={onFinish}
      options={claimClassificationOptions}
      style={{width: '100%'}}
    />
  )
}
