import {HistoryMetrics} from '@settleindex/domain'
import {colors} from '@settleindex/react'

import {Role} from '../../graphQLTypes'

const {
  army,
  base5,
  field,
  flower,
  grape,
  green,
  hollyhock,
  lightning,
  lime,
  magenta,
  portage,
  raspberry,
  red,
  sea,
  violet,
} = colors

export interface Coding {
  [Role.Claimant]: [whenIncrease: string, whenDecrease: string]
  [Role.Defendant]: [whenIncrease: string, whenDecrease: string]
}

export const defaultCoding: Coding = {
  [Role.Claimant]: [green, red],
  [Role.Defendant]: [red, green],
}

export interface MetricVisuals {
  coding?: Coding
  color: string
  showValues: boolean
  subTitle: string
  title: string
}

export type MetricVisualsMap = typeof metricVisuals

export const metricVisuals = new Map<HistoryMetrics, MetricVisuals>([
  [
    HistoryMetrics['version.totalClaimsWithInterest'],
    {
      coding: defaultCoding,
      color: lightning,
      showValues: true,
      subTitle: 'Total claims, less counterclaims',
      title: 'Claims',
    },
  ],
  [
    HistoryMetrics['version.caseValueNet'],
    {
      coding: defaultCoding,
      color: green,
      showValues: true,
      subTitle: 'Total of risk-weighted Overall Values',
      title: 'Overall Position',
    },
  ],
  [
    HistoryMetrics['version.caseValuePresent'],
    {
      coding: defaultCoding,
      color: field,
      showValues: true,
      subTitle: 'Total of risk-weighted Current Values',
      title: 'Current Position',
    },
  ],
  [
    HistoryMetrics['version.caseValueForecast'],
    {
      coding: defaultCoding,
      color: army,
      showValues: true,
      subTitle: 'Total of risk-weighted Forecast Values',
      title: 'Forecast Position',
    },
  ],
  [
    HistoryMetrics['version.caseValueGross'],
    {
      coding: defaultCoding,
      color: lime,
      showValues: true,
      subTitle: 'Total of risk-weighted Award Values',
      title: 'Award Position',
    },
  ],
  [
    HistoryMetrics['version.perspectiveCost.amount'],
    {
      coding: {
        [Role.Claimant]: [red, green],
        [Role.Defendant]: [red, green],
      },
      color: magenta,
      showValues: true,
      subTitle: 'Total estimated potential costs',
      title: 'Potential Costs',
    },
  ],
  [
    HistoryMetrics['version.perspectiveCost.spent'],
    {
      coding: {
        [Role.Claimant]: [red, green],
        [Role.Defendant]: [red, green],
      },
      color: hollyhock,
      showValues: true,
      subTitle: 'Costs spent to date',
      title: 'Costs Spent',
    },
  ],
  [
    HistoryMetrics['version.perspectiveCost.forecast'],
    {
      coding: {
        [Role.Claimant]: [red, green],
        [Role.Defendant]: [red, green],
      },
      color: raspberry,
      showValues: true,
      subTitle: 'Costs forecast to be spent',
      title: 'Costs Forecast',
    },
  ],
  [
    HistoryMetrics['version.caseTargets.settlementTarget'],
    {
      coding: defaultCoding,
      color: sea,
      showValues: true,
      subTitle: 'Case target, reserve or provision',
      title: 'Case Target',
    },
  ],
  [
    HistoryMetrics['version.perspectiveCost.target'],
    {
      coding: {
        [Role.Claimant]: [red, green],
        [Role.Defendant]: [red, green],
      },
      color: grape,
      showValues: true,
      subTitle: 'Own costs spent and forecast',
      title: 'Own Costs Target',
    },
  ],
  [
    HistoryMetrics['version.caseTargets.expectedNetSettlementValue'],
    {
      coding: defaultCoding,
      color: violet,
      showValues: true,
      subTitle: 'Including costs spent and forecast',
      title: 'Overall Case Target',
    },
  ],
  [
    HistoryMetrics['version.caseTargets.currentGainLoss'],
    {
      coding: {
        [Role.Claimant]: [green, red],
        [Role.Defendant]: [green, red],
      },
      color: portage,
      showValues: true,
      subTitle: 'Considering costs spent to date',
      title: 'Potential Gain/Loss',
    },
  ],
  [
    HistoryMetrics['version.caseTargets.expectedGainLoss'],
    {
      coding: {
        [Role.Claimant]: [green, red],
        [Role.Defendant]: [green, red],
      },
      color: flower,
      showValues: true,
      subTitle: 'Considering costs spent and forecast',
      title: 'Forecast Gain/Loss',
    },
  ],
  [
    HistoryMetrics['event.eventValue'],
    {
      color: base5,
      showValues: false,
      subTitle: 'Overall value of offer after own costs',
      title: 'Settlement Offers',
    },
  ],
])
