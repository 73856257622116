import * as React from 'react'
import styled from 'styled-components'

import {Column, H3, SecondaryButton, usualTransition} from '@settleindex/react'
import {disputeTeamTestIds} from '@settleindex/testids/src/testIds'

import {InviteFragment} from '../../graphQLTypes'

interface Props {
  invite: InviteFragment
  onAccept: (disputeId: string) => void
}

const style = {
  width: '100px',
}

export const InviteCard: React.FC<Props> = ({invite, onAccept}) => {
  return (
    <CardColumn>
      <H3>{invite.disputeTitle}</H3>

      <SecondaryButton
        data-test-id={disputeTeamTestIds.acceptInviteOnDashboard(invite.disputeTitle)}
        medium
        onClick={() => onAccept(invite.disputeId)}
        style={style}
      >
        Accept invite
      </SecondaryButton>
    </CardColumn>
  )
}

const CardColumn = styled(Column)`
  height: 150px;
  justify-content: space-between;
  transition: all ${usualTransition};

  padding: 20px;
`
