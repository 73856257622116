import * as React from 'react'
import styled from 'styled-components'

import {CloseBlockIcon, colors, Drawer, H2, LeftRight} from '@settleindex/react'

const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  min-height: 60px;
  background-color: ${colors.base10};
  padding: 0 20px 0 30px;
  border-bottom: 1px solid ${colors.base9};
`

interface Props {
  close: () => void
  isOpen: boolean
  knowledgeBaseUrl?: string
}

export const KnowledgeBaseDrawer: React.FC<Props> = ({close, isOpen, knowledgeBaseUrl}) => (
  <Drawer
    bodyStyle={{padding: 0}}
    closable={false}
    mask
    maskClosable
    onClose={close}
    open={isOpen}
    placement="right"
    width={420}
  >
    <>
      <HeaderStyle>
        <LeftRight
          left={<H2 noMargin>Help & Support</H2>}
          right={
            <div onClick={close} style={{cursor: 'pointer'}}>
              <CloseBlockIcon />
            </div>
          }
          rowStyle={{width: '100%'}}
        />
      </HeaderStyle>
      <iframe
        src={knowledgeBaseUrl}
        style={{
          padding: '5px 0 0 0',
          margin: 0,
          width: '100%',
          border: 0,
          maxHeight: 'calc(100vh - 70px)',
          height: 'calc(100vh - 70px)',
        }}
      />
    </>
  </Drawer>
)
