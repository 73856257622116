import * as React from 'react'
import {Handle, Position} from 'react-flow-renderer'
import styled from 'styled-components'

import {cond, T} from '@settleindex/fp'
import {colors, OfferIcon, PrimaryText, SecondaryText, usualTransition} from '@settleindex/react'
import {outcomesMapTestIds} from '@settleindex/testids/src/testIds'

import {useDebugLabels} from '../../debug/useDebugLabels'
import {useNodes} from '../../nodes/context/useNodes'
import {nodeNamePrefix} from '../../nodes/nodeNamePrefix'
import {SumFormat} from '../../sum'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {useDecisionTree} from '../useDecisionTree'
import {NodeData} from './NodeData'

export const outcomeNodeWidth = 230
export const outcomeNodeHeight = 85
export const outcomeNodeType = 'outcomeNodeType'
const outcomeNodeId = (id: string) => `outcomeNode-${id}`

interface Props {
  data: NodeData
}

export const OutcomeNode: React.FC<Props> = ({data}) => {
  // const {isBranchValuesEnabled} = useDecisionTreeFeatureToggles()
  // Hard code it to be enabled
  const isBranchValuesEnabled = true
  const {valueToDisplay} = useNodes()
  const {
    version: {variant: versionVariant},
  } = useSafeVersion()
  const {damagesVisibleFor} = useDecisionTree()
  const {debugLabelsEnabled} = useDebugLabels()

  const isPreCaseBot = versionVariant === 'PRE_CASEBOT'

  const rightHandleStyle = React.useMemo(
    () => ({
      opacity: data.childIds.length ? 1 : 0,
    }),
    [data.childIds.length],
  )

  const valueSectionComponent = React.useMemo(
    () => (
      <div>
        <SecondaryText bold style={{color: colors.base4}}>
          <SumFormat sum={data[valueToDisplay]} />
        </SecondaryText>
      </div>
    ),
    [data, valueToDisplay],
  )

  const valueSection = React.useMemo(
    () =>
      cond([
        [() => data.isLeaf, () => valueSectionComponent],
        [() => !data.isLeaf && isBranchValuesEnabled, () => valueSectionComponent],
        [T, () => <></>],
      ])(),
    [data, isBranchValuesEnabled, valueSectionComponent],
  )

  const boxTestId = React.useMemo(() => outcomesMapTestIds.outcomeNode(data.name), [data.name])

  const damagesIcon = React.useMemo(
    () => (data.hasDamages && !isPreCaseBot ? <OfferIcon className="damagesIcon" /> : <></>),
    [data.hasDamages, isPreCaseBot],
  )

  const contentStyle = React.useMemo(() => {
    if (data.hasDamages && !isPreCaseBot) {
      return {
        cursor: 'nesw-resize',
      }
    }

    // Leaf Outcome
    if (!data.nodes.length) {
      return {cursor: 'zoom-in'}
    }

    return {
      cursor: 'default',
    }
  }, [data.hasDamages, data.nodes.length, isPreCaseBot])

  const nodeName = React.useMemo(() => {
    if (debugLabelsEnabled) {
      return `${data.name} (${data.id})`
    }

    if (data.kind === 'calculation') {
      return data.name
    }

    return nodeNamePrefix(data.name)
  }, [data.id, data.kind, data.name, debugLabelsEnabled])

  return (
    <Background>
      <Box
        data-test-id={boxTestId}
        hasDamages={data.hasDamages && !isPreCaseBot}
        isDamages={data.kind === 'calculation'}
        isDamagesVisible={damagesVisibleFor[data.id]}
      >
        <Handle position={Position.Left} type="target" />
        <a>
          <Content id={outcomeNodeId(data.id)} style={contentStyle}>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
              <div>
                <PrimaryText data-test-id="name" medium style={{color: colors.base4}}>
                  {nodeName}
                </PrimaryText>
              </div>
              {valueSection}
            </div>
            <div>{damagesIcon}</div>
          </Content>
        </a>
        <Handle position={Position.Right} style={rightHandleStyle} type="source" />
      </Box>
    </Background>
  )
}

const Background = styled.div`
  background-color: white;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
`

interface BoxProps {
  hasDamages: boolean
  isDamages: boolean
}

const Box = styled.div<BoxProps>`
  width: ${outcomeNodeWidth}px;
  height: ${outcomeNodeHeight}px;

  z-index: 200;

  transition: all ${usualTransition};

  border-radius: ${(props: BoxProps) => {
    return props.hasDamages || props.isDamages ? '20px 3px 20px 3px' : '3px'
  }};

  background-color: ${(props: BoxProps) => {
    if (props.hasDamages) {
      return hasDamagesBg
    }

    if (props.isDamages) {
      return damagesBg
    }

    return normalBg
  }};

  border: ${(props: BoxProps) => {
    if (props.isDamages) {
      return `${borderWidth} solid ${damagesBorderColor}`
    }

    return `${borderWidth} solid ${normalBg}`
  }};

  & ${PrimaryText}, & ${SecondaryText}, & .damagesIcon {
    color: ${(props: BoxProps) => {
      if (props.hasDamages) {
        return hasDamagesSelectedBg
      }

      return normalSelectedBg
    }}!important;
  }
`

const borderWidth = `3px`
const normalBg = colors.base8
const normalSelectedBg = colors.base4
const hasDamagesBg = normalBg
const hasDamagesSelectedBg = normalSelectedBg
const damagesBg = '#d7d2ee'
const damagesBorderColor = damagesBg
