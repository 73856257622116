import React from 'react'

import {safeValue} from '@settleindex/fp'

import {Stage} from '../Stage'

/**
 * TL;DR: add values to Webpack, runtimeConfig.js.tpl, Helm values.yaml & deployment.yaml
 *
 * App configuration is defined in two layers:
 *
 * First, Webpack picks up the values from the environment variables and injects
 * them into the bundle as `window.bundleConfig`. This is all that is required for
 * local development and it is mandatory for production use too. See
 * `webpack.config.js` / `DefinePlugin`.
 *
 * For production use (in dev/QA/prod) we serve the app from a docker container.
 * Since the bundle is generated only once, the `bundleConfig` is not suitable
 * for each environment. So before starting the web server in the container we
 * override the config values and store the overrides in
 * `modules/app/src/useConfig/runtimeConfig.js` that in turn creates a
 * `window.runtimeConfig` object.
 * The runtime config file is created by `modules/app/docker/docker.container-cmd.sh`,
 * it basically replaces $VARIABLE_NAME with the current environment variables.
 *
 * Some of the infrastructure related values can be imported to env using the script in the infra package:
 * `modules/infra/getStackEnvVars.sh`
 *
 * The `useConfig` hook looks up values in the following order:
 * - the highest priority is the `window.runtimeConfig` object (created at run time)
 * - then it looks up in the `bundleConfig` object (created at build time)
 * - finally it returns the default value if provided (see `getConfigValue` function)
 */

interface Config {
  bugsnagKeyApp: string
  // The URL to pass to the FV instance
  featurevisorUrl?: string
  // The SHA of the git commit of the source. Set by Webpack at build time.
  gitSha: string
  // The URL of the GraphQL server, e.g. https://graph.settleindex.cloud/graphql
  graphUrl: string
  // The OIDC IdP authority to fetch `.well-known/openid-configuration` from, e.g.
  // https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_ABCdef123
  oidcAuthority: string
  // The OIDC IdP client ID
  oidcClientId: string
  // The hostname to use for the OIDC logout endpoint - in case of AWS Cognito
  // it is different from the oidcAuthority. Optional, defaults to oidcAuthority
  // e.g. https://auth.dev.settleindex.cloud
  oidcEndpoint: string
  // The scope for the OIDC authN (optional)
  // getStackEnvVars.sh returns an array like string: ["a", "b"] which we try to parse, but plain "a b c" also works.
  oidcScope: string
  // The AWS region, used by the auth config (login)
  region: string
  // The stage as in local, dev, QA or prod
  stage: Stage
  // Weather to enable the user admin features - they only work with AWS Cognito
  // and should only be enabled in the official SettleIndex instance (not client
  // instances)
  userAdminEnabled: boolean
  // The URL to the Web Service (successor to the Graph)
  wsUrl: string
}

type MaybeConfig = Record<string, string>

declare let bundleConfig: MaybeConfig
declare global {
  interface Window {
    runtimeConfig: MaybeConfig
  }
}

export const useConfig = (): Config => {
  const [config, setConfig] = React.useState<Config | undefined>(undefined)

  React.useEffect(() => {
    setConfig(getConfig())
  }, [])

  return config ?? getConfig()
}

/**
 * getConfigValue guarantees a value: it either finds one in the config objects, or
 * returns the default value (second argument).
 * If no value is found, it throws an error.
 *
 * Use UPPER_CASE names!
 */
export const getConfigValue = (key: string, defaultValue?: string): string =>
  safeValue((window.runtimeConfig?.[key] ?? bundleConfig[key] ?? defaultValue) as string, `useConfig/${key}`)

const getConfig = (): Config => ({
  gitSha: getConfigValue('GIT_SHA', 'no-git-sha'),
  graphUrl: getConfigValue('GRAPH_URL'),
  oidcAuthority: getConfigValue('OIDC_AUTHORITY'),
  oidcClientId: getConfigValue('OIDC_CLIENT_ID'),
  oidcEndpoint: getConfigValue('OIDC_ENDPOINT', getConfigValue('OIDC_AUTHORITY')),
  oidcScope: getConfigValue('OIDC_SCOPE', '')
    .replace('[', '')
    .replace(']', '')
    .replaceAll('"', '')
    .replaceAll(',', ' '),
  region: getConfigValue('REGION'),
  stage: getConfigValue('STAGE') as Stage,
  userAdminEnabled: getConfigValue('AWS_COGNITO_ENABLED') === 'true',
  featurevisorUrl: getConfigValue('FEATUREVISOR_URL'),
  wsUrl: getConfigValue('WS_URL'),
  bugsnagKeyApp: getConfigValue('BUGSNAG_KEY_APP', ''),
})
