import * as React from 'react'

import {Option, TertiarySelect} from '@settleindex/react'
import {outcomesTestIds} from '@settleindex/testids/src/testIds'

import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {useNodes} from '../../nodes/context/useNodes'
import {getPartiesToShow} from '../../nodes/getPartiesToShow'
import {PerspectiveAwareText} from '../../party/perspectiveAwareColors/PerspectiveAwareText'

export const PartySelect: React.FC = () => {
  const {dispute} = useSafeDispute()
  const {selectedNodePartyId, setSelectedNodePartyId} = useNodes()

  const partiesToShow = React.useMemo(
    () =>
      getPartiesToShow({
        parties: dispute.parties,
        perspectiveId: dispute.perspectiveId,
        perspectiveRole: dispute.perspectiveRole,
      }),
    [dispute.parties, dispute.perspectiveId, dispute.perspectiveRole],
  )

  const options = React.useMemo(
    () =>
      partiesToShow.map((party, selectPartyIndex) => (
        <Option data-test-id={outcomesTestIds.partySelectOption(selectPartyIndex)} key={party.id} value={party.id}>
          <PerspectiveAwareText isPerspective={dispute.perspectiveId === party.id}>
            {party.label} View
          </PerspectiveAwareText>
        </Option>
      )),
    [dispute.perspectiveId, partiesToShow],
  )

  const selectValue = React.useMemo(
    () => selectedNodePartyId ?? partiesToShow[0].id,
    [partiesToShow, selectedNodePartyId],
  )

  return (
    <TertiarySelect
      data-test-id={outcomesTestIds.partySelect}
      dropdownMatchSelectWidth={false}
      onChange={setSelectedNodePartyId}
      value={selectValue}
    >
      {options}
    </TertiarySelect>
  )
}
