import * as React from 'react'
import {NodeMouseHandler} from 'react-flow-renderer'

import {useNodes} from '../../nodes/context/useNodes'
import {useOutcomeDrawer} from '../../nodes/drawer/useOutcomeDrawer'
import {handleOutcomeClick} from '../outcome/handleOutcomeClick'
import {useDecisionTree} from '../useDecisionTree'

export const useOnNodeClick = (): NodeMouseHandler => {
  const {selectedPartyNodes} = useNodes()
  const {toggleDamagesVisibleForId} = useDecisionTree()
  const {openDrawer, setDrawerOutcomeId} = useOutcomeDrawer()

  const openDrawerForOutcome = React.useCallback(
    (id: string) => {
      setDrawerOutcomeId(id)
      openDrawer()
    },
    [openDrawer, setDrawerOutcomeId],
  )

  return React.useCallback<NodeMouseHandler>(
    (_, node) => {
      handleOutcomeClick({
        nodes: selectedPartyNodes,
        nodeId: node.id,
        toggleDamagesVisibleForId,
        openDrawer: openDrawerForOutcome,
      })
    },
    [openDrawerForOutcome, selectedPartyNodes, toggleDamagesVisibleForId],
  )
}
