import {Edge, Node} from 'react-flow-renderer'

import {sortOutcomesByRankAsc} from '@settleindex/domain'
import {sort} from '@settleindex/fp'

import {NodeTree} from '../../nodes/tree/NodeTree'
import {makeEdge} from '../edge/makeEdge'
import {startId} from '../start/startId'
import {makeOutcomeElement} from './makeOutcomeElement'
import {NodeData} from './NodeData'
import {WeightById} from './WeightById'

interface Args {
  edges: Edge[]
  nodeTree: NodeTree[]
  nodes: Node[]
  readonly: boolean
  weightById: WeightById
}

const sorter = sort(sortOutcomesByRankAsc)

export const nodeTreeToElements = ({
  edges = [],
  nodeTree,
  nodes = [],
  readonly,
  weightById,
}: Args): [Node<NodeData>[], Edge<NodeData>[]] => {
  let levelIndex = 0

  const sortedNodes = sorter(nodeTree)

  sortedNodes.forEach((node) => {
    nodes.push(makeOutcomeElement({node, levelIndex}))
    levelIndex++

    const is1stLevelOutcome = !node.parentId
    is1stLevelOutcome && edges.push(makeEdge({readonly, sourceId: startId, targetNode: node}))

    if (node.nodes.length) {
      const sortedChildNodes = sorter(node.nodes)

      sortedChildNodes.forEach((childNode) => {
        edges.push(
          makeEdge({
            readonly,
            sourceId: node.id,
            targetNode: childNode,
          }),
        )
      })

      nodeTreeToElements({
        edges,
        nodes,
        nodeTree: sortedChildNodes,
        readonly,
        weightById,
      })
    }
  })

  return [nodes, edges]
}
