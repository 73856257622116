import * as React from 'react'

import {DisputeClassification} from '@settleindex/domain'
import {ChevronDownIcon, FormItem, TreeSelect} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {fieldNames} from './fieldNames'
import {makeDisputeClassificationTreeData} from './makeDisputeClassificationTreeData'

interface Props {
  disputeClassifications: DisputeClassification[]
}

export const Classification: React.FC<Props> = ({disputeClassifications}) => {
  const classificationOptionsTree = React.useMemo(
    () => makeDisputeClassificationTreeData(disputeClassifications),
    [disputeClassifications],
  )

  return (
    <FormItem
      label="Case Type"
      name={fieldNames.classificationId}
      rules={[
        {
          required: true,
          message: 'Please select a case type',
        },
      ]}
    >
      <TreeSelect
        data-test-id={disputeFormTestIds.editClassification}
        placeholder="Select..."
        suffixIcon={<ChevronDownIcon />}
        treeData={classificationOptionsTree}
        treeDefaultExpandAll
      />
    </FormItem>
  )
}
