import styled from 'styled-components'

import {AccordionPanel as AccordionPanelBase, H2} from '@settleindex/react'

export const AccordionPanel = styled(AccordionPanelBase)`
  & .ant-collapse-header {
    padding-bottom: 15px !important;
    padding-top: 15px !important;
  }

  /* Move the expand icon to the left */
  & .ant-collapse-expand-icon a {
    left: 0 !important;
  }
  & .ant-collapse-header-text {
    margin-left: 25px;
  }
  /* END Move the expand icon to the left */

  & .ant-collapse-expand-icon a {
    margin-top: 1px !important;
  }

  /* Rotate the open icon so it points down */
  & [aria-expanded='true'] a svg {
    transform: rotate(180deg);
  }
  /* Rotate the closed icon so it points right */
  & [aria-expanded='false'] a svg {
    transform: rotate(-90deg);
  }

  /* Push content below title so the icon is outside the content */
  & .ant-collapse-content-box {
    padding-left: 25px !important;
  }

  & .ant-collapse-header-text ${H2} {
    margin-bottom: 0;
  }
`
