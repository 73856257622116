import dayjs from 'dayjs'
import * as React from 'react'

import {
  CenterLine,
  DatePicker,
  H1,
  H2,
  HSpaceMedium,
  HSpaceSmall,
  LeftRight,
  LinkToTop,
  PrimaryButton,
  Row,
  SecondaryText,
  StateToggleButtons,
  Table,
  TableColumn,
  TableRow,
  VSpace,
  VSpaceMedium,
} from '@settleindex/react'
import {disputeTestIds} from '@settleindex/testids/src/testIds'

import {date} from '../../date/date'
import {MemberAvatar} from '../../disputeTeam/avatar/MemberAvatar'
import {useDisputeTeam} from '../../disputeTeam/state/useDisputeTeam'
import {AccessKind, DisputeFragment, Lifecycle, VersionPatchInput} from '../../graphQLTypes'
import {Tip} from '../../help'
import {paths} from '../../routing'
import {lifecycleTags} from '../../version/tags/lifecycleTags'
import {PreCaseBotTag} from '../../version/tags/tags'
import {DuplicateButton} from '../../version/widgets/DuplicateButton'
import {VersionMenu} from '../../version/widgets/VersionMenu'
import {NoVersions} from './NoVersions'

const dateWidth = 130
const editOrViewButtonLabel = new Map<boolean, string>([
  [true, 'View'],
  [false, 'Edit'],
])

type VersionColumnNames = 'left' | 'right'

const versionColumns: TableColumn<VersionColumnNames>[] = [
  {id: 'left', align: 'left', width: '45%'},
  {id: 'right', align: 'right'},
]

export interface VersionListProps {
  addEmptyVersion: () => void
  archiveVersion: (id: string) => void
  dispute: DisputeFragment
  duplicateVersion: (id: string) => void
  patchVersion: (versionId: string, patch: VersionPatchInput) => void
  userId: string
}

export const VersionList: React.FC<VersionListProps> = ({
  addEmptyVersion,
  archiveVersion,
  dispute,
  duplicateVersion,
  patchVersion,
  userId,
}) => {
  const {disputeTeam} = useDisputeTeam()

  const [lifecycleFilter, setLifecycleFilter] = React.useState<Lifecycle.Draft | Lifecycle.Report | undefined>(
    undefined,
  )
  const [showOnlyMyVersions, setShowOnlyMyVersions] = React.useState<boolean>(false)
  const [isSortAbc, setIsSortAbc] = React.useState<boolean>(false)
  const {versions} = dispute
  const filteredVersions = React.useMemo(
    () =>
      versions
        .filter((version) => (!lifecycleFilter ? true : version.lifecycle === lifecycleFilter))
        .filter((version) => (!showOnlyMyVersions ? true : version.auth.createdBy.id === userId)),
    [lifecycleFilter, showOnlyMyVersions, userId, versions],
  )

  const sortedVersions = React.useMemo(
    () => (isSortAbc ? [...filteredVersions].sort((a, b) => a.title.localeCompare(b.title)) : filteredVersions),
    [filteredVersions, isSortAbc],
  )

  const userDisputeTeamAccessKind = React.useMemo(
    () => disputeTeam?.members.find((m) => m.id === userId)?.accessKind,
    [disputeTeam?.members, userId],
  )

  const allowedToCreateNewVersion = React.useMemo(
    () =>
      userDisputeTeamAccessKind &&
      [AccessKind.DISPUTE_CREATOR, AccessKind.DISPUTE_TEAM_MEMBER].includes(userDisputeTeamAccessKind),
    [userDisputeTeamAccessKind],
  )

  const versionRows: TableRow<VersionColumnNames>[] = React.useMemo(
    () =>
      sortedVersions.map((version, versionIndex) => {
        const isVersionCreator = userId === version.auth.createdBy.id
        const isAdminTeamMember = userDisputeTeamAccessKind === AccessKind.DISPUTE_CREATOR
        const isExternalMember = userDisputeTeamAccessKind === AccessKind.EXTERNAL_TEAM_MEMBER
        const userIsVersionOwnerOrAdminMember = isVersionCreator || isAdminTeamMember
        const isPublishedReport = version.lifecycle === Lifecycle.Report
        const isPreCaseBot = version.variant === 'PRE_CASEBOT'
        const showReportDatePicker = userIsVersionOwnerOrAdminMember && isPublishedReport && !isPreCaseBot
        const defaultReportDate = dayjs(version.reportDate ?? undefined)
        const actions = (
          <>
            {!isExternalMember ? (
              <DuplicateButton
                onClick={() => duplicateVersion(version.id)}
                testId={disputeTestIds.duplicateVersionButton(versionIndex)}
              />
            ) : (
              <></>
            )}
            <LinkToTop to={paths.version(dispute.id, version.id)}>
              <PrimaryButton
                data-test-id={disputeTestIds.viewVersionButton(versionIndex)}
                style={{marginLeft: 10, width: 50, minWidth: 50}}
              >
                {editOrViewButtonLabel.get(version.readonly)}
              </PrimaryButton>
            </LinkToTop>
            <VersionMenu
              archive={() => archiveVersion(version.id)}
              isExternalMember={isExternalMember}
              lifecycle={version.lifecycle}
              toDraft={() => patchVersion(version.id, {lifecycle: Lifecycle.Draft})}
              toReport={() => patchVersion(version.id, {lifecycle: Lifecycle.Report})}
              userIsVersionOwnerOrAdminMember={userIsVersionOwnerOrAdminMember}
            />
          </>
        )

        return {
          id: version.id,
          cells: {
            left: (
              <LinkToTop to={paths.version(dispute.id, version.id)}>
                <H2 data-test-id={`version-title-link`} noMargin>
                  {version.title}
                </H2>
              </LinkToTop>
            ),
            right: (
              <>
                <MemberAvatar user={version.auth.createdBy} />
                <HSpaceSmall />

                {showReportDatePicker ? (
                  <DatePicker
                    allowClear={false}
                    bordered={false}
                    defaultValue={defaultReportDate}
                    format="D MMMM YYYY"
                    onChange={(newDate: dayjs.Dayjs | null) =>
                      patchVersion(version.id, {reportDate: newDate?.toISOString()})
                    }
                    style={{width: dateWidth}}
                    suffixIcon={<></>}
                  />
                ) : (
                  <SecondaryText style={{width: dateWidth}}>{date(version.updatedAt)}</SecondaryText>
                )}
                <div style={{marginLeft: 10, marginRight: 10}}>
                  {isPreCaseBot ? <PreCaseBotTag /> : lifecycleTags.get(version.lifecycle ?? Lifecycle.Draft)}
                </div>
                {actions}
              </>
            ),
          },
        }
      }),
    [archiveVersion, dispute.id, duplicateVersion, patchVersion, sortedVersions, userDisputeTeamAccessKind, userId],
  )

  const versionList = React.useMemo(
    () => <Table columns={versionColumns} rows={versionRows} stateId={disputeTestIds.versionList} />,
    [versionRows],
  )

  const lifecycleFilterButtons = React.useMemo(
    () => (
      <StateToggleButtons<Lifecycle | undefined>
        buttons={[
          {title: 'All Models', valueToSet: undefined},
          {title: 'Published', valueToSet: Lifecycle.Report},
          {title: 'Drafts', valueToSet: Lifecycle.Draft},
        ]}
        canEmpty={false}
        currentValue={lifecycleFilter}
        setter={setLifecycleFilter}
      />
    ),
    [lifecycleFilter],
  )

  const showOnlyMyVersionsButton = React.useMemo(
    () => (
      <StateToggleButtons<boolean>
        buttons={[{valueToSet: true, title: 'My Models'}]}
        canEmpty
        currentValue={showOnlyMyVersions}
        emptyValue={false}
        setter={setShowOnlyMyVersions}
      />
    ),
    [showOnlyMyVersions],
  )

  const sortAbcButton = React.useMemo(
    () => (
      <StateToggleButtons<boolean>
        buttons={[{valueToSet: true, title: 'Sort A-Z'}]}
        canEmpty
        currentValue={isSortAbc}
        emptyValue={false}
        setter={setIsSortAbc}
      />
    ),
    [isSortAbc],
  )

  const left = React.useMemo(
    () => (
      <Row>
        {lifecycleFilterButtons}
        <HSpaceMedium />
        {showOnlyMyVersionsButton}
        <HSpaceMedium />
        {sortAbcButton}
      </Row>
    ),
    [lifecycleFilterButtons, showOnlyMyVersionsButton, sortAbcButton],
  )

  const right = React.useMemo(
    () =>
      allowedToCreateNewVersion ? (
        <Tip id="Model.NewVersionButton">
          <PrimaryButton
            data-test-id={disputeTestIds.createVersionButton}
            medium
            onClick={addEmptyVersion}
            type="primary"
          >
            New Model
          </PrimaryButton>
        </Tip>
      ) : (
        <></>
      ),
    [addEmptyVersion, allowedToCreateNewVersion],
  )

  const noFilterMatch = React.useMemo(
    () => (
      <>
        <VSpace space={25} />
        <CenterLine>
          <SecondaryText>No models match this filter</SecondaryText>
        </CenterLine>
      </>
    ),
    [],
  )

  const filteredContent = React.useMemo(
    () => (filteredVersions.length ? versionList : noFilterMatch),
    [filteredVersions.length, noFilterMatch, versionList],
  )

  const content = React.useMemo(
    () => (
      <>
        {versions.length ? (
          <>
            <LeftRight left={left} leftSpan={20} right={right} rightSpan={4} />
            <VSpaceMedium />
            {filteredContent}
          </>
        ) : (
          <NoVersions addEmptyVersion={addEmptyVersion} allowedToCreateNewVersion={allowedToCreateNewVersion} />
        )}
      </>
    ),
    [addEmptyVersion, allowedToCreateNewVersion, filteredContent, left, right, versions.length],
  )

  return (
    <>
      <H1 noMargin>Models</H1>
      <VSpaceMedium />
      {content}
    </>
  )
}
