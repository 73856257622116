import {PartyInForm} from './PartyInForm'

export const partyFieldNames: Record<keyof PartyInForm, string> = {
  id: 'id',
  industry: 'industry',
  industrySection: 'industrySection',
  isRemovable: 'isRemovable',
  label: 'label',
  lawFirm: 'lawFirm',
  leadSolicitor: 'leadSolicitor',
  name: 'name',
  type: 'type',
}
