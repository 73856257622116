import React from 'react'

import {FormInstance, H3, VSpaceLarge, VSpaceSmall} from '@settleindex/react'

import {onAddIssue} from './form/onAddIssue'
import {IssuesTable} from './IssuesTable'
import {useCaseBot} from './useCaseBot'

export const IssuesSection: React.FC<{form: FormInstance}> = ({form}) => {
  const {cumulativeWeights} = useCaseBot()

  return (
    <>
      <H3>Cumulative Defences (Claimant Hurdles)</H3>
      <IssuesTable
        cumulativeTooltip={'Aggregate chance of the Claimant winning'}
        lead="To Win, the Claimant must first succeed on all of these Issues"
        myCumulativeWeight={cumulativeWeights.loseFinals.myWeight}
        name="loseFinals"
        onAddIssue={onAddIssue(form, 'loseFinals')}
        theirCumulativeWeight={cumulativeWeights.loseFinals.theirWeight}
      />
      <VSpaceLarge />

      <H3>Cumulative Bases for Liability (Defendant Hurdles)</H3>
      <IssuesTable
        cumulativeTooltip={'Aggregate chance of the Claimant winning'}
        lead="To Win the Claimant need only succed on one of these Issues"
        myCumulativeWeight={cumulativeWeights.winFinals.myWeight}
        name="winFinals"
        onAddIssue={onAddIssue(form, 'winFinals')}
        theirCumulativeWeight={cumulativeWeights.winFinals.theirWeight}
      />
      <VSpaceLarge />

      <H3>Independent Issues (Permutations)</H3>
      <IssuesTable
        lead="Winning or losing these issues affect a part of but not the whole case"
        name="others"
        onAddIssue={onAddIssue(form, 'others')}
      />
      <VSpaceSmall />
    </>
  )
}
