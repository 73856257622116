import * as React from 'react'

import {NoData, PrimaryButton2} from '@settleindex/react'

import {useVersionVariablesDrawer} from '../../versionVariablesDrawer/useVersionVariablesDrawer'

interface Props {
  readonly?: boolean
}

export const CostRecoveryTableEmpty: React.FC<Props> = ({readonly}) => {
  const {editCosts} = useVersionVariablesDrawer()

  return (
    <NoData
      button={
        !readonly && (
          <PrimaryButton2 onClick={editCosts} size="medium" weight="medium">
            Manage Cost Variables
          </PrimaryButton2>
        )
      }
      heading="No Costs"
      text="To model cost recovery
          in this outcome, you must first add some cost variables. Cost
          variables are managed centrally and can then be adjusted in each
          outcome to reflect the proportion of costs that would be recovered."
    />
  )
}
