import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {useAddEmptyVersionMutation, VersionOverviewFragment} from '../../graphQLTypes'
import {useHistory} from '../../routing'
import {paths} from '../../routing/paths'

const useAddVersion = (disputeId: string, handler?: (response?: VersionOverviewFragment) => void) => {
  const [addVersionMutation] = useAddEmptyVersionMutation()
  const {runAsync} = useAppIsLoading()

  return () =>
    runAsync(async () => {
      const response = await addVersionMutation({variables: {id: disputeId}})

      return handler ? handler(response.data?.addEmptyVersion.versions[0]) : response
    })
}

export const useAddVersionAndRedirect = (disputeId: string) => {
  const {gotoTop} = useHistory()

  return useAddVersion(disputeId, (newVersion?: VersionOverviewFragment) => {
    newVersion && gotoTop(paths.version(disputeId, newVersion.id))
  })
}
