import * as React from 'react'

import {Tooltip} from '@settleindex/react'

import {TipContent, TipId} from './TipContent'

type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom'

interface Props {
  id?: TipId
  mouseEnterDelay?: number
  placement?: TooltipPlacement
  title?: string
}

export const Tip: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  id,
  mouseEnterDelay = 0.3,
  placement = 'top',
  title = '',
}) => (
  <Tooltip mouseEnterDelay={mouseEnterDelay} placement={placement} title={id ? TipContent[id] : title}>
    {children}
  </Tooltip>
)
