import * as React from 'react'

import {usePrimaryNav} from '../../navigation'

export const useCollapsePrimaryNav = () => {
  const {isPrimaryNavExpanded, isPrimaryNavLocked, setIsPrimaryNavExpanded, setIsPrimaryNavLocked} = usePrimaryNav()
  const [original] = React.useState<[expanded: boolean, locked: boolean]>([isPrimaryNavExpanded, isPrimaryNavLocked])

  React.useEffect(() => {
    setIsPrimaryNavExpanded(false)
    setIsPrimaryNavLocked(true)

    return () => {
      setIsPrimaryNavExpanded(original[0])
      setIsPrimaryNavLocked(original[1])
    }
  }, [original, setIsPrimaryNavExpanded, setIsPrimaryNavLocked])
}
