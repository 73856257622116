import * as React from 'react'

import {HistoryMetrics} from '@settleindex/domain'
import {
  Checkbox,
  Circle,
  PrimaryText,
  Table,
  TableCell,
  TableColumn,
  TableRow,
  TertiaryLabel,
  VSpaceSmall,
} from '@settleindex/react'

import {DebugLabel} from '../../../debug/DebugLabel'
import {MetricVisuals, MetricVisualsMap} from '../metricVisuals'

type Cols = 'title' | 'subTitle'

export const titleColumn: TableColumn<Cols> = {id: 'title', align: 'left', label: 'Metric'}
const descriptionColumn: TableColumn<Cols> = {id: 'subTitle', align: 'left', label: 'Description'}
const defaultLeftColumns: TableColumn<Cols>[] = [titleColumn, descriptionColumn]

interface Props<ExtraCols extends string> {
  /**
   * The list of metrics that are selected
   */
  checkedMetrics: HistoryMetrics[]
  /**
   * The state ID of the table
   */
  dataTableStateId: string
  /**
   * The number of lines/metrics the table will show above the "Show More" button.
   * To show everything don't set the property.
   */
  defaultNumberOfVisibleMetrics?: number
  /**
   * Any extra columns to add to the right of the default columns. The final
   * list of columns is `leftColumns + extraColumns`.
   */
  extraColumns: TableColumn<ExtraCols>[]
  /**
   * Override the first two left columns - they are title and description by
   * default. The final list of columns is `leftColumns + extraColumns`.
   */
  leftColumns?: TableColumn<Cols>[]
  /**
   * A function to generate the extra cells for each metric.
   * The component will create the checkboxes and titles/subtitles, this
   * function can be used to create the data for any extra columns.
   * It has to return an object where the keys are column names and the values
   * are {@link TableCell} definitions, e.g.:
   *
   * ```
   * {
   *  title: <h1>{something.title}</h1>,
   * }
   * ```
   */
  makeExtraCells?: (
    metricPath: HistoryMetrics,
    visualConfig: MetricVisuals,
  ) => {
    [K in ExtraCols]?: TableCell
  }
  /**
   * The {@link MetricVisualsMap} that defines which metrics are displayed and how
   */
  metricsToShow: MetricVisualsMap
  /**
   * Turns off interactivity/checkboxes if true
   */
  nonInteractive: boolean
  /**
   * The event handler for checkbox clicks. Used for state management by the
   * parent.
   */
  onCheckboxClick: (metricPath: HistoryMetrics, isChecked: boolean) => void
  /**
   * Extra rows that appear above the calculated rows, right under the column
   * titles.
   */
  prefixRows?: TableRow<Cols | ExtraCols>[]
  /**
   * Weather to render the colored circle between the checkbox and the metric
   * title. Default is true.
   */
  renderMetricCircle?: boolean
}

export function MetricsSelector<ExtraCols extends string>({
  checkedMetrics = [],
  dataTableStateId,
  defaultNumberOfVisibleMetrics,
  extraColumns,
  leftColumns = defaultLeftColumns,
  makeExtraCells,
  metricsToShow,
  nonInteractive,
  onCheckboxClick,
  prefixRows = [],
  renderMetricCircle = true,
}: Props<ExtraCols>): React.ReactElement<Props<ExtraCols>> {
  const cursor = nonInteractive ? 'auto' : 'pointer'

  const columns: TableColumn<Cols | ExtraCols>[] = React.useMemo(
    () => [...leftColumns, ...extraColumns],
    [extraColumns, leftColumns],
  )

  const calculatedRows = React.useMemo(
    () =>
      [...metricsToShow.entries()].map(([metricPath, metric]) => {
        const extraCells = makeExtraCells ? makeExtraCells(metricPath, metric) : {}

        return {
          id: metricPath,
          cells: {
            title: (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Checkbox
                  checked={checkedMetrics.includes(metricPath)}
                  disabled={nonInteractive}
                  id={metricPath}
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onCheckboxClick(metricPath, event.target.checked)
                  }
                  style={{marginRight: 20}}
                />
                {renderMetricCircle ? <Circle color={metric.color} style={{marginRight: 20}} /> : null}
                <DebugLabel title={metricPath}>
                  <label htmlFor={metricPath} style={{cursor}}>
                    <PrimaryText medium style={{display: 'inline-block'}}>
                      {metric.title}
                    </PrimaryText>
                  </label>
                </DebugLabel>
              </div>
            ),
            subTitle: (
              <label htmlFor={metricPath} style={{cursor}}>
                <TertiaryLabel>{metric.subTitle}</TertiaryLabel>
              </label>
            ),
            ...extraCells,
          },
        }
      }),
    [checkedMetrics, cursor, makeExtraCells, metricsToShow, nonInteractive, onCheckboxClick, renderMetricCircle],
  )

  const rows = [...prefixRows, ...calculatedRows]

  return (
    <>
      <VSpaceSmall />
      <Table<Cols | ExtraCols>
        collapseRowsAfterIndex={defaultNumberOfVisibleMetrics}
        columns={columns}
        rows={rows}
        stateId={dataTableStateId}
      />
    </>
  )
}
