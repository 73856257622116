import {HistoryMetrics} from '@settleindex/domain'
import {isDefined, path} from '@settleindex/fp'

import {metricVisuals} from '../../history/metricVisuals'
import {MaybeHistoryPoints} from '../MaybeHistoryPoint'
import {MetricData} from './MetricData'

interface Args {
  historyPoints: MaybeHistoryPoints
  metricsToShow: HistoryMetrics[]
}

export const getBarValuesForEnabledMetrics = ({historyPoints, metricsToShow}: Args): MetricData[] =>
  [...metricVisuals.entries()]
    .map(([metricPath, visuals]) => {
      if (!metricsToShow.includes(metricPath)) {
        return undefined
      }

      return {
        color: visuals.color,
        key: metricPath,
        numberOfVersions: historyPoints.length,
        title: metricVisuals.get(metricPath)?.title ?? '',
        version1: path<number>(metricPath, historyPoints?.[0]) ?? 0,
        version1Title: historyPoints?.[0]?.version?.title ?? '',
        version2: path<number>(metricPath, historyPoints?.[1]) ?? 0,
        version2Title: historyPoints?.[1]?.version?.title ?? '',
        version3: path<number>(metricPath, historyPoints?.[2]) ?? 0,
        version3Title: historyPoints?.[2]?.version?.title ?? '',
        weighted: path<number>(metricPath, historyPoints?.[3]) ?? 0,
      }
    })
    .filter(isDefined)
