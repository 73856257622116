import * as React from 'react'

import {PrimaryText, SecondaryText} from '@settleindex/react'

import {DebugLabel} from '../../../debug/'
import {OpponentValueFragment, VersionFragment} from '../../../graphQLTypes'
import {SumText} from '../../../sum'

const valuesToShow: {
  description: string
  isHighlighted?: boolean
  key: 'caseValueNet' | 'caseValuePresent' | 'caseValueForecast' | 'caseValueGross'
  stage: string
}[] = [
  {
    key: 'caseValueNet',
    stage: 'Overall Position',
    description: 'Overall Value',
  },
  {
    key: 'caseValuePresent',
    stage: 'Current Position',
    description: 'Current Value',
    isHighlighted: true,
  },
  {
    key: 'caseValueForecast',
    stage: 'Forecast Position',
    description: 'Forecast Value',
  },
  {
    key: 'caseValueGross',
    stage: 'Award Position',
    description: 'Award Value',
  },
]

export const makeRows = (version: VersionFragment, opponentValues?: OpponentValueFragment) => {
  const separator = <PrimaryText medium>-</PrimaryText>

  return valuesToShow.map((v) => ({
    id: v.key,
    cells: {
      stage: (
        <PrimaryText bold={v.isHighlighted} medium={!v.isHighlighted}>
          {v.stage}
        </PrimaryText>
      ),
      description: <SecondaryText medium>{v.description}</SecondaryText>,
      perspective: (
        <DebugLabel title={`version.${v.key}`}>
          <SumText bold={v.isHighlighted} sum={version[v.key]} />
        </DebugLabel>
      ),
      separator,
      opponent: (
        <DebugLabel title={`version.opponentValues[].${v.key}`}>
          <SumText bold={v.isHighlighted} sum={opponentValues?.[v.key]} />
        </DebugLabel>
      ),
    },
  }))
}
