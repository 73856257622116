import * as React from 'react'

import {FormItem, SecondarySelect} from '@settleindex/react'

import {fieldNames} from './fieldNames'
import {PartyInForm} from './party/PartyInForm'

interface Props {
  initialValue?: string
  parties?: PartyInForm[]
}

export const Perspective: React.FC<Props> = ({initialValue, parties}) => (
  <FormItem
    initialValue={initialValue}
    label="Party Representing"
    name={fieldNames.perspectiveId}
    rules={[
      {
        required: true,
        message: 'Please select the perspective',
      },
    ]}
  >
    <SecondarySelect
      data-test-id={`perspective`}
      options={parties?.map((p) => ({
        label: p.label,
        value: p.id,
      }))}
      placeholder="Select..."
      style={{
        width: '100%',
      }}
    />
  </FormItem>
)
