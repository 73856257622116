import React from 'react'
import {useRecoilState} from 'recoil'

import {isDefined} from '@settleindex/fp'

import {DisputeFragment} from '../../graphQLTypes'
import {useVersionContainer} from '../../version/context/useVersionContainer'
import {disputeAtom} from './disputeAtom'

interface Args {
  /**
   * The ID of the required Dispute. If this argument is present and the
   * existing Dispute has a different ID, then the state will be reset and
   * undefined will be returned.
   */
  requiredId?: string
  /**
   * Reset the Dispute state to undefined first, if the argument is true.
   */
  reset?: boolean
  /**
   * The hook will set the Dispute to this value when it is defined. Can
   * be used for passing `data?.dispute` from an Apollo hook result.
   */
  setDisputeFrom?: DisputeFragment | null
}

export const useDisputeContainer = (args?: Args) => {
  const [dispute, setDispute] = useRecoilState(disputeAtom)
  const {resetVersion} = useVersionContainer()
  const wantsToReset = args?.reset === true
  const requiresSpecificDispute = isDefined(args?.requiredId)

  const resetDispute = React.useCallback(() => {
    setDispute(undefined)
    resetVersion()
  }, [resetVersion, setDispute])

  React.useEffect(() => {
    if (wantsToReset) {
      resetDispute()
    }

    const wrongDisputeInState = dispute?.id !== args?.requiredId
    if (requiresSpecificDispute && wrongDisputeInState) {
      resetDispute()
    }
  }, [args?.requiredId, dispute?.id, requiresSpecificDispute, resetDispute, wantsToReset])

  React.useEffect(() => {
    args?.setDisputeFrom && setDispute(args.setDisputeFrom)
  }, [args?.setDisputeFrom, setDispute])

  return {
    dispute,
    resetDispute,
    setDispute,
  }
}
