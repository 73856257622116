import * as React from 'react'

import {Row, spaceLarge, VSpaceLarge} from '@settleindex/react'

import {useDebugPanel} from '../../debug/useDebugPanel'
import {usePageTitle} from '../../pageTitle/usePageTitle'
import {useSafeDispute} from '../context/useSafeDispute'
import {Frame} from '../Frame'
import {TitleAndReference} from '../TitleAndReference'
import {CaseInformationBox} from './CaseInformationBox'
import {InsuranceInformationBox} from './InsuranceInformationBox'

interface Props {
  versionList: React.ReactElement
}

export const DisputeDashboardPage: React.FC<Props> = ({versionList}) => {
  const {dispute} = useSafeDispute()
  usePageTitle(dispute.title)
  const {setDebugPanelContent} = useDebugPanel()
  React.useEffect(() => {
    setDebugPanelContent(dispute)

    return () => setDebugPanelContent(undefined)
  }, [dispute, dispute.history, setDebugPanelContent])

  return (
    <>
      <Frame activeKey="dashboard" dispute={dispute}>
        <TitleAndReference dispute={dispute} />
        <VSpaceLarge />

        {versionList}
        <VSpaceLarge />

        <Row gutter={spaceLarge}>
          <CaseInformationBox dispute={dispute} />
          <InsuranceInformationBox dispute={dispute} />
        </Row>
      </Frame>
    </>
  )
}
