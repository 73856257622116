import * as React from 'react'

import {disputeClassifications} from '@settleindex/domain'

import {captureException} from '../../captureException'
import {AllDisputesDocument, NewDisputeInput, useCreateDisputeMutation} from '../../graphQLTypes'
import {useHistory} from '../../routing'
import {paths} from '../../routing/paths'
import {useDisputeContainer} from '../context/useDisputeContainer'
import {NewDisputePage} from './NewDisputePage'

const NewDisputeContainer: React.FC<{}> = () => {
  useDisputeContainer({reset: true})
  const history = useHistory()
  const [createDisputeMutation, {loading}] = useCreateDisputeMutation()

  const createDispute = async (newDispute: NewDisputeInput): Promise<void> => {
    try {
      const {data} = await createDisputeMutation({
        variables: {newDispute},
        refetchQueries: [{query: AllDisputesDocument}],
        awaitRefetchQueries: true,
      })

      history.gotoTop(paths.dispute(data!.createDispute.id))
    } catch (error) {
      captureException(error)
    }
  }

  const onCancel = () => {
    history.gotoTop(paths.disputes())
  }

  return (
    <NewDisputePage
      disputeClassifications={disputeClassifications}
      loading={loading}
      onCancel={onCancel}
      onSubmit={createDispute}
    />
  )
}

export default NewDisputeContainer
