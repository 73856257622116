import styled from 'styled-components'

import {collapsedRightSidebarWidth, expandedRightSidebarWidth, minPageWidthToShowRightSidebar} from '@settleindex/react'

interface Props {
  isSingleCol: boolean
}

const widthWithCollapsedSidebar = `calc(100% - ${collapsedRightSidebarWidth}px)`
const widthWithExpandedSidebar = `calc(100% - ${expandedRightSidebarWidth}px)`

export const LeftCol = styled.div<Props>`
  overflow-y: auto;

  width: ${({isSingleCol}: Props) => (isSingleCol ? '100%' : widthWithCollapsedSidebar)};

  @media screen and (min-width: ${minPageWidthToShowRightSidebar}px) {
    width: ${({isSingleCol}: Props) => (isSingleCol ? '100%' : widthWithExpandedSidebar)};
  }

  & > div {
    margin: 0;
  }
`
