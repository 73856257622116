import * as React from 'react'

import {Row, spaceLarge, VSpaceMedium, VSpaceSmall} from '@settleindex/react'

import {SettlementStrategyInput} from '../../graphQLTypes'
import {useTutorial} from '../../tutorial/useTutorial'
import {SettlementStrategyBox} from '../strategy/SettlementStrategyBox'
import {MetricsBox} from './MetricsBox'
import {TargetsBox} from './TargetsBox'

interface Props {
  setSettlementStrategy: (strategy: SettlementStrategyInput) => void
}

export const SettlementTargetsPage: React.FC<Props> = React.memo(({setSettlementStrategy}) => {
  const {setTutorialId} = useTutorial()
  React.useEffect(() => setTutorialId('targets'), [setTutorialId])

  return (
    <>
      <VSpaceSmall />
      <SettlementStrategyBox setSettlementStrategy={setSettlementStrategy} />

      <VSpaceMedium />
      <Row gutter={spaceLarge}>
        <TargetsBox />
        <MetricsBox />
      </Row>
    </>
  )
})
