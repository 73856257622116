import {useHistory as baseUseHistory} from 'react-router-dom'

import {paths} from './paths'

interface UseHistory {
  gotoHome: () => void
  gotoTop: (to: string) => void
  history: ReturnType<typeof baseUseHistory>
}

export const useHistory = (): UseHistory => {
  const history = baseUseHistory()

  const gotoTop = (to: string) => {
    history.push(to)
    window.scrollTo(0, 0)
  }

  const gotoHome = () => gotoTop(paths.home())

  return {history, gotoTop, gotoHome}
}
