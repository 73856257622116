import * as React from 'react'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'

import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {outcomeValueLabels} from '../valueLabels'
import {nodeValueToDisplayAtom} from './atoms/nodeValueToDisplayAtom'
import {selectedPartyIdAtom} from './atoms/selectedPartyIdAtom'
import {caseValueLabelSelector} from './selectors/caseValueLabelSelector'
import {caseValueSumSelector} from './selectors/caseValueSumSelector'
import {selectedPartyDecisionsSelector} from './selectors/selectedPartyDecisionsSelector'
import {selectedPartyOutcomesSelector} from './selectors/selectedPartyOutcomesSelector'
import {selectedPartyRoleSelector} from './selectors/selectedPartyRoleSelector'

/**
 * Decision tree nodes
 */
export const useNodes = () => {
  const {dispute} = useSafeDispute()
  const {version} = useSafeVersion()
  const [selectedPartyId = dispute.perspectiveId, setSelectedPartyId] = useRecoilState(selectedPartyIdAtom)
  const isPerspective = selectedPartyId === dispute.perspectiveId
  const [valueToDisplay, setValueToDisplay] = useRecoilState(nodeValueToDisplayAtom)
  const caseValueSum = useRecoilValue(caseValueSumSelector(selectedPartyId))
  const caseValueLabel = useRecoilValue(caseValueLabelSelector)
  const nodeValueLabel = React.useMemo(() => outcomeValueLabels[valueToDisplay], [valueToDisplay])
  const selectedOutcomes = useRecoilValue(selectedPartyOutcomesSelector)
  const selectedDecisions = useRecoilValue(selectedPartyDecisionsSelector)
  const selectedPartyRole = useRecoilValue(selectedPartyRoleSelector)
  const cumulativeWeights = (version?.cumulativeWeights ?? []).filter((cw) => cw.partyId === selectedPartyId)

  const selectedPartyNodes = React.useMemo(
    () => [...selectedDecisions, ...selectedOutcomes],
    [selectedDecisions, selectedOutcomes],
  )

  return {
    caseValueLabel,
    caseValueSum,
    /**
     * The cumulative weights for the party
     */
    cumulativeWeights,
    isPerspective,
    nodeValueLabel,
    selectedNodePartyId: selectedPartyId,
    selectedPartyDecisions: selectedDecisions,
    selectedPartyNodes,
    selectedPartyOutcomes: selectedOutcomes,
    selectedPartyRole,
    setSelectedNodePartyId: setSelectedPartyId,
    setValueToDisplay,
    valueToDisplay,
  }
}

export const useResetSelectedNodePartyId = () => {
  const setSelectedNodePartyId = useSetRecoilState(selectedPartyIdAtom)

  React.useEffect(() => {
    setSelectedNodePartyId(undefined)
  }, [setSelectedNodePartyId])
}
