import * as React from 'react'

import {useSafeVersion} from '../version/context/useSafeVersion'
import {CaseBotForm} from './CaseBotForm'
import {CaseBotFormContent} from './CaseBotFormContent'
import {ReadOnlyWrapper} from './ReadOnlyWrapper'

export const CaseBotPage: React.FC = () => {
  const {version} = useSafeVersion()

  return (
    <ReadOnlyWrapper>
      <CaseBotForm readonly={version.readonly}>
        <CaseBotFormContent />
      </CaseBotForm>
    </ReadOnlyWrapper>
  )
}
