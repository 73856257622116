import React from 'react'
import {useRecoilState} from 'recoil'

import {enforceIdIfDefined, isDefined} from '@settleindex/fp'

import {VersionFragment} from '../../graphQLTypes'
import {versionAtom} from './versionAtom'

interface Args {
  /**
   * The ID of the required Version. If this argument is present and the
   * existing Version has a different ID, then the state will be reset and
   * undefined will be returned.
   */
  requiredId?: string
  /**
   * The hook will set the Version to this value when it is defined. Can
   * be used for passing `data?.Version` from an Apollo hook result.
   */
  setVersionFrom?: VersionFragment | null
}

export const useVersionContainer = (args?: Args) => {
  const [version, setVersion] = useRecoilState(versionAtom)
  const requiresSpecificVersion = isDefined(args?.requiredId)

  const resetVersion = React.useCallback(() => setVersion(undefined), [setVersion])

  React.useEffect(() => {
    const wrongVersionInState = version?.id !== args?.requiredId
    if (requiresSpecificVersion && wrongVersionInState) {
      resetVersion()
    }
  }, [args?.requiredId, requiresSpecificVersion, resetVersion, version?.id])

  React.useEffect(() => {
    args?.setVersionFrom && setVersion(args.setVersionFrom)
  }, [args?.setVersionFrom, setVersion])

  return {
    resetVersion,
    setVersion,
    version: enforceIdIfDefined(version, args?.requiredId),
  }
}
