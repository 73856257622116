import {notCombinedDefendantParty} from '@settleindex/domain'
import {dateToPicker} from '@settleindex/react'

import {DisputeFragment, Role} from '../../graphQLTypes'
import {PartyInForm} from '../form/party/PartyInForm'
import {DisputeEditFormValues} from './DisputeEditFormValues'

export const disputeToEditFormValues = (dispute: DisputeFragment): DisputeEditFormValues => {
  const partyIdsInvolvedInEvents = dispute.events
    .map((event) => event.payments.map((payment) => [payment.payee, payment.payer]))
    .flat(2)

  const parties: PartyInForm[] = dispute.parties.filter(notCombinedDefendantParty).map((party) => {
    const partyRole = dispute.partyRoles.find((role) => role.id === party.id)?.role
    const type = partyRole === Role.Claimant ? 'claimant' : 'defendant'

    return {
      ...party,
      id: party.id,
      industry: party.industry ?? undefined,
      isRemovable: !partyIdsInvolvedInEvents.includes(party.id),
      label: party.label,
      name: party.name ?? undefined,
      type,
    }
  })

  return {
    ...dispute,
    disputeStartedOn: dateToPicker(dispute.disputeStartedOn),
    parties,
  }
}
