import * as React from 'react'

import {isUndefined} from '@settleindex/fp'
import {ArrowDownIcon, ArrowUpIcon, PrimaryText} from '@settleindex/react'

import {SumFormat} from './SumFormat'

interface Props {
  bold?: boolean
  change?: number
  color: string
  medium?: boolean
  placeholder?: string
  semibold?: boolean
  sum?: number | null
  testId?: string
}

/**
 * Shows an up/down arrow and uses red/green color coding to show whether the
 * sum has increased or decreased.
 */
export const SumChange: React.FC<Props> = ({
  bold,
  change = 0,
  color,
  medium,
  placeholder = '-',
  semibold,
  sum,
  testId,
}) => {
  const textProps = {
    bold,
    ['data-test-id']: testId,
    medium,
    semibold,
  }

  if (isUndefined(sum)) {
    return <PrimaryText {...textProps}>{placeholder}</PrimaryText>
  }

  return (
    <PrimaryText {...textProps} style={{color}}>
      {change > 0 && <ArrowUpIcon style={{height: 10, width: 10, marginRight: 5}} />}
      {change < 0 && <ArrowDownIcon style={{height: 10, width: 10, marginRight: 5}} />}
      <SumFormat placeholder={placeholder} sum={sum} />
    </PrimaryText>
  )
}
