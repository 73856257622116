import {BarItemProps} from '@nivo/bar'
import {useTheme} from '@nivo/core'
import {useTooltip} from '@nivo/tooltip'
import {animated, to, useSpring} from '@react-spring/web'
import * as React from 'react'

import {ChartData} from './ChartData'

type Props = BarItemProps<ChartData> & {
  width: number
}

/**
 * The component is an almost verbatim clone from
 * https://github.com/plouc/nivo/blob/master/packages/bar/src/BarItem.tsx
 *
 * The aim of this "fork" is to have fixed width bars and then move the group
 * members next to each other.
 */
export const BarItem = ({
  ariaDescribedBy,
  ariaLabel,
  ariaLabelledBy,
  bar: {data, ...bar},
  borderRadius,
  borderWidth,
  isFocusable,
  isInteractive,
  label,
  onClick,
  onMouseEnter,
  onMouseLeave,
  shouldRenderLabel,
  style: {borderColor, color, height, labelColor, labelOpacity, labelX, labelY},
  tooltip,
  width,
}: Props) => {
  const transformCss = React.useMemo(
    () => `translate(${bar.x + bar.width / 2 - width / 2}, ${bar.y})`,
    [bar.width, bar.x, bar.y, width],
  )
  const {transform} = useSpring({transform: transformCss})

  const theme = useTheme()
  const {hideTooltip, showTooltipAt, showTooltipFromEvent} = useTooltip()

  const renderTooltip = React.useMemo(() => () => React.createElement(tooltip, {...bar, ...data}), [tooltip, bar, data])

  const handleClick = React.useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      onClick?.({color: bar.color, ...data}, event)
    },
    [bar, data, onClick],
  )
  const handleTooltip = React.useCallback(
    (event: React.MouseEvent<SVGRectElement>) => showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip],
  )
  const handleMouseEnter = React.useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(data, event)
      showTooltipFromEvent(renderTooltip(), event)
    },
    [data, onMouseEnter, showTooltipFromEvent, renderTooltip],
  )
  const handleMouseLeave = React.useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(data, event)
      hideTooltip()
    },
    [data, hideTooltip, onMouseLeave],
  )

  const handleFocus = React.useCallback(() => {
    showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY])
  }, [showTooltipAt, renderTooltip, bar])
  const handleBlur = React.useCallback(() => {
    hideTooltip()
  }, [hideTooltip])

  return (
    <animated.g transform={transform}>
      <animated.rect
        aria-describedby={ariaDescribedBy ? ariaDescribedBy(data) : undefined}
        aria-label={ariaLabel ? ariaLabel(data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
        //  @ts-ignore
        fill={data.fill ?? color}
        focusable={isFocusable}
        height={to(height, (value: any) => Math.max(value, 0))}
        onBlur={isInteractive && isFocusable ? handleBlur : undefined}
        onClick={isInteractive ? handleClick : undefined}
        onFocus={isInteractive && isFocusable ? handleFocus : undefined}
        onMouseEnter={isInteractive ? handleMouseEnter : undefined}
        onMouseLeave={isInteractive ? handleMouseLeave : undefined}
        onMouseMove={isInteractive ? handleTooltip : undefined}
        rx={borderRadius}
        ry={borderRadius}
        //  @ts-ignore
        stroke={borderColor}
        strokeWidth={borderWidth}
        tabIndex={isFocusable ? 0 : undefined}
        width={width}
      />
      {shouldRenderLabel && (
        <animated.text
          dominantBaseline="central"
          //  @ts-ignore
          fillOpacity={labelOpacity}
          style={{
            ...theme.labels.text,
            pointerEvents: 'none',
            //  @ts-ignore
            fill: labelColor,
          }}
          textAnchor="middle"
          //  @ts-ignore
          x={labelX}
          //  @ts-ignore
          y={labelY}
        >
          {label}
        </animated.text>
      )}
    </animated.g>
  )
}
