import * as React from 'react'

import {Table, TableRow} from '@settleindex/react'
import {settlementTestIds} from '@settleindex/testids/src/testIds'

import {useSafeDispute} from '../../../dispute/context/useSafeDispute'
import {useSafeVersion} from '../../../version/context/useSafeVersion'
import {useSettlementAnalysis} from '../useSettlementAnalysis'
import {CaseValuesRecord} from './CaseValuesRecord'
import {makeColumns} from './makeColumns'
import {makeRows} from './makeRows'

export const CaseValuesTable: React.FC<{}> = () => {
  const {
    dispute: {perspective},
  } = useSafeDispute()
  const {version} = useSafeVersion()
  const {settlementOpponent, settlementOpponentValues} = useSettlementAnalysis()

  const columns = React.useMemo(
    () => makeColumns(perspective.label, settlementOpponent.label),
    [perspective.label, settlementOpponent?.label],
  )

  const rows: TableRow<CaseValuesRecord>[] = React.useMemo(
    () => makeRows(version, settlementOpponentValues),
    [settlementOpponentValues, version],
  )

  return <Table columns={columns} rows={rows} stateId={`CaseValuesTable`} testId={settlementTestIds.caseValues} />
}
