import {CaseBotIssue, defaultIssueMyWeight, defaultIssueTheirWeight, newIdFor} from '@settleindex/domain'
import {FormInstance} from '@settleindex/react'

import {CaseBotFormIssueType} from './CaseBotFormValues'

/**
 * onAddIssue generates a new issue with some sane defaults as name and weights
 */
export const onAddIssue =
  (formInstance: FormInstance, issueType: CaseBotFormIssueType) =>
  (addItemToFormList: (issue: CaseBotIssue) => void) => {
    const existing = (formInstance.getFieldValue(issueType) as CaseBotIssue[]) ?? []
    const name = `${typeNameMap[issueType]} ${existing.length + 1}`
    const myWeight = defaultIssueMyWeight
    const theirWeight = defaultIssueTheirWeight

    addItemToFormList({name, id: newIdFor('caseBotIssue'), myWeight, theirWeight, claimGroupIds: []})
  }

const typeNameMap: Record<CaseBotFormIssueType, string> = {
  loseFinals: 'Claimant Hurdle',
  winFinals: 'Defendant Hurdle',
  others: 'Independent Issue',
}
