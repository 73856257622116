import styled from 'styled-components'

import {colors, semiBoldWeight} from '@settleindex/react'

import {PercentInput} from '../../percent/PercentInput'
import {inputHeight, inputWidth} from './style'

interface InputProps {
  $hasManualWeight: boolean
}

export const Input = styled(PercentInput)<InputProps>`
  width: ${inputWidth}px !important;
  height: ${inputHeight}px !important;
  padding: 2px 5px 2px 2px;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: ${colors.base10} !important;
  box-shadow: 0 0 0 0 transparent;

  & span,
  &:hover span,
  &:focus span,
  & input,
  &:hover input,
  &:focus input {
    border: 0 transparent none !important;
    background-color: transparent !important;
    box-shadow: 0 0 0 0 transparent;
    color: ${(props: InputProps) => (props.$hasManualWeight ? colors.base4 : colors.base6)} !important;
    font-weight: ${semiBoldWeight} !important;
  }
`
