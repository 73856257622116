import {has} from '@settleindex/fp'
import {FormInstance} from '@settleindex/react'

import {captureException} from '../../captureException'
import {fieldNames} from './fieldNames'
import {PartyInForm} from './party/PartyInForm'
import {tmpId} from './tmpId'

interface Args {
  form: FormInstance<any>
  setLeaveConfirmationRequired?: (required: boolean) => void
  setPartiesWithLabel: (parties: PartyInForm[]) => void
}

export const makeOnChange =
  ({form, setLeaveConfirmationRequired, setPartiesWithLabel}: Args) =>
  () => {
    setLeaveConfirmationRequired?.(true)
    try {
      const currPerspective = form.getFieldValue(fieldNames.perspectiveId)
      const partiesFormValue = form.getFieldValue(fieldNames.parties)
      const partiesWithLabel: PartyInForm[] = partiesFormValue?.filter(has('label'))
      setPartiesWithLabel(partiesWithLabel)

      if (!partiesWithLabel.some((p) => p.id === currPerspective)) {
        form.setFieldsValue({[fieldNames.perspectiveId]: undefined})
      }

      const partiesWithEnforcedId = partiesFormValue?.map((p: PartyInForm) =>
        p ? {...p, id: p.id ?? tmpId()} : ({id: tmpId()} as PartyInForm),
      )

      form.setFieldsValue({parties: partiesWithEnforcedId})

      if (!currPerspective && partiesWithLabel.length) {
        form.setFieldsValue({[fieldNames.perspectiveId]: partiesWithLabel[0].id})
      }
    } catch (error) {
      captureException(error)
    }
  }
