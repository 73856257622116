import styled from 'styled-components'

import {mediumWeight, TertiaryLabel} from '@settleindex/react'

interface Props {
  noMargin?: boolean
}

export const DimmedText = styled(TertiaryLabel)<Props>`
  display: block;
  line-height: 16px;
  font-weight: ${mediumWeight};
`
