import * as React from 'react'

import {
  H2,
  Input,
  LeftRight,
  Option,
  PlusIcon,
  PrimaryButton2,
  PrimaryText,
  SecondarySelect,
  Table,
  TableRow,
  VSpaceMedium,
} from '@settleindex/react'
import {partyCostTableTestIds} from '@settleindex/testids/src/testIds'

import {DebugLabel} from '../../debug'
import {CostGroupFragment, CostPartyTotalsFragment} from '../../graphQLTypes'
import {HelpTitleWithTooltip} from '../../help/HelpTitleWithTooltip'
import {SumText} from '../../sum'
import {SumInput} from '../../sum/sumInput'
import {CostOperations} from '../graph/CostOperations'
import {columns} from './columns'
import {CostRecord} from './CostRecord'
import {DeleteIcon} from './DeleteIcon'
import {NoCostGroups} from './NoCostGroups'

interface Props {
  costGroups: CostGroupFragment[]
  costOperations: CostOperations
  costTotals?: CostPartyTotalsFragment
  partyId: string
  partyIndex: number
  readonly: boolean
  title: string
  versionId: string
}

export const PartyCostTable: React.FC<Props> = ({
  costGroups,
  costOperations,
  costTotals,
  partyId,
  partyIndex,
  readonly,
  title,
  versionId,
}) => {
  const dataTableStateId = `Version-${versionId}-PartyCostTable-${partyId}`

  const onAddCostGroup = () => {
    costOperations.costGroupAdd(partyId)
  }

  const costGroupRows: TableRow<CostRecord>[] = costGroups.map((costGroup, costGroupIndex) => {
    const subTableCostRows: TableRow<CostRecord>[] = costGroup.costs.map((cost) => ({
      cells: {
        title: (
          <Input
            defaultValue={cost.title}
            onSave={(newCostTitle: string) => {
              costOperations.costUpdate({
                costGroupId: costGroup.id,
                costId: cost.id,
                property: 'title',
                value: newCostTitle,
              })
            }}
            readonly={readonly}
          />
        ),
        isRecoverable: readonly ? (
          <PrimaryText>{cost.isRecoverable ? 'Recoverable' : 'Non-recoverable'}</PrimaryText>
        ) : (
          <SecondarySelect
            defaultValue={cost.isRecoverable}
            onChange={(isRecoverable: boolean) => {
              costOperations.costUpdate({
                costGroupId: costGroup.id,
                costId: cost.id,
                property: 'isRecoverable',
                value: isRecoverable,
              })
            }}
            style={{width: '100%'}}
          >
            <Option key={'true'} value={true as boolean}>
              Recoverable
            </Option>
            <Option key={'false'} value={false as boolean}>
              Non-recoverable
            </Option>
          </SecondarySelect>
        ),
        amount: (
          <DebugLabel title="cost.amount">
            <SumInput
              defaultValue={cost.amount}
              onSave={(amount: number) => {
                costOperations.costUpdate({
                  costGroupId: costGroup.id,
                  costId: cost.id,
                  property: 'amount',
                  value: amount,
                })
              }}
              readonly={readonly}
            />
          </DebugLabel>
        ),
        spent: (
          <DebugLabel title="cost.spent">
            <SumInput
              defaultValue={cost.spent}
              onSave={(spent: number) => {
                costOperations.costUpdate({costGroupId: costGroup.id, costId: cost.id, property: 'spent', value: spent})
              }}
              readonly={readonly}
            />
          </DebugLabel>
        ),
        forecast: (
          <DebugLabel title="cost.forecast">
            <SumInput
              defaultValue={cost.forecast}
              onSave={(forecast: number) => {
                costOperations.costUpdate({
                  costGroupId: costGroup.id,
                  costId: cost.id,
                  property: 'forecast',
                  value: forecast,
                })
              }}
              readonly={readonly}
            />
          </DebugLabel>
        ),
        actions: readonly ? null : <DeleteIcon onClick={() => costOperations.costDelete(costGroup.id, cost.id)} />,
      },
      id: cost.id,
    }))

    const subTableAddCostRow = {
      cells: {
        title: readonly ? null : (
          <PrimaryButton2
            data-test-id={partyCostTableTestIds.addCostButton(costGroupIndex)}
            onClick={() => costOperations.costAdd(costGroup.id)}
            size="medium"
            style={{marginBottom: 30}}
            weight="medium"
          >
            <PlusIcon /> Add Cost
          </PrimaryButton2>
        ),
      },
      id: `add-cost-${costGroup.id}`,
    }

    const subTable = {
      indentAmount: 'parentAlign' as 'parentAlign',
      rows: [...subTableCostRows, subTableAddCostRow],
    }

    return {
      id: costGroup.id,
      cells: {
        title: (
          <Input
            defaultValue={costGroup.title}
            onSave={(newCostGroupTitle: string) => {
              costOperations.costGroupUpdate(costGroup.id, 'title', newCostGroupTitle)
            }}
            readonly={readonly}
          />
        ),
        isRecoverable: <></>,
        amount: (
          <DebugLabel title="costGroup.amount">
            <SumText semibold sum={costGroup.amount} />
          </DebugLabel>
        ),
        spent: (
          <DebugLabel title="costGroup.spent">
            <SumText semibold sum={costGroup.spent} />
          </DebugLabel>
        ),
        forecast: (
          <DebugLabel title="costGroup.forecast">
            <SumText semibold sum={costGroup.forecast} />
          </DebugLabel>
        ),
        actions: readonly ? null : <DeleteIcon onClick={() => costOperations.costGroupDelete(costGroup.id)} />,
      },
      expandable: true,
      subTable,
    }
  })

  const totalsRow: TableRow<CostRecord> = {
    id: `${partyId}-costGroups-total`,
    cells: {
      title: <PrimaryText bold>Total</PrimaryText>,
      amount: (
        <DebugLabel title="DerivedVersion.costTotals.budget">
          <SumText semibold sum={costTotals?.budget} />
        </DebugLabel>
      ),
      spent: (
        <DebugLabel title="DerivedVersion.costTotals.spent">
          <SumText semibold sum={costTotals?.spent} />
        </DebugLabel>
      ),
      forecast: (
        <DebugLabel title="DerivedVersion.costTotals.forecast">
          <SumText semibold sum={costTotals?.forecast} />
        </DebugLabel>
      ),
    },
  }

  const rows = [...costGroupRows, totalsRow]

  return (
    <>
      <HelpTitleWithTooltip
        id="help_for_costs_page"
        left="Enter costs that will be incurred by each Party if the case is not settled."
        leftId="help_for_costs_page_title"
      />
      <LeftRight
        left={<H2 noMargin>{title}</H2>}
        right={
          <PrimaryButton2
            data-test-id={partyCostTableTestIds.addCostGroupButton}
            disabled={readonly}
            onClick={onAddCostGroup}
            size="medium"
            weight="medium"
          >
            Add Cost Group
          </PrimaryButton2>
        }
      />
      <VSpaceMedium />
      {costGroups.length ? (
        <Table
          columns={columns}
          rows={rows}
          stateId={dataTableStateId}
          subTableSeparatorHeight={0}
          testId={partyCostTableTestIds.partyCostTable(partyIndex)}
        />
      ) : (
        <NoCostGroups />
      )}
    </>
  )
}
