import * as React from 'react'
import {useParams} from 'react-router-dom'

import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData} from '@settleindex/react'

import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {DisputeNotFound} from '../../dispute/DisputeNotFound'
import {disputeNotFoundGraphError} from '../../dispute/graph/disputeErrorGuards'
import {DisputeFragment, EventUpdateInput, useDisputeQuery, useUpdateEventMutation} from '../../graphQLTypes'
import {LoadingIndicatorWithNav} from '../../page'
import {useHistory} from '../../routing'
import {paths} from '../../routing/paths'
import {EditEventModal} from './EditEventModal'

interface Match {
  disputeId: string
  eventId: string
  mode: 'edit' | 'view'
}

const EditEventContainer: React.FC = () => {
  const {disputeId, eventId, mode} = useParams<Match>()

  const {history} = useHistory()

  const {runAsync} = useAppIsLoading()
  const {data, error, loading} = useDisputeQuery({variables: {id: disputeId}})
  const [createEventMutation] = useUpdateEventMutation()

  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: data?.dispute})

  const whenAvailable = () => {
    const dispute = disputeInContext as DisputeFragment

    const eventResponse = dispute.events.find((oneEvent) => oneEvent.id === eventId)

    if (!eventResponse) {
      return <Ambulance message="Event not found" />
    }

    const goToEventList = () => {
      history.push(paths.disputeEvents(disputeId))
    }

    const updateEvent = (upEvent: EventUpdateInput) => {
      runAsync(() => createEventMutation({variables: {event: upEvent}}).then(goToEventList))
    }

    const event = {
      ...eventResponse,
      __typename: undefined,
      payments: eventResponse?.payments.map((payment) => ({...payment, __typename: undefined})),
    }

    return (
      <EditEventModal
        claimantId={dispute.claimant.id}
        event={event}
        onCancel={goToEventList}
        onFinish={updateEvent}
        parties={dispute.parties}
        perspectiveRole={dispute.perspectiveRole}
        readonly={(mode === 'view' || dispute.readonly) ?? false}
      />
    )
  }

  return (
    <LoadingData
      state={{
        available: isDefined(disputeInContext),
        empty: disputeNotFoundGraphError(error),
        error: error && !disputeNotFoundGraphError(error),
        loading,
      }}
      whenAvailable={whenAvailable}
      whenEmpty={<DisputeNotFound />}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicatorWithNav />}
    />
  )
}

export default EditEventContainer
