import {isDefined, isNumber} from '@settleindex/fp'

export type ChartDataKey = 'claimantCurrent' | 'customSettlement' | 'modelSettlement' | 'opponentCurrent'

export type ChartData = {
  key: ChartDataKey
  label: string
  value: number
}

export const isChartData = (data: any): data is ChartData =>
  isDefined(data.key) && isDefined(data.value) && isNumber(data.value)
