import {TooltipProps} from '@nivo/marimekko'
import * as React from 'react'
import styled, {keyframes} from 'styled-components'

import {percentToStr} from '@settleindex/domain'
import {isDefined} from '@settleindex/fp'
import {PrimaryText, VSpace} from '@settleindex/react'

import {Box as BaseBox} from '../../chart/tooltip/Box'
import {SumFormat} from '../../sum'
import {useNodes} from '../context/useNodes'
import {DimmedText} from '../DimmedText'
import {LabelledNodeProperty, outcomeValueLabels} from '../valueLabels'
import {Data} from './Data'

export const AnalyseChartTooltip = (props: TooltipProps<Data>) => {
  const {valueToDisplay} = useNodes()
  const {
    absoluteWeight,
    claimsWithInterest,
    costRecoveryNet,
    cumulativeWeight,
    description,
    name,
    path,
    valueForecast,
    valueGross,
    valueNet,
    valuePresent,
  } = props.bar.datum.data
  const values: Record<LabelledNodeProperty, number> = {
    claimsWithInterest,
    costRecoveryNet,
    valueForecast,
    valueGross,
    valueNet,
    valuePresent,
  }

  const preventLeavingScreen = React.useMemo(() => {
    // 400 and -150 are based on the width and position of the chart as of today - so they might need to be adjusted
    // in the future
    const x = props.bar.x < 400 ? 0 : -150

    return {
      transform: `translate(${x}px, 0)`,
    }
  }, [props.bar.x])

  return (
    <Box style={preventLeavingScreen}>
      {path && (
        <>
          <DimmedText>{path.join(' / ')}</DimmedText>
          <VSpace space={5} />
        </>
      )}
      <PrimaryText semibold>{name}</PrimaryText>
      {description && (
        <>
          <VSpace space={7} />
          <DimmedText>{description}</DimmedText>
        </>
      )}

      <VSpace space={10} />
      {isDefined(absoluteWeight) && <PrimaryText medium>{percentToStr(absoluteWeight)} Chance</PrimaryText>}
      {isDefined(cumulativeWeight) && (
        <>
          <br />
          <PrimaryText medium>{percentToStr(cumulativeWeight)} Cumulative</PrimaryText>
        </>
      )}

      <VSpace space={10} />
      <PrimaryText medium>
        {outcomeValueLabels[valueToDisplay]}: <SumFormat sum={values[valueToDisplay]} />
      </PrimaryText>
    </Box>
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Box = styled(BaseBox)`
  width: 275px;
  padding: 18px 22px 18px 22px;
  animation: ${fadeIn} 0.4s ease-in-out;
`
