import * as React from 'react'
import {Route, useParams} from 'react-router-dom'

import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData} from '@settleindex/react'

import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {DisputeNotFound} from '../../dispute/DisputeNotFound'
import {disputeNotFoundGraphError} from '../../dispute/graph/disputeErrorGuards'
import {DisputeFragment, useDeleteEventMutation, useDisputeQuery} from '../../graphQLTypes'
import {LoadingIndicatorWithNav} from '../../page'
import {useHistory} from '../../routing'
import {paths} from '../../routing/paths'
import NewEventContainer from '../create/NewEventContainer'
import EditEventContainer from '../edit/EditEventContainer'
import {EventListPage} from './EventListPage'

const EventListContainer: React.FC = () => {
  const {id: disputeId} = useParams<{id: string}>()
  const {history} = useHistory()
  const {runAsync} = useAppIsLoading()
  const {data, error, loading} = useDisputeQuery({variables: {id: disputeId}})
  const [deleteEventMutation] = useDeleteEventMutation()

  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: data?.dispute})

  const whenAvailable = () => {
    const dispute = disputeInContext as DisputeFragment

    const onAddEvent = () => {
      history.push(paths.disputeNewEvent(disputeId))
    }
    const onDeleteEvent = (eventId: string) => {
      runAsync(() => deleteEventMutation({variables: {eventId}}))
    }
    const onViewEvent = (eventId: string) => {
      history.push(paths.disputeEditOrViewEvent(disputeId, eventId, 'view'))
    }
    const onEditEvent = (eventId: string) => {
      history.push(paths.disputeEditOrViewEvent(disputeId, eventId, 'edit'))
    }

    return (
      <>
        <Route component={NewEventContainer} exact path={paths.disputeNewEventPattern} />
        <Route component={EditEventContainer} exact path={paths.disputeEditOrViewEventPattern} />
        <EventListPage
          dispute={dispute}
          events={dispute.events}
          loading={loading}
          onAddEvent={onAddEvent}
          onDeleteEvent={onDeleteEvent}
          onEditEvent={onEditEvent}
          onViewEvent={onViewEvent}
        />
      </>
    )
  }

  return (
    <LoadingData
      state={{
        available: isDefined(disputeInContext),
        empty: disputeNotFoundGraphError(error),
        error: error && !disputeNotFoundGraphError(error),
        loading,
      }}
      whenAvailable={whenAvailable}
      whenEmpty={<DisputeNotFound />}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicatorWithNav />}
    />
  )
}

export default EventListContainer
