import dayjs from 'dayjs'

import {IsoDate} from '@settleindex/domain'
import {head, tail} from '@settleindex/fp'

const minimumRangeInDays = 5

export const getXScales = (datesInAscOrder: IsoDate[]): [min: string, max: string] | null => {
  const start = dayjs(head(datesInAscOrder))
  const end = dayjs(tail(datesInAscOrder))
  const diffInDays = end.diff(start, 'days')

  if (diffInDays >= minimumRangeInDays) {
    return null
  }

  const paddingRequired = Math.ceil((minimumRangeInDays - diffInDays) / 2)

  return [start.subtract(paddingRequired, 'days').toISOString(), end.add(paddingRequired, 'days').toISOString()]
}
