import * as React from 'react'

import {
  Col,
  H2,
  LeftRight,
  PrimaryButton,
  PrimaryLabel,
  Row,
  SecondaryButton,
  SecondaryButton2,
  TertiaryLabel,
  VSpace,
} from '@settleindex/react'

import {SumFormat} from '../../sum'

interface Props {
  eventValue: number
  loading?: boolean
  netPaymentToClaimant: number
  onCancel: () => void
  readonly?: boolean
}

export const Footer: React.FC<Props> = ({
  eventValue,
  loading = false,
  netPaymentToClaimant,
  onCancel,
  readonly = false,
}) => {
  const cancelAndSave = (
    <>
      <SecondaryButton data-test-id={`event-cancel`} medium onClick={onCancel}>
        Cancel
      </SecondaryButton>
      <PrimaryButton data-test-id="event-done" disabled={loading} htmlType="submit" medium style={{marginLeft: 30}}>
        Done
      </PrimaryButton>
    </>
  )

  const closeButton = (
    <SecondaryButton2 data-test-id={`event-cancel`} medium onClick={onCancel}>
      Close
    </SecondaryButton2>
  )

  return (
    <>
      <VSpace space={5} />
      <LeftRight
        left={
          <Row gutter={20} justify="space-between" style={{width: '100%'}}>
            <Col span={12}>
              <Col style={{paddingLeft: 0}}>
                <H2 noMargin>
                  <SumFormat sum={netPaymentToClaimant} />
                </H2>
                <VSpace space={5} />
              </Col>
              <Col style={{paddingLeft: 0}}>
                <PrimaryLabel>Total</PrimaryLabel>
                <div>
                  <TertiaryLabel>Payable to claimant</TertiaryLabel>
                </div>
              </Col>
            </Col>
            <Col span={12}>
              <Col span={24}>
                <H2 noMargin>
                  <SumFormat sum={eventValue} />
                </H2>
                <VSpace space={5} />
              </Col>
              <Col style={{paddingRight: 0}}>
                <PrimaryLabel>Overall Offer Value</PrimaryLabel>
                <div>
                  <TertiaryLabel>After own costs</TertiaryLabel>
                </div>
              </Col>
            </Col>
          </Row>
        }
        right={readonly ? closeButton : cancelAndSave}
      />
    </>
  )
}
