import * as React from 'react'

import {HistoryMetrics} from '@settleindex/domain'
import {isDefined} from '@settleindex/fp'
import {H3, LinkToTop, SecondaryButton2} from '@settleindex/react'

import {DisputeFragment, DisputeHistoryPointFragment} from '../../graphQLTypes'
import {paths} from '../../routing/paths'
import {VersionValuesBarChart} from '../../version/versionValuesChart/VersionValuesBarChart'

interface Props {
  dispute: DisputeFragment
  historyPoints: DisputeHistoryPointFragment[]
  metricsOnChart: HistoryMetrics[]
}

/**
 * Shows published models, from left to right: the latest, the previous (if any)
 * and finally the first
 */
export const LatestVersionChartSection: React.FC<Props> = ({dispute, historyPoints, metricsOnChart}) => {
  const points = React.useMemo(() => {
    const latest = historyPoints.at(-1)
    const penultimate = historyPoints.length > 1 ? historyPoints.at(-2) : undefined
    const first = historyPoints.length > 2 ? historyPoints.at(0) : undefined
    const barHistoryPoints = [latest, penultimate, first].filter(isDefined)

    return {
      latest,
      barHistoryPoints,
    }
  }, [historyPoints])

  return (
    <VersionValuesBarChart
      headerLeft={<H3>Latest Model</H3>}
      headerRight={
        <LinkToTop to={points.latest ? paths.version(dispute.id, points.latest?.id) : ''}>
          <SecondaryButton2 disabled={!points.latest}>View Model</SecondaryButton2>
        </LinkToTop>
      }
      metricsToShow={metricsOnChart}
      versionHistoryPoints={points.barHistoryPoints}
    />
  )
}
