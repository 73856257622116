import * as React from 'react'

import {CaseBotIssue, percentToStr} from '@settleindex/domain'
import {isDefined} from '@settleindex/fp'
import {
  CenterLeftLine,
  Col,
  DeleteIcon,
  Form,
  FormItem,
  HSpace,
  Input,
  Row,
  SecondaryButton,
  SecondaryText,
  Select,
  Tag,
  TertiaryButton,
  Tooltip,
  VSpaceSmall,
} from '@settleindex/react'

import {DebugFormItem} from '../debug/'
import {Hint} from '../help/'
import {HelpTitleWithTooltip} from '../help/HelpTitleWithTooltip'
import {useVersionVariablesDrawer} from '../nodes/versionVariablesDrawer/useVersionVariablesDrawer'
import {PerspectiveAwarePercentInput} from '../party/perspectiveAwareColors/PerspectiveAwarePercentInput'
import {PerspectiveAwareText} from '../party/perspectiveAwareColors/PerspectiveAwareText'
import {useSafeVersion} from '../version/context/useSafeVersion'
import {claimSpan, gutter, nameSpan, removeSpan, weightSpan} from './columnWidths'
import {CaseBotFormIssueType} from './form/CaseBotFormValues'
import {ExtraLabel} from './form/ExtraLabel'

interface IssuesTableProps {
  buttonLabel?: string
  // The tooltip for the cumulative weight tag
  cumulativeTooltip?: string
  // lead is the explanation sentence above the table
  lead: string
  // The cumulative weight ("effect") of all issues probabilities in Our View. Not used in Others.
  myCumulativeWeight?: number
  // name is the form field name of the issue list
  name: CaseBotFormIssueType
  // onAddIssue is used to generate and add a new issue to the form data
  onAddIssue: (addItemToFormList: (issue: CaseBotIssue) => void) => void
  // The cumulative weight ("effect") of all issues probabilities in Their View. Not used in Others.
  theirCumulativeWeight?: number
}

const helpIds: Record<CaseBotFormIssueType, {overview: string; title: string}> = {
  loseFinals: {
    overview: 'casebot_lose_issue_overview',
    title: 'casebot_lose_issue_title',
  },
  winFinals: {
    overview: 'casebot_win_issue_overview',
    title: 'casebot_win_issue_title',
  },
  others: {
    overview: 'casebot_other_issue_overview',
    title: 'casebot_other_issue_title',
  },
}

export const IssuesTable: React.FC<IssuesTableProps> = ({
  buttonLabel = 'Add Issue',
  cumulativeTooltip,
  lead,
  myCumulativeWeight,
  name,
  onAddIssue,
  theirCumulativeWeight,
}) => {
  const {version} = useSafeVersion()
  const {editClaims} = useVersionVariablesDrawer()

  const claimGroupOptions = React.useMemo(
    () => version.claimGroups.map((cg) => ({label: cg.name, value: cg.id})),
    [version.claimGroups],
  )

  return (
    <>
      <HelpTitleWithTooltip id={helpIds[name].overview} left={lead} leftId={helpIds[name].title} />
      <Form.List name={name}>
        {(fields, {add, remove}) => (
          <>
            <div>
              {fields.length ? <ExtraLabel label="Chance of Claimant Win (%)" /> : <></>}

              {fields.map((field, index) => (
                <Row align="top" gutter={gutter} key={field.key}>
                  <Col span={nameSpan}>
                    <FormItem hidden name={[field.name, 'id']} noStyle>
                      <Input />
                    </FormItem>
                    <FormItem {...field} label={index == 0 ? 'Name of Issue' : ''} name={[field.name, 'name']}>
                      <Input placeholder="Name" />
                    </FormItem>
                  </Col>
                  <Col span={weightSpan}>
                    <DebugFormItem
                      isPerspective
                      {...field}
                      label={index == 0 ? 'Our View' : ''}
                      name={[field.name, 'myWeight']}
                      title={`caseBot.${name}.[].myWeight`}
                    >
                      <PerspectiveAwarePercentInput isPerspective />
                    </DebugFormItem>
                  </Col>
                  <Col span={weightSpan}>
                    <DebugFormItem
                      isPerspective={false}
                      title={`caseBot.${name}.[].theirWeight`}
                      {...field}
                      label={index == 0 ? 'Their View' : ''}
                      name={[field.name, 'theirWeight']}
                    >
                      <PerspectiveAwarePercentInput isPerspective={false} />
                    </DebugFormItem>
                  </Col>
                  <Col span={claimSpan}>
                    <DebugFormItem
                      {...field}
                      label={index == 0 ? 'Claim Groups' : ''}
                      name={[field.name, 'claimGroupIds']}
                      rules={[{required: false}]}
                      title={`caseBot.${name}.[].claimGroupIds`}
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        notFoundContent={
                          <div onClick={editClaims} style={{cursor: 'pointer'}}>
                            <SecondaryText>Add a new claim...</SecondaryText>
                          </div>
                        }
                        options={claimGroupOptions}
                        placeholder="Please select"
                        style={{width: '100%'}}
                      />
                    </DebugFormItem>
                  </Col>
                  <Col span={removeSpan} style={{paddingTop: index === 0 ? 35 : 0}}>
                    <FormItem>
                      <TertiaryButton onClick={() => remove(field.name)}>
                        <DeleteIcon />
                      </TertiaryButton>
                    </FormItem>
                  </Col>
                </Row>
              ))}

              {fields.length > 1 && isDefined(myCumulativeWeight) && isDefined(theirCumulativeWeight) ? (
                <>
                  <Row>
                    <Col span={nameSpan}>
                      <Tooltip title={cumulativeTooltip}>
                        <Hint>
                          <Tag color="purpleLight" style={{width: 160}}>
                            Cumulative Chance
                          </Tag>
                        </Hint>
                      </Tooltip>
                    </Col>
                    <Col span={weightSpan}>
                      <CenterLeftLine>
                        <HSpace space={10} />
                        <PerspectiveAwareText isPerspective semibold>
                          {percentToStr(myCumulativeWeight)}
                        </PerspectiveAwareText>
                      </CenterLeftLine>
                    </Col>
                    <Col span={weightSpan}>
                      <CenterLeftLine>
                        <HSpace space={10} />
                        <PerspectiveAwareText semibold>{percentToStr(theirCumulativeWeight)}</PerspectiveAwareText>
                      </CenterLeftLine>
                    </Col>
                  </Row>
                  <VSpaceSmall />
                </>
              ) : null}

              <Row>
                <Col span={nameSpan + weightSpan + weightSpan}>
                  <SecondaryButton medium onClick={() => onAddIssue(add)}>
                    {buttonLabel}
                  </SecondaryButton>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Form.List>
    </>
  )
}
