import * as React from 'react'

import {HistoryMetrics, Nullable} from '@settleindex/domain'
import {adjust} from '@settleindex/fp'
import {H1, LeftRight, VSpaceLarge, VSpaceMedium, VSpaceSmall} from '@settleindex/react'

import {usePageTitle} from '../../pageTitle/usePageTitle'
import {useSafeDispute} from '../context/useSafeDispute'
import {Frame} from '../Frame'
import {useMetricsSelector} from '../history/metricsSelector/useMetricsSelector'
import {VersionCompareChart} from './chart/VersionCompareChart'
import {MaybeHistoryPoints} from './MaybeHistoryPoint'
import {ModeSelectButtons} from './ModeSelectButtons'
import {ThreeNumbers} from './ThreeNumbers'
import {ToggleWeightingButton} from './ToggleWeightingButton'
import {useVersionCompare} from './useVersionCompare'
import {VersionCompareMetricsSelector} from './VersionCompareMetricsSelector'
import {VersionSelector} from './VersionSelector'

export const VersionComparePage: React.FC = () => {
  const {dispute} = useSafeDispute()

  const {
    compareMode,
    differenceHistoryPoints,
    isWeightEnabled,
    modelWeights,
    onModelWeightChange,
    selectedHistoryIndexes,
    selectedVersionHistoryPoints,
    setCompareMode,
    setIsWeightEnabled,
    setSelectedHistoryIndexes,
    totalWeight,
    versionHistoryPoints,
    weightedDifferenceHistoryPoint,
    weightedTotalHistoryPoint,
  } = useVersionCompare()

  usePageTitle(`${dispute.title} - Compare Models`)

  const metricsStorageKey = React.useMemo(() => `VersionComparePageMetrics-${dispute.id}`, [dispute.id])

  const {checkedMetrics, onCheckboxClick} = useMetricsSelector({
    storageKey: metricsStorageKey,
    defaultMetrics: [
      HistoryMetrics['version.caseValueNet'],
      HistoryMetrics['version.caseValuePresent'],
      HistoryMetrics['version.caseValueForecast'],
      HistoryMetrics['version.caseValueGross'],
    ],
  })

  const onVersionSelectChange = React.useCallback(
    (index: number, value: Nullable<number>) =>
      setSelectedHistoryIndexes(adjust(index, () => value, selectedHistoryIndexes) as ThreeNumbers),
    [selectedHistoryIndexes, setSelectedHistoryIndexes],
  )

  const metricsSelectorHistoryPoints: MaybeHistoryPoints = React.useMemo(
    () => (compareMode === 'absolute' ? selectedVersionHistoryPoints : differenceHistoryPoints),
    [compareMode, differenceHistoryPoints, selectedVersionHistoryPoints],
  )

  const weightedTotalForSelector = React.useMemo(
    () => (compareMode === 'absolute' ? weightedTotalHistoryPoint : weightedDifferenceHistoryPoint),
    [compareMode, weightedDifferenceHistoryPoint, weightedTotalHistoryPoint],
  )

  const chartHistoryPoints = React.useMemo(
    () =>
      isWeightEnabled ? [...selectedVersionHistoryPoints, weightedTotalHistoryPoint] : selectedVersionHistoryPoints,
    [isWeightEnabled, selectedVersionHistoryPoints, weightedTotalHistoryPoint],
  )

  return (
    <>
      <Frame activeKey="compare" dispute={dispute}>
        <H1>Compare Models</H1>
        <VSpaceSmall />

        <VersionSelector
          disputeId={dispute.id}
          onChange={onVersionSelectChange}
          selectedIndexes={selectedHistoryIndexes}
          versionHistoryPoints={versionHistoryPoints}
        />

        <VersionCompareChart metricsToShow={checkedMetrics} versionHistoryPoints={chartHistoryPoints} />
        <VSpaceMedium />

        <LeftRight
          left={<ModeSelectButtons compareMode={compareMode} setCompareMode={setCompareMode} />}
          right={<ToggleWeightingButton isWeightEnabled={isWeightEnabled} setIsWeightEnabled={setIsWeightEnabled} />}
        />
        <VSpaceSmall />

        <VersionCompareMetricsSelector
          checkedMetrics={checkedMetrics}
          historyPoints={metricsSelectorHistoryPoints}
          isWeightEnabled={isWeightEnabled}
          modelWeights={modelWeights}
          onCheckboxClick={onCheckboxClick}
          onModelWeightChange={onModelWeightChange}
          totalWeight={totalWeight}
          weightedTotalHistoryPoint={weightedTotalForSelector}
        />
        <VSpaceLarge />
      </Frame>
    </>
  )
}
