import {CaseBotIssue, CaseBotIssueCostAward} from '@settleindex/domain'
import {FormInstance} from '@settleindex/react'

import {CaseBotFormValues} from './CaseBotFormValues'

/**
 * Ensure there is an `issueCostAwards` entry in the form values model for each
 * issue the user created. This is how we can show a list of issues in the "cost
 * awards" form tab.
 */
export const setIssueCostAwardsInForm = (form: FormInstance, values: CaseBotFormValues, issues: CaseBotIssue[]) => {
  const existingIssueCostAwards = values.issueCostAwards

  const newIssueCostAwards = issues.map((issue) => {
    const existingIssueCostAward = existingIssueCostAwards?.find((i) => i.issueId === issue.id)

    return {
      issueId: issue.id,
      myRecovery: existingIssueCostAward?.myRecovery ?? 0,
      theirRecovery: existingIssueCostAward?.theirRecovery ?? 0,
    } satisfies CaseBotIssueCostAward
  })

  form.setFieldValue('issueCostAwards', newIssueCostAwards)
}
