import * as React from 'react'

import {
  Col,
  Form,
  FormItem,
  H3,
  Input,
  Option,
  Row,
  SecondarySelect,
  Switch,
  TertiaryButton,
  VSpaceSmall,
} from '@settleindex/react'

import {capTypeNames} from '../cap/capTypeNames'
import {DebugFormItem} from '../debug'
import {HelpTitleWithTooltip} from '../help/HelpTitleWithTooltip'
import {useVersionVariablesDrawer} from '../nodes/versionVariablesDrawer/useVersionVariablesDrawer'
import {PerspectiveAwarePercentInput} from '../party/perspectiveAwareColors/PerspectiveAwarePercentInput'
import {useSafeVersion} from '../version/context/useSafeVersion'
import {gutter, maxSpan, nameSpan, weightSpan} from './columnWidths'

export const CapsTable: React.FC = () => {
  const {version} = useSafeVersion()
  const caps = version.caps
  const {editCaps} = useVersionVariablesDrawer()
  const formInstance = Form.useFormInstance()

  const onCapIdChange = () => {
    const capId = formInstance.getFieldValue(['cap', 'capId'])

    if (!capId) {
      formInstance.validateFields()
    }
  }

  return (
    <>
      <HelpTitleWithTooltip id={'casebot_caps_overview'} left="Caps & Limits" leftId="casebot_caps_title" />
      <Row align="bottom" gutter={gutter}>
        <Col span={nameSpan * 2}>
          <FormItem label="Cap / Limit" name={['cap', 'capId']} rules={[{required: false}]}>
            <SecondarySelect onChange={onCapIdChange} style={{width: '100%'}}>
              <Option value={''} />
              {caps.map((cap) => (
                <Option key={cap.id} value={cap.id}>
                  {cap.description} ({capTypeNames.get(cap.type)})
                </Option>
              ))}
            </SecondarySelect>
          </FormItem>
        </Col>
        <Col span={weightSpan}>
          <DebugFormItem
            isPerspective
            label="Our View"
            name={['cap', 'myWeight']}
            rules={[{required: false}]}
            title="CaseBot.cap.myWeight"
          >
            <PerspectiveAwarePercentInput isPerspective />
          </DebugFormItem>
        </Col>
        <Col span={weightSpan}>
          <DebugFormItem
            isPerspective={false}
            label="Their View"
            name={['cap', 'theirWeight']}
            rules={[{required: false}]}
            title="CaseBot.cap.theirWeight"
          >
            <PerspectiveAwarePercentInput isPerspective={false} />
          </DebugFormItem>
        </Col>
      </Row>

      <VSpaceSmall />
      <TertiaryButton medium onClick={editCaps}>
        Edit Caps
      </TertiaryButton>

      <VSpaceSmall />
      <VSpaceSmall />
      <Form.List name={['capBlockerIssues']}>
        {(fields) => {
          if (!fields.length) {
            return <H3>No Issues defined yet</H3>
          }

          return fields.map((field, index) => (
            <Row align="bottom" gutter={gutter} key={field.key}>
              <Col span={nameSpan}>
                <FormItem hidden name={[field.name, 'issueId']} noStyle>
                  <Input />
                </FormItem>

                <FormItem label={index === 0 ? 'Issue' : ''} name={[field.name, 'issueName']}>
                  <Input readonly />
                </FormItem>
              </Col>

              <Col span={maxSpan - nameSpan}>
                <FormItem
                  label={
                    index === 0 ? (
                      <>
                        Cap <strong>does not</strong> apply if claimant wins this issue
                      </>
                    ) : (
                      ''
                    )
                  }
                  name={[field.name, 'value']}
                  valuePropName="checked"
                >
                  <Switch />
                </FormItem>
              </Col>
            </Row>
          ))
        }}
      </Form.List>
    </>
  )
}
