import {NewDisputeFormValues} from '../create/NewDisputeFormValues'

export const fieldNames: Record<keyof NewDisputeFormValues, string> = {
  classificationId: 'classificationId',
  currency: 'currency',
  description: 'description',
  disputeStartedOn: 'disputeStartedOn',
  insurance: 'insurance',
  jurisdiction: 'jurisdiction',
  parties: 'parties',
  perspectiveId: 'perspectiveId',
  reference: 'reference',
  title: 'title',
}
