export const eventFields = {
  budgetToSettle: 'budgetToSettle',
  costsSpent: 'costsSpent',
  disputeId: 'disputeId',
  eventDate: 'eventDate',
  id: 'id',
  notes: 'notes',
  payments: 'payments',
  sourcePartyId: 'sourcePartyId',
  type: 'type',
}
