import {BarDatum} from '@nivo/bar'

import {HistoryMetrics} from '@settleindex/domain'

export interface MetricData extends BarDatum {
  color: string
  key: HistoryMetrics
  version1: number | string
  version1Title: string
  version2: number | string
  version2Title: string
  version3: number | string
  version3Title: string
  weighted: number | string
}

export const allValueKeys = ['version1', 'version2', 'version3', 'weighted']
