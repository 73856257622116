import {cond} from '@settleindex/fp'

import {visibleBarWidth} from './visibleBarWidth'

interface Args {
  bar: {
    width: number
    x: number
    y: number
  }
  id: string
}

export const getBarTransformCss = ({bar, id}: Args) => {
  const x = cond<any, number>([
    [() => id === 'version1', () => bar.x + bar.width * 2 - visibleBarWidth * 2],
    [() => id === 'version2', () => bar.x + bar.width - visibleBarWidth],
    [() => id === 'version3', () => bar.x],
    [() => id === 'weighted', () => bar.x - bar.width + visibleBarWidth],
  ])()

  return `translate(${x}, ${bar.y})`
}
