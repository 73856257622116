import * as React from 'react'
import useLocalStorage from 'use-local-storage'

const localStorageKey = 'AutoLogout'

interface Args {
  inactiveTimeoutMs: number
  onIdle: () => void
}

export const useAutoLogout = ({inactiveTimeoutMs, onIdle}: Args) => {
  // We keep a second timer as `react-idle-timer` can't keep track when the computer is sleeping.
  // So when the user is back from the sleep, we check if the planned logout time has passed,
  // if so we log the user out, if not we update the time.
  // Both forceLogoutAfterMs and onActive are used for the above purposes.
  const [forceLogoutAfterMs, setForceLogoutAfterMs] = useLocalStorage<number | undefined>(
    localStorageKey,
    addMillisecondsToNow(inactiveTimeoutMs),
  )

  const checkIdleAfterSleep = React.useCallback(() => {
    const nowMs = new Date().getTime()

    if (forceLogoutAfterMs && nowMs > forceLogoutAfterMs) {
      onIdle()

      return
    }

    setForceLogoutAfterMs(addMillisecondsToNow(inactiveTimeoutMs))
  }, [forceLogoutAfterMs, inactiveTimeoutMs, onIdle, setForceLogoutAfterMs])

  // Check timeout once on mount to handle the case when the user closes the
  // browser then re-opens the app after more than an hour
  React.useEffect(() => {
    checkIdleAfterSleep()
  }, [checkIdleAfterSleep])

  return {
    checkIdleAfterSleep,
  }
}

const addMillisecondsToNow = (milliseconds: number): number => new Date().getTime() + milliseconds

const clearLocalStorage = () => {
  localStorage.removeItem(localStorageKey)
}

export const resetAutoLogout = () => {
  clearLocalStorage()
}
