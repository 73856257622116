import * as React from 'react'

import {
  CenterLeftLine,
  CenterRightLine,
  Col,
  Form,
  FormItem,
  H1,
  Modal,
  ModalProps,
  PrimaryButton,
  Row,
  SecondaryButton,
  SecondarySelectXl,
  VSpaceSmall,
} from '@settleindex/react'

import {AccessKind, ResolvedUserResponse} from '../../graphQLTypes'
import {getAccessKindOptions} from './getAccessKindOptions'

export interface ManageModalProps extends ModalProps {
  memberToManage: ResolvedUserResponse
  onCancel: () => void
  onChangeAccess: (memberId: string, accessKind: AccessKind) => void
  onRemoveShare: (memberId: string) => void
}

export const ManageModal: React.FC<ManageModalProps> = ({
  memberToManage,
  onCancel,
  onChangeAccess,
  onRemoveShare,
  open,
}) => {
  const [form] = Form.useForm()
  const onFormFinish = (values: {accessKind: AccessKind}) => {
    onChangeAccess(memberToManage.id, values.accessKind)
    onCancel()
  }

  const handleRemoveShare = () => {
    onRemoveShare(memberToManage.id)
    onCancel()
  }

  const accessKindOptions = React.useMemo(getAccessKindOptions, [])
  const memberTitle =
    memberToManage.user.firstName && memberToManage.user.lastName
      ? `${memberToManage.user.firstName} ${memberToManage.user.lastName}`
      : memberToManage.user.email

  return (
    <Modal closable={false} footer={null} onCancel={onCancel} open={open} width={480}>
      <div style={{padding: '20px'}}>
        <H1>Manage access for {memberTitle}</H1>
        <VSpaceSmall />

        <Form form={form} onFinish={onFormFinish}>
          <Row>
            <Col span={24}>
              <FormItem initialValue={memberToManage.accessKind} name="accessKind">
                <SecondarySelectXl>{accessKindOptions}</SecondarySelectXl>
              </FormItem>
            </Col>
            <Col span={12}>
              <CenterLeftLine>
                <SecondaryButton medium onClick={handleRemoveShare}>
                  Remove Access
                </SecondaryButton>
              </CenterLeftLine>
            </Col>
            <Col span={12}>
              <CenterRightLine>
                <PrimaryButton htmlType="submit" medium>
                  Done
                </PrimaryButton>
              </CenterRightLine>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
