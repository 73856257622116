import {atom, useAtom} from 'jotai'

const damagesVisibleForAtom = atom<Record<string, boolean>>({})
const isLargeViewAtom = atom<boolean>(false)

export const useDecisionTree = () => {
  const [isLargeView, setIsLargeView] = useAtom(isLargeViewAtom)
  const [damagesVisibleFor, setDamagesVisibleFor] = useAtom(damagesVisibleForAtom)

  const toggleDamagesVisibleForId = (id: string) => {
    setDamagesVisibleFor((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  return {
    damagesVisibleFor,
    toggleDamagesVisibleForId,
    isLargeView,
    setIsLargeView,
  }
}
