import styled from 'styled-components'

import {colors} from '@settleindex/react'

export const Footer = styled.div`
  flex-shrink: 0;
  background-color: ${colors.base1};

  & h2,
  // Expand icon
  & a svg,
  & a:hover svg,
  & span {
    color: ${colors.base8};
  }

  & th,
  & td {
    border-color: rgba(250, 250, 253, 0.1) !important;
  }
`
