import * as React from 'react'
import {useParams} from 'react-router'

import {claimClassifications} from '@settleindex/domain'
import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData, LoadingIndicator} from '@settleindex/react'

import {useDisputeContainer} from '../dispute/context/useDisputeContainer'
import {VersionPagePaths} from '../routing'
import {useVersionContainer} from '../version/context/useVersionContainer'
import {useVersionGraph} from '../version/graph/useVersionGraph'
import {ClaimGroupsTable} from './claimGroups/ClaimGroupsTable'

export const ClaimsContainer: React.FC = () => {
  const {disputeId, versionId} = useParams<VersionPagePaths>()

  const {
    dispute,
    error,
    loading,
    onAddClaim,
    onAddClaimGroup,
    onAddCounterclaim,
    onClaimChange,
    onClaimGroupChange,
    onDeleteClaim,
    onDeleteClaimGroup,
    version,
  } = useVersionGraph({disputeId, versionId})

  useDisputeContainer({requiredId: disputeId, setDisputeFrom: dispute})
  useVersionContainer({requiredId: versionId, setVersionFrom: version})

  const whenAvailable = () => {
    return (
      <ClaimGroupsTable
        claimGroupClassifications={claimClassifications}
        onAddClaim={onAddClaim}
        onAddClaimGroup={onAddClaimGroup}
        onAddCounterclaim={onAddCounterclaim}
        onClaimChange={onClaimChange}
        onClaimGroupChange={onClaimGroupChange}
        onDeleteClaim={onDeleteClaim}
        onDeleteClaimGroup={onDeleteClaimGroup}
      />
    )
  }

  return (
    <LoadingData
      state={{
        available: isDefined(version),
        error,
        loading,
      }}
      whenAvailable={whenAvailable}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicator />}
    />
  )
}
