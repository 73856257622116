import * as React from 'react'

import {TertiaryButton} from '@settleindex/react'

import {Tip} from '../../help/Tip'

interface Props {
  onClick: () => void
  testId?: string
}

export const DuplicateButton = ({onClick, testId}: Props) => (
  <Tip id="Model.DuplicateButton">
    <TertiaryButton data-test-id={testId} onClick={onClick} style={{marginLeft: 10}}>
      Duplicate
    </TertiaryButton>
  </Tip>
)
