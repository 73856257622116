import * as React from 'react'

import {noop} from '@settleindex/fp'
import {InputNumberProps, useNumberInput} from '@settleindex/react'

import {SumText} from '../SumText'
import {formatter} from './formatter'
import {parser} from './parser'

type InputSize = 'small' | 'middle' | 'large'

export interface SumInputProps extends Omit<InputNumberProps, 'defaultValue'> {
  defaultValue?: number
  disabled?: boolean
  onChange?: any
  onSave?: (value: number) => void
  onSaveNull?: () => void
  readonly?: boolean
  size?: InputSize
}

export const baseSumInput =
  (SumInputStyle: React.FC<any>): React.FC<SumInputProps> =>
  ({
    defaultValue,
    disabled,
    onChange: parentOnChange = noop,
    onSave = noop,
    onSaveNull,
    readonly = false,
    size,
    ...restProps
  }) => {
    const {amount, onChange, sendChange} = useNumberInput({
      defaultValue,
      onChange: parentOnChange,
      onSave,
      onSaveNull,
    })

    if (readonly) {
      return <SumText sum={defaultValue} />
    }

    return (
      <SumInputStyle
        autoCorrect="off"
        data-1p-ignore
        data-lpignore="true"
        defaultValue={amount}
        disabled={disabled}
        formatter={formatter}
        max={999999999999}
        onBlur={sendChange}
        onChange={onChange}
        onPressEnter={sendChange}
        parser={parser}
        size={size}
        step={100000}
        value={amount}
        {...restProps}
      />
    )
  }
