import * as React from 'react'
import styled from 'styled-components'

import {H1, LinkToTop, PrimaryButton2, VSpaceLarge, VSpaceSmall} from '@settleindex/react'
import {disputeTestIds} from '@settleindex/testids/src/testIds'

import {disputeBreadcrumbs} from '../navigation/disputeBreadcrumbs'
import {Content} from '../page/'
import {paths} from '../routing'

export const DisputeNotFound: React.FC<{}> = () => (
  <Content breadcrumbs={disputeBreadcrumbs()}>
    <CenterTop>
      <VSpaceLarge />
      <H1 data-test-id={disputeTestIds.disputeNotAvailable}>Case not available</H1>
      <VSpaceLarge />
      <p>You don't have access to this case, or it doesn't exist.</p>
      <VSpaceSmall />
      <LinkToTop to={paths.home()}>
        <PrimaryButton2 size="medium">Go to Dashboard</PrimaryButton2>
      </LinkToTop>
    </CenterTop>
  </Content>
)

const CenterTop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`
