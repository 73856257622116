import * as React from 'react'

import {LeftRight, Tag} from '@settleindex/react'

import {Tip} from '../../help/Tip'

interface Props {
  title?: React.ReactElement
}

export const ChartLegend: React.FC<Props> = ({title}) => (
  <LeftRight
    left={title}
    leftSpan={18}
    right={
      <>
        <Tag color="light">Sunk Costs</Tag>
        <Tip id="Chart.Gain">
          <Tag color="green" style={{marginLeft: 20}}>
            Mutual Gain
          </Tag>
        </Tip>
        <Tip id="Chart.Loss">
          <Tag color="raspberry" style={{marginLeft: 20}}>
            Mutual Loss
          </Tag>
        </Tip>
      </>
    }
    rightSpan={6}
  />
)
