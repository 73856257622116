import {CaseBotContribution, CaseBotDamage, Decimal, sumDecimals} from '@settleindex/domain'
import {FormInstance} from '@settleindex/react'

import {CaseBotFormValues} from './CaseBotFormValues'

/**
 * onAddContribution adds a new contribution with sane default weights
 */
export const onAddContribution =
  (form: FormInstance<CaseBotFormValues>) =>
  (addItemToFormList: (issue: CaseBotContribution) => void, fieldName: string, title: string) => {
    const existing: CaseBotDamage[] = form.getFieldValue(fieldName)
    const myWeight = Math.max(0, new Decimal(1).sub(sumDecimals(existing.map((i) => i.myWeight))).toNumber())
    const theirWeight = Math.max(0, new Decimal(1).sub(sumDecimals(existing.map((i) => i.theirWeight))).toNumber())
    const name = `${title} ${existing.length + 1}`
    const newContribution = {
      name,
      myWeight,
      theirWeight,
      proportion: -1,
    }
    addItemToFormList(newContribution)
  }
