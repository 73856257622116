import * as React from 'react'
import {Prompt} from 'react-router-dom'

import {DisputeClassification} from '@settleindex/domain'
import {Form, Popconfirm, PrimaryButton, SecondaryButton} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {DisputePatchInput} from '../../graphQLTypes'
import {FormLayout} from '../form/FormLayout'
import {makeOnChange} from '../form/makeOnChange'
import {PartyInForm} from '../form/party/PartyInForm'
import {Title} from '../form/Title'
import {DisputeEditFormValues} from './DisputeEditFormValues'
import {formValuesToDisputePatch} from './formValuesToDisputePatch'

const leaveConfirmMessage = 'You have unsaved changes. Are you sure you want to leave?'

interface Props {
  disputeClassifications: DisputeClassification[]
  initialValues: DisputeEditFormValues
  onArchive: () => void
  onCancel: () => void
  onFinish: (values: DisputePatchInput) => void
}

export const DisputeEditForm: React.FC<Props> = ({
  disputeClassifications,
  initialValues,
  onArchive,
  onCancel,
  onFinish,
}) => {
  const handleOnCancel = () => {
    setLeaveConfirmationRequired(false)
    onCancel()
  }
  const [leaveConfirmationRequired, setLeaveConfirmationRequired] = React.useState<boolean>(false)
  const [form] = Form.useForm<DisputeEditFormValues>()
  const [partiesWithLabel, setPartiesWithLabel] = React.useState<PartyInForm[]>(initialValues.parties)

  React.useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (form.isFieldsTouched()) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [form])

  React.useEffect(() => {
    form.setFieldsValue(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- prevents changing controlled fields otherwise
  }, [])

  const onChange = makeOnChange({form, setPartiesWithLabel, setLeaveConfirmationRequired})

  const sendPayload = () => {
    onFinish(formValuesToDisputePatch(form.getFieldsValue()))
  }

  const claimantMissing = !partiesWithLabel?.some((p) => p.type === 'claimant')
  const tooFewParties = partiesWithLabel?.length < 2
  const submitDisabled = claimantMissing || tooFewParties

  const primaryButton = (
    <>
      <Popconfirm
        cancelText="No"
        okText="Yes"
        onConfirm={() => {
          onArchive()
        }}
        title="Are you sure you want to delete this Case?"
      >
        <SecondaryButton data-test-id="archive" medium>
          Delete Case
        </SecondaryButton>
      </Popconfirm>

      <PrimaryButton
        data-test-id={disputeFormTestIds.disputeFormSubmit}
        disabled={submitDisabled}
        htmlType="submit"
        medium
        style={{marginLeft: 30}}
      >
        Save Changes
      </PrimaryButton>
    </>
  )

  return (
    <>
      <FormLayout
        disputeClassifications={disputeClassifications}
        form={form}
        onCancel={handleOnCancel}
        onChange={onChange}
        onFinish={sendPayload}
        partiesForPerspective={partiesWithLabel}
        primaryButton={primaryButton}
        titleInput={<Title />}
      />
      <Prompt message={leaveConfirmMessage} when={leaveConfirmationRequired} />
    </>
  )
}
