import * as React from 'react'

import {MaybeNumber} from '@settleindex/domain'
import {cond, equals, isUndefined, negative, positive, T} from '@settleindex/fp'
import {ArrowDownIcon, ArrowUpIcon, PrimaryText} from '@settleindex/react'

const arrowDown = () => <ArrowDownIcon style={{height: 10, width: 10}} />
const arrowUp = () => <ArrowUpIcon style={{height: 10, width: 10}} />
const EmptyText = ({color}: {color: string}) => <PrimaryText style={{color}}>-</PrimaryText>

interface Props {
  color: string
  percent?: number | null
  testId?: string
}

export const PercentChange: React.FC<Props> = ({color, percent, testId}) => {
  const icon = cond<MaybeNumber, React.ReactElement>([
    [negative, arrowDown],
    [positive, arrowUp],
    [T, () => <></>],
  ])(percent)

  const text = cond<MaybeNumber, React.ReactElement>([
    [isUndefined, () => <EmptyText color={color} />],
    [equals<MaybeNumber>(0), () => <EmptyText color={color} />],
    [T, (p) => <PrimaryText bold style={{color}}>{`${Math.abs((p ?? 0) * 100).toFixed(0)}%`}</PrimaryText>],
  ])(percent)

  return (
    <span data-test-id={testId}>
      <span style={{color}}>{icon}</span> {text}
    </span>
  )
}
