import * as React from 'react'

import {
  Cards,
  CenterLeftLine,
  H1,
  HSpace,
  InfoIcon,
  PageHeaderLeftRight,
  PrimaryButton,
  PrimaryText,
  VSpaceLarge,
} from '@settleindex/react'
import {disputeTestIds} from '@settleindex/testids/src/testIds'

import {InviteCard} from '../../disputeTeam/invites/InviteCard'
import {DisputeInListFragment, InviteFragment} from '../../graphQLTypes'
import {disputeBreadcrumbs} from '../../navigation/disputeBreadcrumbs'
import {Content} from '../../page/'
import {DisputeCard} from './DisputeCard'
import {DuplicateModal} from './DuplicateModal'

interface Props {
  disputes: DisputeInListFragment[]
  invites: InviteFragment[]
  loading?: boolean
  onAcceptInvite: (disputeId: string) => void
  onDuplicate: (disputeId: string) => void
  onNewCaseClick: () => void
}

export const DisputeListPage: React.FC<Props> = ({
  disputes,
  invites,
  loading,
  onAcceptInvite,
  onDuplicate,
  onNewCaseClick,
}) => {
  const [disputeToDuplicate, setDisputeToDuplicate] = React.useState<DisputeInListFragment>()
  const unsetDisputeToDuplicate = React.useCallback(() => setDisputeToDuplicate(undefined), [])
  const modalOnDuplicate = React.useCallback(() => {
    disputeToDuplicate && onDuplicate(disputeToDuplicate.id)
    unsetDisputeToDuplicate()
  }, [disputeToDuplicate, onDuplicate, unsetDisputeToDuplicate])

  const cards = React.useMemo(() => {
    const onDuplicateInit = (disputeId: string) => {
      setDisputeToDuplicate(disputes.find((dispute) => dispute.id === disputeId))
    }

    const inviteCards = invites.length ? (
      <>
        <Cards
          contents={invites.map((dispute) => (
            <InviteCard invite={dispute} key={dispute.disputeId} onAccept={onAcceptInvite} />
          ))}
        />
        <CenterLeftLine>
          <InfoIcon />
          <HSpace space={10} />
          <PrimaryText>By joining a case you reveal your name and company to the other members</PrimaryText>
        </CenterLeftLine>
        <VSpaceLarge />
      </>
    ) : (
      <></>
    )

    return (
      <>
        {inviteCards}
        <Cards
          contents={disputes.map((dispute) => (
            <DisputeCard dispute={dispute} key={dispute.id} onDuplicate={onDuplicateInit} />
          ))}
        />
      </>
    )
  }, [disputes, invites, onAcceptInvite])

  return (
    <Content breadcrumbs={disputeBreadcrumbs()} loading={loading} testId={disputeTestIds.disputeList}>
      <PageHeaderLeftRight
        left={<H1>Cases</H1>}
        right={
          <PrimaryButton data-test-id={`create-case`} medium onClick={onNewCaseClick}>
            New Case
          </PrimaryButton>
        }
      />
      {cards}
      <DuplicateModal
        disputeTitle={disputeToDuplicate?.title}
        onCancel={unsetDisputeToDuplicate}
        onDuplicate={modalOnDuplicate}
        visible={!!disputeToDuplicate}
      />
    </Content>
  )
}

DisputeListPage.displayName = 'DisputeListPage'
