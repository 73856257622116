import * as React from 'react'
import {useHistory} from 'react-router-dom'

import {Ambulance, LoadingData} from '@settleindex/react'

import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {useAcceptInviteMutation, useAllDisputesQuery, useDuplicateDisputeMutation, useMeQuery} from '../../graphQLTypes'
import {LoadingIndicatorWithNav} from '../../page'
import {paths} from '../../routing'
import {useDisputeContainer} from '../context/useDisputeContainer'
import {DisputeListEmpty} from './DisputeListEmpty'
import {DisputeListPage} from './DisputeListPage'

const DisputeListContainer: React.FC = () => {
  // Redirect the user to the login target, if it was set
  React.useEffect(() => {
    const target = localStorage.getItem('loginTarget')
    localStorage.removeItem('loginTarget')
    if (target && target !== location.href) {
      document.location.replace(target)
    }
  }, [])

  const {runAsync} = useAppIsLoading()
  const history = useHistory()
  const onNewCaseClick = () => history.push(paths.newDisputePattern)
  useDisputeContainer({reset: true})
  const {data, error, loading, refetch} = useAllDisputesQuery()
  const [runDuplicateDispute] = useDuplicateDisputeMutation()
  const onDuplicate = React.useCallback(
    (disputeId: string) => runAsync(() => runDuplicateDispute({variables: {id: disputeId}}).then(() => refetch())),
    [refetch, runAsync, runDuplicateDispute],
  )

  const {data: meData, loading: meLoading, refetch: meRefetch} = useMeQuery()

  const [runAcceptInvite] = useAcceptInviteMutation()

  const acceptInvite = React.useCallback(
    (disputeId: string) => {
      runAsync(() =>
        runAcceptInvite({variables: {disputeId}}).then(() => {
          refetch()
          meRefetch()
        }),
      )
    },
    [meRefetch, refetch, runAcceptInvite, runAsync],
  )

  const empty = data?.disputes.length === 0 && meData?.me.invites.length === 0
  const available = (data && data.disputes.length > 0) || (meData?.me.invites ?? []).length > 0

  const invites = React.useMemo(() => {
    return meData?.me.invites ?? []
  }, [meData])

  return (
    <LoadingData
      state={{
        error,
        loading: loading || meLoading,
        available,
        empty,
      }}
      whenAvailable={() => (
        <DisputeListPage
          disputes={data!.disputes}
          invites={invites}
          onAcceptInvite={acceptInvite}
          onDuplicate={onDuplicate}
          onNewCaseClick={onNewCaseClick}
        />
      )}
      whenEmpty={() => <DisputeListEmpty onNewCaseClick={onNewCaseClick} />}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicatorWithNav />}
    />
  )
}

export default DisputeListContainer
