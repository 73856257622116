import * as React from 'react'
import {useParams} from 'react-router'
import styled from 'styled-components'

import {isUndefined, omit} from '@settleindex/fp'
import {secondaryNavHeight, Sticky, Tag, theme} from '@settleindex/react'

import {CaseBotPage} from '../../casebot/CaseBotPage'
import {useDebugPanel} from '../../debug/useDebugPanel'
import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {disputeBreadcrumbs, SecondaryNav} from '../../navigation'
import {useResetSelectedNodePartyId} from '../../nodes/context/useNodes'
import {OutcomeDetailsDrawer} from '../../nodes/drawer/OutcomeDetailsDrawer'
import {VersionVariablesDrawer} from '../../nodes/versionVariablesDrawer/VersionVariablesDrawer'
import {RightSidebar, SidebarTabs, usePage, useSidebarTabs} from '../../page'
import {paths, useHistory, VersionPagePaths} from '../../routing'
import {Tutorial} from '../../tutorial/Tutorial'
import {useSafeVersion} from '../context/useSafeVersion'
import {VersionSettingsContainer} from '../settings/VersionSettingsContainer'
import {VersionSummaryBar} from '../summaryBar/VersionSummaryBar'
import {LeftCol} from './layout/LeftCol'
import {RightCol} from './layout/RightCol'
import {useCollapsePrimaryNav} from './useCollapsePrimaryNav'
import {VersionReports} from './versionReports/VersionReports'

interface VersionPageProps {
  loading: boolean
}

export const VersionPage: React.FC<VersionPageProps> = ({loading}) => {
  const {dispute} = useSafeDispute()
  const {version} = useSafeVersion()

  useCollapsePrimaryNav()
  useResetSelectedNodePartyId()

  const {history} = useHistory()
  const {activeTab} = useParams<VersionPagePaths>()

  // redirect from old /issues
  React.useEffect(() => {
    if (activeTab === ('issues' as any)) {
      history.push(paths.version(dispute.id, version.id, 'casebot'))
    }
  }, [activeTab, dispute.id, history, version.id])

  const breadcrumbs = React.useMemo(() => disputeBreadcrumbs({dispute, version}), [dispute, version])
  const {sidebarActiveTab} = useSidebarTabs()
  const {setRightSidebarVisible} = usePage()

  const sidebar = React.useMemo(
    () =>
      !sidebarActiveTab ? null : (
        <RightSidebar>
          <SidebarTabs
            left={{
              content: <VersionSummaryBar />,
            }}
            right={{
              content: <Tutorial />,
            }}
          />
        </RightSidebar>
      ),
    [sidebarActiveTab],
  )

  React.useEffect(() => {
    setRightSidebarVisible(!!sidebar)

    return () => setRightSidebarVisible(false)
  }, [setRightSidebarVisible, sidebar])

  const {setDebugPanelContent} = useDebugPanel()
  React.useEffect(() => {
    setDebugPanelContent({version, dispute: omit('version', dispute)})

    return () => setDebugPanelContent(undefined)
  }, [dispute, setDebugPanelContent, version])

  const perspectiveTag = <Tag color={'perspective'}>Party representing: {dispute.perspective.label}</Tag>

  return (
    <>
      <Sticky>
        <SecondaryNav breadcrumbs={breadcrumbs} loading={loading} tools={[perspectiveTag]} />
      </Sticky>
      <LayoutRow>
        <LeftCol isSingleCol={isUndefined(sidebar)}>
          <div>
            <VersionSettingsContainer disputeId={dispute.id} versionId={version.id} />
          </div>
          <TwoPaneWrapper data-role="TwoPaneWrapper">
            <Pane data-side="left">
              <CaseBotPage />
            </Pane>
            <RightPane data-side="right">
              <VersionReports />
            </RightPane>
          </TwoPaneWrapper>
        </LeftCol>
        {sidebar && <RightCol>{sidebar}</RightCol>}
      </LayoutRow>
      <OutcomeDetailsDrawer />
      <VersionVariablesDrawer />
    </>
  )
}

const TwoPaneWrapper = styled.div`
  display: flex;
  margin: 0 auto !important;
  width: 100%;
  min-width: 1280px;
  max-width: 1920px;

  // to make Panes scroll independently
  height: calc(100% - ${secondaryNavHeight}px);
`

const Pane = styled.div`
  width: 50%;

  // to make Panes scroll independently
  overflow-y: auto;
  height: 100%;
`

const RightPane = styled(Pane)`
  border-left: 1px solid ${theme.divider.color};
`

const LayoutRow = styled.div`
  display: flex;
  height: calc(100vh - ${secondaryNavHeight}px);
  overflow: hidden;
`
