import React from 'react'
import {ReactFlowProvider} from 'react-flow-renderer'

import {DecisionTree, DecisionTreeProps} from './DecisionTree'

export const DecisionTreePage: React.FC<DecisionTreeProps> = (props) => (
  <ReactFlowProvider key="normalProvider">
    <DecisionTree {...props} key="normalTree" />
  </ReactFlowProvider>
)
