import * as React from 'react'

import {NoData, PrimaryButton} from '@settleindex/react'
import {disputeTestIds} from '@settleindex/testids/src/testIds'

interface Props {
  addEmptyVersion?: () => void
  allowedToCreateNewVersion?: boolean
}

export const NoVersions: React.FC<Props> = ({addEmptyVersion, allowedToCreateNewVersion}) => {
  const text = allowedToCreateNewVersion
    ? 'Models represent a view of the case at a point in time. You haven’t created any models yet. To get started, create a model.'
    : 'Models represent a view of the case at a point in time. There are no models in this case yet.'

  return (
    <span data-test-id={disputeTestIds.noVersionsYet}>
      <NoData
        button={
          allowedToCreateNewVersion ? (
            <PrimaryButton data-test-id={disputeTestIds.createVersionButton} medium onClick={addEmptyVersion}>
              Create a Model
            </PrimaryButton>
          ) : undefined
        }
        heading="No Models"
        text={text}
      />
    </span>
  )
}
