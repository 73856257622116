import * as React from 'react'
import {Redirect} from 'react-router-dom'

import {Right} from '@settleindex/domain'
import {message} from '@settleindex/react'

import {captureException} from '../../../captureException'
import {MutationCreateUserArgs, useCreateUserMutation, useUsersLazyQuery} from '../../../graphQLTypes'
import {paths} from '../../../routing'
import {requiresRight} from '../../requiresRight'
import {UserCreatePage} from './UserCreatePage'

const UserCreatePageContainer_: React.FC<{}> = () => {
  const [createUserMutation, {error, loading}] = useCreateUserMutation()
  const [reloadUsers, {called: reloadCalled, data: reloadData, error: reloadError, loading: reloadLoading}] =
    useUsersLazyQuery({
      fetchPolicy: 'network-only',
    })

  const reloadFinished = reloadCalled && reloadData
  const dataLoading = loading || reloadLoading

  if (reloadFinished) {
    message.success(`User created`)

    return <Redirect to={paths.userAdmin()} />
  }

  const createUser = async (variables: MutationCreateUserArgs) => {
    await createUserMutation({variables})
    reloadUsers()
  }

  if (error) {
    message.error(error.message, 10)
    captureException(error)
  }

  if (reloadError) {
    captureException(reloadError)
  }

  return <UserCreatePage loading={dataLoading} onSubmit={createUser} />
}

export default requiresRight(Right.AdminUsers, UserCreatePageContainer_)
