import * as React from 'react'

import {H1, LeftRight, PrimaryButton} from '@settleindex/react'

import {disputeBreadcrumbs} from '../../navigation'
import {Content} from '../../page'

export const TeamPageLoading: React.FC<{}> = () => {
  return (
    <Content breadcrumbs={disputeBreadcrumbs()} loading>
      <LeftRight
        left={<H1 noMargin>Team</H1>}
        right={
          <PrimaryButton medium onClick={open}>
            Invite
          </PrimaryButton>
        }
      />
    </Content>
  )
}
