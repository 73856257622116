import * as React from 'react'

import {Col, LinkToTop, NoData, PrimaryButton2, Row, SecondaryButton2} from '@settleindex/react'

interface Props {
  addEmptyVersion: () => void
  versionListLink: string
}

export const DashboardNoHistory: React.FC<Props> = ({addEmptyVersion, versionListLink}) => {
  return (
    <NoData
      button={
        <Row gutter={20}>
          <Col>
            <PrimaryButton2 onClick={addEmptyVersion} size="medium" weight="medium">
              Create a Model
            </PrimaryButton2>
          </Col>

          <Col>
            <LinkToTop to={versionListLink}>
              <SecondaryButton2 size="medium" weight="medium">
                View All Models
              </SecondaryButton2>
            </LinkToTop>
          </Col>
        </Row>
      }
      heading={'No Published Models'}
      text={'As models are published, the key metrics will be displayed here.'}
    />
  )
}
