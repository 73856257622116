import * as React from 'react'

import {notCombinedDefendantParty, partyPerspectiveFirst} from '@settleindex/domain'
import {ContentWidth, Divider, TabPane, Tabs, VSpaceLarge, VSpaceMedium} from '@settleindex/react'
import {partyCostTableTestIds} from '@settleindex/testids/src/testIds'

import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {CostOperations} from '../graph/CostOperations'
import {PartyCostTable} from './PartyCostTable'

interface Props {
  costOperations: CostOperations
  defaultPartyId?: string
  hasTabPadding?: boolean
}

export const CostsTable: React.FC<Props> = ({costOperations, defaultPartyId, hasTabPadding = true}) => {
  const {
    dispute: {parties, perspectiveId},
  } = useSafeDispute()

  const {
    version: {costGroups, costTotals, id: versionId, readonly},
  } = useSafeVersion()

  const partyCostTables = React.useMemo(
    () =>
      [...parties]
        .filter(notCombinedDefendantParty)
        .sort(partyPerspectiveFirst(perspectiveId))
        .map((party, partyIndex) => {
          const partyCostGroups = costGroups.filter((costGroup) => costGroup.partyId === party.id)
          const partyCostTotals = costTotals.find((t) => t.partyId === party.id)

          return (
            <TabPane key={party.id} tab={party.label}>
              <Divider />
              <ContentWidth>
                <VSpaceMedium />
                <PartyCostTable
                  costGroups={partyCostGroups}
                  costOperations={costOperations}
                  costTotals={partyCostTotals}
                  partyId={party.id}
                  partyIndex={partyIndex}
                  readonly={readonly}
                  title={`${party.label} Costs`}
                  versionId={versionId}
                />
                <VSpaceLarge />
              </ContentWidth>
            </TabPane>
          )
        }),
    [parties, costGroups, costTotals, costOperations, perspectiveId, readonly, versionId],
  )

  const defaultActiveKey = React.useMemo(() => defaultPartyId ?? perspectiveId, [defaultPartyId, perspectiveId])

  const tabBarStyle = React.useMemo(() => ({marginLeft: hasTabPadding ? 60 : 0}), [hasTabPadding])

  return (
    <Tabs data-test-id={partyCostTableTestIds.page} defaultActiveKey={defaultActiveKey} tabBarStyle={tabBarStyle}>
      {partyCostTables}
    </Tabs>
  )
}
