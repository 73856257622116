import * as React from 'react'

import {PercentAsDecimal} from '@settleindex/domain'
import {isDefined} from '@settleindex/fp'
import {PrimaryText} from '@settleindex/react'

import {percentToStr} from './percentToStr'

interface Props {
  bold?: boolean
  percent?: PercentAsDecimal | null
  precision?: number
}

export const Percent: React.FC<Props> = ({bold, percent, precision}) => {
  const text = isDefined(percent) ? percentToStr(percent, precision) : '-'

  return <PrimaryText semibold={bold}>{text}</PrimaryText>
}
