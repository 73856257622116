import {atom, useRecoilState} from 'recoil'

import {SidebarTab} from './SidebarTab'

const sidebarActiveTabState = atom<SidebarTab | undefined>({
  default: undefined,
  key: 'sidebarActiveTabState',
})

export const useSidebarTabs = () => {
  const [sidebarActiveTab, setSidebarActiveTab] = useRecoilState(sidebarActiveTabState)

  return {
    sidebarActiveTab,
    setSidebarActiveTab,
  }
}
