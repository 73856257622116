import * as React from 'react'
import styled from 'styled-components'

import {isDefined} from '@settleindex/fp'
import {H2, PrimaryText} from '@settleindex/react'

import {useNodes} from '../nodes/context/useNodes'
import {SumFormat} from '../sum'
import {usePartyCaseValues} from '../version/usePartyCaseValues/usePartyCaseValues'

export const MetricsSummary: React.FC = () => {
  const {caseValueLabel, caseValueSum, selectedNodePartyId} = useNodes()
  const partyCaseValues = usePartyCaseValues(selectedNodePartyId)

  return !isDefined(partyCaseValues?.caseValuePresent) ? (
    <></>
  ) : (
    <MetricsSummaryLayout>
      <H2 noMargin>
        <SumFormat sum={caseValueSum} />
      </H2>
      <PrimaryText medium>{caseValueLabel}</PrimaryText>
    </MetricsSummaryLayout>
  )
}

export const MetricsSummaryLayout = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  z-index: 10;
`
