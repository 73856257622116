import {TableColumn} from '@settleindex/react'

import {OutcomeTotalsRecord} from './OutcomeTotalsRecord'

export const columns: TableColumn<OutcomeTotalsRecord>[] = [
  {
    id: 'label',
    label: '',
    align: 'left',
  },
  {
    id: 'description',
    label: '',
    align: 'left',
    width: '51%',
  },
  {
    id: 'payReceive',
    label: '',
    align: 'right',
  },
  {
    id: 'amount',
    label: '',
    align: 'right',
  },
]
