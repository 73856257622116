import * as React from 'react'

import {KeyValue, KeyValueBox, PrimaryText, Right} from '@settleindex/react'

import {DebugLabel} from '../../debug'
import {Tip} from '../../help'
import {PercentHighlight} from '../../percent/PercentHighlight'
import {SumHighlight} from '../../sum'
import {useSafeVersion} from '../../version/context/useSafeVersion'

export const MetricsBox: React.FC<{}> = () => {
  const {
    version: {caseTargets},
  } = useSafeVersion()

  const data: KeyValue[] = [
    {
      label: (
        <Tip id="Targets.CurrentSaving">
          <PrimaryText>Potential Gain/Loss</PrimaryText>
        </Tip>
      ),
      value: (
        <DebugLabel title="version.caseTargets.currentGainLoss">
          <Right>
            <SumHighlight sum={caseTargets?.currentGainLoss} />
          </Right>
        </DebugLabel>
      ),
    },
    {
      label: (
        <Tip id="Targets.ForecastSaving">
          <PrimaryText>Forecast Gain/Loss</PrimaryText>
        </Tip>
      ),
      value: (
        <DebugLabel title="version.caseTargets.expectedGainLoss">
          <Right>
            <SumHighlight sum={caseTargets?.expectedGainLoss} />
          </Right>
        </DebugLabel>
      ),
    },
    {
      label: (
        <Tip id="Targets.TargetSettlementRatio">
          <PrimaryText>Value Ratio</PrimaryText>
        </Tip>
      ),
      value: (
        <DebugLabel title="version.caseTargets.settlementRatio">
          <Right>
            <PercentHighlight percent={caseTargets?.settlementRatio} />{' '}
          </Right>
        </DebugLabel>
      ),
    },
  ]

  return <KeyValueBox data={data} span={12} title="KPIs" />
}
