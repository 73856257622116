import {message} from '@settleindex/react'

export const copyToClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      message.success(`Copied`)
    })
    .catch((err: any) => {
      message.error(err)
    })
}
