import {selectorFamily} from 'recoil'

import {cond, safeValue, T} from '@settleindex/fp'

import {disputeAtom} from '../../dispute/context/disputeAtom'
import {versionAtom} from '../context/versionAtom'
import {PartyCaseValues} from './PartyCaseValues'

export const partyCaseValueSelector = selectorFamily<PartyCaseValues | undefined, string | undefined>({
  key: 'partyCaseValueSelector',
  get:
    (partyId?: string) =>
    ({get}) => {
      const {perspectiveId} = safeValue(get(disputeAtom), 'dispute in partyCaseValueSelector')
      const {
        caseValueForecast: perspectiveCaseValueForecast,
        caseValueGross: perspectiveCaseValueGross,
        caseValueNet: perspectiveCaseValueNet,
        caseValuePresent: perspectiveCaseValuePresent,
        opponentValues,
      } = safeValue(get(versionAtom), 'version in partyCaseValueSelector')

      const perspectiveValues = {
        caseValueGross: perspectiveCaseValueGross,
        caseValueNet: perspectiveCaseValueNet,
        caseValuePresent: perspectiveCaseValuePresent,
        caseValueForecast: perspectiveCaseValueForecast,
      }

      return cond<string, PartyCaseValues | undefined>([
        [() => !partyId, () => perspectiveValues],
        [() => partyId === perspectiveId, () => perspectiveValues],
        [T, () => opponentValues.find((o) => o.partyId === partyId)],
      ])()
    },
})
