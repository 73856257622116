import React from 'react'
import useLocalStorage from 'use-local-storage'

import {HistoryMetrics} from '@settleindex/domain'

import {metricVisuals} from '../metricVisuals'

const defaultNumberOfVisibleMetrics = 8

interface Args {
  /**
   * List of default metrics. Overrides `numberOfVisibleMetrics`
   */
  defaultMetrics?: HistoryMetrics[]
  /**
   * Number of visible metrics
   */
  numberOfVisibleMetrics?: number
  storageKey: string
}

export const useMetricsSelector = ({
  defaultMetrics,
  numberOfVisibleMetrics = defaultNumberOfVisibleMetrics,
  storageKey,
}: Args) => {
  const [checkedMetrics, setCheckedMetrics] = useLocalStorage<HistoryMetrics[]>(
    storageKey,
    defaultMetrics ? defaultMetrics : [...metricVisuals.keys()].slice(0, numberOfVisibleMetrics),
  )

  const onCheckboxClick = React.useCallback(
    (metricPath: HistoryMetrics, isChecked: boolean) => {
      isChecked
        ? setCheckedMetrics([...checkedMetrics, metricPath])
        : setCheckedMetrics([...checkedMetrics.filter((m) => m !== metricPath)])
    },
    [setCheckedMetrics, checkedMetrics],
  )

  return {
    onCheckboxClick,
    checkedMetrics,
  }
}
