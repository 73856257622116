import {slugify} from '@settleindex/fp'

export const accessRightTestIds = {
  accessForbidden: 'accessForbidden',
}

interface TableTdTestIdArgs {
  columnId: string
  /**
   * rowPath examples:
   * - Find the first row in the table: [0]
   * - Find the second row then the second row under that: [1,1]
   * - Find the third row then the first child then the second child (on the third level): [2, 0, 1]
   */
  rowPath: number[]
  tableTestId: string
}

interface TableExpandTestIdArgs {
  isOpened: boolean
  /**
   * See DataTableTdTestIdArgs
   */
  rowPath: number[]
  tableTestId: string
}

export const tableTestIds = {
  // `td` creates a table cell test ID
  // Example:
  // tableTestIds.td({columnId: 'name', rowPath: [0], tableTestId: 'claimsTable'})
  // => `claimsTable-row-0-name`
  td: ({columnId, rowPath, tableTestId}: TableTdTestIdArgs) =>
    `${tableTestId}-${rowPath
      .map((i) => ['row', i])
      .flat()
      .join('-')}-${columnId}`,
  expandOpened: 'expandOpened',
  expandClosed: 'expandClosed',
  expand: ({isOpened, rowPath, tableTestId}: TableExpandTestIdArgs) =>
    tableTestIds.td({
      rowPath,
      tableTestId,
      columnId: isOpened ? tableTestIds.expandOpened : tableTestIds.expandClosed,
    }),
}

export const logoTestId = 'logoTestId'

export const outcomesTestIds = {
  addEmptyOutcome: `add-outcome`,
  partySelect: `outcomes-partySelect`,
  partySelectOption: (partyIndex: number) => `outcomes-partySelect-${partyIndex}`,
}

export const outcomeDetailsTestIds = {
  titleInput: 'outcomeTitleInput',
  closeDrawerIcon: 'closeDrawerIcon',
  partyCostAccordion: (suffix: string | number) => `party-cost-accordion-${suffix}`,
  claimGroupWeightInput: (claimGroupIndex: number) => `claim-group-weight-${claimGroupIndex}`,
}

export const outcomesMapTestIds = {
  outcomeNode: (outcomeName: string) => `outcomeNode-${slugify(outcomeName)}`,
  outcomeWeightInput: (outcomeName: string) => `outcomeWeightInput-${slugify(outcomeName)}`,
  toolbar: 'outcomeToolbar',
  toolbarBranchOutcomeButton: 'toolbarBranchOutcomeButton',
  toolbarDeleteOutcomeButton: 'toolbarDeleteOutcomeButton',
  toolbarDuplicateOutcomeButton: 'toolbarDuplicateOutcomeButton',
  toolbarEditOutcomeButton: 'toolbarEditOutcomeButton',
  toolbarMoveOutcomeUpButton: 'toolbarMoveOutcomeUpButton',
  toolbarMoveOutcomeDownButton: 'toolbarMoveOutcomeDownButton',
}

export const outcomesAnalyseTestIds = {
  analyseTable: `outcome-analyse-table`,
}

export const disputeFormTestIds = {
  editClassification: `editClassification`,
  editJurisdiction: `editJurisdiction`,
  editReference: `editReference`,
  editDisputeTitle: `editDisputeTitle`,
  editPartyLabel: `editPartyLabel`,
  editPartyName: `editPartyName`,
  editPartyLawFirm: `editPartyLawFirm`,
  editPartyLeadSolicitor: `editPartyLeadSolicitor`,
  editPartyIndustry: `editPartyIndustry`,
  editPartyIndustryDivision: `editPartyIndustryDivision`,
  disputeFormSubmit: 'disputeFormSubmit',
  editParty: (partyIndex: number) => `edit-party-${partyIndex}`,
  partyLabel: (partyIndex: number) => `party-${partyIndex}-label`,
  partyName: (partyIndex: number) => `party-${partyIndex}-name`,
}

export const disputeTestIds = {
  noVersionsYet: 'noVersionsYet',
  disputeHistoryContent: 'disputeHistoryContent',
  disputeList: 'disputeList',
  versionList: 'versionList',
  viewVersionButton: (index: number) => `viewVersionButton-${index}`,
  duplicateVersionButton: (index: number) => `duplicateVersionButton-${index}`,
  createVersionButton: 'createVersionButton',
  disputeNotAvailable: 'disputeNotAvailable',
}

export const versionReportTestIds = {
  strategyAmount: (opponentIndex: number) => `strategy-amount-${opponentIndex}`,
}

export const primaryNavigationTestIds = {
  account: 'primaryNavAccount',
  admin: 'primaryNavAdmin',
  compare: 'primaryNavCompare',
  dashboard: 'primaryNavDashboard',
  debugLabelsToggle: 'debugLabelsToggle',
  debugPanelToggle: 'debugPanelToggle',
  disputeList: 'primaryNavCases',
  disputeTitle: 'primaryNavTitle',
  disputeHistory: 'primaryNavDisputeHistory',
  help: 'primaryNavHelp',
  logout: 'primaryNavLogout',
  offers: 'primaryNavOffers',
  primaryNav: `primaryNav`,
  settings: 'primaryNavSettings',
  team: 'primaryNavTeam',
}

export const versionVariablesTabsTestIds = {
  claims: 'versionTabClaims',
  costs: 'versionTabCosts',
  caps: 'versionTabCaps',
}

export const versionSummaryBarTestIds = {
  versionSummaryBar: 'versionSummaryBar',
}

export const tutorialTestIds = {
  tutorialBar: 'tutorialBar',
}

export const versionSettingsTestIds = {
  deleteButton: 'deleteButton',
  duplicateButton: 'duplicateButton',
  confirmMakeReportButton: 'confirmMakeReportButton',
  makeDraftButton: 'makeDraftButton',
  makeReportButton: 'makeReportButton',
  versionSettings: 'versionSettings',
}

export const eventTestIds = {
  eventDateFormItem: 'eventDateFormItem',
  eventTable: 'eventTable',
}

export const chartTestIds = {
  chartSection: 'chartSection',
}

export const partyCostTableTestIds = {
  addCostGroupButton: 'addCostGroupButton',
  addCostButton: (costGroupIndex: number) => `addCostButton-${costGroupIndex}`,
  page: `party-costs-page`,
  partyCostTable: (partyIndex: number) => `partyCostTable-${partyIndex}`,
  totalsTable: 'partyCostTableTotals',
  noCosts: 'noCosts',
}

export const claimsTableTestIds = {
  noClaimGroups: 'noClaimGroups',
  createClaimButton: `create-claim-button`,
  createClaimGroupButton: `create-claim-group-button`,
  createCounterClaimButton: `create-counter-claim-button`,
  claimGroupsTable: () => `claimGroupsTable`,
}

export const capTestIds = {
  noCaps: 'noCaps',
  capsTable: 'capsTable',
}

export const settlementTestIds = {
  caseValues: 'caseValues',
}

export const loginTestIds = {
  submitButton: 'loginSubmitButton',
  emailField: 'emailField',
  passwordField: 'pWordField',
}

export const registerTestIds = {
  registerHeader: 'registerHeader',
  submitButton: 'registerSubmitButton',
}

export const disputeTeamTestIds = {
  mangeButton: (email: string) => `manageButton-${email}`,
  acceptInviteOnDashboard: (disputeTitle: string) => `accept-invite-${slugify(disputeTitle)}`,
}

export const caseBotTestIds = {
  inputClaimsAccordion: 'inputClaimsAccordion',
}
