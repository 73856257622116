import * as React from 'react'

import {Col, PrimaryText, SecondaryText} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {Row} from '../Row'
import {PartyInForm} from './PartyInForm'

interface Props {
  editButton?: React.ReactElement
  index: number
  party: PartyInForm
  removeButton?: React.ReactElement
}

export const PartyDisplayRow: React.FC<Props> = ({editButton, index, party, removeButton}) => (
  <Row align="middle" justify="center" style={{height: 46}}>
    <Col span={7}>
      <PrimaryText data-test-id={disputeFormTestIds.partyLabel(index)} semibold>
        {party.label}
      </PrimaryText>
    </Col>
    <Col span={11}>
      <SecondaryText data-test-id={disputeFormTestIds.partyName(index)}>{party.name}</SecondaryText>
    </Col>
    <Col span={3} style={{display: 'flex', justifyContent: 'space-evenly'}}>
      {editButton}
    </Col>
    <Col span={3} style={{display: 'flex', justifyContent: 'space-evenly'}}>
      {removeButton}
    </Col>
  </Row>
)
