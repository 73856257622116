import {SliceTooltipProps} from '@nivo/line'
import dayjs from 'dayjs'
import * as React from 'react'

import {HistoryMetrics} from '@settleindex/domain'
import {reverse, sortByPath} from '@settleindex/fp'
import {PrimaryLabel} from '@settleindex/react'

import {Box} from '../../../chart/tooltip/Box'
import {LineItem} from '../../../chart/tooltip/LineItem'
import {SumFormat} from '../../../sum'
import {MetricVisualsMap} from '../metricVisuals'

type Props = SliceTooltipProps & {
  metricVisualsMap: MetricVisualsMap
}

export const Tooltip: React.FC<Props> = React.memo(({metricVisualsMap, slice}) => {
  const date = dayjs(slice.points[0].data.x).format('D MMMM YYYY')

  return (
    <Box>
      <PrimaryLabel medium>{date}</PrimaryLabel>
      {reverse(sortByPath(['data', 'y'], slice.points)).map((point) => {
        return (
          <LineItem
            key={point.id}
            label={metricVisualsMap.get(point.serieId as HistoryMetrics)?.title ?? ''}
            point={point}
            value={<SumFormat sum={Number(point.data.y)} />}
          />
        )
      })}
    </Box>
  )
})
