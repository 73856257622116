import * as React from 'react'
import {useMemo} from 'react'

import {cond, T} from '@settleindex/fp'
import {
  LeftRight,
  PrimaryText,
  SecondaryButton2,
  SecondaryText,
  Table,
  TableRow,
  Tag,
  VSpaceMedium,
  VSpaceSmall,
} from '@settleindex/react'

import {DebugLabel} from '../../../debug'
import {PartyFragment} from '../../../graphQLTypes'
import {Percent} from '../../../percent/Percent'
import {SumFormat, SumText} from '../../../sum'
import {useSafeVersion} from '../../../version/context/useSafeVersion'
import {AppOutcome} from '../../AppOutcome'
import {useVersionVariablesDrawer} from '../../versionVariablesDrawer/useVersionVariablesDrawer'
import {PayReceiveTag} from '../payReceiveTag/makePayReceiveTag'
import {CostRecoveryRecord} from './CostRecoveryRecord'
import {costRecoveryTableColumns} from './costRecoveryTableColumns'

interface Props {
  outcome: AppOutcome
  parties: PartyFragment[]
  payReceiveTag: PayReceiveTag
  readonly?: boolean
}

export const CostRecoveryTable: React.FC<Props> = ({outcome, parties, payReceiveTag, readonly}) => {
  const {editCosts} = useVersionVariablesDrawer()
  const {version} = useSafeVersion()

  const part36Enabled = React.useMemo(() => !!version.caseBot?.part36?.[0]?.enabled, [version.caseBot?.part36])
  const isPart36AutoMode = React.useMemo(() => version.caseBot.part36[0]?.mode === 'auto', [version.caseBot.part36])
  const modeText = isPart36AutoMode ? 'Auto Mode' : 'Custom Mode'
  const tagColor = useMemo(() => (isPart36AutoMode ? 'violet' : 'grape'), [isPart36AutoMode])

  const part36Tag = React.useMemo(() => {
    if (!part36Enabled) {
      return <VSpaceMedium />
    }

    const offerAmount = isPart36AutoMode ? 0 : version.caseBot?.part36?.[0]?.offerAmount ?? 0

    const text = cond([
      [
        () => outcome.claimsWithInterest <= 0,
        () => (
          <span>
            Value Based Cost Awards apply: Claims Award (<SumFormat sum={outcome.claimsWithInterest} />) is zero or
            less.
          </span>
        ),
      ],
      // Cost Award less than or equal to offer
      [
        () => outcome.claimsWithInterest <= offerAmount,
        () => (
          <span>
            Value Based Cost Awards apply: Claims Award (<SumFormat sum={outcome.claimsWithInterest} />) less than or
            equal to Offer Amount (<SumFormat sum={offerAmount} />)
          </span>
        ),
      ],
      // Cost Award greater then offer
      [
        T,
        () => (
          <span>
            Value Based Cost Awards apply: Claims Award (<SumFormat sum={outcome.claimsWithInterest} />) greater than
            Offer Amount (<SumFormat sum={offerAmount} />)
          </span>
        ),
      ],
    ])()

    return (
      <>
        <VSpaceSmall />
        <LeftRight left={<Tag color={tagColor}>{text}</Tag>} right={<Tag color={tagColor}>{modeText}</Tag>} />
        <VSpaceMedium />
      </>
    )
  }, [isPart36AutoMode, modeText, outcome.claimsWithInterest, part36Enabled, tagColor, version.caseBot?.part36])

  const partyRows: TableRow<CostRecoveryRecord>[] = React.useMemo(
    () =>
      parties.map((party) => {
        const costRecovery = outcome.costRecoveryPartyAmounts.find((a) => a.partyId === party.id)

        return {
          id: party.id,
          cells: {
            party: <PrimaryText semibold>{party.label}</PrimaryText>,
            potential: (
              <DebugLabel title="outcome.costRecoveryPartyAmounts.potential">
                <SumText semibold sum={costRecovery?.potential} />
              </DebugLabel>
            ),
            percent: (
              <DebugLabel title="outcome.costRecoveryPartyAmounts[].recoverablePercent">
                <Percent percent={costRecovery?.recoverablePercent} />
              </DebugLabel>
            ),
            recoverable: (
              <DebugLabel title="outcome.costRecoveryPartyAmounts[].amount">
                <SumText semibold sum={costRecovery?.amount} />
              </DebugLabel>
            ),
          },
        }
      }),
    [outcome.costRecoveryPartyAmounts, parties],
  )

  const subTotalRow: TableRow<CostRecoveryRecord> = {
    id: 'CostRecoveryTable-subTotal',
    cells: {
      party: <PrimaryText semibold>Subtotal</PrimaryText>,
      percent: (
        <DebugLabel placement="bottom" title="outcome.costRecoveryGross">
          {payReceiveTag(outcome.costRecoveryGross)}
        </DebugLabel>
      ),
      recoverable: (
        <DebugLabel title="outcome.costRecoveryGross">
          <SumText semibold sum={outcome.costRecoveryGross} />
        </DebugLabel>
      ),
    },
  }

  // "Discount on Assessment" row
  const irrecoverableRow: TableRow<CostRecoveryRecord> = {
    id: 'CostRecoveryTable-irrecoverable',
    cells: {
      party: <PrimaryText>Discount on Assessment</PrimaryText>,
      percent: (
        <DebugLabel title="outcome.estimate.costIrrecoverablePercentage">
          <Percent percent={outcome.discountOnAssessment} />
        </DebugLabel>
      ),
      recoverable: (
        <DebugLabel title="outcome.costRecoveryIrrecoverable">
          <SumText semibold sum={outcome.costRecoveryIrrecoverable} />
        </DebugLabel>
      ),
    },
  }

  const totalRow: TableRow<CostRecoveryRecord> = {
    id: 'CostRecoveryTable-total',
    cells: {
      party: <PrimaryText semibold>Costs Award</PrimaryText>,
      percent: payReceiveTag(outcome.costRecoveryNet),
      recoverable: (
        <DebugLabel title="outcome.costRecoveryNet">
          <SumText semibold sum={outcome.costRecoveryNet} />
        </DebugLabel>
      ),
    },
  }
  const rows = part36Enabled
    ? [...partyRows, subTotalRow, totalRow]
    : [...partyRows, subTotalRow, irrecoverableRow, totalRow]

  return (
    <>
      <LeftRight
        left={<SecondaryText medium>Own costs recovered or opponent costs paid</SecondaryText>}
        right={!readonly && <SecondaryButton2 onClick={editCosts}>Manage Values</SecondaryButton2>}
      />

      {part36Tag}

      <Table<CostRecoveryRecord> columns={costRecoveryTableColumns} rows={rows} stateId={`CostRecoveryTable`} />
    </>
  )
}
