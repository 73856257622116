import {selector} from 'recoil'

import {safeDisputeSelector} from '../../../dispute/context/useSafeDispute'
import {versionAtom} from '../../../version/context/versionAtom'
import {AppDecision} from '../../AppDecision'
import {toAppDecision} from '../../toAppDecision'
import {selectedPartyIdAtom} from '../atoms/selectedPartyIdAtom'

export const selectedPartyDecisionsSelector = selector<AppDecision[]>({
  key: 'selectedPartyDecisionsSelector',
  get: ({get}) => {
    const dispute = get(safeDisputeSelector)
    const selectedNodePartyId = get(selectedPartyIdAtom) ?? dispute.perspectiveId
    const isPerspective = selectedNodePartyId === dispute.perspectiveId

    return (get(versionAtom)?.decisions ?? []).map((decision) =>
      toAppDecision(decision, selectedNodePartyId, isPerspective),
    )
  },
})
