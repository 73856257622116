import * as React from 'react'
import styled from 'styled-components'

import {
  LeftRight,
  Option,
  PrimaryText,
  SecondaryButton,
  SecondarySelectXl,
  SecondaryText,
  VSpaceMedium,
  XlOption,
} from '@settleindex/react'

import {CapFragment, PartyFragment} from '../../graphQLTypes'
import {useVersionVariablesDrawer} from '../../nodes/versionVariablesDrawer/useVersionVariablesDrawer'
import {SumFormat} from '../../sum'
import {capTypeNames} from '../capTypeNames'

const LeftBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 10px;

  & span {
    display: block;
  }
`

const RightBlock = styled.div`
  margin-right: 60px;
`

interface Props {
  caps: CapFragment[]
  defaultValue: string
  onChange: (capId: string) => void
  parties: PartyFragment[]
  readonly: boolean
}

export const CapDropdown: React.FC<Props> = ({caps, defaultValue = '', onChange, parties, readonly}) => {
  const {editCaps} = useVersionVariablesDrawer()

  return (
    <>
      <LeftRight
        left={<SecondaryText medium>Caps or limits that apply in this outcome</SecondaryText>}
        right={!readonly && <SecondaryButton onClick={editCaps}>Manage Values</SecondaryButton>}
      />
      <VSpaceMedium />
      <SecondarySelectXl defaultValue={defaultValue} disabled={readonly} onChange={(capId: string) => onChange(capId)}>
        <Option key={'none'} value={''}>
          <XlOption>
            <LeftBlock>
              <PrimaryText style={{padding: '15px 0'}}>No Cap/Limit Applies</PrimaryText>
            </LeftBlock>
          </XlOption>
        </Option>
        {caps.map((cap) => {
          const party = parties.find((p) => p.id === cap.partyId)
          const line2 = cap.description
            ? `${cap.description.trim()}, ${capTypeNames.get(cap.type)}`
            : capTypeNames.get(cap.type)

          return (
            <Option key={cap.id} value={cap.id}>
              <XlOption>
                <LeftRight
                  left={
                    <LeftBlock>
                      <PrimaryText>{party?.label}</PrimaryText>
                      <SecondaryText>{line2}</SecondaryText>
                    </LeftBlock>
                  }
                  right={
                    <RightBlock>
                      <PrimaryText>
                        <SumFormat sum={cap.total} />
                      </PrimaryText>
                    </RightBlock>
                  }
                />
              </XlOption>
            </Option>
          )
        })}
      </SecondarySelectXl>
    </>
  )
}
