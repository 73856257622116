import * as React from 'react'
import {useParams} from 'react-router-dom'

import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData} from '@settleindex/react'

import {useDisputeContainer} from '../dispute/context/useDisputeContainer'
import {DisputeNotFound} from '../dispute/DisputeNotFound'
import {disputeNotFoundGraphError} from '../dispute/graph/disputeErrorGuards'
import {DisputeFragment} from '../graphQLTypes'
import {LoadingIndicatorWithNav} from '../page'
import {VersionPagePaths} from '../routing'
import {useVersionContainer} from './context/useVersionContainer'
import {useVersionGraph} from './graph/useVersionGraph'
import {versionNotFoundGraphError} from './graph/versionErrorGuards'
import {VersionPage} from './page/VersionPage'
import {VersionNotFound} from './VersionNotFound'

const VersionContainer: React.FC = () => {
  const {disputeId, versionId} = useParams<VersionPagePaths>()

  const {
    dispute: graphDispute,
    disputeTeam,
    error,
    loading,
    version: graphVersion,
    ...useVersionProps
  } = useVersionGraph({disputeId, versionId})

  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: graphDispute})
  const {version: versionInContext} = useVersionContainer({
    requiredId: versionId,
    setVersionFrom: graphVersion,
  })

  const disputeNotFound = React.useMemo(() => disputeNotFoundGraphError(error), [error])
  const versionNotFound = React.useMemo(
    () => versionNotFoundGraphError(error) || graphDispute?.version === null,
    [error, graphDispute?.version],
  )

  const whenEmpty = React.useCallback(
    () => (disputeNotFound ? <DisputeNotFound /> : <VersionNotFound dispute={disputeInContext as DisputeFragment} />),
    [disputeInContext, disputeNotFound],
  )

  const whenAvailable = React.useCallback(() => <VersionPage {...useVersionProps} loading={false} />, [useVersionProps])

  const state = React.useMemo(() => {
    const available = isDefined(versionInContext)
    const empty = disputeNotFound || versionNotFound

    return {
      available,
      empty,
      error,
      loading,
    }
  }, [disputeNotFound, error, loading, versionInContext, versionNotFound])
  const whenError = React.useMemo(() => <Ambulance />, [])
  const whenLoading = React.useMemo(() => <LoadingIndicatorWithNav />, [])

  return (
    <LoadingData
      state={state}
      whenAvailable={whenAvailable}
      whenEmpty={whenEmpty}
      whenError={whenError}
      whenLoading={whenLoading}
    />
  )
}

export default VersionContainer
