import * as React from 'react'

import {Tag} from '@settleindex/react'

import {Role} from '../graphQLTypes'

interface Props {
  /**
   * Is the party represented by this tag the Dispute perspective?
   */
  isPerspective: boolean
  role?: Role
}

export const RoleTag: React.FC<Props> = ({isPerspective, role = Role.Claimant}) => {
  const tagColor = isPerspective ? 'perspective' : 'opponent'

  return (
    <Tag color={tagColor} style={{minWidth: 52, paddingLeft: 8, paddingRight: 8}}>
      {role} View
    </Tag>
  )
}
