import * as React from 'react'

import {isDefined} from '@settleindex/fp'
import {Col, FormItem, FormListFieldData, Input, PrimaryText, Row, SecondaryButton2} from '@settleindex/react'

import {PartyFragment} from '../../graphQLTypes'
import {Tip} from '../../help/Tip'
import {SumFormat} from '../../sum'
import {SumInput} from '../../sum/sumInput'
import {PartySelect} from './PartySelect'

interface Props {
  add: () => void
  fields: FormListFieldData[]
  handleOnChange: () => void
  parties: PartyFragment[]
  payments: {amount: number; costAmount: number}[]
  readonly?: boolean
  remove: (n: number) => void
}

export const Payments: React.FC<Props> = ({
  add,
  fields,
  handleOnChange,
  parties,
  payments,
  readonly = false,
  remove,
}) => {
  const addPayment = !readonly && (
    <SecondaryButton2 medium onClick={add}>
      Add Payment
    </SecondaryButton2>
  )

  if (!isDefined(payments)) {
    return <>{addPayment}</>
  }

  return (
    <>
      {fields.map((field, index) => {
        const isFirstPayment = index === 0
        const onlyOnePaymentExists = fields.length === 1
        const removePaymentDisabled = isFirstPayment && onlyOnePaymentExists
        const removeButtonWidthFix = {minWidth: '100%'}
        const removeButtonStyle = index === 0 ? {...removeButtonWidthFix, marginTop: 30} : {...removeButtonWidthFix}

        return (
          <Row align="top" gutter={20} key={index}>
            <FormItem
              {...field}
              fieldKey={[field.key, 'id']}
              name={[field.name, 'id']}
              noStyle
              rules={[{required: false}]}
            >
              <Input type="hidden" />
            </FormItem>
            <Col span={5}>
              <FormItem
                label={index === 0 ? 'Payer' : ''}
                {...field}
                fieldKey={[field.key, 'payer']}
                name={[field.name, 'payer']}
              >
                <PartySelect
                  data-test-id={`event-${index}-payer`}
                  disabled={readonly}
                  onChange={handleOnChange}
                  parties={parties}
                />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                label={index === 0 ? 'Payee' : ''}
                {...field}
                dependencies={[[`payments`, index, `payer`]]}
                fieldKey={[field.key, 'payee']}
                name={[field.name, 'payee']}
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                  ({getFieldValue}) => ({
                    validator(_, payeeId) {
                      const payerId = getFieldValue([`payments`, index, `payer`])
                      return payeeId && payeeId === payerId ? Promise.reject('Invalid Payee') : Promise.resolve()
                    },
                  }),
                ]}
              >
                <PartySelect
                  data-test-id={`event-${index}-payee`}
                  disabled={readonly}
                  onChange={handleOnChange}
                  parties={parties}
                />
              </FormItem>
            </Col>
            <Col span={5}>
              <Tip id="Event.PaymentAmount" placement="top">
                <FormItem
                  label={index === 0 ? 'Amount' : ''}
                  {...field}
                  fieldKey={[field.key, 'amount']}
                  name={[field.name, 'amount']}
                >
                  {readonly ? (
                    <PrimaryText>
                      <SumFormat sum={payments[index].amount} />
                    </PrimaryText>
                  ) : (
                    <SumInput data-test-id={`event-${index}-amount`} onChange={handleOnChange} />
                  )}
                </FormItem>
              </Tip>
            </Col>
            <Col span={5}>
              <Tip id="Event.PaymentCosts" placement="top">
                <FormItem
                  label={index === 0 ? 'Costs Offer' : ''}
                  {...field}
                  fieldKey={[field.key, 'costAmount']}
                  name={[field.name, 'costAmount']}
                >
                  {readonly ? (
                    <PrimaryText>
                      <SumFormat sum={payments[index].costAmount} />
                    </PrimaryText>
                  ) : (
                    <SumInput data-test-id={`event-${index}-costAmount`} onChange={handleOnChange} />
                  )}
                </FormItem>
              </Tip>
            </Col>
            <Col span={4}>
              {!readonly && (
                <SecondaryButton2
                  disabled={removePaymentDisabled}
                  medium
                  onClick={() => {
                    remove(index)
                    handleOnChange()
                  }}
                  style={removeButtonStyle}
                >
                  Remove
                </SecondaryButton2>
              )}
            </Col>
          </Row>
        )
      })}

      {addPayment}
    </>
  )
}
