import * as React from 'react'

import {HSpaceSmall, LeftRight, NameDescription, VSpaceMedium, VSpaceSmall} from '@settleindex/react/lib/src'

import {ChartSectionNormalLayout} from '../../chart/layout/ChartSectionNormalLayout'
import {HelpTitleWithTooltip} from '../../help/HelpTitleWithTooltip'
import {PartySelect} from '../../version/outcomes/PartySelect'
import {ValueToDisplaySelect} from '../../version/outcomes/ValueToDisplaySelect'
import {useNodes} from '../context/useNodes'
import {AnalyseChart} from './AnalyseChart'
import {AnalyseTable} from './AnalyseTable'
import {NoOutcomes} from './NoOutcomes'

// outcomeVisibleThreshold hides outcomes with less than 0.5% chance weight,
// so the users can't see "0%" outcomes
const outcomeVisibleThreshold = 0.5 / 100

export const AnalysisSection: React.FC = () => {
  const {selectedNodePartyId, selectedPartyOutcomes: partyOutcomes} = useNodes()

  // This key forces the otherwise quite dodgy chart to re-render when the selected party or their outcomes change
  const chartKey = React.useMemo(
    () => partyOutcomes.map((o) => o.id).join('') + selectedNodePartyId,
    [partyOutcomes, selectedNodePartyId],
  )

  if (!partyOutcomes.length) {
    return <NoOutcomes />
  }

  const headline = (
    <LeftRight
      left={<NameDescription description="Ordered by value" name="" />}
      leftSpan={10}
      leftStyle={{flexDirection: 'column'}}
      right={
        <>
          <PartySelect />
          <HSpaceSmall />
          <ValueToDisplaySelect />
        </>
      }
      rightSpan={14}
      rowStyle={{alignItems: 'center'}}
    />
  )

  return (
    <>
      <HelpTitleWithTooltip
        id="help_for_outcome_analysis"
        left="Displays all material Outcomes in the Map, sorted by value"
        leftId="help_for_outcome_analysis_title"
      />

      <VSpaceSmall />
      <ChartSectionNormalLayout headline={headline}>
        <AnalyseChart key={chartKey} outcomeVisibleThreshold={outcomeVisibleThreshold} />
      </ChartSectionNormalLayout>

      <VSpaceMedium />

      <AnalyseTable outcomeVisibleThreshold={outcomeVisibleThreshold} />
    </>
  )
}
