import * as React from 'react'

import {KeyValueBox, PrimaryText, Right, Row, VSpaceSmall} from '@settleindex/react'

import {Tip} from '../../help'
import {PercentHighlight} from '../../percent/PercentHighlight'
import {SumFormat, SumHighlight} from '../../sum'
import {useSafeVersion} from '../context/useSafeVersion'

export const VersionSummaryBar: React.FC = () => {
  const {version} = useSafeVersion()

  const caseValueBox = (
    <KeyValueBox
      data={[
        {
          label: 'Overall',
          value: (
            <PrimaryText semibold>
              <SumFormat sum={version?.caseValueNet} />
            </PrimaryText>
          ),
        },
        {
          label: 'Current',
          value: (
            <PrimaryText semibold>
              <SumFormat sum={version?.caseValuePresent} />
            </PrimaryText>
          ),
        },
        {
          label: 'Forecast',
          value: (
            <PrimaryText semibold>
              <SumFormat sum={version?.caseValueForecast} />
            </PrimaryText>
          ),
        },
        {
          label: 'Award',
          value: (
            <PrimaryText semibold>
              <SumFormat sum={version?.caseValueGross} />
            </PrimaryText>
          ),
        },
      ]}
      span={8}
      title="Position"
    />
  )

  const targetsBox = (
    <KeyValueBox
      data={[
        {
          label: (
            <Tip id="Targets.SettlementTarget">
              <PrimaryText>Case Target</PrimaryText>
            </Tip>
          ),
          value: (
            <PrimaryText medium>
              <SumFormat sum={version?.caseTargets?.settlementTarget} />
            </PrimaryText>
          ),
        },
        {
          label: (
            <Tip id="Targets.CostsTargets">
              <PrimaryText>Costs Target</PrimaryText>
            </Tip>
          ),
          value: (
            <PrimaryText medium>
              <SumFormat sum={version?.perspectiveCost?.target} />
            </PrimaryText>
          ),
        },
        {
          label: (
            <Tip id="Targets.TotalCaseTarget">
              <PrimaryText>Overall Target</PrimaryText>
            </Tip>
          ),
          value: (
            <PrimaryText medium>
              <SumFormat sum={version?.caseTargets?.expectedNetSettlementValue} />
            </PrimaryText>
          ),
        },
      ]}
      span={8}
      title="Case Targets"
    />
  )

  const metricsBox = (
    <KeyValueBox
      data={[
        {
          label: (
            <Tip id="Targets.CurrentSaving">
              <PrimaryText>Potential Gain/Loss</PrimaryText>
            </Tip>
          ),
          labelSpan: 14,
          valueSpan: 10,
          value: (
            <Right>
              <SumHighlight sum={version?.caseTargets?.currentGainLoss} />
            </Right>
          ),
        },
        {
          label: (
            <Tip id="Targets.ForecastSaving">
              <PrimaryText>Forecast Gain/Loss</PrimaryText>
            </Tip>
          ),
          labelSpan: 14,
          valueSpan: 10,
          value: (
            <Right>
              <SumHighlight sum={version?.caseTargets?.expectedGainLoss} />
            </Right>
          ),
        },
        {
          label: (
            <Tip id="Targets.TargetSettlementRatio">
              <PrimaryText>Value Ratio</PrimaryText>
            </Tip>
          ),
          value: (
            <Right>
              <PercentHighlight percent={version?.caseTargets?.settlementRatio} />{' '}
            </Right>
          ),
        },
      ]}
      span={8}
      title="KPIs"
    />
  )

  return (
    <>
      <VSpaceSmall />
      <Row gutter={30}>
        {caseValueBox}
        {targetsBox}
        {metricsBox}
      </Row>
      <VSpaceSmall />
    </>
  )
}
