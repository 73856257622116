import * as React from 'react'

import {
  Divider,
  H2,
  Modal,
  PlusIcon,
  PrimaryButton,
  SecondaryButton,
  Tooltip,
  VSpace,
  VSpaceSmall,
} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {tmpId} from '../tmpId'
import {PartyDisplayRow} from './PartyDisplayRow'
import {PartyForm} from './PartyForm'
import {PartyInForm} from './PartyInForm'

const modalProps = {
  closable: false,
  destroyOnClose: true,
  footer: null,
  width: 580,
}

const newPartySkeleton = (type: PartyInForm['type']) => ({id: tmpId(), type, label: ''})

interface Props {
  add: () => void
  addButtonLabel: string
  limit: number
  parties?: PartyInForm[]
  partyIndexStart?: number
  remove: (id: string) => void
  sectionTitle: string
  type: PartyInForm['type']
}

export const GroupSection: React.FC<Props> = ({
  addButtonLabel,
  limit,
  parties = [],
  partyIndexStart = 0,
  remove,
  sectionTitle,
  type,
}) => {
  const [modalVisible, setModalVisible] = React.useState<string | undefined>()
  const hideModal = () => setModalVisible(undefined)

  const newPartyModal = (
    <Modal {...modalProps} open={modalVisible === 'newParty'}>
      <PartyForm onFinish={hideModal} party={newPartySkeleton(type)} type={type} />
    </Modal>
  )
  const canAddNewParty = parties.length < limit
  const addNewPartyButton = canAddNewParty ? (
    <PrimaryButton data-test-id={`add-${type}`} medium onClick={() => setModalVisible('newParty')}>
      <PlusIcon /> {addButtonLabel}
    </PrimaryButton>
  ) : undefined

  const partyRows = parties.map((party, partyIndex) => {
    const partyModal = (
      <Modal {...modalProps} visible={modalVisible === party.id}>
        <PartyForm onFinish={hideModal} party={party} type={type} />
      </Modal>
    )
    const editButton = (
      <SecondaryButton
        data-test-id={disputeFormTestIds.editParty(partyIndex + partyIndexStart)}
        onClick={() => setModalVisible(party.id)}
      >
        Edit
      </SecondaryButton>
    )
    const removeButton = (
      <Tooltip
        title={party.isRemovable === false ? 'This party is involved in an event and hence can not be removed' : ''}
      >
        <div>
          <SecondaryButton
            data-test-id={`remove-party`}
            disabled={party.isRemovable === false}
            onClick={() => remove(party.id)}
          >
            Remove
          </SecondaryButton>
        </div>
      </Tooltip>
    )

    return (
      <span key={party.id}>
        <PartyDisplayRow
          editButton={editButton}
          index={partyIndex + partyIndexStart}
          party={party}
          removeButton={removeButton}
        />
        <Divider margin={false} />
        {partyModal}
      </span>
    )
  })

  const content = parties?.length ? (
    <>
      <Divider margin={false} />
      {partyRows}
      <VSpaceSmall />
      {addNewPartyButton}
    </>
  ) : (
    addNewPartyButton
  )

  return (
    <>
      <H2>{sectionTitle}</H2>
      <VSpace space={5} />
      {newPartyModal}
      {content}
    </>
  )
}
