const minimumRange = 100_000

type Range = [min: number, max: number]

export const getYScales = (dataPoints: number[]): Range =>
  ensureMeaningfulRange(dataPoints.length === 1 ? handleSinglePoint(dataPoints) : handleMultiPoints(dataPoints))

const makeDefaultRange = (n: number): Range => [n / 2, n * 1.5]

const ensureMeaningfulRange = ([min, max]: Range): Range =>
  max - min > minimumRange ? [min, max] : [min - minimumRange / 2, max + minimumRange / 2]

const handleSinglePoint = ([n]: number[]): Range => makeDefaultRange(n)

const handleMultiPoints = (dataPoints: number[]): Range =>
  dataPoints.reduce<Range>(
    (acc, curr) => [curr < acc[0] ? curr : acc[0], curr > acc[1] ? curr : acc[1]],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
  )
