import * as React from 'react'
import styled from 'styled-components'

import {
  colors,
  Column,
  DotDotDot,
  H3,
  LeftRight,
  Link,
  SecondaryText,
  usualTransition,
  VSpace,
} from '@settleindex/react'

import {MemberAvatarRow} from '../../disputeTeam/avatar/MemberAvatarRow'
import {DisputeInListFragment} from '../../graphQLTypes'
import {paths} from '../../routing/paths'

interface Props {
  dispute: DisputeInListFragment
  onDuplicate: (disputeId: string) => void
}

export const DisputeCard: React.FC<Props> = ({dispute, onDuplicate}) => {
  const menu = React.useMemo(() => {
    const menuEnabled = dispute.readonly !== true

    return menuEnabled ? (
      <DotDotDot
        menu={{
          items: [
            {
              key: 'duplicate',
              label: 'Duplicate',
              onClick: () => onDuplicate(dispute.id),
            },
          ],
        }}
      />
    ) : (
      <></>
    )
  }, [dispute.id, dispute.readonly, onDuplicate])

  return (
    <Link to={paths.dispute(dispute.id)}>
      <CardColumn>
        <div>
          <LeftRight
            left={<H3>{dispute.title}</H3>}
            leftSpan={20}
            right={<span onClick={(e) => e.preventDefault()}>{menu}</span>}
            rightSpan={4}
          />
          <VSpace space={10} />
          <SecondaryText medium>{dispute.reference}</SecondaryText>
        </div>
        <MemberAvatarRow members={dispute.team.members} />
      </CardColumn>
    </Link>
  )
}

const CardColumn = styled(Column)`
  height: 150px;
  justify-content: space-between;
  transition: all ${usualTransition};

  padding: 20px;

  & .ant-dropdown-trigger {
    opacity: 0;
    margin-left: 30px;
  }

  &:hover {
    background-color: ${colors.base10};

    .ant-dropdown-trigger {
      opacity: 1;
    }

    .ant-dropdown-trigger:hover {
      background-color: ${colors.base10};
    }
  }
`
