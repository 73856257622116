import * as React from 'react'
import styled from 'styled-components'

import {colors, Tooltip, TooltipProps} from '@settleindex/react'

import {copyToClipboard} from '../clipboard/copyToClipboard'
import {useDebugLabels} from './useDebugLabels'

const Highlighter = styled.div`
  &.highlighted {
    border-bottom: 1px solid ${colors.lightning};
  }
`

interface Props {
  /**
   * fallbackDebugContent is visible when the debug labels are enabled but the
   * title is empty. Without this option the user might not have anything to
   * hover on in debug mode in some cases.
   */
  fallbackDebugContent?: string
  title: string
}

export const DebugLabel: React.FC<React.PropsWithChildren<Props & TooltipProps>> = ({
  children,
  fallbackDebugContent,
  title,
  ...tooltipProps
}) => {
  const {debugLabelsEnabled} = useDebugLabels()

  if (!debugLabelsEnabled) {
    return <>{children}</>
  }

  const titleWithCopy = (
    <span onClick={() => copyToClipboard(title)} style={{cursor: 'copy'}}>
      {title}
    </span>
  )

  return (
    <Tooltip color={colors.lightning} title={titleWithCopy} {...tooltipProps} overlayStyle={{minWidth: 'fit-content'}}>
      <Highlighter className="highlighted">{children ?? fallbackDebugContent}</Highlighter>
    </Tooltip>
  )
}
