import * as React from 'react'

import {Col, H1, PrimaryText, Row} from '@settleindex/react'

import {DisputeFragment} from '../graphQLTypes'

interface Props {
  dispute: DisputeFragment
}

export const TitleAndReference: React.FC<Props> = ({dispute}) => (
  <Row align="bottom">
    <Col span={24}>
      <H1>{dispute.title}</H1>
    </Col>
    <Col span={24}>
      <PrimaryText medium>{dispute.reference}</PrimaryText>
    </Col>
  </Row>
)
