import * as React from 'react'
import styled from 'styled-components'

import {
  CenterLine,
  CloseIcon,
  Col,
  colors,
  DataIcon,
  H2,
  Modal,
  PrimaryButton,
  PrimaryText,
  Row,
  UserIcon,
  VSpace,
  VSpaceMedium,
} from '@settleindex/react'

export interface DuplicateModalProps {
  disputeTitle?: string
  onCancel: () => void
  onDuplicate: () => void
  visible: boolean
}

export const DuplicateModal: React.FC<DuplicateModalProps> = ({disputeTitle, onCancel, onDuplicate, visible}) => (
  <Modal
    bodyStyle={{padding: 0}}
    centered
    closeIcon={<CloseIcon />}
    footer={null}
    keyboard
    mask
    maskClosable
    onCancel={onCancel}
    open={visible}
    width={500}
  >
    <ModalBody>
      <H2>Make a copy of "{disputeTitle}"</H2>
      <VSpaceMedium />
      <Row>
        <Col span={12}>
          <CenterLine>
            <BlueCircle>
              <DataIcon />
            </BlueCircle>
          </CenterLine>
          <VSpace space={15} />
          <PrimaryText>
            All models, data and settings <strong>will</strong> be copied
          </PrimaryText>
        </Col>
        <Col span={12}>
          <CenterLine>
            <RedCircle>
              <UserIcon style={{color: colors.red}} />
            </RedCircle>
          </CenterLine>
          <VSpace space={15} />
          <PrimaryText>
            Access to the case will <strong>not</strong> be copied
          </PrimaryText>
        </Col>
      </Row>
      <VSpaceMedium />
      <CenterLine>
        <PrimaryButton medium onClick={onDuplicate}>
          Make a copy of "{disputeTitle}"
        </PrimaryButton>
      </CenterLine>
    </ModalBody>
  </Modal>
)

const ModalBody = styled.div`
  padding: 50px;
  text-align: center;
`

const Circle = styled.div`
  height: 41px;
  width: 41px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BlueCircle = styled(Circle)`
  background-color: ${colors.base9};
`

const RedCircle = styled(Circle)`
  background-color: ${colors.redLight};
`
