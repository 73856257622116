import * as React from 'react'

import {CenterLine, Col, Form, H1, PrimaryButton, Row, SecondaryText, VSpaceSmall} from '@settleindex/react'
import {loginTestIds} from '@settleindex/testids/src/testIds'

import {PrivacyLink, TosLink} from './legalLinks'
import {LoginPageFrame} from './LoginPageFrame'
import {Logo} from './Logo'
import {useUser} from './useUser/useUser'

const LoginPage: React.FC = () => {
  const {isAuthenticated, login} = useUser()

  const [form] = Form.useForm()

  const primaryButtonAction = React.useCallback(() => login('/'), [login])

  React.useEffect(() => {
    !isAuthenticated && localStorage.setItem('loginTarget', location.href)
  }, [isAuthenticated])

  return (
    <LoginPageFrame>
      <CenterLine>
        <Logo />
      </CenterLine>
      <CenterLine>
        <H1 noMargin style={{marginTop: 40}}>
          SettleIndex
        </H1>
      </CenterLine>
      <Form form={form} name="login" onFinish={primaryButtonAction} style={{width: 360, marginTop: 30}}>
        <Row>
          <Col span={24}>
            <PrimaryButton data-test-id={loginTestIds.submitButton} medium onClick={primaryButtonAction}>
              Sign in to SettleIndex
            </PrimaryButton>
            <VSpaceSmall />
          </Col>
        </Row>
      </Form>
      <SecondaryText style={{marginTop: 20, display: 'inline-block'}}>
        By clicking Sign In you agree to our <br />
        <TosLink /> and <PrivacyLink />.
      </SecondaryText>
    </LoginPageFrame>
  )
}

export default LoginPage
