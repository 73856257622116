import * as React from 'react'

import {NoData} from '@settleindex/react'
import {partyCostTableTestIds} from '@settleindex/testids/src/testIds'

export const NoCostGroups: React.FC = () => {
  return (
    <span data-test-id={partyCostTableTestIds.noCosts}>
      <NoData
        heading="Add Costs"
        text="Costs are managed centrally and can
  then be adjusted in each outcome to reflect the proportion of the cost that
  would be spent. You can provide high-level cost estimates or enter detailed
  budgets. Costs are organised by group, so to get started, create a cost
  group."
      />
    </span>
  )
}
