import * as React from 'react'

import {sumFormat} from '@settleindex/domain'
import {isDefined} from '@settleindex/fp'

import {useSafeDispute} from '../dispute/context/useSafeDispute'

interface Props {
  placeholder?: string
  sum?: number | null
}

/**
 * Displays a number as a currency. Requires `useSafeDispute` to be available.
 * Use `SumText` to display the sum in a `PrimaryText`
 */
export const SumFormat: React.FC<Props> = ({placeholder = '-', sum}) => {
  const {dispute} = useSafeDispute()
  const currency = dispute?.currency

  if (!currency) {
    return <>{placeholder}</>
  }

  if (!isDefined(sum)) {
    return <>{placeholder}</>
  }

  return <>{sumFormat(sum, currency)}</>
}
