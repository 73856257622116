import {atom} from 'recoil'

import {Nullable} from '@settleindex/domain'

import {VersionFragment} from '../../graphQLTypes'

export const versionAtom = atom<Nullable<VersionFragment>>({
  key: 'versionAtom',
  default: undefined,
})
