import {ClaimGroupModified, DecisionNodeCaseResult, decisionTreeRootNodeId} from '@settleindex/domain'

import {CostPartyTotalsFragment, CostRecoveryPartyAmountsFragment, OutcomeFragment} from '../graphQLTypes'
import {AppOutcome} from './AppOutcome'

export const toAppOutcome = (o: OutcomeFragment, selectedPartyId: string, isPerspective: boolean): AppOutcome => ({
  // for the selected party
  absoluteValues: o.partyAbsoluteValues.find((v) => v.partyId === selectedPartyId),
  absoluteWeight: (isPerspective ? o.perspectiveWeights.absoluteWeight : o.opponentWeights.absoluteWeight) as number,
  capId: o.capId ?? undefined,
  caseResult: o.caseResult as DecisionNodeCaseResult,
  claimGroupModifiers: o.claimGroupModifiers,
  claimGroupsModified: o.claimGroupsModified as ClaimGroupModified[],
  claimsWithInterest: o.claimsWithInterest,
  costPartyTotals: o.costPartyTotals,
  // for the selected party
  costPartyTotalsSelected: o.costPartyTotals.find((v) => v.partyId === selectedPartyId) as CostPartyTotalsFragment,
  costRecoveryGross: o.costRecoveryGross,
  costRecoveryIrrecoverable: o.costRecoveryIrrecoverable,
  costRecoveryNet: o.costRecoveryNet,
  costRecoveryPartyAmounts: o.costRecoveryPartyAmounts,
  discountOnAssessment: o.discountOnAssessment,
  hasDamages: false,
  id: o.id,
  interestDecimal: o.interestDecimal,
  isLeaf: true,
  name: o.name,
  childIds: o.childIds,
  // Remove reference to ROOT from all nodes
  parentId: o.parentId === decisionTreeRootNodeId ? undefined : o.parentId,
  rank: o.rank,
  // for the selected party
  recoveryPartyAmountsSelected: o.costRecoveryPartyAmounts.find(
    (v) => v.partyId === selectedPartyId,
  ) as CostRecoveryPartyAmountsFragment,
  // for the selected party
  valueForecast: o.partyValues.find((v) => v.partyId === selectedPartyId)?.valueForecast as number,
  valueGross: o.partyValues.find((v) => v.partyId === selectedPartyId)?.valueGross as number,
  valueNet: o.partyValues.find((v) => v.partyId === selectedPartyId)?.valueNet as number,
  valuePresent: o.partyValues.find((v) => v.partyId === selectedPartyId)?.valuePresent as number,
  valueForecastWeighted: o.partyAbsoluteValues.find((v) => v.partyId === selectedPartyId)?.valueForecast as number,
  valueGrossWeighted: o.partyAbsoluteValues.find((v) => v.partyId === selectedPartyId)?.valueGross as number,
  valueNetWeighted: o.partyAbsoluteValues.find((v) => v.partyId === selectedPartyId)?.valueNet as number,
  valuePresentWeighted: o.partyAbsoluteValues.find((v) => v.partyId === selectedPartyId)?.valuePresent as number,
  // for the selected party
  weight: (isPerspective ? o.perspectiveWeights.weight : o.opponentWeights.weight) as number,
  kind: o.kind,
})
