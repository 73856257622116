import * as React from 'react'

import {Input, InputProps} from '@settleindex/react/lib/src'

import {opponentColor, perspectiveColor} from './index'

type Props = InputProps & {
  isPerspective: boolean
}

export const PerspectiveAwareInput = ({isPerspective, ...props}: Props) => {
  const style = props.readonly
    ? {color: isPerspective ? perspectiveColor : opponentColor}
    : {
        border: `1px solid ${isPerspective ? perspectiveColor : opponentColor}`,
      }

  return <Input {...props} style={style} />
}
