import {Theme} from '@nivo/core'

import {colors, mediumWeight, theme as siTheme} from '@settleindex/react'

export const theme: Theme = {
  axis: {
    legend: {
      text: {
        color: siTheme.text.secondary.color,
        fill: siTheme.text.secondary.color,
        fontFamily: siTheme.generalFontFamily,
        fontSize: siTheme.text.baseFontSize,
        fontWeight: mediumWeight,
      },
    },
    ticks: {
      line: {
        stroke: colors.base6,
      },
      text: {
        fontSize: '12px',
        fill: colors.base6,
      },
    },
  },
  grid: {
    line: {
      stroke: 'rgba(81, 45, 168, 0.07)',
    },
  },
}
