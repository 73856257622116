import {ComputedDatum} from '@nivo/bar'

import {hex2rgba} from '@settleindex/react'

import {allValueKeys, MetricData} from './MetricData'

export const getBarColors = () => {
  const idToOpacity = {
    [allValueKeys[0]]: 1,
    [allValueKeys[1]]: 0.5,
    [allValueKeys[2]]: 0.25,
  }

  return (d: ComputedDatum<MetricData>) => hex2rgba(d.data.color, idToOpacity[d.id])
}
