import {decisionTreeRootNodeId} from '@settleindex/domain'

import {DecisionFragment} from '../graphQLTypes'
import {AppDecision} from './AppDecision'

export const toAppDecision = (o: DecisionFragment, selectedPartyId: string, isPerspective: boolean): AppDecision => ({
  hasDamages: o.hasDamages,
  id: o.id,
  isLeaf: false,
  name: o.name,
  childIds: o.childIds,
  // Remove reference to ROOT from all nodes
  parentId: o.parentId === decisionTreeRootNodeId ? undefined : o.parentId,
  rank: o.rank,
  // for the selected party
  valueForecast: o.partyValues.find((v) => v.partyId === selectedPartyId)?.valueForecast as number,
  valueGross: o.partyValues.find((v) => v.partyId === selectedPartyId)?.valueGross as number,
  valueNet: o.partyValues.find((v) => v.partyId === selectedPartyId)?.valueNet as number,
  valuePresent: o.partyValues.find((v) => v.partyId === selectedPartyId)?.valuePresent as number,
  // for the selected party
  weight: (isPerspective ? o.perspectiveWeights.weight : o.opponentWeights.weight) as number,
  kind: o.kind,
})
