import styled from 'styled-components'

import {contentHorizontalPadding, theme} from '@settleindex/react'

export const Layout = styled.div`
  height: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${theme.separator.color};

  & > div {
    width: 100%;
    padding: 0px ${contentHorizontalPadding}px;
  }
`
