export type TipId = keyof typeof TipContent

/**
 * @deprecated
 */
export enum TipContent {
  'CaseValueTable.CaseValueNet' = 'Total of risk-weighted Overall Values',
  'CaseValueTable.ValueGross' = 'Total of risk-weighted Award Values',
  'CaseValueTable.CaseValuePresent' = 'Total of risk-weighted Current Values',
  'CaseValueTable.CaseValueForecast' = 'Total of risk-weighted Forecast Values',
  'Chart.Opponent' = 'Shows how the value of the case for your opponent changes as costs are spent.',
  'Chart.Gain' = 'Settlement in this area is better for both parties than settling for the Overall Position.',
  'Chart.Owner' = 'Shows how the value of your case changes as costs are spent.',
  'Chart.Loss' = 'Settlement in this area is worse for both parties than settling for the Overall Position.',
  'Claims.Adjust' = 'Make an adjustment to a claim or group that will apply in every outcome. For example, interest.',
  'Claims.Category' = 'Specify the type of claim',
  'Claims.Claim' = 'Specify the claims and organise them into groups.',
  'Costs.BudgetToSettle' = 'Estimated costs required to settle the case from this point.',
  'Costs.Estimated' = 'The total of all potential costs estimated for the case.',
  'Costs.Recoverable' = 'Total costs that could potentially be recoverable, depending on the outcome.',
  'Costs.SpendToDate' = 'Total costs spent to date.',
  'Event.BudgetToSettle' = 'Your costs required to reach a settlement.',
  'Event.CostsSpentToDate' = 'Your costs spent to date.',
  'Event.ListNetValue' = 'Net value to your side, taking into account costs spent.',
  'Event.ListTotalValue' = 'Total amount payable to claimant.',
  'Event.PaymentAmount' = 'Proposed amount of the offer, excluding any separate amount for costs.',
  'Event.PaymentCosts' = 'Proposed amount towards the payees costs.',
  'Outcomes.AddSubOutcomeButton' = 'Branch this outcome to create variations of the outcome within it.',
  'Outcomes.AnalysisTable.Columns.Name' = 'The title of the outcome and the branches that lead to it.',
  'Outcomes.AnalysisTable.Columns.AbsoluteChance' = 'The actual chance of the outcome occuring based on all of the chances provided in the model.',
  'Outcomes.AnalysisTable.Columns.CumulativeChance' = 'The cumulative chance of the outcome, when outcomes are ordered by value.',
  'Outcomes.PayReceiveTag' = `If you are viewing a claimant, positive values are
    receivable and negative values are payable. If you are viewing a defendant, positive
    values are payable and negative values are receivable.`,
  'Model.caseValuePresent' = `Your Current Position is the total of the
  risk-weighted Current Values of all potential outcomes. It represents your
  current position considering the costs you have spent to date.`,
  'Model.caseValueForecast' = `Your Forceast Position is the total of the
  risk-weighted Forecast Values of all potential outcomes. It represents your
  position considering both the costs you have spent to date and costs that are
  forecast to be spent.`,
  'Model.caseValueGross' = `Your Award Position is the total of the
  risk-weighted Award Values of all potential outcomes. It represents your
  position if all costs are spent.`,
  'Model.caseValueNet' = `Your Overall Position is the total of the
  risk-weighted Overall Values of all potential outcomes. It represents your
  position before any costs were spent.`,
  'Targets.CostsTargets' = 'Total costs spent to date and forecast. Your total cost target for the case.',
  'Targets.CurrentSaving' = 'The cost saving the case is settled now, taking into account costs already spent.',
  'Targets.ForecastSaving' = 'The cost saving if the case is settled on target (including costs spent and forecast).',
  'Targets.SettlementTarget' = 'Your overall settlement target with all opponents.',
  'Targets.TargetSettlementRatio' = 'The percentage of your current position that the settlement target represents. This represents the value of the settlement to you.',
  'Targets.TotalCaseTarget' = 'The settlement target, plus costs spent and forecast. Your overall target for the case.',
  'Model.DuplicateButton' = 'Make a copy of this model.',
  'Model.PublishButton' = 'Publish this draft model. You will no longer be able to edit it.',
  'Model.DraftButton' = 'Revert this model to draft. It will be editable again.',
  'Model.ArchiveButton' = 'Permanently delete this model.',
  'Model.NewVersionButton' = 'Create a new model.',
  'Model.ReadOnly' = 'You can only view this model, not edit it.',
  'CommonSettlementTable.CommonSettlement' = 'Model Settlements are identified where the positions of parties intersect.',
  'CommonSettlementTable.ExpectedGainLoss' = 'Represents the cost saving from settling the case at the Overall Target. Negative figures indicate a loss.',
  'CommonSettlementTable.ExpectedSettlement' = `Settlement target, including
  costs already spent and forecasted. This represents the value/cost of the
  settlement if there are remaining costs required to settle the case.`,
  'CommonSettlementTable.GrossGainLoss' = 'Represents the cost saving from settling the case on target. Negative figures indicate a loss.',
  'CommonSettlementTable.GrossSettlement' = `Settlement target including costs already spent. This represents the value/cost of the settlement now and can be
  used to benchmark settlement events.`,
  'CommonSettlementTable.SettlementRatio' = 'The percentage of your Break Even value that the case target represents.',
}
