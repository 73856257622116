import * as React from 'react'

import {H1, NoData, PageHeaderLeftRight, PrimaryButton} from '@settleindex/react'
import {disputeTestIds} from '@settleindex/testids/src/testIds'

import {disputeBreadcrumbs} from '../../navigation/disputeBreadcrumbs'
import {Content} from '../../page/'

interface Props {
  onNewCaseClick: () => void
}

export const DisputeListEmpty: React.FC<Props> = ({onNewCaseClick}) => (
  <Content breadcrumbs={disputeBreadcrumbs()} loading={false} testId={disputeTestIds.disputeList}>
    <PageHeaderLeftRight left={<H1>Cases</H1>} right={<></>} />
    <NoData
      button={
        <PrimaryButton data-test-id={`create-case`} medium onClick={onNewCaseClick}>
          Open a New Case
        </PrimaryButton>
      }
      heading="No Cases"
      text="You haven’t created any cases yet. To get started, open a new case."
    />
  </Content>
)
