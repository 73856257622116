import * as React from 'react'

import {Table, TableColumn, TableRow} from '@settleindex/react'

import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {SettlementStrategyFragment, SettlementStrategyInput} from '../../graphQLTypes'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {settlementStrategyRow} from './settlementStrategyRow'

type StrategyColumn = 'opponentLabel' | 'opponentName' | 'type' | 'amount'

const columns: TableColumn<StrategyColumn>[] = [
  {id: 'opponentLabel', label: 'Opponent', width: '52.5%'},
  {id: 'type', label: 'Target', width: '27%'},
  {id: 'amount', label: 'Amount', align: 'right'},
]

interface Props {
  setSettlementStrategy: (strategy: SettlementStrategyInput) => void
}

export const SettlementStrategyBox: React.FC<Props> = ({setSettlementStrategy}) => {
  const {
    dispute: {currency, opponents},
  } = useSafeDispute()
  const {
    version: {caseValuePresent, opponentValues, readonly, settlementStrategy: strategies},
  } = useSafeVersion()

  const opponentStrategyMap = React.useMemo(
    () => new Map<string, SettlementStrategyFragment>(strategies?.map((strategy) => [strategy.partyId, strategy])),
    [strategies],
  )

  const opponentRows: TableRow<StrategyColumn>[] = opponents.map(
    settlementStrategyRow({
      perspectiveCaseValuePresent: caseValuePresent,
      opponentValues,
      currency,
      readonly,
      setSettlementStrategy,
      opponentStrategyMap,
    }),
  )

  return <Table columns={columns} rows={opponentRows} stateId="CaseStrategyTable" />
}
