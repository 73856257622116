import * as React from 'react'

import {Right} from '@settleindex/domain'
import {Forbidden, message} from '@settleindex/react'
import {accessRightTestIds} from '@settleindex/testids/src/testIds'

import {useAppIsLoading} from '../../../appContext/useAppIsLoading'
import {captureException} from '../../../captureException'
import {useDisputeContainer} from '../../../dispute/context/useDisputeContainer'
import {Maybe, useDeleteUserMutation, usePatchUserMutation, UserFragment, useUsersQuery} from '../../../graphQLTypes'
import {requiresRight} from '../../requiresRight'
import {UserListPage} from './UserListPage'
import {UserListPageLoading} from './UserListPageLoading'

const UserListPageContainer_: React.FC<{}> = () => {
  useDisputeContainer({reset: true})
  const {loading: appIsLoading, setLoading} = useAppIsLoading()
  const {data, error, fetchMore, loading: userIsLoading, refetch} = useUsersQuery()
  const [deleteUserMutation] = useDeleteUserMutation()
  const [patchUserMutation] = usePatchUserMutation()
  const [paginationToken, setPaginationToken] = React.useState<Maybe<string>>()
  const [users, setUsers] = React.useState<UserFragment[]>([])

  const stillLoading = userIsLoading && !data

  React.useEffect(() => {
    setPaginationToken(data?.users?.paginationToken)
    setUsers(data?.users?.nodes ?? [])
  }, [data])

  const onDelete = async (id: string) => {
    await deleteUserMutation({variables: {id}})
    message.success('User deleted')

    refetch()
  }

  const onMfaChange = async (userId: string, enabled: boolean) => {
    setLoading(true)
    try {
      await patchUserMutation({variables: {id: userId, mfaEnabled: enabled}})
    } catch (mfaError) {
      captureException(mfaError)
    } finally {
      setLoading(false)
    }
  }

  if (stillLoading) {
    return <UserListPageLoading />
  }

  if (error) {
    return <Forbidden data-test-id={accessRightTestIds.accessForbidden} />
  }

  const nextPage = async () => {
    fetchMore({variables: {paginationToken}}).then((result: any) => {
      setPaginationToken(result?.data?.users?.paginationToken)
      setUsers([...users, ...result?.data?.users?.nodes])
    })
  }

  return (
    <UserListPage
      hasNextPage={!!paginationToken}
      loading={appIsLoading}
      nextPage={nextPage}
      onDelete={onDelete}
      onMfaChange={onMfaChange}
      users={users}
    />
  )
}

export default requiresRight(Right.AdminUsers, UserListPageContainer_)
