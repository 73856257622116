import {NodeTree} from '../../nodes/tree/NodeTree'
import {startId} from '../start/startId'
import {EdgeData} from './EdgeData'
import {editableEdgeType} from './EditableEdge'

interface Args {
  readonly: boolean
  sourceId: string
  targetNode: NodeTree
}

export const makeEdge = ({readonly, sourceId, targetNode}: Args) => {
  const data: EdgeData = {
    parentId: sourceId === startId ? undefined : sourceId,
    readonly,
    targetNode,
  }

  return {
    id: makeEdgeId(sourceId, targetNode.id),
    data,
    source: sourceId,
    target: targetNode.id,
    type: editableEdgeType,
  }
}

const edgeIdSeparator = '-to-'
const makeEdgeId = (sourceId: string, targetId: string) => `${sourceId}${edgeIdSeparator}${targetId}`
