import {atom, useRecoilState} from 'recoil'

import {TutorialId} from './TutorialId'

const tutorialIdState = atom<TutorialId>({
  key: 'tutorialIdState',
  default: 'caps',
})

export const useTutorial = () => {
  const [tutorialId, setTutorialId] = useRecoilState(tutorialIdState)

  return {
    tutorialId,
    setTutorialId,
  }
}
