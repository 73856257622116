import * as React from 'react'

import {SumInput} from '../../sum/sumInput'
import {SumInputProps} from '../../sum/sumInput/baseSumInput'
import {opponentColor, perspectiveColor} from './index'

type Props = SumInputProps & {
  isPerspective: boolean
}

export const PerspectiveAwareSumInput = ({isPerspective, style, ...props}: Props) => {
  const styleWithBorder = React.useMemo(
    () => ({
      ...style,
      border: `1px solid ${isPerspective ? perspectiveColor : opponentColor}`,
    }),
    [isPerspective, style],
  )

  return <SumInput {...props} style={styleWithBorder} />
}
