import * as React from 'react'

import {H1, PageHeaderLeftRight, PrimaryButton} from '@settleindex/react'

import {Content} from '../../../page/'
import {paths} from '../../../routing/paths'
import {adminBreadcrumbs} from '../../adminBreadcrumbs'

export const UserListPageLoading: React.FC<{}> = () => {
  return (
    <Content breadcrumbs={adminBreadcrumbs(paths.userAdmin())} loading>
      <PageHeaderLeftRight
        left={<H1>Users</H1>}
        right={
          <PrimaryButton disabled medium>
            Add User
          </PrimaryButton>
        }
      />
    </Content>
  )
}

UserListPageLoading.displayName = 'UserListPageLoading'
