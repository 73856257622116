import * as React from 'react'

import {
  H1,
  Link,
  PageHeaderLeftRight,
  PrimaryButton,
  PrimaryText,
  SecondaryTextSmall,
  Switch,
  Table,
  TableRow,
  Tooltip,
  VSpace,
} from '@settleindex/react'

import {UserResponse} from '../../../graphQLTypes'
import {Content} from '../../../page/'
import {paths} from '../../../routing/paths'
import {adminBreadcrumbs} from '../../adminBreadcrumbs'
import {userListColumns, UserRecord} from './userListColumns'
import {UserListMenu} from './UserListMenu'

interface Props {
  hasNextPage: boolean
  loading?: boolean
  nextPage?: () => void
  onDelete: (userId: string) => void
  onMfaChange: (userId: string, enabled: boolean) => void
  users: UserResponse[]
}

export const UserListPage: React.FC<Props> = ({hasNextPage, loading, nextPage, onDelete, onMfaChange, users}) => {
  const rows: TableRow<UserRecord>[] = users.map((u) => ({
    id: u.id,
    cells: {
      name: (
        <PrimaryText>
          {u.firstName} {u.lastName}
        </PrimaryText>
      ),
      company: <PrimaryText>{u.company}</PrimaryText>,
      email: <Tooltip title={u.id}>{u.email}</Tooltip>,
      phone: <PrimaryText>{u.phone}</PrimaryText>,
      mfaEnabled: (
        <Switch
          checked={Boolean(u.mfaEnabled)}
          disabled={!Boolean(u.phone)}
          onChange={(isChecked) => onMfaChange(u.id, isChecked)}
        />
      ),
      actions: u.isExternal ? (
        <SecondaryTextSmall>
          <small>external</small>
        </SecondaryTextSmall>
      ) : (
        <UserListMenu editPath={paths.userAdminEdit(u.id)} email={u.email} onDelete={() => onDelete(u.id)} />
      ),
    },
  }))

  return (
    <Content breadcrumbs={adminBreadcrumbs(paths.userAdmin())} loading={loading}>
      <PageHeaderLeftRight
        left={<H1>Users</H1>}
        right={
          <Link to={paths.userAdminCreate()}>
            <PrimaryButton medium>Add User</PrimaryButton>
          </Link>
        }
      />

      <Table columns={userListColumns} layout="auto" rows={rows} stateId={'Admin/UserList'} />

      <VSpace space={30} />

      {hasNextPage && (
        <PageHeaderLeftRight
          left={<></>}
          right={
            <PrimaryButton medium onClick={nextPage}>
              Load More
            </PrimaryButton>
          }
        />
      )}
    </Content>
  )
}

UserListPage.displayName = 'UserListPage'
