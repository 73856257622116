import {Nullable} from '@settleindex/domain'
import {isDefined} from '@settleindex/fp'

import {OpponentValueFragment, VersionFragment} from '../../graphQLTypes'
import {ChartData, isChartData} from './ChartData'
import {getLabels} from './getLabels'

interface Args {
  customSettlement: Nullable<number>
  opponentLabel: string
  opponentValues?: OpponentValueFragment
  perspectiveLabel: string
  version: VersionFragment
}

export const getChartData = ({
  customSettlement,
  opponentLabel,
  opponentValues,
  perspectiveLabel,
  version,
}: Args): ChartData[] =>
  [
    {
      key: 'claimantCurrent',
      value: version.caseValuePresent,
      label: getLabels({opponentLabel, perspectiveLabel, key: 'claimantCurrent'}),
    },
    {
      key: 'modelSettlement',
      value: opponentValues?.commonSettlementValue,
      label: getLabels({opponentLabel, perspectiveLabel, key: 'modelSettlement'}),
    },
    {
      key: 'customSettlement',
      value: isDefined(customSettlement) && customSettlement > 0 && customSettlement,
      label: getLabels({opponentLabel, perspectiveLabel, key: 'customSettlement'}),
    },
    {
      key: 'opponentCurrent',
      value: opponentValues?.caseValuePresent,
      label: getLabels({opponentLabel, perspectiveLabel, key: 'opponentCurrent'}),
    },
  ].filter(isChartData)
