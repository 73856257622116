import {Point, ResponsiveLine, SliceTooltipProps} from '@nivo/line'
import * as React from 'react'

import {HistoryMetrics} from '@settleindex/domain'

import {DashboardChartLayout} from '../../../chart/layout/DashboardChartLayout'
import {theme} from '../../../chart/theme'
import {Currency, DisputeHistoryPointFragment} from '../../../graphQLTypes'
import {metricVisuals} from '../metricVisuals'
import {Datum} from './Datum'
import {getData} from './getData'
import {getXScales} from './getXScales'
import {getYScales} from './getYScales'
import {historyChartProps} from './historyChartProps'
import {Tooltip} from './Tooltip'

const yScalePaddingMin = 0.9
const yScalePaddingMax = 1.1

interface Props {
  currency: Currency
  headerLeft?: React.ReactElement
  headerRight?: React.ReactElement
  history: DisputeHistoryPointFragment[]
  metricsToShow: HistoryMetrics[]
}

const HistoryChartComponent: React.FC<Props> = ({currency, headerLeft, headerRight, history, metricsToShow}) => {
  const data = getData(history, currency, metricsToShow)
  const allPoints = data.map((d) => d.data).flat()

  const allYPoints = allPoints.map(({y}) => y)
  const [minYScale, maxYScale] = getYScales(allYPoints)
  const allXPoints = allPoints.map(({x}) => x).sort()
  const xScaleOverride = getXScales(allXPoints)

  const chartProps = historyChartProps({
    theme,
    colors: (datum: Datum) => metricVisuals.get(datum.id)?.color,
    pointBorderColor: (point: Point) => point.serieColor,
    data,
    sliceTooltip: ({...props}: SliceTooltipProps) => <Tooltip {...props} metricVisualsMap={metricVisuals} />,
    xScale: {
      min: xScaleOverride ? xScaleOverride[0] : undefined,
      max: xScaleOverride ? xScaleOverride[1] : undefined,
    },
    yScale: {
      max: maxYScale * yScalePaddingMax,
      min: minYScale > 0 ? minYScale * yScalePaddingMin : minYScale / yScalePaddingMin,
    },
  })

  return (
    <DashboardChartLayout
      chart={<ResponsiveLine {...chartProps} />}
      headerLeft={headerLeft}
      headerRight={headerRight}
    />
  )
}

export const HistoryChart = React.memo(HistoryChartComponent)
