import dayjs from 'dayjs'
import {hc} from 'hono/client'
import * as React from 'react'
import {useState} from 'react'
import styled from 'styled-components'

import {
  colors,
  DatePicker,
  H1Input,
  LeftRightAlignCenter,
  PrimaryText,
  SecondaryText,
  TertiaryButton,
} from '@settleindex/react'
import {versionSettingsTestIds} from '@settleindex/testids/src/testIds'
import type {WsRoutes} from '@settleindex/ws/src'

import {captureException} from '../../captureException'
import {shortDate} from '../../date/shortDate'
import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {useDisputeTeam} from '../../disputeTeam/state/useDisputeTeam'
import {AccessKind, Lifecycle, Role, VersionPatchInput} from '../../graphQLTypes'
import {useConfig} from '../../useConfig/useConfig'
import {useUser} from '../../user/useUser/useUser'
import {useSafeVersion} from '../context/useSafeVersion'
import {lifecycleTags} from '../tags/lifecycleTags'
import {ReadonlyTag} from '../tags/tags'
import {getActionButtons} from './getActionButtons'
import {Layout} from './Layout'

const dateWidth = 100

interface Props {
  archiveVersion: (id: string) => void
  duplicateVersion: (id: string) => void
  patchVersion: (versionId: string, patch: VersionPatchInput) => void
  perspectiveRole: Role
  setVersionTitle?: (title: string) => void
  userId: string
}

export const VersionSettings: React.FC<Props> = ({
  archiveVersion,
  duplicateVersion,
  patchVersion,
  setVersionTitle,
  userId,
}) => {
  const {getToken} = useUser()
  const {dispute} = useSafeDispute()
  const {version} = useSafeVersion()
  const {readonly = false, variant} = version
  const {wsUrl} = useConfig()
  const {disputeTeam} = useDisputeTeam()
  const isPreCaseBot = variant === 'PRE_CASEBOT'
  const [pdfLoading, setPdfLoading] = useState(false)

  const getPdf = () => {
    setPdfLoading(true)
    const client = hc<WsRoutes>(wsUrl)
    // @ts-ignore hono types are not very good unfortunately
    client.report.request.versionReport
      .$post(
        {
          json: {
            disputeId: dispute.id,
            versionId: version.id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
      )
      .then(async (res: Response) => {
        if (res.ok) {
          const data = await res.json()

          // Try to open a new tab with the PDF URL. If the browser blocks it, then open it in the same tab
          const newWindow = window.open(`${wsUrl}${data.url}`, '_blank')
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            document.location.href = `${wsUrl}${data.url}`
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('error requesting report:', res)
          captureException(new Error(`Error requesting PDF report: ${JSON.stringify(res)}`))
        }
      })
      .catch((fetchError: any) => {
        // eslint-disable-next-line no-console
        console.error('error requesting report:', fetchError)
        captureException(new Error(`Error requesting PDF report: ${fetchError}`))
      })
      .finally(() => setPdfLoading(false))
  }

  const userDisputeTeamAccessKind = React.useMemo(
    () => disputeTeam?.members.find((m) => m.id === userId)?.accessKind,
    [disputeTeam?.members, userId],
  )
  const userIsVersionOwnerOrAdminMember = React.useMemo(() => {
    const isVersionCreator = userId === version.auth.createdBy.id
    const isAdminTeamMember = userDisputeTeamAccessKind === AccessKind.DISPUTE_CREATOR

    return isVersionCreator || isAdminTeamMember
  }, [userId, userDisputeTeamAccessKind, version.auth.createdBy.id])
  const titleDisabled = readonly || !Boolean(setVersionTitle)
  const isReport = version.lifecycle === Lifecycle.Report
  const showReportDatePicker = userIsVersionOwnerOrAdminMember && isReport && !isPreCaseBot
  const defaultReportDate = version.reportDate ? dayjs(version.reportDate) : undefined

  const titleInput = (
    <H1Input
      bold
      data-test-id="version-title-input"
      defaultValue={version.title}
      disabled={titleDisabled}
      key={version.id}
      onSave={setVersionTitle}
      style={{marginLeft: -12, height: 36}}
    />
  )

  const date = showReportDatePicker ? (
    <DatePicker
      allowClear={false}
      bordered={false}
      defaultValue={defaultReportDate}
      format="D MMM YYYY"
      onChange={(newDate: dayjs.Dayjs | null) => patchVersion(version.id, {reportDate: newDate?.toISOString()})}
      style={{width: dateWidth}}
      suffixIcon={<></>}
    />
  ) : (
    <SecondaryText style={{width: dateWidth, display: 'inline-block'}}>{shortDate(version.updatedAt)}</SecondaryText>
  )

  const name = (
    <SecondaryText style={{marginLeft: 5}}>
      {version.auth.createdBy.firstName} {version.auth.createdBy.lastName}
    </SecondaryText>
  )

  const actionButtons = React.useMemo(
    () =>
      getActionButtons({
        deleteVersion: archiveVersion,
        duplicateVersion,
        patchVersion,
        userDisputeTeamAccessKind,
        userIsVersionOwnerOrAdminMember,
        version,
      }),
    [
      archiveVersion,
      duplicateVersion,
      patchVersion,
      userDisputeTeamAccessKind,
      userIsVersionOwnerOrAdminMember,
      version,
    ],
  )

  return (
    <>
      {isPreCaseBot && (
        <LegacyBanner>
          <PrimaryText>This legacy model is now in read-only mode</PrimaryText>
          <br />
          <SecondaryText>
            To make modifications duplicate the model and use the CaseBot to recreate the decision tree
          </SecondaryText>
        </LegacyBanner>
      )}
      <Layout data-test-id={versionSettingsTestIds.versionSettings}>
        <LeftRightAlignCenter
          left={titleInput}
          leftSpan={7}
          right={
            <>
              <div style={{marginRight: 10}}>{name}</div>
              <div style={{marginRight: 10}}>{date}</div>
              <div style={{marginRight: 10}}>{readonly && <ReadonlyTag />}</div>
              <div style={{marginRight: 10}}>{lifecycleTags.get(version.lifecycle)}</div>
              {actionButtons}
              <div style={{marginRight: 10}}>
                <TertiaryButton loading={pdfLoading} onClick={getPdf}>
                  PDF
                </TertiaryButton>
              </div>
            </>
          }
          rightSpan={17}
        />
      </Layout>
    </>
  )
}

const LegacyBanner = styled.div`
  padding: 10px 60px;
  background-color: ${colors.amber};
  text-align: center;

  & ${PrimaryText} {
    font-size: 16px;
  }

  & ${SecondaryText} {
    color: #222;
  }
`
