import * as React from 'react'
import {useParams} from 'react-router-dom'

import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData} from '@settleindex/react'

import {useDisputeQuery} from '../../../graphQLTypes'
import {LoadingIndicatorWithNav} from '../../../page'
import {useAddVersionAndRedirect} from '../../../version/graph/useAddVersion'
import {useDisputeContainer} from '../../context/useDisputeContainer'
import {DisputeNotFound} from '../../DisputeNotFound'
import {disputeNotFoundGraphError} from '../../graph/disputeErrorGuards'
import {DisputeHistoryPage} from './DisputeHistoryPage'

interface Match {
  disputeId: string
}

/**
 * The container for the "Case Metrics" page including the Latest Model and Case
 * History tabs
 */
const DisputeHistoryContainer: React.FC = () => {
  const {disputeId} = useParams<Match>()
  const {data, error, loading} = useDisputeQuery({
    variables: {id: disputeId},
  })
  const addEmptyVersion = useAddVersionAndRedirect(disputeId)

  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: data?.dispute})

  const state = React.useMemo(
    () => ({
      error: error && !disputeNotFoundGraphError(error),
      loading,
      empty: disputeNotFoundGraphError(error),
      available: isDefined(disputeInContext),
    }),
    [error, loading, disputeInContext],
  )

  return (
    <LoadingData
      state={state}
      whenAvailable={<DisputeHistoryPage addEmptyVersion={addEmptyVersion} />}
      whenEmpty={<DisputeNotFound />}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicatorWithNav />}
    />
  )
}

export default DisputeHistoryContainer
