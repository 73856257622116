import * as React from 'react'
import ReactFlow, {
  Background,
  Controls,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'react-flow-renderer'
import useKeypress from 'react-use-keypress'

import {HSpaceSmall, LeftRight, TertiaryButton} from '@settleindex/react/lib/src'

import {useNodes} from '../nodes/context/useNodes'
import {opponentColor, perspectiveColor} from '../party/perspectiveAwareColors'
import {useTutorial} from '../tutorial/useTutorial'
import {useSafeVersion} from '../version/context/useSafeVersion'
import {PartySelect} from '../version/outcomes/PartySelect'
import {ValueToDisplaySelect} from '../version/outcomes/ValueToDisplaySelect'
import {DecisionTreeStyle} from './DecisionTreeStyle'
import {useNodeTypes} from './hooks/useNodeTypes'
import {layoutEngine} from './layout/layoutEngine'
import {makeVisibleOutcomeList} from './makeVisibleOutcomeList'
import {MetricsSummary} from './MetricsSummary'
import {outcomesToElements} from './outcome/outcomesToElements'
import {useOnNodeClick} from './treeHandlers/useOnNodeClick'
import {useDecisionTree} from './useDecisionTree'

export interface DecisionTreeProps {
  elementId?: string
  noData?: React.ReactElement
}

export const DecisionTree: React.FC<DecisionTreeProps> = ({elementId = 'DecisionTree', noData = <></>}) => {
  const {version} = useSafeVersion()
  const {isPerspective, selectedPartyDecisions, selectedPartyNodes} = useNodes()
  const [nodes, setNodes] = useNodesState([])
  const [edges, setEdges] = useEdgesState([])
  const {edgeTypes, nodeTypes} = useNodeTypes()
  const onNodeClick = useOnNodeClick()
  const {damagesVisibleFor, isLargeView, setIsLargeView} = useDecisionTree()
  const {fitView} = useReactFlow()

  useKeypress('Escape', () => {
    if (isLargeView) {
      setIsLargeView(false)
    }
  })

  const {setTutorialId} = useTutorial()
  React.useEffect(() => {
    setTutorialId('map')
  }, [setTutorialId])

  const newElementsWithLayout = React.useMemo(() => {
    // By default, the user only sees the decision nodes
    // and the calculations (Outcomes) are hidden.
    // They can show the calculations for a given Decision by
    // clicking on it on the map (this is only allowed for Decisions
    // that have calculation sub-outcomes).
    // Here we pick up all the Decisions and then push those
    // calculations to the list where the parents were clicked to reveal the
    // calculations.
    // Always visible: Decisions where `hasDamages` is false.
    // Hidden behind a toggle for the user to open: Decisions where
    // `hasDamages` is true and all `Outcomes` (`OutcomeFragment`).
    const visibleNodes =
      // For pre-casebot versions we show all outcomes all the time.
      version.variant === 'PRE_CASEBOT'
        ? selectedPartyNodes
        : makeVisibleOutcomeList({
            damagesVisibleFor,
            decisions: selectedPartyDecisions,
            nodes: selectedPartyNodes,
          })

    const newElements = outcomesToElements({
      outcomes: visibleNodes,
      readonly: true,
    })

    return layoutEngine(newElements[0], newElements[1])
  }, [damagesVisibleFor, selectedPartyDecisions, selectedPartyNodes, version.variant])

  React.useEffect(() => {
    setNodes(newElementsWithLayout[0])
    setEdges(newElementsWithLayout[1])
  }, [newElementsWithLayout, setEdges, setNodes])

  React.useEffect(() => {
    // Only fit view if the user added or removed a node, but not if they only
    // edited one.
    const isNodeCountChanged = newElementsWithLayout[0].length !== nodes.length

    if (isNodeCountChanged) {
      setTimeout(() => {
        fitView({duration: 400, maxZoom: 1})
      }, 100)
    }
  }, [fitView, newElementsWithLayout, nodes.length])

  // fitView on load once
  React.useEffect(() => {
    setTimeout(() => {
      fitView({duration: 400, maxZoom: 1})
    }, 100)
  }, [fitView])

  // fitView when switching into and back from full screen
  React.useEffect(() => {
    setTimeout(() => {
      fitView({duration: 400, maxZoom: 1})
    }, 100)
  }, [fitView, isLargeView])

  const onInit = React.useCallback((instance: ReactFlowInstance) => {
    instance.fitView()
    instance.zoomTo(1)
  }, [])

  const proOptions = React.useMemo(
    () => ({
      account: 'paid-pro',
      hideAttribution: true,
    }),
    [],
  )

  const edgeColor = isPerspective ? perspectiveColor : opponentColor

  if (!selectedPartyNodes.length) {
    return noData
  }

  return (
    <>
      <DecisionTreeStyle
        edgeColor={edgeColor}
        id={elementId}
        isPerspective={isPerspective}
        style={{height: isLargeView ? '100%' : 500}}
      >
        <ReactFlow
          edgeTypes={edgeTypes}
          edges={edges}
          maxZoom={1}
          minZoom={0.1}
          nodeTypes={nodeTypes}
          nodes={nodes}
          nodesConnectable={false}
          nodesDraggable={false}
          onInit={onInit}
          onNodeClick={onNodeClick}
          proOptions={proOptions}
          zoomOnScroll={false}
        >
          <>
            <Background />
            <LeftRight
              left={
                <>
                  <PartySelect />
                  <HSpaceSmall />
                  <ValueToDisplaySelect />
                </>
              }
              right={
                isLargeView ? (
                  <TertiaryButton medium onClick={() => setIsLargeView(false)}>
                    Exit
                  </TertiaryButton>
                ) : (
                  <TertiaryButton medium onClick={() => setIsLargeView(true)}>
                    Full Screen
                  </TertiaryButton>
                )
              }
              rowStyle={{zIndex: 11, position: 'inherit', margin: isLargeView ? 30 : 10}}
            />
            <Controls showInteractive={false} />
            <MetricsSummary />
          </>
        </ReactFlow>
      </DecisionTreeStyle>
    </>
  )
}
