import {useRecoilValue} from 'recoil'

import {versionAtom} from './versionAtom'

export const useSafeVersion = () => {
  const version = useRecoilValue(versionAtom)

  if (!version) {
    throw new Error(`Version is not set in context yet`)
  }

  return {version}
}
