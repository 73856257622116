import {getAllCaseBotIssues} from '@settleindex/domain'
import {FormInstance} from '@settleindex/react'

import {makeCapBlockerIssue} from '../graph/convertCaseBotFromGraph'
import {CaseBotFormValues} from './CaseBotFormValues'

/*
We maintain a field `capBlockerIssues`: it's the local
mirror of CaseBot.capBlockerIssues, but in the form it has to be:

```ts
{
  [issueId]: boolean
  cbi_234736: false
  cbi_948549: false
}
```

This code updates the form field so it always contains all the issues.
*/
export const setCapBlockerIssuesInForm = (form: FormInstance, values: CaseBotFormValues) => {
  const issues = getAllCaseBotIssues(values)
  const currentCapBlockerIssues = values.capBlockerIssues
  const currentCapBlockerIssueIds = currentCapBlockerIssues.filter((i) => i.value).map((i) => i.issueId)
  const capBlockerIssues = makeCapBlockerIssue(issues, currentCapBlockerIssueIds)

  form.setFieldValue('capBlockerIssues', capBlockerIssues)
}
