import * as React from 'react'

import {ResponsiveSettlementChart} from '@settleindex/charts'
import {IndexedParty, OpponentValue} from '@settleindex/domain'
import {H3} from '@settleindex/react'

import {ChartSectionNormalLayout} from '../../chart/layout/ChartSectionNormalLayout'
import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {IndexedPartyFragment, OpponentValueFragment} from '../../graphQLTypes'
import {opponentColor, perspectiveColor} from '../../party/perspectiveAwareColors'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {ChartLegend} from '../settlementLineChart/ChartLegend'
import {useSettlementAnalysis} from './useSettlementAnalysis'

export const SettlementChartInReport: React.FC = () => {
  const {dispute} = useSafeDispute()
  const {version} = useSafeVersion()
  const {settlementOpponent} = useSettlementAnalysis()

  const perspectiveLabel = dispute.perspective.label

  const opponentsToShow: IndexedPartyFragment[] = React.useMemo(() => [settlementOpponent], [settlementOpponent])

  const settlementOpponentValues: OpponentValueFragment[] = React.useMemo(
    () => version.opponentValues.filter((opponentValue) => opponentValue.partyId === settlementOpponent.id),
    [settlementOpponent.id, version.opponentValues],
  )

  const legend = React.useMemo(() => <ChartLegend title={<H3>All</H3>} />, [])

  return (
    <ChartSectionNormalLayout legend={legend}>
      <ResponsiveSettlementChart
        currency={dispute.currency}
        opponentColor={opponentColor}
        opponentValues={settlementOpponentValues as OpponentValue[]}
        opponents={opponentsToShow as IndexedParty[]}
        ownerLabel={perspectiveLabel as string}
        ownerValue={{
          cost: version.perspectiveCost,
          caseValuePresent: version.caseValuePresent,
          caseValueForecast: version.caseValueForecast,
          caseValueGross: version.caseValueGross,
          caseValueNet: version.caseValueNet,
        }}
        perspectiveColor={perspectiveColor}
      />
    </ChartSectionNormalLayout>
  )
}
