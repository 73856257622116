import {CaseBotDamage, ClaimDamageProportion, Decimal, newIdFor, sumDecimals} from '@settleindex/domain'
import {difference} from '@settleindex/fp'
import {FormInstance} from '@settleindex/react'

import {CaseBotFormValues, ItemIdToClaimDamageProportion} from './CaseBotFormValues'

/**
 * onAddDamage adds a new damage with sane default weights to the form's damage list.
 *
 * It also ensures that the records in `claimDamageProportionsByItemId` of
 * the form values has an entry for each damage, including the newly added one.
 * This logic must run after the form's add function so that the damage list is
 * up to date.
 */
export const onAddDamage =
  (form: FormInstance<CaseBotFormValues>) =>
  (addItemToFormList: (issue: CaseBotDamage) => void, fieldName: string, title: string) => {
    // Add new damage
    const existing: CaseBotDamage[] = form.getFieldValue(fieldName)
    const myWeight = Math.max(0, new Decimal(1).sub(sumDecimals(existing.map((i) => i.myWeight))).toNumber())
    const theirWeight = Math.max(0, new Decimal(1).sub(sumDecimals(existing.map((i) => i.theirWeight))).toNumber())
    const newDamage = {
      name: title,
      myWeight,
      theirWeight,
      proportion: 1,
      id: newIdFor('caseBotDamage'),
    }
    addItemToFormList(newDamage)

    // Update other form value segments
    const allDamages: CaseBotDamage[] = form.getFieldValue(fieldName)
    const allDamageIds = allDamages.map((damage) => damage.id)
    const currentDamageProportions: ItemIdToClaimDamageProportion = form.getFieldValue('claimDamageProportionsByItemId')

    const newEntries = Object.entries(currentDamageProportions).map(([id, cdps]) => {
      const damageIdsInThisRecord = cdps.map((cdp) => cdp.damageId)
      const hasDamageRecords = damageIdsInThisRecord.length > 0
      const missingDamageIds = difference(allDamageIds, damageIdsInThisRecord)
      const entriesForMissingDamageIds: ClaimDamageProportion[] = missingDamageIds.map((damageId) => ({
        damageId,
        proportion: allDamages.find((damage) => damage.id === damageId)?.proportion ?? 0,
        itemId: id,
      }))
      const entriesToAdd = hasDamageRecords ? entriesForMissingDamageIds : []

      const newCdps = [...cdps, ...entriesToAdd]

      return [id, newCdps]
    })

    const newValues: ItemIdToClaimDamageProportion = Object.fromEntries(newEntries)

    form.setFieldValue(['claimDamageProportionsByItemId'], newValues)
  }
