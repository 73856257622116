import {MaybeNumber} from '@settleindex/domain'
import {cond, negative, positive, T} from '@settleindex/fp'
import {colors} from '@settleindex/react'

import {Role} from '../../../graphQLTypes'
import {Coding} from '../metricVisuals'

export const getChangeColorCoding = (coding: Coding, perspectiveRole: Role, change: MaybeNumber): string =>
  cond<MaybeNumber, string>([
    [positive, () => coding[perspectiveRole][0]],
    [negative, () => coding[perspectiveRole][1]],
    [T, () => colors.base4],
  ])(change)
