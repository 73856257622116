import {BarDatum} from '@nivo/bar'

import {HistoryMetrics} from '@settleindex/domain'

export interface MetricData extends BarDatum {
  color: string
  key: HistoryMetrics
  /**
   * This number is used in the BarItem component to correctly re-position
   * single or triad of bars
   */
  numberOfVersions: number
  version1: number
  version2: number
  version3: number
}

export const allValueKeys = ['version1', 'version2', 'version3']
