import {colors} from '@settleindex/react'

import {Role} from '../../graphQLTypes'
import {opponentColor, perspectiveColor} from '../../party/perspectiveAwareColors'
import {ChartDataKey} from './ChartData'

const chartBarColorsClaimantPerspective = new Map<ChartDataKey, string>([
  ['claimantCurrent', perspectiveColor],
  ['customSettlement', colors.violet],
  ['modelSettlement', colors.lime],
  ['opponentCurrent', opponentColor],
])

const chartBarColorsDefendantPerspective = new Map<ChartDataKey, string>([
  ['claimantCurrent', opponentColor],
  ['customSettlement', colors.violet],
  ['modelSettlement', colors.lime],
  ['opponentCurrent', perspectiveColor],
])

export const getBarColors = (key: ChartDataKey, perspectiveRole: Role) => {
  return perspectiveRole === Role.Claimant
    ? chartBarColorsClaimantPerspective.get(key) ?? 'black'
    : chartBarColorsDefendantPerspective.get(key) ?? 'black'
}
