import * as React from 'react'
import {useMediaQuery} from 'react-responsive'
import {useLocation} from 'react-router'
import styled from 'styled-components'

import {cond, F, T} from '@settleindex/fp'
import {
  collapsedPrimaryNavWidth,
  expandedPrimaryNavWidth,
  minPageWidthToExpandPrimaryNav,
  minPageWidthToShowTwoSidebars,
} from '@settleindex/react'

import {useDebugLabels} from '../debug/useDebugLabels'
import {useDebugPanel} from '../debug/useDebugPanel'
import {useDispute} from '../dispute/context/useDispute'
import {KnowledgeBaseDrawer} from '../knowledgeBase/KnowledgeBaseDrawer'
import {useKnowledgeBase} from '../knowledgeBase/useKnowledgeBase'
import {PrimaryNav, usePrimaryNav} from '../navigation'
import {addIsActiveToMenuItems} from '../navigation/primary/addIsActiveToMenuItems'
import {getBottomMenuItems} from '../navigation/primary/getBottomMenuItems'
import {getDisputeMenuItems} from '../navigation/primary/getDisputeMenuItems'
import {LeftCol} from './LeftCol'
import {RightCol} from './RightCol'
import {usePage} from './usePage'

const Layout = styled.div`
  display: flex;
`

export interface PageComponentProps {
  currentPathname: string
  isAdmin: boolean
  logout: () => void
  navExpanded: boolean
  username?: string
}

const PageComponent: React.FC<React.PropsWithChildren<PageComponentProps>> = ({
  children,
  currentPathname,
  isAdmin,
  logout,
  navExpanded,
  username,
}) => {
  const knowledgeBase = useKnowledgeBase()
  const {debugPanelContent, isDebugPanelVisible, setIsDebugPanelVisible} = useDebugPanel()
  const {dispute} = useDispute()
  const {debugLabelsEnabled, setDebugLabelsEnabled} = useDebugLabels()

  const topMenuItems = React.useMemo(
    () =>
      addIsActiveToMenuItems(
        currentPathname,
        getDisputeMenuItems({
          disputeReadonly: dispute?.readonly ?? false,
          disputeId: dispute?.id,
          disputeTitle: dispute?.title,
        }),
      ),
    [currentPathname, dispute?.id, dispute?.readonly, dispute?.title],
  )

  const bottomMenuItems = React.useMemo(
    () =>
      getBottomMenuItems({
        debugLabelsEnabled,
        isAdmin,
        isDebugPanelVisible,
        knowledgeBase,
        logout,
        setDebugLabelsEnabled,
        setIsDebugPanelVisible,
        showDebugPanelMenuItem: !!debugPanelContent,
        username,
      }),
    [
      debugLabelsEnabled,
      debugPanelContent,
      isAdmin,
      isDebugPanelVisible,
      knowledgeBase,
      logout,
      setDebugLabelsEnabled,
      setIsDebugPanelVisible,
      username,
    ],
  )

  const paddingLeft = React.useMemo(
    () => (navExpanded ? expandedPrimaryNavWidth : collapsedPrimaryNavWidth),
    [navExpanded],
  )

  return (
    <div>
      <Layout>
        <LeftCol>
          <PrimaryNav bottomMenuItems={bottomMenuItems} expanded={navExpanded} topMenuItems={topMenuItems} />
        </LeftCol>
        <RightCol paddingLeft={paddingLeft}>{children}</RightCol>
      </Layout>
      <KnowledgeBaseDrawer
        close={knowledgeBase.hide}
        isOpen={knowledgeBase.isOpen}
        knowledgeBaseUrl={knowledgeBase.url}
      />
    </div>
  )
}

type PageProps = Omit<PageComponentProps, 'navExpanded' | 'currentPathname'>

export const Page: React.FC<React.PropsWithChildren<PageProps>> = (props) => {
  const location = useLocation()
  const {rightSidebarVisible} = usePage()
  const {isPrimaryNavExpanded, isPrimaryNavLocked, setIsPrimaryNavExpanded} = usePrimaryNav()

  const isScreenLargeToShowTwoSidebars = useMediaQuery({query: `(min-width: ${minPageWidthToShowTwoSidebars}px)`})
  const isScreenLargeToExpandPrimaryNav = useMediaQuery({query: `(min-width: ${minPageWidthToExpandPrimaryNav}px)`})

  React.useLayoutEffect(() => {
    const expand = cond([
      // Loading a /version page, keep the sidebar closed
      [() => document.location.href.includes('/version/'), F],
      [() => !isScreenLargeToShowTwoSidebars && rightSidebarVisible, F],
      [() => isScreenLargeToExpandPrimaryNav, T],
      [T, F],
    ])()

    !isPrimaryNavLocked && setIsPrimaryNavExpanded(expand)
  }, [
    isScreenLargeToShowTwoSidebars,
    isScreenLargeToExpandPrimaryNav,
    rightSidebarVisible,
    isPrimaryNavLocked,
    setIsPrimaryNavExpanded,
  ])

  return <PageComponent {...props} currentPathname={location.pathname} navExpanded={isPrimaryNavExpanded} />
}

Page.displayName = 'Page'
