import {selector} from 'recoil'

import {Role} from '@settleindex/domain'

import {safeDisputeSelector} from '../../../dispute/context/useSafeDispute'
import {selectedPartyIdAtom} from '../atoms/selectedPartyIdAtom'

export const selectedPartyRoleSelector = selector<Role>({
  key: 'selectedPartyRoleSelector',
  get: ({get}) => {
    const dispute = get(safeDisputeSelector)
    const selectedPartyId = get(selectedPartyIdAtom) ?? dispute.perspectiveId

    return dispute.partyRoles.find((r) => r.id === selectedPartyId)?.role ?? Role.Defendant
  },
})
