import {useAtom} from 'jotai'
import * as React from 'react'
import styled from 'styled-components'

import {percentToStr} from '@settleindex/domain'
import {cond, T, ucFirst} from '@settleindex/fp'
import {
  Accordion,
  BadgeProps,
  Col,
  Form,
  FormItem,
  H1,
  H2,
  HSpaceContent,
  LeftRightAlignCenter,
  PercentFormInput,
  PrimaryButton,
  PrimaryText,
  Row,
  SecondaryButton,
  SecondaryText,
  Tag,
  VSpaceMedium,
  VSpaceSmall,
} from '@settleindex/react'
import {caseBotTestIds, versionVariablesTabsTestIds} from '@settleindex/testids/src/testIds'

import {useAppIsLoading} from '../appContext/useAppIsLoading'
import {DebugFormItem} from '../debug'
import {HelpTitleWithTooltip} from '../help/HelpTitleWithTooltip'
import {useVersionVariablesDrawer} from '../nodes/versionVariablesDrawer/useVersionVariablesDrawer'
import {useSafeVersion} from '../version/context/useSafeVersion'
import {AccordionPanel} from './AccordionPanel'
import {CapsTable} from './CapsTable'
import {caseBotAccordionOpenAtom} from './caseBotAccordionOpenAtom'
import {ClaimDamageProportionTable} from './ClaimDamageProportionTable'
import {gutter, nameSpan, weightSpan} from './columnWidths'
import {CustomCostsTable} from './CustomCostsTable'
import {DamagesTable} from './DamagesTable'
import {onAddContribution} from './form/onAddContribution'
import {onAddDamage} from './form/onAddDamage'
import {onRemoveDamage} from './form/onRemoveDamage'
import {IssueBasedCostAwards} from './IssueBasedCostAwards'
import {IssuesSection} from './IssuesSection'
import {useCaseBot} from './useCaseBot'
import {ValueBasedCostAwards} from './ValueBasedCostAwards'

interface Section {
  badge?: BadgeProps
  children: React.ReactNode
  key: string
  label: string
  right?: React.ReactNode | string
  testId?: string
}

const notActive = `Not active`

export const CaseBotFormContent = () => {
  const {version} = useSafeVersion()
  const form = Form.useFormInstance()
  const [caseBotAccordionOpen, setCaseBotAccordionOpen] = useAtom(caseBotAccordionOpenAtom)
  const removeDamageReferences = React.useCallback(() => onRemoveDamage(form), [form])
  const {
    allIssues,
    caseBotChanged,
    caseBotFormValues,
    contribution: contributionSummary,
    cumulativeAwardProportions,
    damages: damagesSummary,
    someAwardRefinement,
    someCustomCostsEnabled,
  } = useCaseBot()
  const {loading} = useAppIsLoading()

  const {editCaps, editClaims, editCosts} = useVersionVariablesDrawer()

  React.useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && caseBotChanged) {
        e.preventDefault()
        form.submit()
      }
    }

    window.addEventListener('keydown', handle)

    return () => window.removeEventListener('keydown', handle)
  }, [caseBotChanged, form])

  const sections: Section[] = React.useMemo(() => {
    const valuesButtonVerb = version.readonly ? 'View' : 'Edit'
    const damagesRight = cond([
      [
        () => damagesSummary.theirWeight !== 1 || damagesSummary.myWeight !== 1,
        () => <Tag color={'amber'}>Sum of probabilities isn't 100%</Tag>,
      ],
      [T, () => `${caseBotFormValues?.damages.length}${someAwardRefinement ? ', with refinements' : ''}`],
    ])()
    const contributionRight = cond([
      [
        () => contributionSummary.theirWeight !== 1 || contributionSummary.myWeight !== 1,
        () => <Tag color={'amber'}>Sum of probabilities isn't 100%</Tag>,
      ],
      [T, () => (caseBotFormValues?.contributionEnabled ? caseBotFormValues?.contribution.length : notActive)],
    ])()

    const vbcaRight = caseBotFormValues?.part36.enabled ? ucFirst(caseBotFormValues?.part36.mode) : notActive

    const issueBasedCostAwardsRight =
      !!caseBotFormValues?.part36.enabled || !caseBotFormValues?.costDefaults.issueBasedCostRecoveryEnabled
        ? notActive
        : 'Active'

    return [
      {
        label: 'Claims, Costs & Caps',
        key: 'values',
        testId: caseBotTestIds.inputClaimsAccordion,
        children: (
          <div
            data-readonly="skip"
            style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 20, padding: '20px 0'}}
          >
            <SecondaryButton data-test-id={versionVariablesTabsTestIds.claims} medium onClick={editClaims}>
              {`${valuesButtonVerb} Claims`}
            </SecondaryButton>
            <SecondaryButton data-test-id={versionVariablesTabsTestIds.costs} medium onClick={editCosts}>
              {`${valuesButtonVerb} Costs`}
            </SecondaryButton>
            <SecondaryButton data-test-id={versionVariablesTabsTestIds.caps} medium onClick={editCaps}>
              {`${valuesButtonVerb} Caps/Limits`}
            </SecondaryButton>
            <HSpaceContent />
          </div>
        ),
      },
      {
        label: `Issues`,
        right: allIssues.length ? `${allIssues.length}` : 'No issues',
        key: 'issues',
        children: <IssuesSection form={form} />,
      },
      {
        label: `Award Ranges`,
        right: damagesRight,
        key: 'damages',
        children: (
          <>
            {/*AWARD RANGES*/}
            <DamagesTable
              cumulativeTooltip={'Weighted Proportion for all Award Ranges'}
              myCumulativeProportion={cumulativeAwardProportions.myWeight}
              myWeightTotal={damagesSummary.myWeight}
              name="damages"
              onAdd={onAddDamage(form)}
              onRemove={removeDamageReferences}
              theirCumulativeProportion={cumulativeAwardProportions.theirWeight}
              theirWeightTotal={damagesSummary.theirWeight}
              title="Damages"
            />
            <VSpaceMedium />

            {/*AWARD RANGE REFINEMENTS*/}
            <ClaimDamageProportionTable />
          </>
        ),
      },
      {
        label: `Contribution`,
        right: contributionRight,
        key: 'contribution',
        children: (
          <>
            <DamagesTable
              myWeightTotal={contributionSummary.myWeight}
              name="contribution"
              onAdd={onAddContribution(form)}
              theirWeightTotal={contributionSummary.theirWeight}
              title="Contribution"
            />
          </>
        ),
      },
      {
        label: 'Caps & Limits',
        right: `${caseBotFormValues?.cap?.capId ? 'Active' : notActive}`,
        key: 'caps',
        children: (
          <>
            <CapsTable />
          </>
        ),
      },
      {
        label: 'Discount on Assessment',
        right: percentToStr(caseBotFormValues?.costDefaults.costIrrecoverablePercentage ?? 0),
        key: 'doa',
        children: (
          <>
            <VSpaceSmall />
            <HelpTitleWithTooltip
              id={'casebot_discount_on_assessment_overview'}
              left="Percentage reduction of the costs awarded"
              leftId="casebot_discount_on_assessment_overview"
            />
            <Row align="bottom" gutter={gutter}>
              <Col span={nameSpan}>
                <FormItem>
                  <PrimaryText>Proportion</PrimaryText>
                </FormItem>
              </Col>
              <Col span={weightSpan}>
                <DebugFormItem
                  label=""
                  name={['costDefaults', 'costIrrecoverablePercentage']}
                  title={`caseBot.costDefaults.costIrrecoverablePercentage`}
                >
                  <PercentFormInput />
                </DebugFormItem>
              </Col>
            </Row>
          </>
        ),
      },
      {
        label: 'Issue Based Cost Awards',
        key: 'issueBasedCostAwards',
        right: issueBasedCostAwardsRight,
        children: (
          <>
            <IssueBasedCostAwards />
          </>
        ),
      },
      {
        label: 'Value Based Cost Awards',
        right: vbcaRight,
        key: 'valueBasedCostAwards',
        children: (
          <>
            <ValueBasedCostAwards mode={caseBotFormValues?.part36.mode} />
          </>
        ),
      },
      {
        label: 'Custom Costs',
        right: someCustomCostsEnabled ? 'Active' : notActive,
        key: 'customCosts',
        children: (
          <>
            <CustomCostsTable />
          </>
        ),
      },
    ]
  }, [
    version.readonly,
    caseBotFormValues?.part36.enabled,
    caseBotFormValues?.part36.mode,
    caseBotFormValues?.costDefaults.issueBasedCostRecoveryEnabled,
    caseBotFormValues?.costDefaults.costIrrecoverablePercentage,
    caseBotFormValues?.cap?.capId,
    caseBotFormValues?.damages.length,
    caseBotFormValues?.contributionEnabled,
    caseBotFormValues?.contribution.length,
    editClaims,
    editCosts,
    editCaps,
    allIssues.length,
    form,
    cumulativeAwardProportions.myWeight,
    cumulativeAwardProportions.theirWeight,
    damagesSummary.myWeight,
    damagesSummary.theirWeight,
    removeDamageReferences,
    contributionSummary.myWeight,
    contributionSummary.theirWeight,
    someCustomCostsEnabled,
    someAwardRefinement,
  ])

  return (
    <>
      <Sections>
        <VSpaceMedium />
        <H1 style={{textAlign: 'center'}}>Inputs</H1>
        <VSpaceSmall />
        <Accordion
          // Note: if you disable `accordion`, then some sections will display broken/invalid values, e.g. custom
          // costs won't show all relevant issues.
          // This is due to the way we juggle values in the form and also in an atom, and the two can get out of sync.
          // Having the accordion turned on forces the sections to re-render, hiding concurrency the issue from the
          // user.
          accordion
          activeKey={caseBotAccordionOpen}
          boundless
          onChange={(key) => setCaseBotAccordionOpen(key as string[])}
        >
          {sections.map((item) => (
            <AccordionPanel
              data-test-id={item.testId}
              header={
                <LeftRightAlignCenter
                  left={<H2>{item.label}</H2>}
                  right={<SecondaryText>{item.right}</SecondaryText>}
                />
              }
              key={item.key}
            >
              {item.children}
            </AccordionPanel>
          ))}
        </Accordion>
      </Sections>

      <SubmitRow visible={caseBotChanged}>
        <PrimaryButton htmlType="submit" loading={loading} medium>
          UPDATE MODEL
        </PrimaryButton>
      </SubmitRow>
    </>
  )
}

const Sections = styled.div`
  padding: 0 60px;
`

const SubmitRow = styled.div`
  background-color: #fff;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 20px;
  position: sticky;
  transition: all 0.5s ease;
  width: 100%;
  opacity: ${(p: any) => (p.visible ? 1 : 0)};
  z-index: ${(p: any) => (p.visible ? 'auto' : -9)};
`
