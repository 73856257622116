import {InsuranceInformationInput} from '../../../graphQLTypes'

export const fieldNames: Record<keyof InsuranceInformationInput, string[]> = {
  broker: ['insurance', 'broker'],
  claimReference: ['insurance', 'claimReference'],
  deductible: ['insurance', 'deductible'],
  excess: ['insurance', 'excess'],
  indemnityLimit: ['insurance', 'indemnityLimit'],
  insured: ['insurance', 'insured'],
  insurer: ['insurance', 'insurer'],
  policyNumber: ['insurance', 'policyNumber'],
  policyType: ['insurance', 'policyType'],
}
