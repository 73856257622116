import * as React from 'react'
import styled from 'styled-components'

import {maxPhoneNumberLength, minPhoneNumberLength} from '@settleindex/domain'
import {
  Checkbox as BaseCheckbox,
  CheckboxChangeEvent,
  FormItem,
  Input,
  PhoneInput,
  PrimaryButton,
  TextInput,
} from '@settleindex/react'

const Checkbox = styled(BaseCheckbox)`
  & {
    display: flex !important;
    align-items: inherit;
  }
`

export const fieldNames = {
  id: 'id',
  company: 'company',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  notify: 'notify',
  phone: 'phone',
}

const defaultLabels = {
  [fieldNames.company]: 'Company',
  [fieldNames.email]: 'Email Address',
  [fieldNames.firstName]: 'First Name',
  [fieldNames.lastName]: 'Last Name',
  [fieldNames.company]: 'Company',
  [fieldNames.phone]: 'Phone',
}

interface Args {
  customLabels?: {[key: string]: string}
  formItemProps?: {[key: string]: any}
  onNotifyChange?: (event: CheckboxChangeEvent) => void
  required?: {[key: string]: boolean}
  submitLabel: string
}

export const userEditFields = ({customLabels, formItemProps, onNotifyChange, required, submitLabel}: Args) => {
  const labels = {
    ...defaultLabels,
    ...customLabels,
  }

  return {
    id: (
      <FormItem hidden name={fieldNames.id} noStyle>
        <Input type="hidden" />
      </FormItem>
    ),
    firstName: (
      <FormItem label={labels[fieldNames.firstName]} name={fieldNames.firstName}>
        <TextInput />
      </FormItem>
    ),
    lastName: (
      <FormItem label={labels[fieldNames.lastName]} name={fieldNames.lastName}>
        <TextInput />
      </FormItem>
    ),
    email: (
      <FormItem label={labels[fieldNames.email]} name={fieldNames.email}>
        <TextInput type="email" />
      </FormItem>
    ),
    company: (
      <FormItem label={labels[fieldNames.company]} name={fieldNames.company}>
        <TextInput />
      </FormItem>
    ),
    phone: (
      <FormItem
        label={labels[fieldNames.phone]}
        name={fieldNames.phone}
        rules={[
          {
            required: required?.[fieldNames.phone] ?? true,
            validateTrigger: 'submit',
            pattern: new RegExp(`^\\+?[0-9]{${minPhoneNumberLength},${maxPhoneNumberLength}}$`),
            message: 'Please enter a valid phone number',
          },
        ]}
        {...formItemProps?.[fieldNames.phone]}
      >
        <PhoneInput />
      </FormItem>
    ),
    submit: (
      <PrimaryButton htmlType="submit" medium>
        {submitLabel}
      </PrimaryButton>
    ),
    notify: onNotifyChange ? (
      <FormItem name={fieldNames.notify} rules={[{required: false}]} valuePropName="checked">
        <Checkbox defaultChecked={false} onChange={onNotifyChange}>
          Notify via Email
        </Checkbox>
      </FormItem>
    ) : null,
  }
}
