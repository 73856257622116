import {reduceToUnique} from '@settleindex/fp'

interface Args {
  max: number
  min: number
  numberOfTicksRequired: number
}

export const leftTicks = ({max, min, numberOfTicksRequired}: Args): number[] => {
  const step = (max - min) / numberOfTicksRequired

  return Array(numberOfTicksRequired)
    .fill(0)
    .map((_, index) => min + step * index)
    .concat(max)
    .reduce(reduceToUnique, [])
}
