import * as React from 'react'

import {VersionPageTabKey} from '../version/page/tabs/VersionPageTabKey'
import {AppContext, AppContextValue, defaultAppContextValue} from './AppContext'

export const AppProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [knowledgeBaseOpen, setKnowledgeBaseOpen] = React.useState<boolean>(false)
  const [knowledgeBaseUrl, setKnowledgeBaseUrl] = React.useState<string | undefined>(
    defaultAppContextValue.knowledgeBase.url,
  )
  const [versionPageTab, setVersionPageTab] = React.useState<VersionPageTabKey>(defaultAppContextValue.versionPage.tab)

  const value: AppContextValue = {
    ...defaultAppContextValue,
    knowledgeBase: {
      isOpen: knowledgeBaseOpen,
      setOpen: setKnowledgeBaseOpen,
      setUrl: setKnowledgeBaseUrl,
      url: knowledgeBaseUrl,
    },
    loading: {
      isLoading,
      setLoading,
    },
    versionPage: {
      setTab: setVersionPageTab,
      tab: versionPageTab,
    },
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppProvider.displayName = 'AppProvider'
