import {TableColumn} from '@settleindex/react'

import {CostsTableRecord} from './CostsTableRecord'

export const makeColumns = (
  perspectiveLabel: string,
  opponentLabel: string = 'Opponent',
): TableColumn<CostsTableRecord>[] => [
  {
    id: 'value',
    label: 'Value',
    width: 200,
  },
  {
    id: 'description',
    width: 220,
  },
  {
    id: 'perspective',
    align: 'right',
    label: perspectiveLabel,
  },
  {
    align: 'right',
    id: 'separator',
    label: '',
    width: 35,
  },
  {
    id: 'opponent',
    align: 'right',
    label: opponentLabel,
    width: Math.max(110, opponentLabel.length * 8),
  },
]
