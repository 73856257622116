import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {CaseBotFormValues} from '../../casebot/form/CaseBotFormValues'
import {convertCaseBotToGraph} from '../../casebot/graph/convertCasebotToGraph'
import {
  SettlementStrategyInput,
  UpdateClaimGroupInput,
  UpdateClaimInput,
  useAddClaimGroupMutation,
  useAddClaimMutation,
  useCaseBotMutation,
  useDeleteClaimGroupMutation,
  useDeleteClaimMutation,
  useSetSettlementStrategyMutation,
  useUpdateClaimGroupMutation,
  useUpdateClaimMutation,
  useVersionOfDisputeQuery,
} from '../../graphQLTypes'

interface Args {
  disputeId: string
  versionId: string
}

export const useVersionGraph = ({disputeId, versionId}: Args) => {
  const {runAsync} = useAppIsLoading()
  const {data, error, loading} = useVersionOfDisputeQuery({variables: {disputeId, versionId}})
  const [addClaimGroupMutation] = useAddClaimGroupMutation()
  const [addClaimMutation] = useAddClaimMutation()
  const [deleteClaimGroupMutation] = useDeleteClaimGroupMutation()
  const [deleteClaimMutation] = useDeleteClaimMutation()
  const [setSettlementStrategyMutation] = useSetSettlementStrategyMutation()
  const [updateClaimGroupMutation] = useUpdateClaimGroupMutation()
  const [updateClaimMutation] = useUpdateClaimMutation()
  const [caseBotMutation] = useCaseBotMutation()

  const dispute = data?.dispute
  const disputeTeam = data?.disputeTeam
  const version = dispute?.version
  const onAddClaimGroup = () => {
    runAsync(() =>
      addClaimGroupMutation({
        variables: {
          versionId,
          claimGroup: {
            claims: [],
            name: 'New Claim Group',
            interest: 0,
          },
        },
      }),
    )
  }

  const onAddClaim = (claimGroupId: string) => {
    runAsync(() =>
      addClaimMutation({
        variables: {
          versionId,
          claimGroupId,
          claim: {
            amount: 0,
            name: 'New Claim',
            interest: 0,
          },
        },
      }),
    )
  }

  const onAddCounterclaim = (claimGroupId: string) => {
    runAsync(() =>
      addClaimMutation({
        variables: {
          versionId,
          claimGroupId,
          claim: {
            amount: 0,
            name: 'New Counterclaim',
            interest: 0,
            type: 'counterclaim',
          },
        },
      }),
    )
  }

  const onDeleteClaimGroup = (claimGroupId: string) => {
    runAsync(() =>
      deleteClaimGroupMutation({
        variables: {
          versionId,
          claimGroupId,
        },
      }),
    )
  }

  const onDeleteClaim = (claimId: string) => {
    runAsync(() =>
      deleteClaimMutation({
        variables: {
          versionId,
          claimId,
        },
      }),
    )
  }

  const onClaimGroupChange = (claimGroupId: string, update: UpdateClaimGroupInput) => {
    runAsync(() =>
      updateClaimGroupMutation({
        variables: {
          versionId,
          claimGroupId,
          update,
        },
      }),
    )
  }

  const onClaimChange = (claimId: string, update: UpdateClaimInput) => {
    runAsync(() =>
      updateClaimMutation({
        variables: {
          versionId,
          claimId,
          update,
        },
      }),
    )
  }

  const setSettlementStrategy = ({amount, partyId, type}: SettlementStrategyInput) => {
    runAsync(() =>
      setSettlementStrategyMutation({
        variables: {
          versionId,
          strategy: {
            partyId,
            type,
            amount,
          },
        },
      }),
    )
  }

  const caseBot = async (caseBotInput: CaseBotFormValues) => {
    const caseBotFixed = convertCaseBotToGraph(caseBotInput)

    await runAsync(() => caseBotMutation({variables: {versionId, caseBotInput: caseBotFixed, disputeId}}))
  }

  return {
    caseBot,
    dispute,
    disputeTeam,
    error,
    loading,
    onAddClaim,
    onAddClaimGroup,
    onAddCounterclaim,
    onClaimChange,
    onClaimGroupChange,
    onDeleteClaim,
    onDeleteClaimGroup,
    setSettlementStrategy,
    version,
  }
}
