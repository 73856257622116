import * as React from 'react'

import {Col, Input, PlusIcon, PrimaryButton, Row, SecondaryButton, TableRow} from '@settleindex/react'
import {claimsTableTestIds} from '@settleindex/testids/src/testIds'

import {DeleteIcon} from '../../cost/costsTable/DeleteIcon'
import {DebugLabel} from '../../debug/'
import {ClaimGroupFragment, UpdateClaimGroupInput, UpdateClaimInput} from '../../graphQLTypes'
import {PercentInput} from '../../percent/PercentInput'
import {SumText} from '../../sum'
import {SumInput} from '../../sum/sumInput'
import {CounterclaimTag} from '../CounterClaimTag'
import {ClaimRecord} from './ClaimRecord'
import {ClassificationSelect, ClassificationSelectProps} from './ClassificationSelect'
import {claimColumnWidth, typeColumnWidth} from './columns'

export interface Args {
  claimGroupClassifications: ClassificationSelectProps['claimGroupClassifications']
  claimGroups: ClaimGroupFragment[]
  onAddClaim: (claimGroupId: string) => void
  onAddCounterclaim: (claimGroupId: string) => void
  onClaimChange: (id: string, update: UpdateClaimInput) => void
  onClaimGroupChange: (id: string, update: UpdateClaimGroupInput) => void
  onDeleteClaim: (claimId: string) => void
  onDeleteClaimGroup: (claimGroupId: string) => void
  readonly: boolean
}

export const toClaimGroupRows = ({
  claimGroupClassifications,
  claimGroups,
  onAddClaim,
  onAddCounterclaim,
  onClaimChange,
  onClaimGroupChange,
  onDeleteClaim,
  onDeleteClaimGroup,
  readonly,
}: Args): TableRow<ClaimRecord>[] =>
  claimGroups.map((claimGroup) => {
    const addAnotherClaimButtonRow: TableRow<ClaimRecord> = {
      id: `addAnother${claimGroup.id}`,
      cells: {
        name: (
          <Row gutter={10} style={{height: 65, marginTop: 10}}>
            <Col span={10}>
              <PrimaryButton
                data-test-id={claimsTableTestIds.createClaimButton}
                medium
                onClick={() => {
                  onAddClaim(claimGroup.id)
                }}
              >
                <PlusIcon /> Add Claim
              </PrimaryButton>
            </Col>
            <Col span={14}>
              <SecondaryButton
                data-test-id={claimsTableTestIds.createCounterClaimButton}
                medium
                onClick={() => {
                  onAddCounterclaim(claimGroup.id)
                }}
              >
                <PlusIcon /> Add Counterclaim
              </SecondaryButton>
            </Col>
          </Row>
        ),
      },
    }

    const subTableRows: TableRow<ClaimRecord>[] = claimGroup.claims.map((claim) => ({
      id: claim.id,
      cells: {
        name: (
          <Input
            defaultValue={claim.name}
            disabled={readonly}
            onSave={(name: string) => {
              onClaimChange(claim.id, {name})
            }}
            style={{width: claimColumnWidth}}
          />
        ),
        type: claim.type === 'counterclaim' ? <CounterclaimTag /> : <></>,
        amount: (
          <DebugLabel title="claim.amount">
            <SumInput
              defaultValue={claim.amount}
              onSave={(amount: number) => {
                onClaimChange(claim.id, {amount})
              }}
              readonly={readonly}
            />
          </DebugLabel>
        ),
        interest: (
          <DebugLabel title="claim.interest">
            <PercentInput
              defaultValue={claim.interest}
              onSave={(weight: number) => {
                onClaimChange(claim.id, {interest: weight})
              }}
              readOnly={readonly}
            />
          </DebugLabel>
        ),
        adjusted: (
          <DebugLabel title="claim.amountForType">
            <SumText sum={claim.amountForType} />
          </DebugLabel>
        ),
        actions: readonly ? <></> : <DeleteIcon onClick={() => onDeleteClaim(claim.id)} />,
      },
    }))

    const classificationSelect = (
      <ClassificationSelect
        claimGroup={claimGroup}
        claimGroupClassifications={claimGroupClassifications}
        onClaimGroupChange={onClaimGroupChange}
        readonly={readonly}
      />
    )

    return {
      id: claimGroup.id,
      cells: {
        name: classificationSelect,
        type: <div style={{width: typeColumnWidth}} />,
        amount: (
          <DebugLabel title="claimGroup.totalClaimsBeforeInterest">
            <SumText semibold sum={claimGroup.totalClaimsBeforeInterest} />
          </DebugLabel>
        ),
        interest: (
          <DebugLabel title="claimGroup.interest">
            <PercentInput
              bold
              defaultValue={claimGroup.interest}
              onSave={(weight: number) => {
                onClaimGroupChange(claimGroup.id, {interest: weight})
              }}
              readOnly={readonly}
            />
          </DebugLabel>
        ),
        adjusted: (
          <DebugLabel title="claimGroup.totalClaimsWithInterest">
            <SumText semibold sum={claimGroup.totalClaimsWithInterest} />
          </DebugLabel>
        ),
        actions: readonly ? <></> : <DeleteIcon onClick={() => onDeleteClaimGroup(claimGroup.id)} />,
      },
      subTable: {
        rows: readonly ? subTableRows : subTableRows.concat(addAnotherClaimButtonRow),
        indentAmount: 'parentAlign',
      },
      expandable: true,
    }
  })
