import {useRecoilValue} from 'recoil'

import {partyCaseValueSelector} from './partyCaseValueSelector'

/**
 * Returns the case values for the selected party.
 * @param partyId The selected party - falls back to the Dispute perspectiveId
 * if not specified
 */
export const usePartyCaseValues = (partyId?: string) => useRecoilValue(partyCaseValueSelector(partyId))
