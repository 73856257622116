import Bugsnag from '@bugsnag/js'
import Debug from 'debug'

const dbug = Debug('app')

export const captureException = (error: any): void => {
  Bugsnag.notify(new Error(error))

  dbug('captured exception', error)
}
