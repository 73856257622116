import {
  CaseBotIssue,
  combinedDefendantPartyId,
  getAllCaseBotIssues,
  Part36Offer,
  Party,
  PartyId,
  validOfferParties,
} from '@settleindex/domain'

import {CaseBotCustomCostFragment, CaseBotFragment, IndexedPartyFragment, PartyFragment} from '../../graphQLTypes'
import {
  CaseBotFormValues,
  FormCaseBotCapBlockerIssue,
  FormCaseBotCustomCost,
  IssueIdToCaseBotCustomCost,
  ItemIdToClaimDamageProportion,
} from '../form/CaseBotFormValues'

interface ConvertArgs {
  caseBot: CaseBotFragment
  claimantId: string
  parties: PartyFragment[]
}

export const convertCaseBotFromGraph = ({
  caseBot: {claimDamageProportions, ...dirtyFragment},
  claimantId,
  parties,
}: ConvertArgs): CaseBotFormValues => {
  const fragment: CaseBotFragment = removeTypeName(dirtyFragment)

  const allIssues = getAllCaseBotIssues(fragment)

  const claimDamageProportionsByItemId: ItemIdToClaimDamageProportion = claimDamageProportions
    ? claimDamageProportions.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.itemId]: [
            ...(acc[curr.itemId] || []),
            {
              damageId: curr.damageId,
              itemId: curr.itemId,
              proportion: curr.proportion,
            },
          ],
        }
      }, {} as ItemIdToClaimDamageProportion)
    : {}

  const capBlockerIssues = makeCapBlockerIssue(allIssues, fragment.capBlockerIssues)

  const customCosts = makeCustomCosts({
    existingCustomCosts: fragment.customCosts,
    loseFinals: fragment.loseFinals ?? [],
    partiesInDispute: parties,
    winFinals: fragment.winFinals ?? [],
  })

  const validOfferByPartyIds = validOfferParties({
    claimantId: claimantId as PartyId,
    parties: parties as Party[],
  }).map((p) => p.id)

  const fallbackOfferBy = validOfferByPartyIds[0]
  // any because it uses the deprecated opaque type PartyId
  const offerBy: any = validOfferByPartyIds.includes(fragment.part36[0]?.offerBy as any)
    ? fragment?.part36?.[0]?.offerBy
    : fallbackOfferBy

  return {
    ...fragment,
    capBlockerIssues,
    claimDamageProportionsByItemId,
    customCosts,
    part36: {
      claimantCostsAtOffer: fragment?.part36?.[0]?.claimantCostsAtOffer ?? 0,
      defendantCostsAtOffer: fragment?.part36?.[0]?.defendantCostsAtOffer ?? 0,
      enabled: offerBy ? fragment?.part36?.[0]?.enabled ?? false : false,
      offerAmount: fragment?.part36?.[0]?.offerAmount ?? 0,
      offerBy,
      mode: (fragment?.part36?.[0]?.mode ?? 'custom') as Part36Offer['mode'],
    },
  } satisfies CaseBotFormValues
}

export const makeCapBlockerIssue = (
  allIssues: CaseBotIssue[],
  capBlockerIssueIds: string[] = [],
): FormCaseBotCapBlockerIssue[] =>
  allIssues.map((issue) => ({
    issueId: issue.id,
    issueName: issue.name,
    value: capBlockerIssueIds?.includes(issue.id),
  }))

export const makeCustomCosts = ({
  existingCustomCosts,
  loseFinals,
  partiesInDispute,
  winFinals,
}: {
  existingCustomCosts: FormCaseBotCustomCost[] | CaseBotCustomCostFragment[]
  loseFinals: CaseBotIssue[]
  partiesInDispute: IndexedPartyFragment[]
  winFinals: CaseBotIssue[]
}) =>
  [...loseFinals, ...winFinals].reduce<IssueIdToCaseBotCustomCost>((acc, currIssue) => {
    const parties = partiesInDispute.filter((p) => p.id !== combinedDefendantPartyId)
    const existing = (existingCustomCosts ?? []).filter((cc) => cc.issueId === currIssue.id)

    return {
      ...acc,
      [currIssue.id]: parties.map<FormCaseBotCustomCost>((party) => {
        const existingForParty = existing.find((cc) => cc.partyId === party.id)

        return existingForParty
          ? {
              ...existingForParty,
              partyLabel: party.label,
              partyId: party.id as PartyId,
              recoverableCost: existingForParty.recoverableCost ?? 0,
              irrecoverableCost: existingForParty.irrecoverableCost ?? 0,
            }
          : {
              enabled: false,
              irrecoverableCost: 0,
              issueId: currIssue.id,
              partyId: party.id as PartyId,
              recoverableCost: 0,
              partyLabel: party.label,
            }
      }),
    }
  }, {})

const removeTypeName = (result: Record<string, any>) =>
  JSON.parse(JSON.stringify(result, (key, value) => (key === '__typename' ? undefined : value)))
