import {atom, useRecoilState} from 'recoil'

const rightSidebarVisibleState = atom({
  key: 'rightSidebarState',
  default: false,
})

export const usePage = () => {
  const [rightSidebarVisible, setRightSidebarVisible] = useRecoilState(rightSidebarVisibleState)

  return {
    rightSidebarVisible,
    setRightSidebarVisible,
  }
}
