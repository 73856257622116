import * as React from 'react'

import {Col, Form, FormItem, Input, PrimaryText, Row, Switch, useFormInstance, VSpaceSmall} from '@settleindex/react'

import {DebugFormItem} from '../debug'
import {useSafeDispute} from '../dispute/context/useSafeDispute'
import {Role} from '../graphQLTypes'
import {HelpTitleWithTooltip} from '../help/HelpTitleWithTooltip'
import {PerspectiveAwarePercentInput} from '../party/perspectiveAwareColors/PerspectiveAwarePercentInput'
import {gutter, nameSpan, weightSpan} from './columnWidths'
import {useCaseBot} from './useCaseBot'

export const IssueBasedCostAwards: React.FC = () => {
  const form = useFormInstance()
  const {issueNames} = useCaseBot()
  const {dispute} = useSafeDispute()
  const perspectiveIsClaimant = dispute.perspectiveRole === Role.Claimant
  const perspectiveIsDefendant = dispute.perspectiveRole === Role.Defendant

  return (
    <>
      <HelpTitleWithTooltip id={'casebot_issue_based_cost_awards_overview'} left="Issue based Cost Awards" />

      <VSpaceSmall />

      <Row align="bottom" gutter={gutter}>
        <Col span={nameSpan}>
          <FormItem>
            <PrimaryText>Enabled</PrimaryText>
          </FormItem>
        </Col>
        <Col span={nameSpan}>
          <DebugFormItem
            name={['costDefaults', 'issueBasedCostRecoveryEnabled']}
            rules={[{required: false}]}
            title={`caseBot.costDefaults.issueBasedCostRecoveryEnabled`}
            valuePropName="checked"
          >
            <Switch />
          </DebugFormItem>
        </Col>
      </Row>

      <Row align="bottom" gutter={gutter}>
        <Col span={nameSpan}>
          <FormItem label="">
            <PrimaryText>Win All Issues</PrimaryText>
          </FormItem>
        </Col>
        <Col span={weightSpan}>
          <DebugFormItem
            isPerspective={perspectiveIsClaimant}
            label="Claimant"
            name={['costDefaults', 'myRecoveryWinAll']}
            title={`caseBot.costDefaults.myRecoveryWinAll`}
          >
            <PerspectiveAwarePercentInput isPerspective={perspectiveIsClaimant} />
          </DebugFormItem>
        </Col>
        <Col span={weightSpan}>
          <DebugFormItem
            isPerspective={perspectiveIsDefendant}
            label="Defendant"
            name={['costDefaults', 'theirRecoveryWinAll']}
            title={`caseBot.costDefaults.theirRecoveryWinAll`}
          >
            <PerspectiveAwarePercentInput isPerspective={perspectiveIsDefendant} />
          </DebugFormItem>
        </Col>
      </Row>

      <Row align="bottom" gutter={gutter}>
        <Col span={nameSpan}>
          <FormItem label="">
            <PrimaryText>Win Some Issues</PrimaryText>
          </FormItem>
        </Col>
        <Col span={weightSpan}>
          <DebugFormItem
            isPerspective={perspectiveIsClaimant}
            label=""
            name={['costDefaults', 'myRecoveryWinSome']}
            title={`caseBot.costDefaults.myRecoveryWinSome`}
          >
            <PerspectiveAwarePercentInput isPerspective={perspectiveIsClaimant} />
          </DebugFormItem>
        </Col>
      </Row>

      <VSpaceSmall />

      <Form.List name={['issueCostAwards']}>
        {(fields) => {
          if (!fields.length) {
            return (
              <>
                <PrimaryText>You haven't defined any issues yet</PrimaryText>
                <VSpaceSmall />
              </>
            )
          }

          return fields.map((field, index) => {
            const issueName = issueNames[form.getFieldValue(['issueCostAwards', field.name, 'issueId'])]

            return (
              <Row align="bottom" gutter={gutter} key={field.key}>
                <Col span={nameSpan}>
                  <FormItem hidden name={[field.name, 'issueId']} noStyle>
                    <Input />
                  </FormItem>

                  <FormItem label={index === 0 ? 'Issue Cost Recovery' : ''}>
                    <Input readonly value={issueName} />
                  </FormItem>
                </Col>

                <Col span={weightSpan}>
                  <DebugFormItem
                    isPerspective={perspectiveIsClaimant}
                    label={index === 0 ? 'Claimant Win' : ''}
                    name={[field.name, 'myRecovery']}
                    title={`caseBot.issueCostAwards[].myRecovery`}
                  >
                    <PerspectiveAwarePercentInput isPerspective={perspectiveIsClaimant} />
                  </DebugFormItem>
                </Col>

                <Col span={weightSpan}>
                  <DebugFormItem
                    isPerspective={perspectiveIsDefendant}
                    label={index === 0 ? 'Claimant Lose' : ''}
                    name={[field.name, 'theirRecovery']}
                    title={`caseBot.issueCostAwards[].theirRecovery`}
                  >
                    <PerspectiveAwarePercentInput isPerspective={perspectiveIsDefendant} />
                  </DebugFormItem>
                </Col>
              </Row>
            )
          })
        }}
      </Form.List>
    </>
  )
}
