import * as React from 'react'

import {calculateDerivedEventFields, EventPayment, MoneySum, PartyId} from '@settleindex/domain'
import {dateToPicker, Form} from '@settleindex/react'

import {EventFragment, EventUpdateInput, PartyFragment, Role, UpdateEventPaymentInput} from '../../graphQLTypes'
import {EventModal} from '../EventModal'
import {EventForm} from '../form/EventForm'
import {EventInForm} from '../form/EventInForm'
import {Footer} from '../form/Footer'

type ExistingEventInForm = EventInForm<EventUpdateInput>

interface Props {
  claimantId: string
  event: EventFragment
  onCancel: () => void
  onFinish: (event: EventUpdateInput) => void
  parties: PartyFragment[]
  perspectiveRole: Role
  readonly?: boolean
}

const eventToFormData = (event: EventFragment) => ({
  ...event,
  eventDate: dateToPicker(event.eventDate),
})
const formDataToEventUpdateInput = (formData: ExistingEventInForm) => ({
  ...formData,
  eventDate: formData.eventDate.toISOString(),
})

export const EditEventModal: React.FC<Props> = ({
  claimantId,
  event,
  onCancel,
  onFinish,
  parties,
  perspectiveRole,
  readonly = false,
}) => {
  const formTitle = readonly ? 'Offer' : 'Edit Offer'
  const [netPaymentToClaimant, setNetPaymentToClaimant] = React.useState<number>(0)
  const [eventValue, setEventValue] = React.useState<number>(0)
  const [updatedPayments, setUpdatedPayments] = React.useState<UpdateEventPaymentInput[]>(event.payments)
  const [form] = Form.useForm<ExistingEventInForm>()

  const recalculateEvent = React.useCallback(
    (eventData: EventFragment | ExistingEventInForm) => {
      const derivedEvent = calculateDerivedEventFields({
        budgetToSettle: eventData.budgetToSettle as MoneySum,
        claimantId: claimantId as PartyId,
        costsSpent: eventData.costsSpent as MoneySum,
        payments: eventData.payments as EventPayment[],
        perspectiveRole,
      })
      setNetPaymentToClaimant(derivedEvent.netPaymentToClaimant)
      setEventValue(derivedEvent.eventValue)
      setUpdatedPayments(eventData.payments)
    },
    [claimantId, perspectiveRole],
  )

  React.useEffect(() => {
    recalculateEvent(event)
    form.setFieldsValue(eventToFormData(event))
  }, [event, form, recalculateEvent])

  const onChange = (formData: ExistingEventInForm) => {
    recalculateEvent(formData)
  }

  const handleOnFinish = (formData: ExistingEventInForm) => {
    recalculateEvent(formData)
    onFinish(formDataToEventUpdateInput(formData))
  }

  return (
    <>
      <EventModal maskClosable={readonly} onCancel={onCancel}>
        <EventForm<ExistingEventInForm>
          footer={
            <Footer
              eventValue={eventValue}
              netPaymentToClaimant={netPaymentToClaimant}
              onCancel={onCancel}
              readonly={readonly ?? false}
            />
          }
          form={form}
          formName="editEvent"
          onCancel={onCancel}
          onChange={onChange}
          onFinish={handleOnFinish}
          parties={parties}
          payments={updatedPayments}
          readonly={readonly ?? false}
          title={formTitle}
        />
      </EventModal>
    </>
  )
}
