import dagre from 'dagre'
import {Edge, Node, Position} from 'react-flow-renderer'

export const layoutEngine = (nodes: Node[], edges: Edge[]): [Node[], Edge[]] => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))
  dagreGraph.setGraph({rankdir: 'LR', ranksep: 100})

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {width: node.data.width, height: node.data.height})
  })
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  const nodesWithLayout = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = Position.Left
    node.sourcePosition = Position.Right
    // Hack, see https://github.com/wbkd/react-flow/blob/main/example/src/Layouting/index.tsx#L53
    node.position = {x: nodeWithPosition.x + Math.random() / 1000, y: nodeWithPosition.y}

    return node
  })

  return [nodesWithLayout, edges]
}
