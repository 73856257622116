import * as React from 'react'

import {Right} from '@settleindex/domain'
import {Forbidden} from '@settleindex/react'
import {accessRightTestIds} from '@settleindex/testids/src/testIds'

export interface Props {
  rights?: Right[]
}

export const requiresRight =
  (right: Right, Component: React.FC<any>): React.FC<React.PropsWithChildren<Props>> =>
  ({rights = [], ...props}) => {
    if (!rights.includes(right)) {
      return <Forbidden data-test-id={accessRightTestIds.accessForbidden} />
    }

    return <Component {...props} />
  }
