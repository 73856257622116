import {isNil} from '@settleindex/fp'

import {DisputePatchInput} from '../../graphQLTypes'
import {partyInFormToParty} from '../form/party/partyInFormToParty'
import {isTmpId} from '../form/tmpId'
import {DisputeEditFormValues} from './DisputeEditFormValues'

export const formValuesToDisputePatch = (fields: DisputeEditFormValues): DisputePatchInput => {
  const {disputeStartedOn, parties: formParties, perspectiveId, ...restOfFields} = fields
  const perspectiveIndex = formParties.findIndex((p) => p.id === perspectiveId)

  if (isNil(perspectiveIndex)) {
    throw new Error(`No perspective index found: ${JSON.stringify(fields)}`)
  }

  const partiesToSend = formParties.map((formParty) => {
    const party = partyInFormToParty(formParty)

    return {
      ...party,
      id: isTmpId(party.id) ? undefined : party.id,
      label: party.label,
      name: party.name,
      industry: party.industry,
    }
  })

  return {
    ...restOfFields,
    disputeStartedOn: disputeStartedOn?.toISOString(),
    parties: partiesToSend,
    perspectiveIndex,
  }
}
