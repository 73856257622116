import {AppNode} from '../../nodes/AppNode'

interface Args {
  /** The ID of the outcome that was clicked */
  nodeId: string
  /** The list of all outcomes */
  nodes: AppNode[]
  /** Opens the outcome details drawer for the selected ID */
  openDrawer: (id: string) => void
  /** Toggles the visibility of damages outcomes */
  toggleDamagesVisibleForId: (id: string) => void
}

/**
 * Handles the single click on an outcome.
 *
 * Click on an outcome with damages ($ icon): open damages (then the
 * damages become final outcomes…)
 * Single click on a final outcome: open drawer
 * Otherwise: do nothing
 */
export const handleOutcomeClick = ({nodeId, nodes, openDrawer, toggleDamagesVisibleForId}: Args) => {
  const node = nodes.find((o) => o.id === nodeId)

  if (!node) {
    return
  }

  const hasSubOutcomes = node.childIds.length > 0
  const hasDamages = node.hasDamages

  if (hasDamages) {
    return toggleDamagesVisibleForId(node.id)
  }

  if (!hasSubOutcomes) {
    openDrawer(node.id)
  }
}
