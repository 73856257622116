import * as React from 'react'

import {FormItem, TextInput} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {fieldNames} from './fieldNames'

export const Reference = () => (
  <FormItem
    label="Reference"
    markAs="optional"
    name={fieldNames.reference}
    rules={[
      {
        required: false,
      },
    ]}
  >
    <TextInput data-test-id={disputeFormTestIds.editReference} />
  </FormItem>
)
