import * as React from 'react'

import {Form} from '@settleindex/react'

import {useSafeDispute} from '../dispute/context/useSafeDispute'
import {useSafeVersion} from '../version/context/useSafeVersion'
import {useVersionGraph} from '../version/graph/useVersionGraph'
import {CaseBotFormValues} from './form/CaseBotFormValues'
import {setCapBlockerIssuesInForm} from './form/setCapBlockerIssuesInForm'
import {setCustomCostsInForm} from './form/setCustomCostsInForm'
import {setIssueCostAwardsInForm} from './form/setIssueCostAwardsInForm'
import {convertCaseBotFromGraph} from './graph/convertCaseBotFromGraph'
import {useCaseBot} from './useCaseBot'

interface CaseBotFormProps {
  readonly?: boolean
}

export const CaseBotForm: React.FC<React.PropsWithChildren<CaseBotFormProps>> = ({children, readonly = false}) => {
  const className = readonly ? 'casebot-readonly' : undefined
  const [form] = Form.useForm<CaseBotFormValues>()
  const {dispute} = useSafeDispute()
  const {version} = useSafeVersion()
  const {caseBot: graphCaseBot} = useVersionGraph({
    disputeId: dispute.id,
    versionId: version.id,
  })
  const {allIssues, caseBotFormValues, setCaseBotChanged, setCaseBotFormValues} = useCaseBot()

  const initialValues = React.useMemo(
    () =>
      convertCaseBotFromGraph({caseBot: version.caseBot, parties: dispute.parties, claimantId: dispute.claimant.id}),
    [dispute, version],
  )

  // Set the initial values in the casebot values atom
  React.useEffect(() => {
    setCaseBotFormValues(initialValues)
    setCaseBotChanged(false)
    // We really only need to do this once - otherwise it becomes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = React.useCallback(() => {
    setCaseBotFormValues(form.getFieldsValue())
  }, [form, setCaseBotFormValues])

  const onFinish = React.useCallback(() => {
    if (!caseBotFormValues) {
      return
    }

    return graphCaseBot(caseBotFormValues).then(() => {
      setCaseBotChanged(false)
    })
  }, [caseBotFormValues, graphCaseBot, setCaseBotChanged])

  // See useCaseBot.ts for why we need to store the form values in a separate atom.
  React.useEffect(() => {
    if (!caseBotFormValues) {
      return
    }

    setIssueCostAwardsInForm(form, caseBotFormValues, allIssues)
    setCustomCostsInForm({form, values: caseBotFormValues, partiesInDispute: dispute.parties})
    setCapBlockerIssuesInForm(form, caseBotFormValues)
  }, [allIssues, caseBotFormValues, dispute.parties, form])

  const style: React.CSSProperties = React.useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
    }),
    [],
  )

  return (
    <Form
      className={className}
      form={form}
      initialValues={initialValues}
      name="caseBot"
      onFinish={onFinish}
      onValuesChange={onChange}
      style={style}
    >
      {children}
    </Form>
  )
}
