import * as React from 'react'

import {NoData, PrimaryButton} from '@settleindex/react'
import {capTestIds} from '@settleindex/testids/src/testIds'

interface Props {
  disabled?: boolean | null
  onAddCap: () => void
}

export const NoCaps: React.FC<Props> = ({disabled, onAddCap}) => (
  <span data-test-id={capTestIds.noCaps}>
    <NoData
      button={
        <PrimaryButton data-test-id={`add-cap`} disabled={disabled} medium onClick={onAddCap}>
          Add Cap/Limit
        </PrimaryButton>
      }
      heading={`Caps/Limits`}
      text={`Specify any potential caps or limits that may apply, such as
    contractual liability caps, insurance policy limits, or solvency. You
    can then apply these caps in different outcomes to see their impact.`}
    />
  </span>
)
