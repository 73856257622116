import * as React from 'react'
import styled from 'styled-components'

import {FormItem, FormItemProps} from '@settleindex/react'

import {opponentColor, perspectiveColor} from '../party/perspectiveAwareColors'
import {DebugLabel} from './DebugLabel'

interface DebugFormItemProps extends FormItemProps {
  /**
   * If isPerspective is true, the label color will be green.
   * If isPerspective is false, the label color will be red.
   * If the property is not set then the color won't be changed.
   */
  isPerspective?: boolean
  /**
   * title is the debug information to display
   */
  title: string
}

export const DebugFormItem: React.FC<DebugFormItemProps> = ({children, isPerspective, title, ...props}) => {
  const color = isPerspective === true ? perspectiveColor : isPerspective === false ? opponentColor : undefined

  return (
    <Colored {...props} color={color}>
      <DebugLabel title={title}>
        <FormItem {...props} noStyle>
          {children}
        </FormItem>
      </DebugLabel>
    </Colored>
  )
}

const Colored = styled(FormItem)`
  & label {
    color: ${(p: any) => p.color ?? 'auto'};
  }
`
