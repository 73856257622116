import * as React from 'react'

import {Nullable} from '@settleindex/domain'
import {isDefined, range} from '@settleindex/fp'
import {Col, Form, FormItem, LinkToTop, Row, SecondarySelect, Tag, Tooltip} from '@settleindex/react'

import {DisputeHistoryPointFragment} from '../../graphQLTypes'
import {paths} from '../../routing/paths'
import {indexToLabel} from './indexToLabel'
import {tagColors} from './tagColors'

interface OptionItem {
  label: string
  value: Nullable<number>
}

interface Props {
  disputeId: string
  onChange: (index: number, value: Nullable<number>) => void
  selectedIndexes: Nullable<number>[]
  versionHistoryPoints: DisputeHistoryPointFragment[]
}

export const VersionSelector: React.FC<Props> = ({disputeId, onChange, selectedIndexes, versionHistoryPoints}) => {
  const [form] = Form.useForm()
  const options: OptionItem[] = React.useMemo(
    () =>
      [{label: '', value: null} as OptionItem].concat(
        versionHistoryPoints.map((point, index) => ({label: String(point.version?.title), value: index})),
      ),
    [versionHistoryPoints],
  )

  const versionSelects = React.useMemo(
    () =>
      range(0, 3).map((index) => {
        const versionId = versionHistoryPoints?.[index]?.version?.id
        const isSelected = isDefined(selectedIndexes[index])
        const tooltipTitle =
          versionId && isSelected ? <LinkToTop to={paths.version(disputeId, versionId)}>View model</LinkToTop> : null

        return (
          <Col key={versionHistoryPoints[index]?.id} span={8}>
            <FormItem
              initialValue={selectedIndexes[index]}
              label={
                <Tooltip title={tooltipTitle}>
                  <Tag color={tagColors[index]} style={{textTransform: 'none'}}>
                    {indexToLabel[index]}
                  </Tag>
                </Tooltip>
              }
              name={`model${index}`}
              onReset={() => onChange(index, null)}
              rules={[{required: false}]}
            >
              <SecondarySelect
                onChange={(newValue: number) => onChange(index, newValue)}
                options={options}
                placeholder="Select..."
                style={{width: '100%'}}
              />
            </FormItem>
          </Col>
        )
      }),
    [disputeId, onChange, options, selectedIndexes, versionHistoryPoints],
  )

  return (
    <Form form={form}>
      <Row align="bottom" gutter={20}>
        {versionSelects}
      </Row>
    </Form>
  )
}
