import * as React from 'react'

import {LeftRight, PrimaryButton} from '@settleindex/react'

import {HelpBar} from '../../help'

interface Props {
  onAddEvent: () => void
}

export const HelpAndCreateBar: React.FC<Props> = ({onAddEvent}) => (
  <LeftRight
    left={<HelpBar bottom={0} id="event/list" top={0} />}
    leftSpan={16}
    right={
      <>
        <PrimaryButton data-test-id={`add-event`} disabled={false} medium onClick={onAddEvent}>
          Add Offer
        </PrimaryButton>
      </>
    }
    rightSpan={8}
    rowStyle={{marginBottom: 30}}
  />
)
