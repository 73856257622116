import * as React from 'react'

import {NoData} from '@settleindex/react'

export const NoOutcomes: React.FC = () => (
  <NoData
    heading="Outcome Analysis"
    text="Add various issues to your model to begin analysing potential outcomes from the perspective of the different parties."
  />
)
