import * as React from 'react'
import styled from 'styled-components'

import {
  CloseBlockIcon,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  FormItem,
  H1,
  H2,
  Input,
  LeftRight,
  PrimaryText,
  RadioButton as BaseRadioButton,
  RadioGroup,
  Row,
  SecondaryText,
  spaceMedium,
  spaceSmall,
  TextArea,
  VSpace,
  VSpaceMedium,
  VSpaceSmall,
} from '@settleindex/react'
import {eventTestIds} from '@settleindex/testids/src/testIds'

import {date} from '../../date/date'
import {NewEventPaymentInput, PartyFragment} from '../../graphQLTypes'
import {Tip} from '../../help/Tip'
import {SumFormat} from '../../sum'
import {SumInput} from '../../sum/sumInput'
import {eventFields} from './fieldNames'
import {Payments} from './Payments'

const Padded = styled.div`
  padding: 0 ${spaceMedium}px;
`

const PartySourceRadioButton = styled(BaseRadioButton)`
  & span {
    display: 'block';
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

interface Props<E> {
  footer: React.ReactElement
  form: FormInstance<E>
  formName: string
  onCancel?: () => void
  onChange: (event: E) => void
  onFinish: (event: E) => void
  parties: PartyFragment[]
  payments: NewEventPaymentInput[]
  readonly?: boolean
  title: string
}

export function EventForm<E>({
  footer,
  form,
  formName,
  onCancel,
  onChange,
  onFinish,
  parties,
  payments,
  readonly = false,
  title,
}: Props<E>): React.ReactElement<Props<E>> {
  const handleOnChange = () => {
    onChange(form.getFieldsValue())
  }
  const values: any = form.getFieldsValue()
  const currentSourcePartyLabel = parties.find((party) => party.id === values?.sourcePartyId)?.label ?? '-'
  const sourcePartyIdButtonWidth = `${Math.min(33, 100 / parties.length)}%`

  return (
    <div style={{width: '100%'}}>
      <Form form={form} name={formName} onChange={handleOnChange} onFinish={onFinish}>
        <FormItem name={eventFields.id} noStyle rules={[{required: false}]}>
          <Input type="hidden" />
        </FormItem>
        <FormItem name={eventFields.disputeId} noStyle rules={[{required: false}]}>
          <Input type="hidden" />
        </FormItem>
        <FormItem name={eventFields.type} noStyle>
          <Input type="hidden" />
        </FormItem>
        <Padded>
          <VSpaceMedium />
          <LeftRight
            left={<H1>{title}</H1>}
            right={
              <a data-test-id={`close-button`} onClick={onCancel} style={{marginRight: -10}}>
                <CloseBlockIcon />
              </a>
            }
          />
          <VSpaceSmall />
          <Row>
            <Col span={24}>
              <FormItem label="Offer by" name={eventFields.sourcePartyId}>
                {readonly ? (
                  <PrimaryText>{currentSourcePartyLabel}</PrimaryText>
                ) : (
                  <RadioGroup style={{display: 'flex', width: '100%'}}>
                    {parties.map((party) => (
                      <PartySourceRadioButton key={party.id} style={{width: sourcePartyIdButtonWidth}} value={party.id}>
                        {party.label}
                      </PartySourceRadioButton>
                    ))}
                  </RadioGroup>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <FormItem data-test-id={eventTestIds.eventDateFormItem} label="Date" name={eventFields.eventDate}>
                {readonly ? (
                  <PrimaryText>{date(values.eventDate)}</PrimaryText>
                ) : (
                  <DatePicker
                    allowClear={false}
                    format="DD MMMM YYYY"
                    onChange={handleOnChange}
                    placeholder=""
                    suffixIcon={<></>}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem label="Notes" markAs="optional" name={eventFields.notes} rules={[{required: false}]}>
                {readonly ? (
                  <PrimaryText>{values.notes}</PrimaryText>
                ) : (
                  <TextArea data-test-id="event-notes" style={{width: '100%'}} />
                )}
              </FormItem>
            </Col>
          </Row>
          <VSpace space={6} />

          <Divider />

          <VSpaceSmall />
          <H2>Payments</H2>
          {!readonly && <SecondaryText>Include details of the proposed payments</SecondaryText>}
          <VSpaceSmall />
          <Form.List name={eventFields.payments}>
            {(fields, {add, remove}) => (
              <Payments
                add={() => {
                  handleOnChange()
                  add()
                }}
                fields={fields}
                handleOnChange={handleOnChange}
                parties={parties}
                payments={payments}
                readonly={readonly}
                remove={(n: number) => {
                  handleOnChange()
                  remove(n)
                }}
              />
            )}
          </Form.List>
          <VSpaceSmall />

          <Divider />

          <VSpaceSmall />
          <H2>Costs</H2>
          {!readonly && (
            <SecondaryText>
              Provide details of your spend to date to calculate the overall value or cost of the offer.
            </SecondaryText>
          )}
          <VSpaceSmall />
          <Row gutter={spaceSmall}>
            <Col span={6}>
              <Tip id="Event.CostsSpentToDate" placement="top">
                <FormItem label="Spend to Date" name={eventFields.costsSpent}>
                  {readonly ? (
                    <PrimaryText>
                      <SumFormat sum={values.costsSpent ?? 0} />
                    </PrimaryText>
                  ) : (
                    <SumInput data-test-id="event-costs" disabled={readonly} onChange={handleOnChange} />
                  )}
                </FormItem>
              </Tip>
            </Col>
            <Col span={6}>
              <Tip id="Event.BudgetToSettle" placement="top">
                <FormItem label="Forecast Costs" name={eventFields.budgetToSettle}>
                  {readonly ? (
                    <PrimaryText>
                      <SumFormat sum={values.budgetToSettle ?? 0} />
                    </PrimaryText>
                  ) : (
                    <SumInput data-test-id="event-budget" disabled={readonly} onChange={handleOnChange} />
                  )}
                </FormItem>
              </Tip>
            </Col>
          </Row>
          <VSpace space={10} />
          <Divider />
          <VSpaceSmall />
          {footer}
          <VSpaceMedium />
        </Padded>
      </Form>
    </div>
  )
}
