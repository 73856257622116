import {atom} from 'recoil'

import {Nullable} from '@settleindex/domain'

import {DisputeFragment} from '../../graphQLTypes'

export const disputeAtom = atom<Nullable<DisputeFragment>>({
  key: 'disputeAtom',
  default: undefined,
})
