import * as React from 'react'

import {Col, StateToggleButtons} from '@settleindex/react'

import {CompareMode} from './CompareMode'

interface Props {
  compareMode: CompareMode
  setCompareMode: (compareMode: CompareMode) => void
}

export const ModeSelectButtons: React.FC<Props> = ({compareMode, setCompareMode}) => {
  const modeToggleButtons = React.useMemo(
    () => (
      <Col span={6} style={{paddingTop: 5}}>
        <StateToggleButtons<CompareMode>
          buttons={[
            {valueToSet: 'absolute', title: 'Absolute'},
            {valueToSet: 'difference', title: 'Difference'},
          ]}
          canEmpty={false}
          currentValue={compareMode}
          setter={setCompareMode}
        />
      </Col>
    ),
    [compareMode, setCompareMode],
  )

  return <>{modeToggleButtons}</>
}
