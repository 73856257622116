import {useAtom} from 'jotai'
import * as React from 'react'
import {useMemo} from 'react'

import {getAllCaseBotIssues, mulDecimals, WeightByPerspective} from '@settleindex/domain'

import {useSafeVersion} from '../../version/context/useSafeVersion'
import {caseBotChangedAtom} from './caseBotChangedAtom'
import {caseBotFormValuesAtom} from './caseBotFormValuesAtom'

/**
 * We present the CaseBot form in antd tabs. There is a quirk in the way the
 * tabs work: only the initial tab is rendered, so the form has no notion of the
 * rest of the form fields initially. If there is an existing CaseBot coming
 * from the Graph but the user does not click each tab before submitting, the
 * form fields on unvisited tabs are lost.
 * Hence, we have to maintain a separate atom for the form values and keep it
 * updated on form changes.
 * This also allows us to calculate some derived values on form changes here.
 *
 * For more info on the derived values see below and also check {@link CaseBotFormValues}
 * and how it differs from the domain {@link CaseBot} and also `set***InForm`
 * modules in the `form/` folder, e.g. `setIssueCostAwardsInForm`. These modules
 * must be called by the form wrapper module on each change (`useEffect`).
 */
export const useCaseBot = () => {
  const {version} = useSafeVersion()
  const [caseBotChanged, setCaseBotChanged] = useAtom(caseBotChangedAtom)
  const [caseBotFormValues, setCaseBotFormValues] = useAtom(caseBotFormValuesAtom)

  const allIssues = React.useMemo(() => getAllCaseBotIssues(caseBotFormValues), [caseBotFormValues])

  const finalIssues = React.useMemo(
    () => [...(caseBotFormValues?.loseFinals ?? []), ...(caseBotFormValues?.winFinals ?? [])],
    [caseBotFormValues?.loseFinals, caseBotFormValues?.winFinals],
  )

  const issueNames: Record<string, string> = React.useMemo(
    () => allIssues.reduce((acc, curr) => ({...acc, [curr.id]: curr.name}), {}),
    [allIssues],
  )

  const someCustomCostsEnabled = React.useMemo(() => {
    let someEnabled = false

    for (const key in caseBotFormValues?.customCosts) {
      if (caseBotFormValues.customCosts[key].some((c) => c.enabled)) {
        someEnabled = true
        break
      }
    }

    return someEnabled
  }, [caseBotFormValues?.customCosts])

  const someAwardRefinement = React.useMemo(() => {
    let some = false

    for (const key in caseBotFormValues?.claimDamageProportionsByItemId) {
      if (caseBotFormValues?.claimDamageProportionsByItemId?.[key]?.length) {
        some = true
        break
      }
    }

    return some
  }, [caseBotFormValues?.claimDamageProportionsByItemId])

  /**
   * Calculate the cumulative weights for the final issues.
   * These are displayed under Hurdles as "Cumulative Chance".
   */
  const cumulativeWeights = React.useMemo(() => {
    /**
     * Cumulative for Claimant hurdles (loseFinal) it is the total of: 100*hurdle1 chance % * hurdle 2 chance % *
     * hurdle n chance %.
     */
    const loseFinals = (caseBotFormValues?.loseFinals ?? []).reduce(
      (acc, curr) => {
        return {
          myWeight: acc.myWeight * curr.myWeight,
          theirWeight: acc.theirWeight * curr.theirWeight,
        }
      },
      {myWeight: 1, theirWeight: 1},
    )

    /**
     * For Defendant hurdles (winFinal) = 1 * (100 - hurdle 1 chance) * (100 - hurdle 2 chance) * n = claimant win
     */
    const winFinalsMul = (caseBotFormValues?.winFinals ?? []).reduce(
      (acc, curr) => {
        return {
          myWeight: acc.myWeight * (1 - curr.myWeight),
          theirWeight: acc.theirWeight * (1 - curr.theirWeight),
        }
      },
      {myWeight: 1, theirWeight: 1},
    )

    return {
      loseFinals,
      winFinals: {
        myWeight: 1 - winFinalsMul.myWeight,
        theirWeight: 1 - winFinalsMul.theirWeight,
      },
    }
  }, [caseBotFormValues?.loseFinals, caseBotFormValues?.winFinals])

  /**
   * Calculates the cumulative, weighted award range proportions for the claimant and the defendant.
   * This is displayed under the award ranges as Cumulative Proportion
   */
  const cumulativeAwardProportions: WeightByPerspective = React.useMemo(
    () =>
      (caseBotFormValues?.damages ?? []).reduce(
        (acc, curr) => ({
          myWeight: acc.myWeight + mulDecimals([curr.myWeight, curr.proportion]),
          theirWeight: acc.theirWeight + mulDecimals([curr.theirWeight, curr.proportion]),
        }),
        {myWeight: 0, theirWeight: 0},
      ),
    [caseBotFormValues?.damages],
  )

  /**
   * The cumulative proportions for Award Range refinements as an array of WeightByPerspective.
   * The list is ordered by the claim groups, so ClaimDamageProportionTable.tsx can iterate
   * over the claim groups and pick up these cumulative values based on the iteration index.
   * The value is null if the refinement is not enabled for the claim group.
   **/
  const cumulativeClaimDamageProportions: Array<WeightByPerspective | null> = React.useMemo(() => {
    const refinements = caseBotFormValues?.claimDamageProportionsByItemId ?? {}
    const damages = caseBotFormValues?.damages ?? []
    const claimGroups = version.claimGroups

    return claimGroups.map((cg) => {
      const claimGroupRefinement = refinements[cg.id]

      if (!claimGroupRefinement) {
        return null
      }

      return claimGroupRefinement.reduce(
        (acc, curr) => {
          const damage = damages.find((d) => d.id === curr.damageId)

          if (!damage) {
            return acc
          }

          return {
            myWeight: acc.myWeight + mulDecimals([damage.myWeight, curr.proportion]),
            theirWeight: acc.theirWeight + mulDecimals([damage.theirWeight, curr.proportion]),
          }
        },
        {myWeight: 0, theirWeight: 0},
      )
    })
  }, [caseBotFormValues?.claimDamageProportionsByItemId, caseBotFormValues?.damages, version.claimGroups])

  const damages = useMemo(() => {
    return (caseBotFormValues?.damages ?? []).reduce(
      (acc, curr) => {
        return {
          myWeight: acc.myWeight + curr.myWeight,
          theirWeight: acc.theirWeight + curr.theirWeight,
        }
      },
      {myWeight: 0, theirWeight: 0},
    )
  }, [caseBotFormValues?.damages])

  const contribution = useMemo(() => {
    return (caseBotFormValues?.contribution ?? []).reduce(
      (acc, curr) => {
        return {
          myWeight: acc.myWeight + curr.myWeight,
          theirWeight: acc.theirWeight + curr.theirWeight,
        }
      },
      {myWeight: 0, theirWeight: 0},
    )
  }, [caseBotFormValues?.contribution])

  return {
    allIssues,
    caseBotChanged,
    caseBotFormValues,
    contribution,
    /** The cumulative proportions for Award Ranges */
    cumulativeAwardProportions,
    /** The cumulative proportions for Award Range refinements by claim group */
    cumulativeClaimDamageProportions,
    /** Cumulative weights for win-finals and lose-finals */
    cumulativeWeights,
    damages,
    finalIssues,
    issueNames,
    setCaseBotChanged,
    setCaseBotFormValues,
    someAwardRefinement,
    someCustomCostsEnabled,
  }
}
