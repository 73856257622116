import * as React from 'react'

import {Modal} from '@settleindex/react'

import {captureException} from '../captureException'
import {useMeQuery} from '../graphQLTypes'
import {CompleteRegistrationContainer} from './CompleteRegistrationContainer'

interface Props {
  signOut: () => void
}

export const EnforceActionContainer: React.FC<React.PropsWithChildren<Props>> = ({signOut}) => {
  const {data, error} = useMeQuery()
  if (data?.me.user.completeProfileRequired) {
    return (
      <Modal bodyStyle={{padding: 0}} centered closable={false} footer={null} keyboard={false} open width={580}>
        <CompleteRegistrationContainer signOut={signOut} />
      </Modal>
    )
  }

  if (data?.me.user.reloadPageSuggested) {
    document.location.reload()

    return <></>
  }

  if (error) {
    captureException(error)
  }

  return <></>
}
