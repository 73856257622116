import * as React from 'react'

import {
  CenterRightLine,
  Col,
  Form,
  FormItem,
  H1,
  Modal,
  ModalProps,
  PrimaryButton,
  PrimaryText,
  Row,
  SecondarySelectXl,
  TextInput,
  VSpaceSmall,
} from '@settleindex/react'

import {AccessKind} from '../../graphQLTypes'
import {getAccessKindOptions} from './getAccessKindOptions'

export interface InviteModalProps extends ModalProps {
  disputeTitle: string
  onCancel: () => void
  onInvite: (email: string, accessKind: AccessKind) => void
}

export const InviteModal: React.FC<InviteModalProps> = ({disputeTitle, onCancel, onInvite, open}) => {
  const [form] = Form.useForm()
  const onFormFinish = (values: {accessKind: AccessKind; email: string}) => {
    onInvite(values.email, values.accessKind)
    onCancel()
  }

  const accessKindOptions = React.useMemo(getAccessKindOptions, [])

  return (
    <Modal closable={false} footer={null} onCancel={onCancel} open={open} width={480}>
      <div style={{padding: '20px'}}>
        <H1>Invite into "{disputeTitle}"</H1>
        <VSpaceSmall />

        <Form form={form} onFinish={onFormFinish}>
          <Row>
            <Col span={24}>
              <FormItem label="Email" name="email">
                <TextInput autoFocus type="email" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem initialValue={AccessKind.DISPUTE_TEAM_MEMBER} name="accessKind">
                <SecondarySelectXl>{accessKindOptions}</SecondarySelectXl>
              </FormItem>
            </Col>

            <Col span={24}>
              <PrimaryText>
                We'll notify the new team member in an email. They will have to accept the invitation to access the
                case.
              </PrimaryText>
              <VSpaceSmall />
            </Col>

            <Col span={24}>
              <CenterRightLine>
                <PrimaryButton htmlType="submit" medium>
                  Invite
                </PrimaryButton>
              </CenterRightLine>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
