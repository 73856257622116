import * as React from 'react'
import styled from 'styled-components'

import {noop} from '@settleindex/fp'
import {primaryNavigationTestIds} from '@settleindex/testids/src/testIds'

import {Logo} from '../../logo'
import {MenuSection} from './MenuSection'
import {PrimaryMenuItem} from './PrimaryMenuItem'
import {PrimaryNavLayout} from './PrimaryNavLayout'

const TopSection = styled.div``

const BottomSection = styled.div`
  padding-bottom: 10px;
`

interface Props {
  bottomMenuItems: PrimaryMenuItem[]
  expanded: boolean
  topMenuItems: PrimaryMenuItem[]
}

export const PrimaryNav: React.FC<Props> = ({bottomMenuItems, expanded, topMenuItems}) => {
  const [layoutExpanded, setLayoutExpanded] = React.useState<boolean>(expanded)
  React.useEffect(() => {
    setLayoutExpanded(expanded)
  }, [expanded])

  const onMouseEnter = expanded ? noop : () => setLayoutExpanded(true)
  const onMouseLeave = expanded ? noop : () => setLayoutExpanded(false)

  return (
    <PrimaryNavLayout
      data-test-id={primaryNavigationTestIds.primaryNav}
      expanded={layoutExpanded}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TopSection>
        <Logo />
        <MenuSection expanded={layoutExpanded} menuItems={topMenuItems} />
      </TopSection>
      <BottomSection>
        <MenuSection expanded={layoutExpanded} menuItems={bottomMenuItems} />
      </BottomSection>
    </PrimaryNavLayout>
  )
}
