import * as React from 'react'

import {
  Accordion,
  AccordionPanel,
  H3,
  LeftRight,
  StateToggleButtons,
  VSpaceLarge,
  VSpaceMedium,
  VSpaceSmall,
} from '@settleindex/react'

import {SettlementStrategyInput} from '../../graphQLTypes'
import {HelpTitleWithTooltip} from '../../help/HelpTitleWithTooltip'
import {useTutorial} from '../../tutorial/useTutorial'
import {CurrentPositionChart} from '../currentPositionChart/CurrentPositionChart'
import {CaseValuesTable} from './caseValuesTable/CaseValuesTable'
import {CostsTable} from './costsTable/CostsTable'
import {SettlementChartInReport} from './SettlementChartInReport'
import {SettlementPartySelector} from './SettlementPartySelector'
import {SettlementValuesTable} from './settlementValuesTable/SettlementValuesTable'
import {useRestSettlementAnalysis} from './useSettlementAnalysis'

interface Props {
  setSettlementStrategy: (strategy: SettlementStrategyInput) => void
}

export const SettlementAnalysisPage: React.FC<Props> = React.memo(({setSettlementStrategy}) => {
  const [mode, setMode] = React.useState<'all' | 'current'>('all')

  useRestSettlementAnalysis()

  const {setTutorialId} = useTutorial()
  React.useEffect(() => setTutorialId('strategy'), [setTutorialId])

  return (
    <>
      <HelpTitleWithTooltip
        id="help_for_settlement_positions"
        left="Position Analysis and Settlement Ranges"
        leftId="help_for_settlement_positions_title"
      />
      <LeftRight
        right={
          <>
            <StateToggleButtons<'all' | 'current'>
              buttons={[
                {title: 'All', valueToSet: 'all'},
                {title: 'Current Position', valueToSet: 'current'},
              ]}
              canEmpty={false}
              currentValue={mode}
              setter={setMode}
            />
          </>
        }
      />
      <VSpaceMedium />

      <SettlementPartySelector />
      <VSpaceSmall />

      {mode === 'all' ? (
        <>
          <SettlementChartInReport />
          <VSpaceMedium />
          <CaseValuesTable />
        </>
      ) : (
        <CurrentPositionChart />
      )}
      <VSpaceMedium />

      <SettlementValuesTable setSettlementStrategy={setSettlementStrategy} />

      <Accordion boundless>
        <AccordionPanel header={<H3>Costs</H3>} key={1}>
          <CostsTable />
        </AccordionPanel>
      </Accordion>
      <VSpaceLarge />
    </>
  )
})
