import * as React from 'react'

import {isEmpty} from '@settleindex/fp'
import {Avatar, colors} from '@settleindex/react'

import {DisputeTeamMemberFragment} from '../../graphQLTypes'
import {MemberAvatar} from './MemberAvatar'

interface Props {
  members: DisputeTeamMemberFragment[]
}

export const MemberAvatarRow: React.FC<Props> = React.memo(({members}) => {
  const membersWithName = React.useMemo(() => members.filter(memberHasFullName), [members])
  const membersToShow = React.useMemo(() => membersWithName.filter(memberHasFullName).slice(0, 8), [membersWithName])
  const membersHidden = React.useMemo(
    () => membersWithName.length - membersToShow.length,
    [membersToShow.length, membersWithName.length],
  )

  return (
    <div style={{display: 'flex'}}>
      {membersToShow.map((member) => (
        <MemberAvatar key={member.user.id} user={member.user} />
      ))}
      {membersHidden ? (
        <Avatar bgColor={colors.base9} textColor={colors.base4}>
          <span style={{fontSize: 9}}>+{membersHidden}</span>
        </Avatar>
      ) : (
        <></>
      )}
    </div>
  )
})

const memberHasFullName = (m: DisputeTeamMemberFragment) => !isEmpty(m.user.firstName) && !isEmpty(m.user.lastName)
