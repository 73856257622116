import * as React from 'react'

import {
  CenterRightLine,
  H2,
  HSpaceMedium,
  HSpaceSmall,
  LeftRight,
  LoadingIndicator,
  PrimaryButton,
} from '@settleindex/react'
import {outcomeDetailsTestIds} from '@settleindex/testids/src/testIds'

import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {RoleTag} from '../../party/RoleTag'
import {useNodes} from '../context/useNodes'
import {HeaderStyle} from './HeaderStyle'

interface Props {
  onDone: () => void
  showRoleTag?: boolean
  title?: string
}

export const DrawerHeader: React.FC<Props> = ({onDone, showRoleTag, title}) => {
  const {loading} = useAppIsLoading()
  const {isPerspective, selectedPartyRole} = useNodes()

  const roleTag = showRoleTag ? (
    <>
      <RoleTag isPerspective={isPerspective} role={selectedPartyRole} />
      <HSpaceMedium />
    </>
  ) : (
    <></>
  )

  const right = (
    <CenterRightLine>
      {loading && (
        <>
          <LoadingIndicator />
          <HSpaceSmall />
        </>
      )}
      {roleTag}
      <PrimaryButton data-test-id={outcomeDetailsTestIds.closeDrawerIcon} medium onClick={onDone}>
        Done
      </PrimaryButton>
    </CenterRightLine>
  )

  return (
    <HeaderStyle>
      <LeftRight left={<H2 noMargin>{title}</H2>} right={right} rowStyle={{alignItems: 'center', flex: 1}} />
    </HeaderStyle>
  )
}
