import * as React from 'react'

import {KeyValue, KeyValueBox, PrimaryText} from '@settleindex/react'

import {DebugLabel} from '../../debug/DebugLabel'
import {Tip} from '../../help/Tip'
import {SumFormat} from '../../sum'
import {useSafeVersion} from '../../version/context/useSafeVersion'

export const TargetsBox: React.FC<{}> = () => {
  const {
    version: {caseTargets, perspectiveCost},
  } = useSafeVersion()

  const data: KeyValue[] = [
    {
      label: (
        <Tip id="Targets.SettlementTarget">
          <PrimaryText>Case Target</PrimaryText>
        </Tip>
      ),
      value: (
        <DebugLabel title="version.caseTargets.settlementTarget">
          <PrimaryText medium>
            <SumFormat sum={caseTargets?.settlementTarget} />
          </PrimaryText>
        </DebugLabel>
      ),
    },
    {
      label: (
        <Tip id="Targets.CostsTargets">
          <PrimaryText>Costs Target</PrimaryText>
        </Tip>
      ),
      value: (
        <DebugLabel title="version.caseTargets.target">
          <PrimaryText medium>
            <SumFormat sum={perspectiveCost?.target} />
          </PrimaryText>
        </DebugLabel>
      ),
    },
    {
      label: (
        <Tip id="Targets.TotalCaseTarget">
          <PrimaryText>Overall Case Target</PrimaryText>
        </Tip>
      ),
      value: (
        <DebugLabel title="version.caseTargets.expectedNetSettlementValue">
          <PrimaryText medium>
            <SumFormat sum={caseTargets?.expectedNetSettlementValue} />
          </PrimaryText>
        </DebugLabel>
      ),
    },
  ]

  return <KeyValueBox data={data} span={12} title="Case Targets" />
}
