import * as React from 'react'

import {Form, H1, PageHeaderLeftRight} from '@settleindex/react'

import {MutationCreateUserArgs} from '../../../graphQLTypes'
import {Content} from '../../../page/'
import {paths} from '../../../routing/paths'
import {fieldNames, userEditFields} from '../../../user/userEditFields'
import {adminBreadcrumbs} from '../../adminBreadcrumbs'
import {FormLayout} from './FormLayout'

interface Props {
  loading: boolean
  onSubmit: (user: MutationCreateUserArgs) => void
}

export const UserCreatePage: React.FC<Props> = ({loading, onSubmit}) => {
  const [form] = Form.useForm()
  const onNotifyChange = (e: any) => {
    form.setFieldsValue({notify: e.target.checked})
  }
  const fields = userEditFields({onNotifyChange, submitLabel: 'Add User', required: {[fieldNames.phone]: false}})

  return (
    <Content breadcrumbs={adminBreadcrumbs(paths.userAdmin(), paths.userAdminCreate())} loading={loading}>
      <PageHeaderLeftRight left={<H1>Add User</H1>} right={<></>} />
      <Form form={form} initialValues={{}} name="userCreate" onFinish={onSubmit}>
        <FormLayout fields={fields} showNotify />
      </Form>
    </Content>
  )
}
