import * as React from 'react'

import {calculateDerivedEventFields, MoneySum, NewEventPayment, PartyId} from '@settleindex/domain'
import {dateToPicker, Form} from '@settleindex/react'

import {EventType, NewEventInput, PartyFragment, Role} from '../../graphQLTypes'
import {EventModal} from '../EventModal'
import {EventForm} from '../form/EventForm'
import {EventInForm} from '../form/EventInForm'
import {Footer} from '../form/Footer'

type NewEventInForm = EventInForm<NewEventInput>

const formDataToNewEventInput = (formData: NewEventInForm) => ({
  ...formData,
  eventDate: formData.eventDate.toISOString(),
})

interface Props {
  claimantId: string
  loading?: boolean
  onCancel: () => void
  onFinish: (event: NewEventInput) => void
  parties: PartyFragment[]
  perspectiveRole: Role
}

export const NewEventModal: React.FC<Props> = ({
  claimantId,
  loading = false,
  onCancel,
  onFinish,
  parties,
  perspectiveRole,
}) => {
  const [netPaymentToClaimant, setNetPaymentToClaimant] = React.useState<number>(0)
  const [eventValue, setEventValue] = React.useState<number>(0)
  const initialValues: NewEventInForm = React.useMemo(
    () => ({
      budgetToSettle: 0,
      costsSpent: 0,
      eventDate: dateToPicker(new Date()),
      sourcePartyId: parties[0].id,
      type: EventType.offer,
      payments: [
        {
          payer: parties?.[1].id,
          payee: parties[0].id,
          amount: 0,
          costAmount: 0,
        },
      ],
    }),
    [parties],
  )

  const [form] = Form.useForm<NewEventInForm>()

  React.useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const footer = (
    <Footer eventValue={eventValue} loading={loading} netPaymentToClaimant={netPaymentToClaimant} onCancel={onCancel} />
  )

  const onChange = (formData: NewEventInForm) => {
    const derivedEvent = calculateDerivedEventFields({
      budgetToSettle: formData.budgetToSettle as MoneySum,
      claimantId: claimantId as PartyId,
      costsSpent: formData.costsSpent as MoneySum,
      payments: formData.payments as NewEventPayment[],
      perspectiveRole,
    })
    setNetPaymentToClaimant(derivedEvent.netPaymentToClaimant)
    setEventValue(derivedEvent.eventValue)
  }

  const handleOnFinish = (formData: NewEventInForm) => {
    onFinish(formDataToNewEventInput(formData))
  }

  return (
    <EventModal maskClosable={false} onCancel={onCancel}>
      <EventForm<NewEventInForm>
        footer={footer}
        form={form}
        formName="newEvent"
        onCancel={onCancel}
        onChange={onChange}
        onFinish={handleOnFinish}
        parties={parties}
        payments={initialValues.payments}
        title={'New Offer'}
      />
    </EventModal>
  )
}
