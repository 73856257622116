import * as React from 'react'
import styled from 'styled-components'

import {cond} from '@settleindex/fp'

import {useSidebarTabs} from './useSidebarTabs'

interface TabDefinition {
  content: React.ReactElement
}

interface SidebarTabsProps {
  left: TabDefinition
  right: TabDefinition
}

export const SidebarTabs: React.FC<SidebarTabsProps> = ({left, right}) => {
  const {sidebarActiveTab} = useSidebarTabs()
  const content = cond([
    [(t) => t === 'left', () => left.content],
    [(t) => t === 'right', () => right.content],
  ])(sidebarActiveTab)

  return <Content>{content}</Content>
}

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
`
