import * as React from 'react'
import styled from 'styled-components'

import {notCombinedDefendantParty} from '@settleindex/domain'
import {Circle, HSpace, LeftRight, Option, SecondarySelect, theme} from '@settleindex/react'

import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {Role} from '../../graphQLTypes'
import {opponentColor, perspectiveColor} from '../../party/perspectiveAwareColors'
import {SumText} from '../../sum'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {useSettlementAnalysis} from './useSettlementAnalysis'

export const SettlementPartySelector: React.FC = () => {
  const {dispute} = useSafeDispute()
  const {version} = useSafeVersion()
  const {setSettlementOpponentId, settlementOpponent} = useSettlementAnalysis()

  const opponents = React.useMemo(
    () =>
      dispute.perspectiveRole === Role.Claimant
        ? dispute.opponents
        : dispute.opponents.filter(notCombinedDefendantParty),
    [dispute.opponents, dispute.perspectiveRole],
  )

  const options = React.useMemo(
    () =>
      opponents.map((opponent) => {
        const settlementSum = version.opponentValues.find((o) => o.partyId === opponent.id)?.caseValuePresent
        const sumText = <SumText semibold sum={settlementSum} />

        return (
          <Option key={opponent.id} value={opponent.id}>
            <OpponentOptionContent>
              <div>
                <Circle color={opponentColor} />
                <HSpace inline space={10} />
                {opponent.label}
              </div>
              <div>{sumText}</div>
            </OpponentOptionContent>
          </Option>
        )
      }),
    [opponents, version.opponentValues],
  )

  const opponentSelector = React.useMemo(
    () => (
      <OpponentSelector onChange={setSettlementOpponentId} value={settlementOpponent.id}>
        {options}
      </OpponentSelector>
    ),
    [options, setSettlementOpponentId, settlementOpponent.id],
  )

  return (
    <LeftRight
      gutter={10}
      left={
        <PerspectiveSelector>
          <div>
            <Circle color={perspectiveColor} style={{marginRight: 10}} />
            {dispute.perspective.label}
          </div>
          <div style={{paddingRight: 18}}>
            <SumText semibold sum={version.caseValuePresent} />
          </div>
        </PerspectiveSelector>
      }
      right={opponentSelector}
    />
  )
}

const OpponentOptionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 47px !important;

  padding-bottom: 3px;
`

const OpponentSelector = styled(SecondarySelect)`
  width: 442px;

  & .ant-select-selector {
    height: 47px !important;
    align-items: flex-start !important;
  }
`

const PerspectiveSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 47px;
  width: 442px;

  border: 1px solid ${theme.select.secondary.border}!important;
  border-radius: 3px;

  padding-left: 11px;

  background-color: ${theme.select.secondary.bg}!important;

  color: ${theme.select.secondary.color};
  font-weight: ${theme.select.secondary.weight};
  font-size: ${theme.select.fontSize}px;
`
