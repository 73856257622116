import * as React from 'react'

import {Modal} from '@settleindex/react'

interface Props {
  maskClosable?: boolean
  onCancel: () => void
}

export const EventModal: React.FC<React.PropsWithChildren<Props>> = ({children, maskClosable, onCancel}) => {
  return (
    <Modal
      bodyStyle={{padding: 0}}
      closable={false}
      destroyOnClose
      footer={null}
      maskClosable={maskClosable}
      onCancel={onCancel}
      open
      width={775}
    >
      {children}
    </Modal>
  )
}
