import * as React from 'react'

import {CapType} from '@settleindex/domain'
import {
  H2,
  LeftRight,
  PrimaryButton,
  PrimaryText,
  SecondaryButton2,
  Table,
  TableColumn,
  TableRow,
  VSpaceMedium,
} from '@settleindex/react'
import {capTestIds} from '@settleindex/testids/src/testIds'

import {CapFragment, CapPatchInput, PartyFragment} from '../../graphQLTypes'
import {PercentInput} from '../../percent/PercentInput'
import {SumInput} from '../../sum/sumInput'
import {CapDescription} from './CapDescription'
import {PartySelect} from './PartySelect'
import {TypeSelect} from './TypeSelect'

type CapRecord = 'partyId' | 'description' | 'type' | 'amount' | 'interest' | 'actions'

const columns: TableColumn<CapRecord>[] = [
  {id: 'partyId', label: 'Party', align: 'left'},
  {id: 'type', label: 'Type', align: 'left', width: 260},
  {id: 'description', label: 'Description', align: 'left'},
  {id: 'amount', label: 'Amount', align: 'right', width: 130},
  {id: 'interest', label: 'Interest', align: 'right', width: 90},
  {id: 'actions', label: 'Actions', align: 'right', width: 85},
]

interface Props {
  caps: CapFragment[]
  onAddCap: () => void
  onDeleteCap: (capId: string) => void
  onPatchCap: (capId: string, patch: CapPatchInput) => void
  parties: PartyFragment[]
  readonly: boolean
}

export const CapListPage: React.FC<Props> = ({caps, onAddCap, onDeleteCap, onPatchCap, parties, readonly}) => {
  const rows: TableRow<CapRecord>[] = caps.map((cap, capIndex) => ({
    id: cap.id,
    cells: {
      partyId: (
        <PartySelect
          defaultValue={cap.partyId}
          onChange={(partyId) => onPatchCap(cap.id, {partyId})}
          parties={parties}
          readonly={readonly}
          testId={`cap-party-${capIndex}`}
        />
      ),
      description: readonly ? (
        <PrimaryText>{cap.description}</PrimaryText>
      ) : (
        <CapDescription
          defaultValue={cap.description}
          onFinish={(description?: string) => onPatchCap(cap.id, {description})}
          testId={`cap-description-${capIndex}`}
        />
      ),
      type: (
        <TypeSelect
          defaultValue={cap.type}
          onChange={(type) => onPatchCap(cap.id, {type: type as CapType})}
          readonly={readonly}
          testId={`cap-type-${capIndex}`}
          types={Object.values(CapType)}
        />
      ),
      amount: (
        <SumInput
          data-test-id={`cap-amount-${capIndex}`}
          defaultValue={cap.amount}
          onSave={(amount: number) => onPatchCap(cap.id, {amount})}
          readonly={readonly}
        />
      ),
      interest: (
        <PercentInput
          data-test-id={`cap-interest-${capIndex}`}
          defaultValue={cap.interest}
          onSave={(i) => onPatchCap(cap.id, {interest: Number(i)})}
          readOnly={readonly}
        />
      ),
      actions: (
        <SecondaryButton2
          data-test-id={`cap-delete-${capIndex}`}
          disabled={readonly}
          onClick={() => onDeleteCap(cap.id)}
        >
          Delete
        </SecondaryButton2>
      ),
    },
  }))

  return (
    <>
      <LeftRight
        left={<H2 noMargin>Caps/Limits</H2>}
        right={
          readonly ? undefined : (
            <PrimaryButton data-test-id={`add-cap`} disabled={false} medium onClick={onAddCap}>
              Add Cap/Limit
            </PrimaryButton>
          )
        }
      />
      <VSpaceMedium />
      <Table columns={columns} rows={rows} stateId="caps" testId={capTestIds.capsTable} />
    </>
  )
}
