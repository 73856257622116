import styled from 'styled-components'

import {InputNumber, theme} from '@settleindex/react'

import {baseSumInput} from './baseSumInput'

const SumInputStyle = styled(InputNumber)`
  width: 100%;

  & .ant-input-number-handler-wrap {
    display: none;
  }

  & .ant-input-number-input {
    text-align: right;
    font-weight: ${theme.input.weight};
  }

  &.ant-input-number-disabled input,
  &.ant-input-number-disabled {
    border: 0px transparent none !important;
  }
`

export const SumInput = baseSumInput(SumInputStyle)
