import * as React from 'react'

import {CenterLine, Col, Form, H1, PrimaryButton, Row, VSpaceSmall} from '@settleindex/react'

import {LoginPageFrame} from './LoginPageFrame'
import {Logo} from './Logo'
import {useUser} from './useUser/useUser'

export const LogoutPage: React.FC = () => {
  const {logout} = useUser()

  const [form] = Form.useForm()

  const primaryButtonAction = React.useCallback(() => logout(), [logout])

  React.useEffect(() => {
    logout()
    // document.location.href = `${paths.home()}?logout=${Date.now()}`
  }, [logout])

  return (
    <LoginPageFrame>
      <CenterLine>
        <Logo />
      </CenterLine>
      <CenterLine>
        <H1 noMargin style={{marginTop: 40}}>
          SettleIndex
        </H1>
      </CenterLine>
      <Form form={form} name="login" onFinish={primaryButtonAction} style={{width: 360, marginTop: 30}}>
        <Row>
          <Col span={24}>
            <PrimaryButton medium onClick={primaryButtonAction}>
              Signing you out...
            </PrimaryButton>
            <VSpaceSmall />
          </Col>
        </Row>
      </Form>
    </LoginPageFrame>
  )
}
