import * as React from 'react'

import {Link} from '@settleindex/react'

import {paths} from '../routing/paths'

const pathsMap = new Map<string, string>([
  [paths.admin(), 'Admin'],
  [paths.userAdmin(), 'Users'],
  [paths.userAdminCreate(), 'Add User'],
  [paths.userAdminEditPattern, 'Edit User'],
])

export const adminBreadcrumbs = (...crumbs: string[]): React.ReactElement[] =>
  [paths.admin(), ...crumbs].map((p) => (
    <Link key={p} to={p}>
      {pathsMap.get(p)}
    </Link>
  ))
