import * as React from 'react'

import {FormItem, TextInput} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {fieldNames} from './fieldNames'

export const Title = () => (
  <FormItem
    label="Case Title"
    name={fieldNames.title}
    rules={[
      {
        required: true,
        message: 'Please provide the case title',
      },
    ]}
  >
    <TextInput data-test-id={disputeFormTestIds.editDisputeTitle} />
  </FormItem>
)
