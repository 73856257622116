import styled from 'styled-components'

import {
  collapsedRightSidebarWidth,
  expandedRightSidebarWidth,
  minPageWidthToShowRightSidebar,
  secondaryNavHeight,
  usualTransition,
} from '@settleindex/react'

export const RightCol = styled.div`
  position: absolute;
  right: 0;

  height: 100%;
  max-height: calc(100% - ${secondaryNavHeight}px);
  width: ${collapsedRightSidebarWidth}px;

  overflow-x: hidden;

  transition: width 200ms ease-in-out;

  /* Trumps decision tree  */
  z-index: 100;

  &:hover {
    width: ${expandedRightSidebarWidth}px;
    transition: width ${usualTransition};
  }

  @media screen and (min-width: ${minPageWidthToShowRightSidebar}px) {
    width: ${expandedRightSidebarWidth}px;
  }
`
