import * as React from 'react'

import {DisputeClassification} from '@settleindex/domain'
import {H1, LeftRight} from '@settleindex/react'

import {DisputeFragment, DisputePatchInput} from '../../graphQLTypes'
import {usePageTitle} from '../../pageTitle/usePageTitle'
import {Frame} from '../Frame'
import {DisputeEditForm} from './DisputeEditForm'
import {DisputeEditFormValues} from './DisputeEditFormValues'

interface Props {
  dispute: DisputeFragment
  disputeClassifications?: DisputeClassification[]
  initialValues: DisputeEditFormValues
  loading?: boolean
  onArchive: () => void
  onCancel: () => void
  patch: (patch: DisputePatchInput) => void
}

export const DisputeEditPage: React.FC<Props> = ({
  dispute,
  disputeClassifications = [],
  initialValues,
  loading = false,
  onArchive,
  onCancel,
  patch,
}) => {
  usePageTitle(dispute.title)

  return (
    <Frame activeKey="settings" dispute={dispute} initialLoading={false} loading={loading}>
      <LeftRight left={<H1>Case Settings</H1>} />
      <DisputeEditForm
        disputeClassifications={disputeClassifications}
        initialValues={initialValues}
        onArchive={onArchive}
        onCancel={onCancel}
        onFinish={patch}
      />
    </Frame>
  )
}
