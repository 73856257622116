import * as React from 'react'

import {Input} from '@settleindex/react'

import {Maybe} from '../../graphQLTypes'

interface Props {
  defaultValue?: Maybe<string>
  onFinish?: (value?: string) => void
  testId?: string
}

export const CapDescription: React.FC<Props> = ({defaultValue, onFinish, testId}) => (
  <Input data-test-id={testId} defaultValue={defaultValue ?? ''} onSave={onFinish} style={{width: 410}} />
)
