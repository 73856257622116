import * as React from 'react'
import styled from 'styled-components'

import {InfoIcon, LeftRight, theme} from '@settleindex/react'

import {helpTexts} from './helpTexts'

interface BarProps {
  justify: 'start' | 'end'
}

const Bar = styled.div<BarProps>`
  display: flex;
  align-items: center;
  justify-content: flex-${(props: BarProps) => props.justify};
  width: 100%;
  height: 32px;

  padding: 6px 0px;
  font-size: ${theme.helpBar.fontSize}px;
  color: ${theme.helpBar.color};
  font-weight: ${theme.helpBar.weight};

  & svg {
    font-size: 14px;
    margin-right: 10px;
  }
`

interface Props {
  bottom?: number
  id: keyof typeof helpTexts
  justify?: 'start' | 'end'
  top?: number
}

/**
 * @deprecated
 */
export const HelpBar: React.FC<Props> = ({bottom, id, justify = 'start', top}) => {
  return (
    <LeftRight
      left={
        <Bar justify={justify}>
          <InfoIcon />
          <span>{helpTexts[id]}</span>
        </Bar>
      }
      leftSpan={24}
      rightSpan={0}
      rowStyle={{marginTop: top ?? 25, marginBottom: bottom ?? 25}}
    />
  )
}
