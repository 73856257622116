import * as React from 'react'

import {colors, PercentFormInput, PercentFormInputProps} from '@settleindex/react/lib/src'

import {opponentColor, perspectiveColor} from './index'

type PerspectiveAwarePercentInputProps = PercentFormInputProps & {
  isPerspective: boolean
}

export const PerspectiveAwarePercentInput = ({
  disabled,
  isPerspective,
  ...props
}: PerspectiveAwarePercentInputProps) => {
  const style = React.useMemo(() => {
    const yesColor = disabled ? colors.greenLight : perspectiveColor
    const noColor = disabled ? colors.redLight : opponentColor

    return {
      border: `1px solid ${isPerspective ? yesColor : noColor}`,
    }
  }, [disabled, isPerspective])

  return <PercentFormInput {...props} disabled={disabled} style={style} />
}
