import * as React from 'react'

import {FormItem, TextInput} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {partyFieldNames} from './partyFieldNames'

export const PartyLeadSolicitor: React.FC<{}> = () => (
  <FormItem
    label="Lead Solicitor / Attorney"
    markAs="optional"
    name={partyFieldNames.leadSolicitor}
    rules={[{required: false}]}
  >
    <TextInput data-test-id={disputeFormTestIds.editPartyLeadSolicitor} placeholder="e.g. Jane Smith" />
  </FormItem>
)
