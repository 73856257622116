import styled from 'styled-components'

import {PrimaryText, theme} from '@settleindex/react'

export const ForeignObject = styled.foreignObject`
  // This rule ensures the input boxes are draggable/zoomable in Safari.
  // Otherwise they would have a fixed position and not respond to zoom or pan.
  & .ant-input-affix-wrapper,
  & .ant-input {
    position: initial !important;
  }

  & ${PrimaryText} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 32px;
    border: 1px solid ${theme.input.border};
    background-color: ${theme.input.bg};
  }
`
