import React, {Suspense} from 'react'
import {Route, Switch} from 'react-router-dom'

import {LoadingIndicatorFullPage} from '@settleindex/react'

import {resetAutoLogout} from '../user/inactivity/useAutoLogout'
import {paths} from './paths'

const LoginPage = React.lazy(() => import('../user/LoginPage'))

export const PublicRouting: React.FC = () => {
  React.useEffect(() => {
    resetAutoLogout()
  }, [])

  return (
    <Suspense fallback={<LoadingIndicatorFullPage />}>
      <Switch>
        <Route path={paths.login}>
          <LoginPage />
        </Route>
      </Switch>
    </Suspense>
  )
}
