import {CaseBotIssue, ClaimDamageProportion} from '@settleindex/domain'
import {isDefined, omit} from '@settleindex/fp'

import {CaseBotInput} from '../../graphQLTypes'
import {CaseBotFormValues, IssueIdToCaseBotCustomCost} from '../form/CaseBotFormValues'

export const fakeValueToForceFormChange = 'fakeValueToForceFormChange'

export const convertCaseBotToGraph = ({claimDamageProportionsByItemId, ...rest}: CaseBotFormValues): CaseBotInput => {
  const claimDamageProportions: ClaimDamageProportion[] = isDefined(claimDamageProportionsByItemId)
    ? Object.values(claimDamageProportionsByItemId).flat().filter(Boolean)
    : []

  const input = omit([fakeValueToForceFormChange], rest)

  const cap = input.cap?.capId?.length
    ? {
        capId: input.cap.capId,
        myWeight: input.cap.myWeight ?? 0,
        theirWeight: input.cap.theirWeight ?? 0,
      }
    : undefined

  const capBlockerIssues = input.capBlockerIssues
    ? input.capBlockerIssues.filter((i) => i.value)?.map((cb) => cb.issueId)
    : []

  const customCosts = convertCustomCosts(input.customCosts)

  return {
    ...input,
    cap,
    capBlockerIssues,
    claimDamageProportions,
    customCosts,
    loseFinals: convertIssues(input.loseFinals),
    others: convertIssues(input.others),
    winFinals: convertIssues(input.winFinals),
    part36: [
      {
        ...input.part36,
        mode: input.part36.mode ?? 'custom',
      },
    ],
  } satisfies CaseBotInput
}

const convertIssues = (issues?: CaseBotIssue[]): CaseBotIssue[] => {
  return issues ?? []
}

const convertCustomCosts = (customCosts: IssueIdToCaseBotCustomCost) =>
  Object.values(customCosts)
    .flat()
    .map(({partyLabel, ...cc}) => cc)
