import {AxisProps} from '@nivo/axes'
import {BarItemProps, ComputedDatum, ResponsiveBar} from '@nivo/bar'
import * as React from 'react'

import {ChartSectionNormalLayout} from '../../chart/layout/ChartSectionNormalLayout'
import {theme} from '../../chart/theme'
import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {SettlementStrategyType} from '../../graphQLTypes'
import {useSafeVersion} from '../../version/context/useSafeVersion'
import {useSettlementAnalysis} from '../analysis/useSettlementAnalysis'
import {BarItem as BaseBareItem} from './BarItem'
import {ChartData, ChartDataKey} from './ChartData'
import {getBarColors} from './getBarColors'
import {getChartData} from './getChartData'
import {tooltip} from './tooltip'

export const CurrentPositionChart: React.FC = () => {
  const {
    dispute: {perspective, perspectiveRole},
  } = useSafeDispute()
  const {version} = useSafeVersion()
  const {settlementOpponent, settlementOpponentValues} = useSettlementAnalysis()
  const customSettlement = React.useMemo(
    () =>
      version.settlementStrategy.find(
        (s) => s.partyId === settlementOpponent.id && s.type === SettlementStrategyType.customTarget,
      )?.amount,
    [settlementOpponent.id, version.settlementStrategy],
  )

  const data: ChartData[] = getChartData({
    version,
    customSettlement,
    opponentValues: settlementOpponentValues,
    opponentLabel: settlementOpponent.label,
    perspectiveLabel: perspective.label,
  })

  const margin = React.useMemo(() => ({top: 5, right: 0, bottom: 35, left: 50}), [])

  const axisBottom: AxisProps = React.useMemo(
    () => ({
      format: (key: ChartDataKey) => data.find((d) => d.key === key)?.label ?? '',
      tickValues: 'label',
      tickPadding: 20,
      tickSize: 0,
    }),
    [data],
  )

  const axisLeft = React.useMemo(
    () => ({
      format: '(.2~s',
      tickPadding: 20,
      tickSize: 0,
    }),
    [],
  )

  const BarItem = React.useCallback((props: BarItemProps<ChartData>) => <BaseBareItem {...props} width={20} />, [])

  const barColors = React.useCallback(
    (props: ComputedDatum<ChartData>) => getBarColors(props.data.key, perspectiveRole),
    [perspectiveRole],
  )

  return (
    <ChartSectionNormalLayout>
      <ResponsiveBar<ChartData>
        animate
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        barComponent={BarItem}
        colors={barColors}
        data={data}
        indexBy="key"
        innerPadding={0}
        isInteractive
        label=""
        margin={margin}
        padding={0.1}
        theme={theme}
        tooltip={tooltip(perspectiveRole)}
      />
    </ChartSectionNormalLayout>
  )
}
