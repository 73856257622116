import * as React from 'react'
import styled from 'styled-components'

import {
  expandedRightSidebarWidth,
  minPageWidthToShowRightSidebar,
  SecondaryText,
  spaceMedium,
  spaceSmall,
  theme,
} from '@settleindex/react'

const FullContent = styled.div`
  height: 100%;
`

const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  & > span {
    writing-mode: vertical-rl;
    padding: ${spaceMedium}px ${spaceSmall}px;
  }
`

const Layout = styled.div`
  background-color: ${theme.sidebar.bgColor};
  height: 100%;

  & > ${FullContent} {
    display: none;
  }

  & > ${Placeholder} {
    display: inherit;
  }

  &:hover {
    width: ${expandedRightSidebarWidth}px;

    & > ${FullContent} {
      display: inherit;
    }
    & > ${FullContent} > div {
      width: ${expandedRightSidebarWidth}px;
    }

    & > ${Placeholder} {
      display: none;
    }
  }

  @media screen and (min-width: ${minPageWidthToShowRightSidebar}px) {
    width: ${expandedRightSidebarWidth}px;

    & > ${FullContent} {
      display: inherit;
    }

    & > ${Placeholder} {
      display: none;
    }
  }
`

export const RightSidebar: React.FC<React.PropsWithChildren<unknown>> = ({children}) => (
  <Layout>
    <FullContent>{children}</FullContent>
    <Placeholder>
      <SecondaryText medium>Metrics</SecondaryText>
    </Placeholder>
  </Layout>
)
