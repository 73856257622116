import * as React from 'react'

import {NoData, PrimaryButton2} from '@settleindex/react'

import {useVersionVariablesDrawer} from '../../versionVariablesDrawer/useVersionVariablesDrawer'

interface Props {
  readonly?: boolean
}

export const ClaimGroupsTableEmpty: React.FC<Props> = ({readonly}) => {
  const {editClaims} = useVersionVariablesDrawer()

  return (
    <NoData
      button={
        !readonly && (
          <PrimaryButton2 onClick={editClaims} size="medium" weight="medium">
            Manage Claim Variables
          </PrimaryButton2>
        )
      }
      heading="No Claims"
      text="To model claims in this outcome, you must
        first add some claim variables. Claims are managed centrally and can
        then be adjusted in each outcome to reflect the damages payable or
        receivable in the outcome."
    />
  )
}
