import {decisionTreeRootNodeId, findNodesFromParentDown} from '@settleindex/domain'

import {AppDecision} from '../nodes/AppDecision'
import {AppNode} from '../nodes/AppNode'

export const makeVisibleOutcomeList = ({
  damagesVisibleFor,
  decisions,
  nodes,
}: {
  damagesVisibleFor: Record<string, boolean>
  decisions: AppDecision[]
  nodes: AppNode[]
}): AppNode[] => {
  let visibleOutcomes: AppNode[] = []

  decisions
    // Ignore "ROOT" and "calculation" nodes e.g. damages
    .filter((o) => o.id !== decisionTreeRootNodeId && o.kind !== 'calculation')
    .forEach((o) => {
      if (!o.hasDamages) {
        visibleOutcomes.push(o)

        return
      }

      if (damagesVisibleFor[o.id]) {
        // findOutcomesFromParentDown will include the current outcome too
        visibleOutcomes.push(...findNodesFromParentDown(nodes, o.id))

        return
      }

      visibleOutcomes.push({...o, childIds: []})
    })

  return visibleOutcomes
}
