import {HistoryMetrics} from '@settleindex/domain'
import {isDefined, path} from '@settleindex/fp'

import {Currency, DisputeHistoryPointFragment} from '../../../graphQLTypes'
import {Datum} from './Datum'

interface HistoryChartData {
  data: Datum[]
  id: HistoryMetrics
}

export const getData = (
  points: DisputeHistoryPointFragment[],
  currency: Currency,
  metricsToShow: HistoryMetrics[],
): HistoryChartData[] => {
  const dataBag: {[key: string]: HistoryChartData} = metricsToShow.reduce(
    (acc, curr) => ({...acc, [curr]: {id: curr, data: []}}),
    {},
  )

  points.forEach((point) => {
    metricsToShow.forEach((metric) => {
      const y = path<number>(metric, point)
      const value = {
        x: point.date,
        y,
        currency,
      }

      if (isDefined(value.y)) {
        dataBag[metric].data.push(value as Datum)
      }
    })
  })

  return metricsToShow.reduce<HistoryChartData[]>((acc, curr) => [...acc, {id: curr, data: dataBag[curr].data}], [])
}
