import * as React from 'react'

import {Label, PrimaryButton2, PrimaryText, SecondaryButton2, Table, TableColumn, TableRow} from '@settleindex/react'
import {eventTestIds} from '@settleindex/testids/src/testIds'

import {date} from '../../date/date'
import {EventFragment, PartyFragment} from '../../graphQLTypes'
import {Tip} from '../../help/Tip'
import {SumFormat} from '../../sum'
import {EventMenu} from './EventMenu'
import {HelpAndCreateBar} from './HelpAndCreateBar'

type EventRecord = 'offerBy' | 'netPaymentToClaimant' | 'eventDate' | 'eventValue' | 'actions'

const columns: TableColumn<EventRecord>[] = [
  {id: 'offerBy', label: 'Offer by', align: 'left'},
  {id: 'eventDate', label: 'Date', align: 'left'},
  {
    id: 'netPaymentToClaimant',
    label: (
      <Tip id="Event.ListTotalValue">
        <Label>Total</Label>
      </Tip>
    ),
    align: 'right',
  },
  {
    id: 'eventValue',
    label: (
      <Tip id="Event.ListNetValue">
        <Label>Overall Value</Label>
      </Tip>
    ),
    align: 'right',
  },
  {id: 'actions', label: 'Actions', align: 'right', width: 250},
]

export interface EventListProps {
  events: EventFragment[]
  onAddEvent: () => void
  onDeleteEvent: (eventId: string) => void
  onEditEvent: (eventId: string) => void
  onViewEvent: (eventId: string) => void
  parties: PartyFragment[]
}

export const EventList: React.FC<EventListProps> = ({
  events,
  onAddEvent,
  onDeleteEvent,
  onEditEvent,
  onViewEvent,
  parties,
}) => {
  const rows: TableRow<EventRecord>[] = events.map((event) => ({
    id: event.id,
    cells: {
      netPaymentToClaimant: (
        <PrimaryText>
          <SumFormat sum={event.netPaymentToClaimant} />
        </PrimaryText>
      ),
      eventDate: <PrimaryText>{date(event.eventDate)}</PrimaryText>,
      eventValue: (
        <PrimaryText>
          <SumFormat sum={event.eventValue} />
        </PrimaryText>
      ),
      offerBy: <PrimaryText semibold>{parties.find((p) => p.id === event.sourcePartyId)?.label}</PrimaryText>,
      actions: (
        <>
          <SecondaryButton2 onClick={() => onViewEvent(event.id)} style={{marginRight: 10}}>
            View
          </SecondaryButton2>
          <PrimaryButton2 onClick={() => onEditEvent(event.id)} style={{marginRight: 10}}>
            Edit
          </PrimaryButton2>
          <EventMenu onDeleteEvent={() => onDeleteEvent(event.id)} />
        </>
      ),
    },
  }))

  return (
    <>
      <HelpAndCreateBar onAddEvent={onAddEvent} />
      <Table columns={columns} rows={rows} stateId="Events" testId={eventTestIds.eventTable} />
    </>
  )
}
