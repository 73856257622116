import * as React from 'react'

import {Label, TableColumn} from '@settleindex/react'

import {Tip} from '../../help/Tip'

export type AnalyseColumn = 'name' | 'absoluteWeight' | 'cumulativeWeight' | 'value' | 'riskWeightedValue' | 'actions'

export const makeColumns = ({
  hasCumulativeWeights,
  valueLabel,
}: {
  hasCumulativeWeights: boolean
  valueLabel: string
}): TableColumn<AnalyseColumn>[] => {
  const columns: TableColumn<AnalyseColumn>[] = [
    {
      id: 'name',
      label: (
        <Tip id="Outcomes.AnalysisTable.Columns.Name">
          <Label>Outcome</Label>
        </Tip>
      ),
      align: 'left',
    },
    {
      id: 'value',
      label: <Label>{valueLabel}</Label>,
      align: 'right',
      width: 140,
    },
    {
      id: 'absoluteWeight',
      label: (
        <Tip id="Outcomes.AnalysisTable.Columns.AbsoluteChance">
          <Label>Chance</Label>
        </Tip>
      ),
      align: 'right',
      width: 100,
    },
    {
      id: 'cumulativeWeight',
      label: <Label>(cumulative)</Label>,
      align: 'right',
      width: 100,
    },
    {
      id: 'riskWeightedValue',
      label: <Label>Risk-Weighted</Label>,
      align: 'right',
      width: 140,
    },
  ]

  return hasCumulativeWeights ? columns : columns.filter((column) => column.id !== 'cumulativeWeight')
}
