import * as React from 'react'

import {Classification, ClassificationTreeItem} from '@settleindex/domain'
import {FormItem, Option, SecondarySelect} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {partyFieldNames} from './partyFieldNames'

const classificationToOption = (classification: Classification) => (
  <Option key={classification.id} value={classification.id}>
    {classification.title}
  </Option>
)

interface Props {
  allSectionsAlphabetical: ClassificationTreeItem[]
  availableIndustryDivisions: Classification[]
  defaultValue?: string
  onChange?: () => void
}

export const PartyIndustry: React.FC<Props> = ({allSectionsAlphabetical, availableIndustryDivisions, onChange}) => {
  const sectionSelect = (
    <FormItem
      data-test-id={disputeFormTestIds.editPartyIndustry}
      label="Industry"
      markAs="optional"
      name={partyFieldNames.industrySection}
      rules={[{required: false}]}
    >
      <SecondarySelect allowClear onChange={onChange} placeholder="Select industry" style={{width: '100%'}}>
        {allSectionsAlphabetical.map(classificationToOption)}
      </SecondarySelect>
    </FormItem>
  )

  const divisionSelect = (
    <FormItem
      data-test-id={disputeFormTestIds.editPartyIndustryDivision}
      label="Sector"
      markAs="optional"
      name={partyFieldNames.industry}
      rules={[{required: Boolean(availableIndustryDivisions.length), message: 'Please select a sector'}]}
    >
      <SecondarySelect
        disabled={!availableIndustryDivisions.length}
        onChange={onChange}
        placeholder="Select sector"
        style={{width: '100%'}}
      >
        {availableIndustryDivisions?.map(classificationToOption)}
      </SecondarySelect>
    </FormItem>
  )

  return (
    <>
      {sectionSelect}
      {divisionSelect}
    </>
  )
}
