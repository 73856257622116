/* eslint-disable sonarjs/no-duplicate-string */
import {selector} from 'recoil'

import {cond} from '@settleindex/fp'

import {nodeValueToDisplayAtom} from '../atoms/nodeValueToDisplayAtom'

export const caseValueLabelSelector = selector<string>({
  key: 'caseValueSumSelector',
  get: ({get}) => {
    const valueToDisplay = get(nodeValueToDisplayAtom)

    return cond<string, string>([
      [() => valueToDisplay === 'valueGross', () => 'Award Position (Risk-weighted)'],
      [() => valueToDisplay === 'valueNet', () => 'Overall Position (Risk-weighted)'],
      [() => valueToDisplay === 'valuePresent', () => 'Current Position (Risk-weighted)'],
      [() => valueToDisplay === 'valueForecast', () => 'Forecast Position (Risk-weighted)'],
    ])()
  },
})
