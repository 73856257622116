import {ResponsiveBar} from '@nivo/bar'
import * as React from 'react'

import {HistoryMetrics} from '@settleindex/domain'

import {DashboardChartLayout} from '../../../chart/layout/DashboardChartLayout'
import {theme} from '../../../chart/theme'
import {metricVisuals} from '../../history/metricVisuals'
import {MaybeHistoryPoints} from '../MaybeHistoryPoint'
import {BarItem} from './barItem/BarItem'
import {getBarColors} from './getBarColors'
import {getBarValuesForEnabledMetrics} from './getBarValuesForEnabledMetrics'
import {allValueKeys, MetricData} from './MetricData'
import {Tooltip} from './Tooltip'

interface Props {
  metricsToShow: HistoryMetrics[]
  versionHistoryPoints: MaybeHistoryPoints
}

export const VersionCompareChart: React.FC<Props> = ({metricsToShow, versionHistoryPoints}) => {
  const valueKeys = React.useMemo(() => {
    const numberOfVersionsToCompare = versionHistoryPoints.length
    return allValueKeys.slice(0, numberOfVersionsToCompare)
  }, [versionHistoryPoints.length])

  const data: MetricData[] = React.useMemo(
    () => getBarValuesForEnabledMetrics({metricsToShow, historyPoints: versionHistoryPoints}),
    [metricsToShow, versionHistoryPoints],
  )

  const axisLeft = React.useMemo(
    () => ({
      format: '(.2~s',
      tickPadding: 20,
      tickSize: 0,
    }),
    [],
  )

  const axisBottom = React.useMemo(
    () => ({
      format: (key: HistoryMetrics) => metricVisuals.get(key)?.title,
      tickPadding: 20,
      tickSize: 0,
    }),
    [],
  )

  const margin = React.useMemo(() => ({top: 5, right: 0, bottom: 35, left: 50}), [])

  const colors = React.useMemo(() => getBarColors(), [])

  /**
   * innerPadding: Padding between group members in px, default is 0
   * padding: Padding between groups as ratio, default is 0.1
   */
  return (
    <DashboardChartLayout
      chart={
        <ResponsiveBar<MetricData>
          animate
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          barComponent={BarItem}
          colors={colors}
          data={data}
          groupMode="grouped"
          indexBy="key"
          innerPadding={0}
          isInteractive
          keys={valueKeys}
          label=""
          margin={margin}
          padding={0.1}
          theme={theme}
          tooltip={Tooltip}
        />
      }
    />
  )
}
