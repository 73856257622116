import * as React from 'react'

import {range} from '@settleindex/fp'
import {LinkToTop, TableColumn, Tag, Tooltip} from '@settleindex/react'

import {paths} from '../../routing/paths'
import {Col} from './Col'
import {indexToLabel} from './indexToLabel'
import {MaybeHistoryPoints} from './MaybeHistoryPoint'
import {tagColors} from './tagColors'

const indexToId: Col[] = ['version1', 'version2', 'version3']
const columnWidth = 140

export const makeExtraColumns = (
  points: MaybeHistoryPoints,
  isWeightEnabled: boolean,
  disputeId: string,
): TableColumn<Col>[] => {
  const fixedColumns = range(0, 3).map<TableColumn<Col>>((index) => {
    const versionId = points?.[index]?.version?.id
    const tooltipTitle = !versionId ? (
      points?.[index]?.version?.title
    ) : (
      <LinkToTop to={paths.version(disputeId, versionId)}>{points?.[index]?.version?.title}</LinkToTop>
    )

    return {
      id: indexToId[index],
      align: 'right' as 'right',
      label: (
        <Tooltip title={tooltipTitle}>
          <Tag color={tagColors[index]}>{indexToLabel[index]}</Tag>
        </Tooltip>
      ),

      width: columnWidth,
    }
  })

  return isWeightEnabled
    ? fixedColumns.concat([
        {
          id: 'weighted',
          align: 'right' as 'right',
          label: <Tag color={tagColors[3]}>{indexToLabel[3]}</Tag>,
          width: columnWidth,
        },
      ])
    : fixedColumns
}
