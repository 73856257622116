import React from 'react'
import {EdgeProps, getBezierPath, getEdgeCenter, getMarkerEnd, MarkerType} from 'react-flow-renderer'

import {decimalToPercent} from '@settleindex/domain'

import {EdgeData} from './EdgeData'
import {ForeignObject} from './ForeignObject'
import {Input} from './Input'
import {inputHeight, inputWidth} from './style'

export const editableEdgeType = 'editableEdge'

type Props = Omit<EdgeProps<EdgeData>, 'data'> & {
  data?: EdgeData
}

export const EditableEdge: React.FC<Props> = ({
  data,
  id,
  markerEnd,
  markerStart,
  sourcePosition,
  sourceX,
  sourceY,
  style = {},
  targetPosition,
  targetX,
  targetY,
}) => {
  const weight = data?.targetNode.weight ?? 0

  const computedMarkerEnd = React.useMemo(
    () => getMarkerEnd(markerStart as MarkerType, markerEnd),
    [markerEnd, markerStart],
  )

  const edgePath = React.useMemo(
    () => getBezierPath({sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition}),
    [sourcePosition, sourceX, sourceY, targetPosition, targetX, targetY],
  )

  const foreignObjectPosition = React.useMemo(() => {
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
      sourceX,
      sourceY,
      targetX,
      targetY,
    })

    return {x: edgeCenterX - inputWidth / 2, y: edgeCenterY - inputHeight / 2}
  }, [sourceX, sourceY, targetX, targetY])

  const value = React.useMemo(() => decimalToPercent(weight), [weight])

  return (
    <>
      <path className="react-flow__edge-path" d={edgePath} id={id} markerEnd={computedMarkerEnd} style={style} />
      {
        <ForeignObject
          height={inputHeight}
          requiredExtensions="http://www.w3.org/1999/xhtml"
          width={inputWidth}
          x={foreignObjectPosition.x}
          y={foreignObjectPosition.y}
        >
          <Input $hasManualWeight={false} defaultValue={weight} key={data?.targetNode.id} readOnly value={value} />
        </ForeignObject>
      }
    </>
  )
}
