import {Node} from 'react-flow-renderer'

import {NodeTree} from '../../nodes/tree/NodeTree'
import {noLayoutPosition} from '../layout/noLayoutPosition'
import {outcomeNodeHeight, outcomeNodeType, outcomeNodeWidth} from './OutcomeNode'

interface Args {
  levelIndex: number
  node: NodeTree
}

export const makeOutcomeElement = ({levelIndex, node}: Args): Node => {
  const data = {
    ...node,
    height: outcomeNodeHeight,
    label: node.name,
    levelIndex,
    width: outcomeNodeWidth,
  }

  return {
    data,
    id: node.id,
    position: noLayoutPosition,
    type: outcomeNodeType,
  }
}
