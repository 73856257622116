import * as React from 'react'

import {Form, H1, PageHeaderLeftRight} from '@settleindex/react'

import {PatchUserMutationVariables, UserResponse} from '../../../graphQLTypes'
import {Content} from '../../../page/'
import {paths} from '../../../routing/paths'
import {fieldNames, userEditFields} from '../../../user/userEditFields'
import {adminBreadcrumbs} from '../../adminBreadcrumbs'
import {FormLayout} from '../create/FormLayout'

interface Props {
  loading: boolean
  onSubmit: (user: PatchUserMutationVariables) => void
  user?: UserResponse | null
}

export const UserEditPage: React.FC<Props> = ({loading, onSubmit, user}) => {
  const [form] = Form.useForm()
  const fields = userEditFields({submitLabel: 'Save User', required: {[fieldNames.phone]: false}})
  const initialValues = {...user, phone: user?.phone?.replace('+', '')}

  return (
    <Content breadcrumbs={adminBreadcrumbs(paths.userAdmin(), paths.userAdminEditPattern)} loading={loading}>
      <PageHeaderLeftRight left={<H1>Edit User</H1>} right={<></>} />
      {user && (
        <Form form={form} initialValues={initialValues} name="userEdit" onFinish={onSubmit}>
          {fields.id}
          <FormLayout fields={fields} />
        </Form>
      )}
    </Content>
  )
}
