import * as React from 'react'

import {GreenHighlight, PurpleHighlight, RedHighlight} from '@settleindex/react'

import {Maybe} from '../graphQLTypes'
import {SumFormat} from './SumFormat'

interface BaseSumHighlightProps {
  missing: React.FunctionComponent<React.PropsWithChildren<unknown>>
  negative: React.FunctionComponent<React.PropsWithChildren<unknown>>
  positive: React.FunctionComponent<React.PropsWithChildren<unknown>>
  sum?: Maybe<number>
  testId?: string
}

const BaseSumHighlight: React.FC<BaseSumHighlightProps> = ({
  missing: Missing,
  negative: Negative,
  positive: Positive,
  sum,
  testId,
}) => {
  if (!sum) {
    return <Missing data-test-id={testId}>-</Missing>
  }

  return sum >= 0 ? (
    <Positive data-test-id={testId}>
      <SumFormat sum={sum} />
    </Positive>
  ) : (
    <Negative data-test-id={testId}>
      <SumFormat sum={sum} />
    </Negative>
  )
}

type SumHighlightProps = Pick<BaseSumHighlightProps, 'sum' | 'testId'>

export const SumHighlight: React.FC<SumHighlightProps> = (props) => {
  return <BaseSumHighlight missing={PurpleHighlight} negative={RedHighlight} positive={GreenHighlight} {...props} />
}
