import * as React from 'react'
import {useMemo} from 'react'

import {Part36Offer, Party, PartyId, validOfferParties} from '@settleindex/domain'
import {Col, FormItem, PrimaryText, RadioButton, RadioGroup, Row, Select, Switch, VSpaceSmall} from '@settleindex/react'

import {DebugFormItem} from '../debug'
import {useSafeDispute} from '../dispute/context/useSafeDispute'
import {Role} from '../graphQLTypes'
import {HelpTitleWithTooltip} from '../help/HelpTitleWithTooltip'
import {PerspectiveAwareSumInput} from '../party/perspectiveAwareColors/PerspectiveAwareSumInput'
import {PerspectiveAwareText} from '../party/perspectiveAwareColors/PerspectiveAwareText'
import {SumInput} from '../sum/sumInput'
import {gutter, nameSpan, sumSpan} from './columnWidths'
import {ReadOnlyWrapper} from './ReadOnlyWrapper'

// aka Part 36 Offers
export const ValueBasedCostAwards: React.FC<{mode: Part36Offer['mode']}> = ({mode}) => {
  const {currencySymbol, dispute} = useSafeDispute()
  const perspectiveIsClaimant = dispute.perspectiveRole === Role.Claimant
  const perspectiveIsDefendant = dispute.perspectiveRole === Role.Defendant
  const customFieldsStyle = useMemo(() => {
    return mode === 'auto' ? {display: 'none'} : {}
  }, [mode])

  const offerByOptions = React.useMemo(
    () =>
      validOfferParties({
        claimantId: dispute.claimant.id as PartyId,
        parties: dispute.parties as Party[],
      }).map((p) => ({
        label: p.label,
        value: p.id,
      })),
    [dispute.claimant.id, dispute.parties],
  )

  return (
    <ReadOnlyWrapper>
      <HelpTitleWithTooltip
        id={'casebot_value_based_cost_awards_overview'}
        left="Value based Cost Awards"
        leftId="casebot_value_based_cost_awards_title"
      />

      <VSpaceSmall />

      <Row align="bottom" gutter={gutter}>
        <Col span={nameSpan}>
          <FormItem>
            <PrimaryText>Enabled</PrimaryText>
          </FormItem>
        </Col>
        <Col span={nameSpan}>
          <FormItem label="" name={['part36', 'enabled']} rules={[{required: false}]} valuePropName="checked">
            <Switch />
          </FormItem>
        </Col>
      </Row>

      <Row align="bottom" gutter={gutter}>
        <Col span={nameSpan}>
          <FormItem>
            <PrimaryText>Offer by</PrimaryText>
          </FormItem>
        </Col>
        <Col span={nameSpan}>
          <FormItem label="" name={['part36', 'offerBy']} rules={[{required: false}]}>
            <Select options={offerByOptions} style={{width: '100%'}} />
          </FormItem>
        </Col>
      </Row>

      <HelpTitleWithTooltip
        id={'casebot_value_based_cost_awards_mode_overview'}
        left="Enable Auto Mode for simplified calculations"
        leftId="casebot_value_based_cost_awards_mode_title"
      />
      <Row align="bottom" gutter={gutter}>
        <Col span={nameSpan}>
          <FormItem>
            <PrimaryText>Set Mode</PrimaryText>
          </FormItem>
        </Col>
        <Col span={nameSpan}>
          <FormItem label="" name={['part36', 'mode']} rules={[{required: false}]}>
            <RadioGroup style={{display: 'flex', width: '100%'}}>
              <RadioButton value={'custom'}>Custom</RadioButton>
              <RadioButton value={'auto'}>Auto</RadioButton>
            </RadioGroup>
          </FormItem>
        </Col>
      </Row>

      {/*These fields are hidden when mode = auto*/}
      <span style={customFieldsStyle}>
        <Row align="bottom" gutter={gutter}>
          <Col span={nameSpan}>
            <FormItem>
              <PrimaryText>Offer amount</PrimaryText>
            </FormItem>
          </Col>
          <Col span={sumSpan}>
            <DebugFormItem label="" name={['part36', 'offerAmount']} title={`caseBot.part36.offerAmount`}>
              <SumInput prefix={currencySymbol} style={{paddingRight: 10}} />
            </DebugFormItem>
          </Col>
        </Row>

        <Row align="bottom" gutter={gutter}>
          <Col span={nameSpan}>
            <FormItem>
              <PerspectiveAwareText isPerspective={perspectiveIsClaimant}>Claimant costs at offer</PerspectiveAwareText>
            </FormItem>
          </Col>
          <Col span={sumSpan}>
            <DebugFormItem
              isPerspective={perspectiveIsClaimant}
              label=""
              name={['part36', 'claimantCostsAtOffer']}
              title={`caseBot.part36.claimantCostsAtOffer`}
            >
              <PerspectiveAwareSumInput
                isPerspective={perspectiveIsClaimant}
                prefix={currencySymbol}
                style={{paddingRight: 10}}
              />
            </DebugFormItem>
          </Col>
        </Row>
        <Row align="bottom" gutter={gutter}>
          <Col span={nameSpan}>
            <FormItem>
              <PerspectiveAwareText isPerspective={perspectiveIsDefendant}>
                Defendant costs at offer
              </PerspectiveAwareText>
            </FormItem>
          </Col>
          <Col span={sumSpan}>
            <DebugFormItem
              label=""
              name={['part36', 'defendantCostsAtOffer']}
              title={`caseBot.part36.defendantCostsAtOffer`}
            >
              <PerspectiveAwareSumInput
                isPerspective={perspectiveIsDefendant}
                prefix={currencySymbol}
                style={{paddingRight: 10}}
              />
            </DebugFormItem>
          </Col>
        </Row>
      </span>
    </ReadOnlyWrapper>
  )
}
