import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {usePatchVersionMutation, VersionPatchInput} from '../../graphQLTypes'

export const usePatchVersion = () => {
  const [patchVersionMutation] = usePatchVersionMutation()
  const {runAsync} = useAppIsLoading()

  return (versionId: string, patch: VersionPatchInput) =>
    runAsync(() => patchVersionMutation({variables: {versionId, patch}}))
}
