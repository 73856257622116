import * as React from 'react'

import {allFalse} from '@settleindex/fp'
import {DotButton, DotDotDot, Popconfirm} from '@settleindex/react'

import {Lifecycle} from '../../graphQLTypes'

interface Props {
  archive?: () => void
  isExternalMember: boolean
  lifecycle: Lifecycle
  toDraft?: () => void
  toReport?: () => void
  userIsVersionOwnerOrAdminMember: boolean
}

export const VersionMenu: React.FC<Props> = ({
  archive,
  isExternalMember,
  lifecycle,
  toDraft,
  toReport,
  userIsVersionOwnerOrAdminMember,
}) => {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false)
  const isPublishedReport = lifecycle === Lifecycle.Report
  const showArchive = userIsVersionOwnerOrAdminMember
  const showToDraft = isPublishedReport && userIsVersionOwnerOrAdminMember
  const showToReport = !isPublishedReport && userIsVersionOwnerOrAdminMember
  const menuDisabled = isExternalMember || allFalse(showArchive, showToDraft)

  const menuItems = React.useMemo(() => {
    const archiveMenuItem = {
      key: 'archiveMenuItem',
      label: (
        <Popconfirm
          cancelText="No"
          okText="Yes"
          onCancel={() => {
            setMenuOpen(false)
          }}
          onConfirm={() => {
            archive && archive()
            setMenuOpen(false)
          }}
          title="Are you sure you want to delete this model?"
        >
          <DotButton>Delete</DotButton>
        </Popconfirm>
      ),
    }

    const makeDraftMenuItem = {
      key: 'makeDraftMenuItem',
      onClick: () => {
        toDraft && toDraft()
      },
      label: 'Unpublish',
    }

    const makeReportMenuItem = {
      key: 'makeReportMenuItem',
      onClick: () => {
        toReport && toReport()
      },
      label: 'Publish',
    }

    const m = []
    showToDraft && m.push(makeDraftMenuItem)
    showToReport && m.push(makeReportMenuItem)
    showArchive && m.push(archiveMenuItem)

    return m
  }, [archive, showArchive, showToDraft, showToReport, toDraft, toReport])

  return !menuItems.length ? (
    <div style={{width: 40}} />
  ) : (
    <div style={{marginLeft: 10}}>
      <DotDotDot disabled={menuDisabled} menu={{items: menuItems}} onOpenChange={setMenuOpen} open={menuOpen} />
    </div>
  )
}
