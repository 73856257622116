import styled from 'styled-components'

import {colors, secondaryNavHeight, theme} from '@settleindex/react'

export const SecondaryNavLayout = styled.div`
  background-color: white;
  height: ${secondaryNavHeight}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  padding-left: 60px;
  border-bottom: 1px solid ${theme.separator.color};

  & > div {
    padding: 0;
    width: 100%;
  }

  & .ant-breadcrumb-separator {
    color: ${colors.base4} !important;
    opacity: 0.65;
  }

  & .ant-breadcrumb-link {
    font-weight: ${theme.navigation.weight};
  }

  & .ant-breadcrumb-link[data-last='yes'],
  & .ant-breadcrumb-link[data-last='yes'] a {
    color: ${colors.base4} !important;
  }

  & .ant-breadcrumb-link[data-last='no'],
  & .ant-breadcrumb-link[data-last='no'] a {
    color: ${colors.base6} !important;
  }
`
