import * as React from 'react'

import {Role} from '@settleindex/domain'
import {cond} from '@settleindex/fp'
import {Tag} from '@settleindex/react'

import {Tip} from '../../../help'

export type PayReceiveTag = (amount: number) => React.ReactElement

export const PayReceiveModeTag: React.FC<{mode: 'pay' | 'receive'}> = ({mode}) => (
  <Tip id="Outcomes.PayReceiveTag">
    <Tag color={mode === 'pay' ? 'red' : 'green'} style={{width: 45, marginRight: 14}}>
      {mode === 'pay' ? 'Loss' : 'Gain'}
    </Tag>
  </Tip>
)

const PayTag = <PayReceiveModeTag mode="pay" />
const ReceiveTag = <PayReceiveModeTag mode="receive" />
const NoTag = <></>

export const makePayReceiveTag =
  (partyRole: Role): PayReceiveTag =>
  (amount: number): React.ReactElement => {
    const isClaimant = partyRole === Role.Claimant

    return cond([
      [() => !amount, () => NoTag],
      [() => amount > 0 && isClaimant, () => ReceiveTag],
      [() => amount < 0 && isClaimant, () => PayTag],
      [() => amount < 0 && !isClaimant, () => ReceiveTag],
      [() => amount > 0 && !isClaimant, () => PayTag],
    ])()
  }
