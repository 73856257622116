import {VersionPageTabKey} from '../version/page/tabs/VersionPageTabKey'

export const paths = {
  home: () => '/',

  admin: () => '/admin',
  userAdmin: () => '/admin/user',
  userAdminCreate: () => '/admin/user/create',
  userAdminEdit: (id: string) => `/admin/user/edit/${id}`,
  userAdminEditPattern: `/admin/user/edit/:id`,

  login: '/',

  disputes: () => '/',
  dispute: (id: string) => `/dispute/${id}/overview`,
  disputePattern: `/dispute/:disputeId/overview`,
  disputeRootPattern: `/dispute/:disputeId`,

  disputeSettings: (disputeId: string) => `/dispute/${disputeId}/settings`,
  disputeSettingsPattern: '/dispute/:id/settings',

  newDisputePattern: '/newdispute',

  disputeSharingList: (disputeId: string) => `/dispute/${disputeId}/access`,
  disputeSharingListPattern: `/dispute/:id/access`,

  disputeEditOrViewEventPattern: '/dispute/:disputeId/events/:eventId/:mode',
  disputeEditOrViewEvent: (disputeId: string, eventId: string, mode: 'edit' | 'view') =>
    `/dispute/${disputeId}/events/${eventId}/${mode}`,
  disputeNewEventPattern: '/dispute/:id/events/new',
  disputeNewEvent: (disputeId: string) => `/dispute/${disputeId}/events/new`,
  disputeEventsPattern: '/dispute/:id/events',
  disputeEvents: (disputeId: string) => `/dispute/${disputeId}/events`,

  disputeMetricsPattern: '/dispute/:disputeId/history',
  disputeMetrics: (disputeId: string) => `/dispute/${disputeId}/history`,

  versionCompare: (disputeId: string) => `/dispute/${disputeId}/compare`,
  versionComparePattern: `/dispute/:disputeId/compare`,

  version: (disputeId: string, versionId: string, tabKey: VersionPageTabKey = 'casebot', subTab: string = 'issues') =>
    `/dispute/${disputeId}/version/${versionId}/${tabKey}/${subTab}`,
  versionPattern: '/dispute/:disputeId/version/:versionId/:activeTab?/:subTab?',
}

export interface VersionPagePaths {
  activeTab?: string
  disputeId: string
  subTab?: string
  versionId: string
}
