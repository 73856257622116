import {atom, useRecoilState} from 'recoil'

const debugPanelVisibleState = atom<boolean>({
  default: false,
  key: 'debugPanelState',
})

const debugPanelContentState = atom<any>({
  default: null,
  key: 'debugPanelContentState',
})

export const useDebugPanel = () => {
  const [isDebugPanelVisible, setIsDebugPanelVisible] = useRecoilState(debugPanelVisibleState)
  const [debugPanelContent, setDebugPanelContent] = useRecoilState(debugPanelContentState)

  const toggleDebugPanel = () => {
    setIsDebugPanelVisible((val) => !val)
  }

  return {
    toggleDebugPanel,
    debugPanelContent,
    isDebugPanelVisible,
    setDebugPanelContent,
    setIsDebugPanelVisible,
  }
}
