import {TableColumn} from '@settleindex/react'

import {TeamTableRows} from './TeamTableRows'

export const teamTableColumns: TableColumn<TeamTableRows>[] = [
  {
    align: 'left',
    id: 'name',
    label: 'Name',
    width: '35%',
  },
  {
    align: 'left',
    id: 'email',
    label: 'Email',
    width: '35%',
  },
  {
    align: 'left',
    id: 'access',
    label: 'Access',
    width: '20%',
  },
  {
    align: 'right',
    id: 'actions',
    label: 'Actions',
  },
]
