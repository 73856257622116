import {FormInstance} from '@settleindex/react'

import {IndexedPartyFragment} from '../../graphQLTypes'
import {makeCustomCosts} from '../graph/convertCaseBotFromGraph'
import {CaseBotFormValues} from './CaseBotFormValues'

interface SetCustomCostsInFormParams {
  form: FormInstance
  partiesInDispute: IndexedPartyFragment[]
  values: CaseBotFormValues
}

/**
 * Ensure there is a record for each issue the user creates
 */
export const setCustomCostsInForm = ({form, partiesInDispute, values}: SetCustomCostsInFormParams) => {
  const newCustomCosts = makeCustomCosts({
    existingCustomCosts: Object.values(values.customCosts).flat(),
    loseFinals: values.loseFinals ?? [],
    partiesInDispute,
    winFinals: values.winFinals ?? [],
  })

  form.setFieldValue('customCosts', newCustomCosts)
}
