import * as React from 'react'
import {useParams} from 'react-router-dom'

import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData, message} from '@settleindex/react'

import {useDisputeTeam} from '../../disputeTeam/state/useDisputeTeam'
import {
  DisputeFragment,
  useArchiveVersionMutation,
  useDisputeQuery,
  useDuplicateVersionMutation,
} from '../../graphQLTypes'
import {LoadingIndicatorWithNav} from '../../page'
import {paths, useHistory} from '../../routing'
import {useAuthenticatedUser} from '../../user/useUser/useAuthenticatedUser'
import {useAddVersionAndRedirect} from '../../version/graph/useAddVersion'
import {usePatchVersion} from '../../version/graph/usePatchVersion'
import {useDisputeContainer} from '../context/useDisputeContainer'
import {DisputeNotFound} from '../DisputeNotFound'
import {disputeNotFoundGraphError} from '../graph/disputeErrorGuards'
import {VersionList} from '../versionList/VersionList'
import {DisputeDashboardPage} from './DisputeDashboardPage'

interface Match {
  disputeId: string
}

const DisputeDashboardContainer: React.FC = () => {
  const {
    user: {id: userId},
  } = useAuthenticatedUser()
  const {disputeId} = useParams<Match>()
  const {gotoTop} = useHistory()

  const addEmptyVersion = useAddVersionAndRedirect(disputeId)
  const patchVersion = usePatchVersion()
  const [archiveVersionMutation, archiveMutationState] = useArchiveVersionMutation()
  const archiveVersion = React.useCallback(
    async (versionId: string): Promise<void> => {
      await archiveVersionMutation({
        variables: {
          disputeId,
          versionId,
        },
      })
    },
    [archiveVersionMutation, disputeId],
  )
  const [duplicateVersionMutation, duplicateMutationState] = useDuplicateVersionMutation()
  const duplicateVersion = React.useCallback(
    async (versionId: string): Promise<void> => {
      try {
        const response = await duplicateVersionMutation({
          variables: {
            disputeId,
            versionId,
          },
        })

        message.success('Model Duplicated')

        const newVersion = response.data?.duplicateVersion.versions[0]
        newVersion && gotoTop(paths.version(disputeId, newVersion.id))
      } catch (mutationError) {
        message.error('Please try again')

        throw mutationError
      }
    },
    [disputeId, duplicateVersionMutation, gotoTop],
  )

  const disputeQueryState = useDisputeQuery({variables: {id: disputeId}})

  const {dispute: disputeInContext} = useDisputeContainer({
    requiredId: disputeId,
    setDisputeFrom: disputeQueryState.data?.dispute,
  })

  useDisputeTeam({setDisputeTeamFrom: disputeQueryState.data?.disputeTeam})

  const whenAvailable = React.useCallback(() => {
    const dispute = disputeInContext as DisputeFragment
    const versionList = (
      <VersionList
        addEmptyVersion={addEmptyVersion}
        archiveVersion={archiveVersion}
        dispute={dispute}
        duplicateVersion={duplicateVersion}
        patchVersion={patchVersion}
        userId={userId}
      />
    )

    return <DisputeDashboardPage versionList={versionList} />
  }, [addEmptyVersion, archiveVersion, disputeInContext, duplicateVersion, patchVersion, userId])

  const state = React.useMemo(
    () => ({
      available: isDefined(disputeInContext),
      empty: disputeNotFoundGraphError(disputeQueryState.error),
      error:
        (disputeQueryState.error && !disputeNotFoundGraphError(disputeQueryState.error)) ||
        archiveMutationState.error ||
        duplicateMutationState.error,
      loading: disputeQueryState.loading || archiveMutationState.loading || duplicateMutationState.loading,
    }),
    [
      archiveMutationState.error,
      archiveMutationState.loading,
      disputeInContext,
      disputeQueryState.error,
      disputeQueryState.loading,
      duplicateMutationState.error,
      duplicateMutationState.loading,
    ],
  )

  return (
    <LoadingData
      state={state}
      whenAvailable={whenAvailable}
      whenEmpty={<DisputeNotFound />}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicatorWithNav />}
    />
  )
}

DisputeDashboardContainer.displayName = 'DisputeDashboardContainer'

export default DisputeDashboardContainer
