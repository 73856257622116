import * as React from 'react'
import {useParams} from 'react-router-dom'

import {isDefined} from '@settleindex/fp'
import {Ambulance, CenterLine, LoadingData, LoadingIndicator} from '@settleindex/react'

import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {DisputeNotFound} from '../../dispute/DisputeNotFound'
import {disputeNotFoundGraphError} from '../../dispute/graph/disputeErrorGuards'
import {DisputeFragment} from '../../graphQLTypes'
import {VersionPagePaths} from '../../routing'
import {useVersionContainer} from '../../version/context/useVersionContainer'
import {useVersionGraph} from '../../version/graph/useVersionGraph'
import {versionNotFoundGraphError} from '../../version/graph/versionErrorGuards'
import {VersionNotFound} from '../../version/VersionNotFound'
import {AnalysisSection} from './AnalysisSection'

interface AnalyseContainerProps {}

export const AnalyseContainer: React.FC<AnalyseContainerProps> = () => {
  const {disputeId, versionId} = useParams<VersionPagePaths>()
  const {dispute: graphDispute, error, loading, version: graphVersion} = useVersionGraph({disputeId, versionId})
  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: graphDispute})
  const {version: versionInContext} = useVersionContainer({
    requiredId: versionId,
    setVersionFrom: graphVersion,
  })

  const disputeNotFound = React.useMemo(() => disputeNotFoundGraphError(error), [error])
  const versionNotFound = React.useMemo(
    () => versionNotFoundGraphError(error) || graphDispute?.version === null,
    [error, graphDispute?.version],
  )

  const whenEmpty = React.useCallback(
    () => (disputeNotFound ? <DisputeNotFound /> : <VersionNotFound dispute={disputeInContext as DisputeFragment} />),
    [disputeInContext, disputeNotFound],
  )

  const whenAvailable = React.useCallback(() => <AnalysisSection />, [])

  const state = React.useMemo(() => {
    const available = isDefined(versionInContext)
    const empty = disputeNotFound || versionNotFound

    return {
      available,
      empty,
      error,
      loading,
    }
  }, [disputeNotFound, error, loading, versionInContext, versionNotFound])
  const whenError = React.useMemo(() => <Ambulance />, [])
  const whenLoading = React.useMemo(
    () => (
      <CenterLine>
        <LoadingIndicator />
      </CenterLine>
    ),
    [],
  )

  return (
    <LoadingData
      state={state}
      whenAvailable={whenAvailable}
      whenEmpty={whenEmpty}
      whenError={whenError}
      whenLoading={whenLoading}
    />
  )
}
