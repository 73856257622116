import * as React from 'react'
import {atom, useRecoilState} from 'recoil'

import {useSafeDispute} from '../../dispute/context/useSafeDispute'
import {IndexedPartyFragment, OpponentValueFragment} from '../../graphQLTypes'
import {useSafeVersion} from '../../version/context/useSafeVersion'

const settlementOpponentIdAtom = atom<string | undefined>({
  key: 'settlementOpponentIdAtom',
  default: undefined,
})

export const useSettlementAnalysis = () => {
  const {
    dispute: {opponents},
  } = useSafeDispute()

  const {
    version: {opponentValues},
  } = useSafeVersion()

  const [settlementOpponentId, setSettlementOpponentId] = useRecoilState(settlementOpponentIdAtom)

  const settlementOpponentIdWithValue = React.useMemo(
    () => settlementOpponentId ?? opponents[0]?.id,
    [opponents, settlementOpponentId],
  )

  const settlementOpponent: IndexedPartyFragment = React.useMemo(
    () => opponents.find((o) => o.id === settlementOpponentIdWithValue) ?? opponents[0],
    [opponents, settlementOpponentIdWithValue],
  )

  const settlementOpponentValues: OpponentValueFragment | undefined = React.useMemo(
    () => opponentValues.find((o) => o.partyId === settlementOpponentIdWithValue),
    [opponentValues, settlementOpponentIdWithValue],
  )

  return {
    setSettlementOpponentId,
    settlementOpponent,
    settlementOpponentValues,
  }
}

export const useRestSettlementAnalysis = () => {
  const [_, setSettlementOpponentId] = useRecoilState(settlementOpponentIdAtom)

  React.useEffect(() => {
    setSettlementOpponentId(undefined)
  }, [setSettlementOpponentId])
}
