import * as React from 'react'

import {DotDotDot, Link, Popconfirm} from '@settleindex/react'

interface Props {
  onDeleteEvent: () => void
}

export const EventMenu: React.FC<Props> = ({onDeleteEvent}) => {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false)

  const menu = {
    items: [
      {
        key: 'delete',
        label: (
          <Popconfirm
            cancelText="No"
            okText="Yes"
            onCancel={() => {
              setMenuOpen(false)
            }}
            onConfirm={() => {
              onDeleteEvent()
              setMenuOpen(false)
            }}
            title="Are you sure you want to delete this offer?"
          >
            <Link to="#">Remove Event</Link>
          </Popconfirm>
        ),
      },
    ],
  }

  return <DotDotDot menu={menu} onOpenChange={setMenuOpen} open={menuOpen} />
}
