import * as React from 'react'

import {FormItem, TextInput} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {partyFieldNames} from './partyFieldNames'

export const PartyName: React.FC<{}> = () => {
  return (
    <FormItem label="Name / Company Name" markAs="optional" name={partyFieldNames.name} rules={[{required: false}]}>
      <TextInput autoComplete="off" data-test-id={disputeFormTestIds.editPartyName} placeholder="e.g. Walmart Inc." />
    </FormItem>
  )
}
