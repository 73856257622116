import * as React from 'react'

import {isDefined} from '@settleindex/fp'
import {PurpleHighlight} from '@settleindex/react'

interface Props {
  percent?: number | null
  testId?: string
}

export const PercentHighlight: React.FC<Props> = ({percent, testId}) => (
  <PurpleHighlight data-test-id={testId}>
    {!isDefined(percent) ? '-' : `${(percent * 100).toFixed(0)}%`}
  </PurpleHighlight>
)
