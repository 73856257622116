import * as React from 'react'

import {Currency as DomainCurrency, currencyData} from '@settleindex/domain'
import {FormItem, SecondarySelect} from '@settleindex/react'

import {fieldNames} from './fieldNames'

export const Currency = () => (
  <FormItem
    label="Currency"
    name={fieldNames.currency}
    rules={[
      {
        required: true,
        message: 'Please select the currency of the case',
      },
    ]}
  >
    <SecondarySelect
      data-test-id={`currency`}
      options={Object.entries(DomainCurrency).map(([key, value]) => {
        const label = value === DomainCurrency.xxx ? 'None / Other' : `${value} (${currencyData.get(value)?.[1]})`

        return {
          label,
          value: key,
        }
      })}
      placeholder="Select..."
      style={{
        width: '100%',
      }}
    />
  </FormItem>
)
