import * as React from 'react'

import {Link} from '@settleindex/react'

import {DisputeFragment, VersionFragment} from '../graphQLTypes'
import {paths} from '../routing'

const isE = (e: React.ReactElement | undefined): e is React.ReactElement => typeof e !== 'undefined'

interface Props {
  dispute?: DisputeFragment
  disputeTab?: React.ReactElement
  version?: VersionFragment
}

export const disputeEventsListLink = (disputeId: string) => <Link to={paths.disputeEvents(disputeId)}>Offers</Link>
export const disputeSettingsLink = (disputeId: string) => <Link to={paths.disputeSettings(disputeId)}>Settings</Link>
export const disputeTeamLink = (disputeId: string) => <Link to={paths.disputeSharingList(disputeId)}>Team</Link>
export const versionCompareLink = (disputeId: string) => <Link to={paths.versionCompare(disputeId)}>Compare</Link>
export const disputeHistoryListLink = (disputeId: string) => <Link to={paths.disputeMetrics(disputeId)}>History</Link>

export const disputeBreadcrumbs = (props?: Props): React.ReactElement[] => {
  const dispute = props?.dispute
  const version = props?.version

  return [
    <Link key={1} to={paths.disputes()}>
      Cases
    </Link>,
    dispute && <Link to={paths.dispute(dispute.id)}>{dispute.title}</Link>,
    dispute && props?.disputeTab && props.disputeTab,
    dispute && version && <Link to={paths.version(dispute.id, version.id)}>{version.title}</Link>,
  ].filter(isE)
}
