import {selectorFamily} from 'recoil'

import {Maybe} from '@settleindex/domain'
import {cond, equals} from '@settleindex/fp'

import {partyCaseValueSelector} from '../../../version/usePartyCaseValues/partyCaseValueSelector'
import {nodeValueToDisplayAtom} from '../atoms/nodeValueToDisplayAtom'

export const caseValueSumSelector = selectorFamily<Maybe<number>, Maybe<string>>({
  key: 'caseValueSumSelector',
  get:
    (partyId?: string) =>
    ({get}) => {
      const valueToDisplay = get(nodeValueToDisplayAtom)
      const partyCaseValues = get(partyCaseValueSelector(partyId))

      return cond<string, Maybe<number>>([
        [equals('valueGross'), () => partyCaseValues?.caseValueGross],
        [equals('valueNet'), () => partyCaseValues?.caseValueNet],
        [equals('valuePresent'), () => partyCaseValues?.caseValuePresent],
        [equals('valueForecast'), () => partyCaseValues?.caseValueForecast],
      ])(valueToDisplay)
    },
})
