import * as React from 'react'

import {Breadcrumb, Col, contentHorizontalPadding, LoadingIndicator, Row} from '@settleindex/react'

import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {SecondaryNavLayout} from './SecondaryNavLayout'

interface Props {
  breadcrumbs?: React.ReactElement[]
  loading?: boolean
  tools?: React.ReactElement[]
}

export const SecondaryNav: React.FC<Props> = ({breadcrumbs = [], loading, tools = []}) => {
  const {loading: appIsLoading} = useAppIsLoading()
  const isLoading = React.useMemo(() => appIsLoading || loading, [appIsLoading, loading])
  const finalTools = React.useMemo(
    () => (isLoading ? [<LoadingIndicator key="i" />, ...tools] : tools),
    [isLoading, tools],
  )

  return (
    <SecondaryNavLayout data-test-id="SecondaryNav">
      <Row align="middle" justify="start">
        <Col span={16}>
          <Breadcrumb>
            {breadcrumbs.map((b, i) => (
              <Breadcrumb.Item data-last={i + 1 < breadcrumbs.length ? 'no' : 'yes'} key={i}>
                {b}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Col>
        <Col
          span={8}
          style={{
            textAlign: 'right',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: contentHorizontalPadding,
          }}
        >
          {finalTools.map((t, i) => (
            <span key={i} style={{marginLeft: 10}}>
              {t}
            </span>
          ))}
        </Col>
      </Row>
    </SecondaryNavLayout>
  )
}
