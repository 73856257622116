import * as React from 'react'

import {NoData, PrimaryButton} from '@settleindex/react'
import {claimsTableTestIds} from '@settleindex/testids/src/testIds'

interface Props {
  addClaimGroup: () => void
  disabled: boolean
}

export const NoClaimGroups: React.FC<Props> = ({addClaimGroup, disabled}) => (
  <span data-test-id={claimsTableTestIds.noClaimGroups}>
    <NoData
      button={
        <PrimaryButton
          data-test-id={claimsTableTestIds.createClaimGroupButton}
          disabled={disabled}
          medium
          onClick={addClaimGroup}
        >
          Add Claim Group
        </PrimaryButton>
      }
      heading={`Claims`}
      text="Specify the claims in dispute here. Claims are
      managed centrally and can then be adjusted in each outcome to reflect the
      damages payable or receivable in the outcome. Claims are organised by
      group, so to get started, add your first claim group."
    />
  </span>
)
