import * as React from 'react'

import {combinedDefendantPartyId, DisputeClassification, setById} from '@settleindex/domain'
import {
  Col,
  Divider,
  Form,
  FormInstance,
  FormItem,
  H2,
  LeftRight,
  SecondaryButton,
  TextInput,
  VSpaceLarge,
  VSpaceMedium,
  VSpaceSmall,
} from '@settleindex/react'

import {PartyFragment} from '../../graphQLTypes'
import {Classification} from './Classification'
import {Currency} from './Currency'
import {Description} from './Description'
import {DisputeStartedOnDateInput} from './DisputeStartedOnDateInput'
import {fieldNames} from './fieldNames'
import {InsuranceSection} from './insurance/InsuranceSection'
import {Jurisdiction} from './Jurisdiction'
import {GroupSection} from './party/GroupSection'
import {partyFormName} from './party/PartyForm'
import {PartyInForm} from './party/PartyInForm'
import {Perspective} from './Perspective'
import {Reference} from './Reference'
import {Row} from './Row'

const notCombinedDefendantParty = (party: PartyFragment) => party.id !== combinedDefendantPartyId

const getParties = (parties?: PartyInForm[]): {claimant?: PartyInForm; defendants?: PartyInForm[]} => ({
  claimant: parties?.find((p) => p.type === 'claimant'),
  defendants: parties?.filter((p) => p.type === 'defendant'),
})

interface Props {
  disputeClassifications: DisputeClassification[]
  form: FormInstance<any>
  onCancel?: () => void
  onChange: () => void
  onFinish?: () => void
  partiesForPerspective?: PartyInForm[]
  primaryButton: React.ReactElement
  titleInput: React.ReactElement
  width?: number
}

export const FormLayout: React.FC<Props> = ({
  disputeClassifications,
  form,
  onCancel,
  onChange,
  onFinish,
  partiesForPerspective: partiesForPerspectiveWithCombinedDefendants = [],
  primaryButton,
  titleInput,
  width = '100%',
}) => {
  const removePartyAndNotifyChange = (id: string) => {
    form.setFieldsValue({
      [fieldNames.parties]: form.getFieldValue(fieldNames.parties).filter((party: PartyInForm) => party.id !== id),
    })
    onChange()
  }
  const partiesForPerspective = partiesForPerspectiveWithCombinedDefendants.filter(notCombinedDefendantParty)
  const parties = getParties(partiesForPerspective)
  const claimantSection = (
    <GroupSection
      add={onChange}
      addButtonLabel="Add Claimant"
      limit={1}
      parties={parties.claimant ? [parties.claimant] : undefined}
      remove={removePartyAndNotifyChange}
      sectionTitle="Claimant"
      type="claimant"
    />
  )
  const opponentSection = (
    <GroupSection
      add={onChange}
      addButtonLabel="Add Defendant"
      limit={99}
      parties={parties.defendants ?? []}
      partyIndexStart={1}
      remove={removePartyAndNotifyChange}
      sectionTitle="Defendants & Third Parties"
      type="defendant"
    />
  )

  const hiddenFieldToHoldPartyData = (
    <FormItem hidden name={fieldNames.parties} noStyle>
      <TextInput />
    </FormItem>
  )

  return (
    <div style={{width}}>
      <Form.Provider
        onFormFinish={(name, {forms, values}) => {
          if (name === partyFormName) {
            const incomingParty = values
            const existingParties = forms.dispute.getFieldValue(fieldNames.parties)
            const isExistingPartyUpdate = existingParties.some((party: PartyInForm) => party.id === incomingParty.id)
            const updatedPartyList = isExistingPartyUpdate
              ? setById(existingParties, incomingParty as PartyInForm)
              : [...existingParties, incomingParty]
            forms.dispute.setFieldsValue({[fieldNames.parties]: updatedPartyList})
            onChange()
          }
        }}
      >
        <Form form={form} name="dispute" onChange={onChange} onFinish={onFinish}>
          <VSpaceMedium />

          <Row>
            <Col span={16}>{titleInput}</Col>
            <Col span={8}>
              <Reference />
            </Col>
          </Row>

          <VSpaceMedium />
          {claimantSection}
          <VSpaceMedium />
          {opponentSection}
          {hiddenFieldToHoldPartyData}

          <VSpaceLarge />
          <H2>Case Information</H2>

          <VSpaceSmall />

          <Row>
            <Col span={12}>
              <Perspective parties={partiesForPerspective} />
            </Col>
            <Col span={12}>
              <Classification disputeClassifications={disputeClassifications} />
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Jurisdiction />
            </Col>
            <Col span={8}>
              <Currency />
            </Col>
            <Col span={8}>
              <DisputeStartedOnDateInput />
            </Col>
          </Row>
          <Description />

          <VSpaceSmall />
          <Divider />

          <InsuranceSection />

          <Divider />

          <VSpaceLarge />
          <LeftRight
            left={
              <SecondaryButton medium onClick={onCancel}>
                Cancel
              </SecondaryButton>
            }
            right={primaryButton}
          />
        </Form>
      </Form.Provider>
    </div>
  )
}
