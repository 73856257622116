import * as React from 'react'

import {SecondaryLinkOut} from '@settleindex/react'

export const TosLink: React.FC<{}> = () => {
  return <SecondaryLinkOut href="https://settleindex.com/terms/">Terms of Service</SecondaryLinkOut>
}

export const PrivacyLink: React.FC<{}> = () => {
  return <SecondaryLinkOut href="https://settleindex.com/privacy/">Privacy Policy</SecondaryLinkOut>
}
