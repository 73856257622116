import {BarItemProps} from '@nivo/bar'
import {useTheme} from '@nivo/core'
import {useTooltip} from '@nivo/tooltip'
import {animated, to, useSpring} from '@react-spring/web'
import * as React from 'react'

import {cond} from '@settleindex/fp'

import {MetricData} from '../MetricData'

/**
 * The component is an almost verbatim clone from
 * https://github.com/plouc/nivo/blob/master/packages/bar/src/BarItem.tsx
 *
 * The aim of this "fork" is to have fixed width bars and then move the group
 * members next to each other.
 */
export const BarItem = ({
  ariaDescribedBy,
  ariaLabel,
  ariaLabelledBy,
  bar: {data, ...bar},
  borderRadius,
  borderWidth,
  isFocusable,
  isInteractive,
  label,
  onClick,
  onMouseEnter,
  onMouseLeave,
  shouldRenderLabel,
  style: {borderColor, color, height, labelColor, labelOpacity, labelX, labelY},
  tooltip,
}: BarItemProps<MetricData>) => {
  // Visible bar width is the width we want to show as opposed to the width
  // calculated by the Nivo library. The automatic width would fill up the whole
  // chart with bars, i.e. they would be very wide, over 100px.
  const visibleBarWidth = 15

  /**
   * Reposition the bars so that they are next to each other with a small gap
   * between them. @niv/bar would create variable width bars so they cover the
   * whole chart area. We want them to be fixed width, but in turn we need to
   * move them on the X axis, to avoid ugly gaps of variable size.
   *
   * * The 2nd bar is the "anchor": it is repositioned to the middle of it's
   *   original position.
   * * The 1st bar is pushed it's full width to the right, then a half width
   *   (that would put it right on top of bar #2), then moved to the left a bit.
   * * The 3rd bar is pulled to a half width the left (that would put it right
   *   on top of bar #2), then moved to the right a little.
   *
   * The numbers to move the bars 1 and 3 were found by experimenting (25px and
   * 10px)
   */
  const transformCss = React.useMemo(() => {
    const bar1PullLeft = 28
    const bar3PushRight = 15
    const numberOfBarsPerGroup = data.data.numberOfVersions

    return numberOfBarsPerGroup === 3
      ? cond([
          [() => data.id === 'version1', () => `translate(${bar.x + bar.width * 1.5 - bar1PullLeft},${bar.y})`],
          [() => data.id === 'version2', () => `translate(${bar.x + bar.width / 2 - visibleBarWidth / 2},${bar.y})`],
          [() => data.id === 'version3', () => `translate(${bar.x - bar.width / 2 + bar3PushRight},${bar.y})`],
        ])()
      : `translate(${bar.x + bar.width / 2 - visibleBarWidth / 2},${bar.y})`
  }, [bar.width, bar.x, bar.y, visibleBarWidth, data.data.numberOfVersions, data.id])

  const theme = useTheme()
  const {hideTooltip, showTooltipAt, showTooltipFromEvent} = useTooltip()

  const renderTooltip = React.useMemo(() => () => React.createElement(tooltip, {...bar, ...data}), [tooltip, bar, data])

  const handleClick = React.useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      onClick?.({color: bar.color, ...data}, event)
    },
    [bar, data, onClick],
  )
  const handleTooltip = React.useCallback(
    (event: React.MouseEvent<SVGRectElement>) => showTooltipFromEvent(renderTooltip(), event),
    [showTooltipFromEvent, renderTooltip],
  )
  const handleMouseEnter = React.useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      onMouseEnter?.(data, event)
      showTooltipFromEvent(renderTooltip(), event)
    },
    [data, onMouseEnter, showTooltipFromEvent, renderTooltip],
  )
  const handleMouseLeave = React.useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      onMouseLeave?.(data, event)
      hideTooltip()
    },
    [data, hideTooltip, onMouseLeave],
  )

  const handleFocus = React.useCallback(() => {
    showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY])
  }, [showTooltipAt, renderTooltip, bar])
  const handleBlur = React.useCallback(() => {
    hideTooltip()
  }, [hideTooltip])

  const {transform} = useSpring({transform: transformCss})

  return (
    <animated.g transform={transform}>
      <animated.rect
        aria-describedby={ariaDescribedBy ? ariaDescribedBy(data) : undefined}
        aria-label={ariaLabel ? ariaLabel(data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
        //  @ts-ignore
        fill={data.fill ?? color}
        focusable={isFocusable}
        height={to(height, (value: any) => Math.max(value, 0))}
        onBlur={isInteractive && isFocusable ? handleBlur : undefined}
        onClick={isInteractive ? handleClick : undefined}
        onFocus={isInteractive && isFocusable ? handleFocus : undefined}
        onMouseEnter={isInteractive ? handleMouseEnter : undefined}
        onMouseLeave={isInteractive ? handleMouseLeave : undefined}
        onMouseMove={isInteractive ? handleTooltip : undefined}
        rx={borderRadius}
        ry={borderRadius}
        //  @ts-ignore
        stroke={borderColor}
        strokeWidth={borderWidth}
        tabIndex={isFocusable ? 0 : undefined}
        width={visibleBarWidth}
      />
      {shouldRenderLabel && (
        <animated.text
          dominantBaseline="central"
          //  @ts-ignore
          fillOpacity={labelOpacity}
          style={{
            ...theme.labels.text,
            pointerEvents: 'none',
            //  @ts-ignore
            fill: labelColor,
          }}
          textAnchor="middle"
          //  @ts-ignore
          x={labelX}
          //  @ts-ignore
          y={labelY}
        >
          {label}
        </animated.text>
      )}
    </animated.g>
  )
}
