import * as React from 'react'
import {useParams} from 'react-router'

import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData, LoadingIndicator} from '@settleindex/react'

import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {VersionPagePaths} from '../../routing'
import {useVersionContainer} from '../../version/context/useVersionContainer'
import {useVersionGraph} from '../../version/graph/useVersionGraph'
import {useCostGraph} from '../graph/useCostGraph'
import {CostsTable} from './CostsTable'

interface Props {
  defaultPartyId?: string
  hasTabPadding?: boolean
}

export const CostsContainer: React.FC<Props> = ({defaultPartyId, hasTabPadding}) => {
  const {disputeId, versionId} = useParams<VersionPagePaths>()

  const costOperations = useCostGraph({versionId})

  const {dispute, error, loading, version} = useVersionGraph({disputeId, versionId})

  useDisputeContainer({requiredId: disputeId, setDisputeFrom: dispute})
  useVersionContainer({requiredId: versionId, setVersionFrom: version})

  const whenAvailable = React.useCallback(
    () => <CostsTable costOperations={costOperations} defaultPartyId={defaultPartyId} hasTabPadding={hasTabPadding} />,
    [costOperations, defaultPartyId, hasTabPadding],
  )

  const state = React.useMemo(
    () => ({
      available: isDefined(version),
      error,
      loading,
    }),
    [error, loading, version],
  )

  return (
    <LoadingData
      state={state}
      whenAvailable={whenAvailable}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicator />}
    />
  )
}
