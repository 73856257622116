import * as React from 'react'

import {PrimaryText, SecondarySelect} from '@settleindex/react'

import {PartyFragment} from '../../graphQLTypes'

interface Props {
  disabled?: boolean
  onChange: () => void
  parties: PartyFragment[]
  value?: string
}

export const PartySelect: React.FC<Props> = ({disabled = false, onChange, parties, ...props}) =>
  disabled ? (
    <PrimaryText>{parties.find((p) => p.id === props.value)?.label}</PrimaryText>
  ) : (
    <SecondarySelect
      {...props}
      onChange={onChange}
      options={parties.map((party) => ({
        label: party.label,
        value: party.id,
      }))}
    />
  )
