import * as React from 'react'
import styled from 'styled-components'

import {H1, LinkToTop, PrimaryButton2, VSpaceLarge, VSpaceSmall} from '@settleindex/react'

import {DisputeFragment} from '../graphQLTypes'
import {disputeBreadcrumbs} from '../navigation/disputeBreadcrumbs'
import {Content} from '../page/'
import {paths} from '../routing'

interface Props {
  dispute: DisputeFragment
}

export const VersionNotFound: React.FC<Props> = ({dispute}) => {
  const breadCrumbArg = dispute ? {dispute} : {}
  const linkTo = dispute ? paths.dispute(dispute.id) : paths.home()

  return (
    <Content breadcrumbs={disputeBreadcrumbs(breadCrumbArg)}>
      <CenterTop>
        <VSpaceLarge />
        <H1>Model not available</H1>
        <VSpaceLarge />
        <p>You don't have access to this model, or it doesn't exist.</p>
        <VSpaceSmall />
        <LinkToTop to={linkTo}>
          <PrimaryButton2 size="medium">Go to Dashboard</PrimaryButton2>
        </LinkToTop>
      </CenterTop>
    </Content>
  )
}

const CenterTop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`
