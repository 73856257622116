import {Authenticated, useUser} from './useUser'

export const useAuthenticatedUser = (): Authenticated => {
  const user = useUser()

  if (!user.user) {
    throw new Error('User is not authenticated')
  }

  return user
}
