import * as React from 'react'

import {HistoryMetrics} from '@settleindex/domain'
import {H3, LinkToTop, SecondaryButton2} from '@settleindex/react'

import {Currency, DisputeHistoryPointFragment} from '../../graphQLTypes'
import {HistoryChart} from '../history/disputeHistoryChart/HistoryChart'

interface Props {
  currency: Currency
  disputeHistoryWithoutDrafts: DisputeHistoryPointFragment[]
  metricsOnChart: HistoryMetrics[]
  versionListLink: string
}

export const DisputeHistoryChartSection: React.FC<Props> = ({
  currency,
  disputeHistoryWithoutDrafts,
  metricsOnChart,
  versionListLink,
}) => {
  const chartHeaderRight = (
    <LinkToTop to={versionListLink}>
      <SecondaryButton2>View All Models</SecondaryButton2>
    </LinkToTop>
  )

  return (
    <HistoryChart
      currency={currency}
      headerLeft={<H3>Case History</H3>}
      headerRight={chartHeaderRight}
      history={disputeHistoryWithoutDrafts}
      metricsToShow={metricsOnChart}
    />
  )
}
