import {NodeDisplayValue} from './NodeDisplayValue'

export type LabelledNodeProperty = NodeDisplayValue | 'claimsWithInterest' | 'costRecoveryNet'

/**
 * Labels to be used for model level numbers: models have position, but outcomes have values only!
 */
export const versionValueLabels: Record<LabelledNodeProperty, string> = {
  valueForecast: 'Forecast Position',
  valueGross: 'Award Position',
  valueNet: 'Overall Position',
  valuePresent: 'Current Position',
  claimsWithInterest: 'Claims Award',
  costRecoveryNet: 'Costs Award',
}

/**
 * Labels to be used for outcomes: outcomes have values not positions
 */
export const outcomeValueLabels: Record<LabelledNodeProperty, string> = {
  valueForecast: 'Forecast Value',
  valueGross: 'Award Value',
  valueNet: 'Overall Value',
  valuePresent: 'Current Value',
  claimsWithInterest: 'Claims Award',
  costRecoveryNet: 'Costs Award',
}

export const outcomeValueDescriptions: Record<LabelledNodeProperty, string> = {
  valueForecast: 'Current Value adjusted for forecast costs',
  valueGross: 'Amount transferred between parties',
  valueNet: 'Overall outcome value after own costs',
  valuePresent: 'Overall Value adjusted for spend to date',
  claimsWithInterest: 'Claims award or agreement',
  costRecoveryNet: 'Costs award or agreement',
}
