import {TableColumn} from '@settleindex/react'

import {SettlementValuesRecord} from './SettlementValuesRecord'

export const columns: TableColumn<SettlementValuesRecord>[] = [
  {
    id: 'methodology',
    label: 'Methodology',
    width: 180,
  },
  {
    id: 'description',
    label: '',
    width: 260,
  },
  {
    id: 'value',
    label: 'Value',
    align: 'right',
  },
  {
    id: 'currentGainLoss',
    label: 'Potential Gain/Loss',
    align: 'right',
  },
  {
    id: 'expectedGainLoss',
    label: 'Forecast Gain/Loss',
    align: 'right',
  },
]
