import * as React from 'react'

import {colors, PrimaryText, SecondaryText, TableRow} from '@settleindex/react'

import {DebugLabel} from '../../../debug/DebugLabel'
import {SumText} from '../../../sum'
import {OutcomeTotalsRecord} from './OutcomeTotalsRecord'

export interface Args {
  debugLabelTitle: string
  description: string | React.ReactElement
  idSuffix: string
  isLabelBold?: boolean
  label: string | React.ReactElement
  payReceive: React.ReactElement
  sum: number
}

export const makeRow = (
  {debugLabelTitle, description, idSuffix, isLabelBold = true, label, payReceive, sum}: Args,
  rowProps?: Partial<TableRow<OutcomeTotalsRecord>>,
): TableRow<OutcomeTotalsRecord> => ({
  id: `OutcomeTotalsTable-${idSuffix}`,
  cells: {
    label: <PrimaryText bold={isLabelBold}>{label}</PrimaryText>,
    description: (
      <SecondaryText medium style={{color: colors.base7}}>
        {description}
      </SecondaryText>
    ),
    payReceive,
    amount: (
      <DebugLabel placement="left" title={debugLabelTitle}>
        <SumText bold sum={sum} />
      </DebugLabel>
    ),
  },
  ...rowProps,
})
