import JSONView from '@uiw/react-json-view'
import * as React from 'react'

import {useOutcomeDrawer} from '../nodes/drawer/useOutcomeDrawer'
import {useDebugPanel} from './useDebugPanel'

const DebugPanel: React.FC = () => {
  const {debugPanelContent} = useDebugPanel()
  const {isDrawerOpen, outcome} = useOutcomeDrawer()

  const collapsed = React.useMemo(() => {
    if ('version' in debugPanelContent) {
      return 2
    }

    if ('outcome' in debugPanelContent) {
      return 2
    }

    return 1
  }, [debugPanelContent])

  const keyName = React.useMemo(() => {
    if ('version' in debugPanelContent) {
      return `Version ${debugPanelContent?.version?.title} of Dispute ${debugPanelContent?.dispute?.title}`
    }

    if ('outcome' in debugPanelContent) {
      return debugPanelContent?.outcome?.name
    }

    return `Dispute ${debugPanelContent?.title}`
  }, [debugPanelContent])

  const value = React.useMemo(() => {
    if (!outcome || !isDrawerOpen) {
      const {outcome: _, ...rest} = debugPanelContent

      return rest
    }

    return {
      outcome,
      ...debugPanelContent,
    }
  }, [debugPanelContent, isDrawerOpen, outcome])

  return <JSONView collapsed={collapsed} keyName={keyName} style={{fontSize: 15}} value={value} />
}

export default DebugPanel
