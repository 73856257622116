import {DisputeClassification} from '@settleindex/domain'

export const makeDisputeClassificationTreeData = (classifications: DisputeClassification[]) =>
  classifications.reduce((acc, curr) => {
    if (curr.id.includes('.')) {
      acc[acc.length - 1].children.push({title: curr.title, value: curr.id})

      return acc
    }

    return [...acc, {title: curr.title, value: curr.id, children: []}]
  }, [] as any)
