import '@settleindex/react/static/style.css'

import {ApolloProvider} from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import {FeaturevisorProvider} from '@featurevisor/react'
import {createInstance, FeaturevisorInstance} from '@featurevisor/sdk'
// @ts-ignore otherwise fails on CI
import * as history from 'history'
import * as React from 'react'
import {Router} from 'react-router-dom'

import {cond, T} from '@settleindex/fp'
import {AntdConfigProvider, Favicon, GlobalStyle, LoadingIndicatorFullPage} from '@settleindex/react'

import {getApolloClient} from './apollo/getApolloClient'
import {AppProvider} from './appContext/AppProvider'
import {emptyFeaturevisorDatafile} from './featureFlags/emptyFeaturevisorDatafile'
import {HelpProvider} from './help/HelpProvider'
import {Page} from './page/'
import {LoggedInRouting, PublicRouting} from './routing/'
import {EnforceActionContainer} from './user/EnforceActionContainer'
import {useUser} from './user/useUser/useUser'

interface AppProps {
  featurevisorUrl?: string
  graphUrl: string
  useBugsnag?: boolean
}

const browserHistory = history.createBrowserHistory()

let featurevisorInstance: FeaturevisorInstance

export const App: React.FC<AppProps> = ({featurevisorUrl, graphUrl, useBugsnag}) => {
  // Create the Featurevisor instance once
  React.useEffect(() => {
    if (featurevisorInstance) {
      return
    }

    const opts = featurevisorUrl ? {datafileUrl: featurevisorUrl} : {datafile: emptyFeaturevisorDatafile}
    featurevisorInstance = createInstance(opts)
  }, [featurevisorUrl])

  const {getToken, getUserId, isAdmin, isLoading: userIsLoading, logout, user} = useUser()

  React.useEffect(() => {
    useBugsnag && Bugsnag.setUser(user?.id, user?.email)
  }, [useBugsnag, user?.email, user?.id])

  const apolloClient = getApolloClient({graphUrl, getToken, getUserId})

  const content = React.useMemo(
    () =>
      cond([
        [() => userIsLoading, () => <LoadingIndicatorFullPage />],
        [
          () => Boolean(user),
          () => (
            <>
              <Page isAdmin={isAdmin} logout={logout} username={user?.name}>
                <HelpProvider />
                <LoggedInRouting rights={user?.rights} />
              </Page>
              <EnforceActionContainer signOut={logout} />
            </>
          ),
        ],
        [T, () => <PublicRouting />],
      ])(),
    [isAdmin, logout, user, userIsLoading],
  )

  return (
    <FeaturevisorProvider instance={featurevisorInstance}>
      <ApolloProvider client={apolloClient}>
        <AppProvider>
          <AntdConfigProvider form={{validateMessages: {required: 'This field is required'}}}>
            <GlobalStyle />
            <Favicon />
            <Router history={browserHistory}>{content}</Router>
          </AntdConfigProvider>
        </AppProvider>
      </ApolloProvider>
    </FeaturevisorProvider>
  )
}
