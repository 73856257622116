import {selector, useRecoilValue} from 'recoil'

import {currencyData, notCombinedDefendantParty, Role} from '@settleindex/domain'
import {cond, T} from '@settleindex/fp'

import {DisputeFragment} from '../../graphQLTypes'
import {disputeAtom} from './disputeAtom'

export const safeDisputeSelector = selector<DisputeFragment>({
  key: 'safeDisputeSelector',
  get: ({get}) => {
    const dispute = get(disputeAtom)

    if (!dispute) {
      throw new Error(`Dispute is not set in context yet`)
    }

    const {opponents, parties} = getParties(dispute)

    return {
      ...dispute,
      parties,
      opponents,
    }
  },
})

export const useSafeDispute = () => {
  const dispute = useRecoilValue(safeDisputeSelector)
  const currencySymbol = currencyData.get(dispute?.currency)?.[0]

  return {dispute, currencySymbol}
}

/**
 * Hides the combined defendant party from cases where it makes no sense. It
 * should only be visible when the perspective is a claimant and there are more
 * then 1 claimants.
 *
 * This can not be achieved on the back-end as there are some
 * edge cases where the combined defendant has to exist in the Dispute even if
 * it makes no sense to show it. One such case is when the combined defendant
 * was modelled (has outcomes) but then the perspective is changed to a
 * defendant.
 */
const getParties = (dispute: DisputeFragment) => {
  const partiesWithoutCombinedDefendant = dispute.parties.filter(notCombinedDefendantParty)
  const opponentsWithoutCombinedDefendant = dispute.opponents.filter(notCombinedDefendantParty)
  const hasSingleOpponent = opponentsWithoutCombinedDefendant.length === 1
  const perspectiveIsDefendant = dispute.perspectiveRole === Role.Defendant

  const withCombinedDefendant = {
    parties: dispute.parties,
    opponents: dispute.opponents,
  }

  const withoutCombinedDefendant = {
    parties: partiesWithoutCombinedDefendant,
    opponents: opponentsWithoutCombinedDefendant,
  }

  return cond([
    [() => perspectiveIsDefendant, () => withoutCombinedDefendant],
    [() => hasSingleOpponent, () => withoutCombinedDefendant],
    [T, () => withCombinedDefendant],
  ])()
}
