import * as React from 'react'

import {CaseBotContribution, CaseBotDamage, percentToStr} from '@settleindex/domain'
import {isDefined, noop} from '@settleindex/fp'
import {
  CenterLeftLine,
  Col,
  DeleteIcon,
  Form,
  FormItem,
  HSpace,
  Input,
  PercentFormInput,
  Row,
  SecondaryButton,
  SecondaryTextSmall,
  Switch,
  Tag,
  TertiaryButton,
  Tooltip,
  VSpaceMini,
  VSpaceSmall,
} from '@settleindex/react'

import {DebugFormItem} from '../debug'
import {Hint} from '../help'
import {HelpTitleWithTooltip} from '../help/HelpTitleWithTooltip'
import {PerspectiveAwarePercentInput} from '../party/perspectiveAwareColors/PerspectiveAwarePercentInput'
import {PerspectiveAwareText} from '../party/perspectiveAwareColors/PerspectiveAwareText'
import {claimSpan, gutter, nameSpan, removeSpan, weightSpan} from './columnWidths'
import {ExtraLabel} from './form/ExtraLabel'
import {warnLabel} from './form/warnLabel'

const help = {
  damages: {
    overviewId: 'casebot_damage_overview',
    left: 'Award Ranges',
    leftId: 'casebot_damage_title',
  },
  contribution: {
    overviewId: 'casebot_contribution_overview',
    left: 'Contribution',
    leftId: 'casebot_contribution_title',
  },
} as const

export const DamagesTable: React.FC<{
  // The tooltip for the cumulative weight tag
  cumulativeTooltip?: string
  // The cumulative probability of the damages for the claimant
  myCumulativeProportion?: number
  myWeightTotal: number
  // name is the form field name
  name: 'damages' | 'contribution'
  onAdd: (
    addItemToFormList: (issue: CaseBotDamage | CaseBotContribution) => void,
    fieldName: string,
    title: string,
  ) => void
  onRemove?: () => void
  // The cumulative probability of the damages for the claimant
  theirCumulativeProportion?: number
  theirWeightTotal: number
  title: string
}> = ({
  cumulativeTooltip,
  myCumulativeProportion,
  myWeightTotal,
  name,
  onAdd,
  onRemove = noop,
  theirCumulativeProportion,
  theirWeightTotal,
  title,
}) => {
  const proportionPercentInputProps = React.useMemo(() => (name === 'contribution' ? {min: -1, max: 0} : {}), [name])

  return (
    <>
      <HelpTitleWithTooltip id={help[name].overviewId} left={help[name].left} leftId={help[name].leftId} />

      {name === 'contribution' && (
        <>
          <Form.Item label="Enable Contribution" name={['contributionEnabled']} valuePropName="checked">
            <Switch />
          </Form.Item>
        </>
      )}

      <Form.List name={name}>
        {(fields, {add, remove}) => {
          return (
            <div>
              {!fields.length && (
                <SecondaryTextSmall style={{margin: '20px 0', display: 'block'}}>
                  No {title} defined yet
                </SecondaryTextSmall>
              )}
              <VSpaceMini />
              {fields.length ? <ExtraLabel label="Chance of Decision (%)" /> : <></>}
              {fields.map((field, index) => {
                return (
                  <Row align="bottom" gutter={gutter} key={field.key}>
                    <Col span={nameSpan}>
                      <FormItem {...field} label={index == 0 ? 'Label' : ''} name={[field.name, 'name']}>
                        <Input placeholder="Label" />
                      </FormItem>
                    </Col>
                    <Col span={weightSpan}>
                      <DebugFormItem
                        isPerspective
                        {...field}
                        label={warnLabel('Our View', myWeightTotal === 1, index)}
                        name={[field.name, 'myWeight']}
                        title={`caseBot.${name}.[].myWeight`}
                      >
                        <PerspectiveAwarePercentInput isPerspective />
                      </DebugFormItem>
                    </Col>
                    <Col span={weightSpan}>
                      <DebugFormItem
                        isPerspective={false}
                        title={`caseBot.${name}.[].theirWeight`}
                        {...field}
                        label={warnLabel('Their View', theirWeightTotal === 1, index)}
                        name={[field.name, 'theirWeight']}
                      >
                        <PerspectiveAwarePercentInput isPerspective={false} />
                      </DebugFormItem>
                    </Col>
                    <Col span={claimSpan}>
                      <DebugFormItem
                        {...field}
                        label={index == 0 ? 'Proportion' : ''}
                        name={[field.name, 'proportion']}
                        title={`caseBot.${name}.[].proportion`}
                      >
                        <PercentFormInput {...proportionPercentInputProps} />
                      </DebugFormItem>
                    </Col>
                    {fields.length > 1 ? (
                      <Col span={removeSpan}>
                        <FormItem>
                          <TertiaryButton
                            medium
                            onClick={() => {
                              remove(field.name)
                              onRemove()
                            }}
                          >
                            <DeleteIcon />
                          </TertiaryButton>
                        </FormItem>
                      </Col>
                    ) : null}
                  </Row>
                )
              })}

              {fields.length > 1 && isDefined(myCumulativeProportion) && isDefined(theirCumulativeProportion) ? (
                <>
                  <Row>
                    <Col span={nameSpan}>
                      <Tooltip title={cumulativeTooltip}>
                        <Hint>
                          <Tag color="purpleLight" style={{width: 160}}>
                            Weighted Proportion
                          </Tag>
                        </Hint>
                      </Tooltip>
                    </Col>
                    <Col span={weightSpan}>
                      <CenterLeftLine>
                        <HSpace space={10} />
                        <PerspectiveAwareText isPerspective semibold>
                          {percentToStr(myCumulativeProportion)}
                        </PerspectiveAwareText>
                      </CenterLeftLine>
                    </Col>
                    <Col span={weightSpan}>
                      <CenterLeftLine>
                        <HSpace space={10} />
                        <PerspectiveAwareText semibold>{percentToStr(theirCumulativeProportion)}</PerspectiveAwareText>
                      </CenterLeftLine>
                    </Col>
                  </Row>
                  <VSpaceSmall />
                </>
              ) : null}

              <div>
                <SecondaryButton onClick={() => onAdd(add, name, title)}>Add</SecondaryButton>
              </div>
            </div>
          )
        }}
      </Form.List>
    </>
  )
}
