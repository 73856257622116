import * as React from 'react'
import {useParams} from 'react-router-dom'

import {notCombinedDefendantParty} from '@settleindex/domain'
import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData} from '@settleindex/react'

import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {
  CapPatchInput,
  CapType,
  useAddCapMutation,
  useDeleteCapMutation,
  usePatchCapMutation,
  useVersionOfDisputeQuery,
  VersionFragment,
} from '../../graphQLTypes'
import {LoadingIndicatorWithNav} from '../../page'
import {useVersionContainer} from '../../version/context/useVersionContainer'
import {CapListPage} from './CapListPage'
import {NoCaps} from './NoCaps'

interface Match {
  disputeId: string
  versionId: string
}

export const CapListContainer: React.FC = () => {
  const {disputeId, versionId} = useParams<Match>()
  const {runAsync} = useAppIsLoading()

  const {data, error, loading} = useVersionOfDisputeQuery({variables: {disputeId, versionId}})
  const [patchCapMutation] = usePatchCapMutation()
  const [addCapMutation] = useAddCapMutation()
  const [deleteCapMutation] = useDeleteCapMutation()

  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: data?.dispute})
  const {version: versionInContext} = useVersionContainer({
    requiredId: versionId,
    setVersionFrom: data?.dispute?.version,
  })

  const defendantParties = React.useMemo(
    () => (disputeInContext?.parties.slice(1) ?? []).filter(notCombinedDefendantParty),
    [disputeInContext],
  )

  const onAddCap = React.useCallback(() => {
    const newCap = {
      amount: 0,
      interest: 0,
      partyId: defendantParties[0].id,
      type: CapType.AllCostsInclusiveAfterCounterclaims,
    }

    runAsync(() => addCapMutation({variables: {versionId, newCap}}))
  }, [addCapMutation, defendantParties, versionId, runAsync])

  const whenAvailable = () => {
    const version = versionInContext as VersionFragment

    const onPatch = (capId: string, patch: CapPatchInput) => {
      runAsync(() =>
        patchCapMutation({
          variables: {
            versionId,
            capId,
            patch,
          },
        }),
      )
    }

    const onDeleteCap = (capId: string) => {
      runAsync(() => deleteCapMutation({variables: {versionId, capId}}))
    }

    return (
      <CapListPage
        caps={version.caps}
        onAddCap={onAddCap}
        onDeleteCap={onDeleteCap}
        onPatchCap={onPatch}
        parties={defendantParties}
        readonly={version.readonly}
      />
    )
  }

  const empty = !data?.dispute?.version?.caps?.length

  const whenEmpty = () => {
    const version = versionInContext as VersionFragment

    return <NoCaps disabled={version.readonly} onAddCap={onAddCap} />
  }

  return (
    <LoadingData
      state={{
        available: !empty && isDefined(versionInContext),
        empty,
        error,
        loading,
      }}
      whenAvailable={whenAvailable}
      whenEmpty={whenEmpty}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicatorWithNav />}
    />
  )
}
