import * as React from 'react'

import {PrimaryText, Switch} from '@settleindex/react'

interface Props {
  isWeightEnabled: boolean
  setIsWeightEnabled: (enabled: boolean) => void
}

export const ToggleWeightingButton: React.FC<Props> = ({isWeightEnabled, setIsWeightEnabled}) => {
  return (
    <div>
      <PrimaryText>Weight Models</PrimaryText> <Switch checked={isWeightEnabled} onChange={setIsWeightEnabled} />
    </div>
  )
}
