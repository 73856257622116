import {TableRow} from '@settleindex/react'

import {AppOutcome} from '../../AppOutcome'
import {LabelledNodeProperty, outcomeValueDescriptions, outcomeValueLabels} from '../../valueLabels'
import {PayReceiveTag} from '../payReceiveTag/makePayReceiveTag'
import {Args as MakeRowArgs, makeRow} from './makeRow'
import {OutcomeTotalsRecord} from './OutcomeTotalsRecord'

export const makeRowFromProperty =
  (payReceiveTag: PayReceiveTag, outcome: AppOutcome) =>
  (
    propertyName: LabelledNodeProperty,
    makeRowProps?: Partial<MakeRowArgs>,
    tableRowProps?: Partial<TableRow<OutcomeTotalsRecord>>,
  ): TableRow<OutcomeTotalsRecord> =>
    makeRow(
      {
        debugLabelTitle: `outcome.${propertyName}`,
        description: outcomeValueDescriptions[propertyName],
        idSuffix: propertyName,
        label: outcomeValueLabels[propertyName],
        payReceive: payReceiveTag(outcome[propertyName]),
        sum: outcome[propertyName],
        ...makeRowProps,
      },
      tableRowProps,
    )
