import * as React from 'react'
import styled from 'styled-components'

import {tutorialTestIds} from '@settleindex/testids/src/testIds'

import {TutorialId} from './TutorialId'
import {useTutorial} from './useTutorial'

type TutorialUrl = `https://${string}`

const tutorialUrls = new Map<TutorialId, TutorialUrl>([
  ['caps', 'https://settleindex.com/support/tutorial/caps/'],
  ['claims', 'https://settleindex.com/support/tutorial/claims/'],
  ['costs', 'https://settleindex.com/support/tutorial/costs/'],
  ['map', 'https://settleindex.com/support/tutorial/outcomes/'],
  ['analyse', 'https://settleindex.com/support/tutorial/outcomes-analyse/'],
  ['strategy', 'https://settleindex.com/support/tutorial/strategy/'],
  ['targets', 'https://settleindex.com/support/tutorial/targets/'],
])

export const Tutorial: React.FC = () => {
  const {tutorialId} = useTutorial()
  const [url, setUrl] = React.useState<string>()
  React.useEffect(() => {
    setUrl(tutorialUrls.get(tutorialId))
  }, [tutorialId])

  return (
    <Layout data-test-id={tutorialTestIds.tutorialBar} key={url}>
      {url ? <Iframe src={url} /> : ''}
    </Layout>
  )
}

const Layout = styled.div`
  height: calc(100% - 10px);
  padding: 0;
  padding-top: 10px;
`

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;

  border-width: 0;

  margin: 0;
  padding: 0;
`
