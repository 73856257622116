import * as React from 'react'

import {defaultJurisdiction, jurisdictions} from '@settleindex/domain'
import {FormItem, TreeSelect} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {fieldNames} from './fieldNames'

export const Jurisdiction = () => (
  <FormItem
    data-test-id={disputeFormTestIds.editJurisdiction}
    initialValue={defaultJurisdiction}
    label="Jurisdiction"
    name={fieldNames.jurisdiction}
    rules={[
      {
        required: true,
        message: 'Please select a jurisdiction',
      },
    ]}
  >
    <TreeSelect
      placeholder="Select..."
      showSearch
      treeData={jurisdictions}
      treeDefaultExpandAll
      treeNodeFilterProp="title"
    />
  </FormItem>
)
