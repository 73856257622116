import * as React from 'react'

import {defaultCurrency, DisputeClassification} from '@settleindex/domain'
import {dateToPicker, Form, message, PrimaryButton} from '@settleindex/react'
import {disputeFormTestIds} from '@settleindex/testids/src/testIds'

import {captureException} from '../../captureException'
import {NewDisputeInput} from '../../graphQLTypes'
import {fieldNames} from '../form/fieldNames'
import {FormLayout} from '../form/FormLayout'
import {makeOnChange} from '../form/makeOnChange'
import {PartyInForm} from '../form/party/PartyInForm'
import {Title} from '../form/Title'
import {formValuesToNewDisputeInput} from './formValuesToNewDisputeInput'
import {NewDisputeFormValues} from './NewDisputeFormValues'

interface NewDisputeFormProps {
  disputeClassifications: DisputeClassification[]
  onCancel: () => void
  onFinish: (values: NewDisputeInput) => void
}

export const NewDisputeForm: React.FC<NewDisputeFormProps> = ({disputeClassifications, onCancel, onFinish}) => {
  const [form] = Form.useForm<NewDisputeFormValues>()
  const [partiesWithLabel, setPartiesWithLabel] = React.useState<PartyInForm[]>([])

  React.useEffect(() => {
    if (!disputeClassifications?.length) {
      return
    }

    const initialValue = {
      [fieldNames.parties]: [],
      [fieldNames.currency]: defaultCurrency,
      [fieldNames.classificationId]: disputeClassifications[0].id,
      [fieldNames.disputeStartedOn]: dateToPicker(new Date().toISOString()),
    }

    form.setFieldsValue(initialValue)
  }, [disputeClassifications, form])

  const sendPayload = () => {
    try {
      onFinish(formValuesToNewDisputeInput(form.getFieldsValue()))
    } catch (error) {
      captureException(error)
      message.error(`Unable to submit request`)
    }
  }

  const claimantMissing = !partiesWithLabel?.some((p) => p.type === 'claimant')
  const tooFewParties = partiesWithLabel?.length < 2
  const submitDisabled = claimantMissing || tooFewParties

  const primaryButton = (
    <PrimaryButton
      data-test-id={disputeFormTestIds.disputeFormSubmit}
      disabled={submitDisabled}
      htmlType="submit"
      medium
    >
      Create Case
    </PrimaryButton>
  )

  const onChange = makeOnChange({form, setPartiesWithLabel})

  return (
    <FormLayout
      disputeClassifications={disputeClassifications}
      form={form}
      onCancel={onCancel}
      onChange={onChange}
      onFinish={sendPayload}
      partiesForPerspective={partiesWithLabel}
      primaryButton={primaryButton}
      titleInput={<Title />}
    />
  )
}
