import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {
  UpdateCostGroupInput,
  UpdateCostInput,
  useAddCostGroupMutation,
  useAddCostMutation,
  useDeleteCostGroupMutation,
  useDeleteCostMutation,
  useUpdateCostGroupMutation,
  useUpdateCostMutation,
} from '../../graphQLTypes'
import {CostOperations} from './CostOperations'

interface Args {
  versionId: string
}

export const useCostGraph = ({versionId}: Args): CostOperations => {
  const {runAsync} = useAppIsLoading()
  const [costAddMutation] = useAddCostMutation()
  const [costDeleteMutation] = useDeleteCostMutation()
  const [costGroupAddMutation] = useAddCostGroupMutation()
  const [costGroupDeleteMutation] = useDeleteCostGroupMutation()
  const [costGroupUpdateMutation] = useUpdateCostGroupMutation()
  const [costUpdateMutation] = useUpdateCostMutation()

  const onCostGroupChange = (costGroupId: string, property: keyof UpdateCostGroupInput, value: string) => {
    const update = {
      [property]: value,
    }

    runAsync(() =>
      costGroupUpdateMutation({
        variables: {
          versionId,
          costGroupId,
          update,
        },
      }),
    )
  }

  const onCostGroupDelete = (costGroupId: string) => {
    runAsync(() =>
      costGroupDeleteMutation({
        variables: {
          versionId,
          costGroupId,
        },
      }),
    )
  }

  const onCostGroupAdd = (partyId: string) => {
    runAsync(() =>
      costGroupAddMutation({
        variables: {
          versionId,
          newCostGroup: {
            title: 'Cost Group',
            partyId,
            costs: [
              {
                title: 'Cost',
                amount: 0,
                forecast: 0,
                isRecoverable: true,
                spent: 0,
              },
            ],
          },
        },
      }),
    )
  }

  const onCostChange = ({
    costGroupId,
    costId,
    property,
    value,
  }: {
    costGroupId: string
    costId: string
    property: keyof UpdateCostInput
    value: string | boolean | number
  }) => {
    const cost = {
      [property]: value,
    }

    runAsync(() =>
      costUpdateMutation({
        variables: {
          versionId,
          costGroupId,
          costId,
          cost,
        },
      }),
    )
  }

  const onCostDelete = (costGroupId: string, costId: string) => {
    runAsync(() =>
      costDeleteMutation({
        variables: {
          versionId,
          costGroupId,
          costId,
        },
      }),
    )
  }

  const onCostAdd = (costGroupId: string) => {
    runAsync(() =>
      costAddMutation({
        variables: {
          costGroupId,
          versionId,
          newCost: {
            amount: 0,
            forecast: 0,
            isRecoverable: true,
            spent: 0,
            title: 'Cost',
          },
        },
      }),
    )
  }

  return {
    costGroupUpdate: onCostGroupChange,
    costGroupDelete: onCostGroupDelete,
    costGroupAdd: onCostGroupAdd,
    costUpdate: onCostChange,
    costDelete: onCostDelete,
    costAdd: onCostAdd,
  }
}
