import * as React from 'react'
import styled from 'styled-components'

import {
  Col,
  Form,
  FormItem,
  H1,
  PrimaryButton,
  PrimaryText,
  Row,
  SecondaryLink,
  SecondaryText,
  SecondaryTextSmall,
  TextInput,
  VSpace,
} from '@settleindex/react'
import {registerTestIds} from '@settleindex/testids/src/testIds'

import {CompleteMyRegistrationMutationVariables, UserResponse} from '../graphQLTypes'
import {PrivacyLink, TosLink} from './legalLinks'
import {Logo} from './Logo'
import {userEditFields} from './userEditFields'

interface Props {
  loading?: boolean
  onSubmit: (data: CompleteMyRegistrationMutationVariables) => void
  signOut: () => void
  user: UserResponse
}

export const CompleteRegistrationForm: React.FC<Props> = ({loading, onSubmit, signOut, user}) => {
  const fields = userEditFields({
    required: {phone: !user.isExternal},
    submitLabel: 'Register',
    customLabels: {phone: ''},
    formItemProps: {
      phone: {
        label: 'Mobile Number',
        initialValue: user.phone,
        extra: <SecondaryTextSmall>We require your mobile number for security verification only.</SecondaryTextSmall>,
      },
    },
  })
  const [form] = Form.useForm()
  const onFinish = (values: CompleteMyRegistrationMutationVariables) => {
    const result = {
      ...values,
    }

    onSubmit(result)
  }

  return (
    <Frame>
      <Logo />
      <VSpace space={40} />
      <H1 data-test-id={registerTestIds.registerHeader}>Complete your registration</H1>
      <PrimaryText medium style={{maxWidth: 360, textAlign: 'center'}}>
        Please check the details below are correct.
      </PrimaryText>
      <VSpace space={30} />
      <Form form={form} name="CompleteRegistrationForm" onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <FormItem initialValue={user.email} label="Email" name="email">
              <TextInput readonly size="middle" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={17}>
          <Col span={12}>
            <FormItem initialValue={user.firstName} label="First Name" name="firstName">
              <TextInput size="middle" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem initialValue={user.lastName} label="Last Name" name="lastName">
              <TextInput size="middle" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem initialValue={user.company} label="Company" name="company">
              <TextInput size="middle" />
            </FormItem>
          </Col>
        </Row>
        {!user.isExternal && (
          <Row className="pad-on-error">
            <Col span={24}>{fields.phone}</Col>
          </Row>
        )}

        <PrimaryButton
          data-test-id={registerTestIds.submitButton}
          htmlType="submit"
          loading={loading}
          medium
          style={{width: '100%'}}
        >
          Register
        </PrimaryButton>
      </Form>

      <VSpace space={20} />
      <SecondaryText style={{maxWidth: 360, textAlign: 'center'}}>
        By clicking 'register' above, you agree to our <TosLink /> and <PrivacyLink />.
      </SecondaryText>
      <SecondaryLink onClick={signOut} style={{marginTop: 20}} to={'#'}>
        <u>Cancel and sign out</u>
      </SecondaryLink>
    </Frame>
  )
}

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  align-items: center;
  justify-content: center;
`
