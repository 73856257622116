import * as React from 'react'
import {useParams} from 'react-router-dom'

import {isDefined} from '@settleindex/fp'
import {Ambulance, Forbidden, LoadingData} from '@settleindex/react'
import {accessRightTestIds} from '@settleindex/testids/src/testIds'

import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {DisputeNotFound} from '../../dispute/DisputeNotFound'
import {disputeNotFoundGraphError} from '../../dispute/graph/disputeErrorGuards'
import {
  AccessKind,
  DisputeFragment,
  DisputeTeamResponse,
  useAddMemberToDisputeTeamMutation,
  useAllDisputesLazyQuery,
  useDisputeQuery,
  useDisputeTeamQuery,
  useRemoveMemberFromDisputeTeamMutation,
  useSetDisputeTeamMemberAccessMutation,
} from '../../graphQLTypes'
import {useAuthenticatedUser} from '../../user/useUser/useAuthenticatedUser'
import {TeamPage} from './TeamPage'
import {TeamPageLoading} from './TeamPageLoading'

interface Match {
  id: string
}

const TeamContainer: React.FC = () => {
  const {
    user: {id: loggedInUserId},
  } = useAuthenticatedUser()
  const {id: disputeId} = useParams<Match>()

  const [refetchAllDisputes] = useAllDisputesLazyQuery({fetchPolicy: 'network-only'})
  const disputeQuery = useDisputeQuery({variables: {id: disputeId}})
  const teamQuery = useDisputeTeamQuery({variables: {disputeId}})
  const [addMemberMutation, addMemberMutationState] = useAddMemberToDisputeTeamMutation()
  const [changeAccessMutation, changeAccessMutationState] = useSetDisputeTeamMemberAccessMutation()
  const [removeMutation, removeMutationState] = useRemoveMemberFromDisputeTeamMutation()

  const {dispute: disputeInContext} = useDisputeContainer({
    requiredId: disputeId,
    setDisputeFrom: disputeQuery?.data?.dispute,
  })

  const loading =
    disputeQuery.loading ||
    teamQuery.loading ||
    addMemberMutationState.loading ||
    changeAccessMutationState.loading ||
    removeMutationState.loading
  const initialLoading = (disputeQuery.loading && !disputeQuery.data) || (teamQuery.loading && !teamQuery.data)
  const isDisputeForbidden = disputeInContext?.readonly === true
  const error =
    disputeQuery.error || addMemberMutationState.error || changeAccessMutationState.error || removeMutationState.error
  const isError = isDisputeForbidden || error
  const dispute = disputeQuery?.data?.dispute as DisputeFragment
  const team = teamQuery.data?.disputeTeam as DisputeTeamResponse
  const members = team?.members ?? []

  const whenError = () =>
    isDisputeForbidden ? <Forbidden data-test-id={accessRightTestIds.accessForbidden} /> : <Ambulance />

  const whenAvailable = () => {
    const onInvite = async (email: string, accessKind: AccessKind) => {
      await addMemberMutation({
        variables: {
          accessKind,
          disputeId,
          email,
        },
      })
    }

    const onChangeAccess = async (userId: string, accessKind: AccessKind) => {
      await changeAccessMutation({
        variables: {
          accessKind,
          disputeId,
          userId,
        },
      })

      if (loggedInUserId === userId) {
        disputeQuery.refetch()
      }
    }

    const onRemoveShare = (userId: string) => {
      removeMutation({
        variables: {
          disputeId,
          userId,
        },
      })

      if (loggedInUserId === userId) {
        refetchAllDisputes()
      }
    }

    return (
      <TeamPage
        dispute={dispute}
        loading={loading}
        members={members}
        onChangeAccess={onChangeAccess}
        onInvite={onInvite}
        onRemoveShare={onRemoveShare}
      />
    )
  }

  return (
    <LoadingData
      state={{
        available: isDefined(disputeInContext),
        empty: disputeNotFoundGraphError(error),
        error: isError && !disputeNotFoundGraphError(error),
        loading: initialLoading,
      }}
      whenAvailable={whenAvailable}
      whenEmpty={<DisputeNotFound />}
      whenError={whenError}
      whenLoading={<TeamPageLoading />}
    />
  )
}

export default TeamContainer
