import * as React from 'react'
import {atom, useRecoilState} from 'recoil'

import {useNodes} from '../context/useNodes'

const outcomeDrawerIsOpenAtom = atom<boolean>({
  key: 'outcomeDrawerIsOpenAtom',
  default: false,
})

const drawerOutcomeIdAtom = atom<string | undefined>({
  key: 'drawerOutcomeIdAtom',
  default: undefined,
})

/**
 * Hook to manage / display the outcome editing drawer.
 * The drawer needs to be placed in the app via `<OutcomeDetailsDrawer />`
 */
export const useOutcomeDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(outcomeDrawerIsOpenAtom)
  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)
  const [drawerOutcomeId, setDrawerOutcomeId] = useRecoilState(drawerOutcomeIdAtom)

  const {selectedPartyOutcomes} = useNodes()
  const outcome = React.useMemo(() => {
    if (!drawerOutcomeId) {
      return undefined
    }

    return selectedPartyOutcomes.find((o) => o.id === drawerOutcomeId)
  }, [drawerOutcomeId, selectedPartyOutcomes])

  return {
    closeDrawer,
    drawerOutcomeId,
    isDrawerOpen,
    openDrawer,
    outcome,
    setDrawerOutcomeId,
    setIsDrawerOpen,
  }
}
