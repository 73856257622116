import {SettlementStrategyType} from '../../graphQLTypes'

export const settlementStrategyTypeLabels = new Map<SettlementStrategyType, [label: string, weight: number]>([
  [SettlementStrategyType.commonSettlementValue, ['Model Settlement', 10]],
  [SettlementStrategyType.perspectiveCaseValuePresent, ['Current Position', 20]],
  [SettlementStrategyType.customTarget, ['Custom Settlement', 30]],
  [SettlementStrategyType.contribution, ['Contribution', 40]],
  [SettlementStrategyType.settled, ['Settled', 50]],
  [SettlementStrategyType.judged, ['Awarded', 60]],
  [SettlementStrategyType.none, ['None', 100]],
])
