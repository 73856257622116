import * as React from 'react'
import styled from 'styled-components'

import {
  CaseIcon,
  colors,
  CompareIcon,
  DashboardIcon,
  OfferIcon,
  RangeIcon,
  SettingsIcon,
  TeamIcon,
} from '@settleindex/react'
import {primaryNavigationTestIds} from '@settleindex/testids/src/testIds'

import {paths} from '../../routing/paths'
import {MenuText} from './MenuText'
import {PrimaryMenuItem} from './PrimaryMenuItem'

const TitleText = styled(MenuText)`
  color: ${colors.base6}!important;
  display: inline-block;
  padding-bottom: 20px;
  width: 155px;
  white-space: break-spaces;
`

export const getDisputeMenuItems = ({
  disputeId,
  disputeReadonly,
  disputeTitle,
}: {
  disputeId?: string
  disputeReadonly?: boolean
  disputeTitle?: string
}): PrimaryMenuItem[] => {
  const disputesMenuItem = {
    icon: <CaseIcon />,
    key: primaryNavigationTestIds.disputeList,
    label: 'Cases',
    linkPath: paths.disputes(),
  }

  if (!disputeId) {
    return [disputesMenuItem]
  }

  const readonlyMenuItems = [
    disputesMenuItem,
    {isGap: true},
    {isGap: true},
    {
      key: primaryNavigationTestIds.disputeTitle,
      label: <TitleText>{disputeTitle}</TitleText>,
      linkPath: paths.dispute(disputeId),
      isActive: false,
      hasHoverEffect: false,
    },
    {
      icon: <DashboardIcon />,
      key: primaryNavigationTestIds.dashboard,
      label: 'Dashboard',
      linkPath: paths.dispute(disputeId),
    },
    {
      icon: <RangeIcon />,
      key: primaryNavigationTestIds.disputeHistory,
      label: 'History',
      linkPath: paths.disputeMetrics(disputeId),
    },
    {
      icon: <CompareIcon />,
      key: primaryNavigationTestIds.compare,
      label: 'Compare',
      linkPath: paths.versionCompare(disputeId),
    },
    {
      icon: <OfferIcon />,
      key: primaryNavigationTestIds.offers,
      label: 'Offers',
      linkPath: paths.disputeEvents(disputeId),
    },
  ]

  if (disputeReadonly) {
    return readonlyMenuItems
  }

  return [
    ...readonlyMenuItems,
    {
      icon: <TeamIcon />,
      key: primaryNavigationTestIds.team,
      label: 'Team',
      linkPath: paths.disputeSharingList(disputeId),
    },
    {
      icon: <SettingsIcon />,
      key: primaryNavigationTestIds.settings,
      label: 'Case Settings',
      linkPath: paths.disputeSettings(disputeId),
    },
  ]
}
