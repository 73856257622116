import {isNil, omit} from '@settleindex/fp'

import {NewDisputeInput, NewPartyInput} from '../../graphQLTypes'
import {partyInFormToParty} from '../form/party/partyInFormToParty'
import {NewDisputeFormValues} from './NewDisputeFormValues'

export const formValuesToNewDisputeInput = (fields: NewDisputeFormValues): NewDisputeInput => {
  const {disputeStartedOn, parties: formParties, perspectiveId, ...restOfFields} = fields
  const perspectiveIndex = formParties.findIndex((p) => p.id === perspectiveId)

  if (isNil(perspectiveIndex)) {
    throw new Error(`No perspective index found: ${JSON.stringify(fields)}`)
  }

  const partiesToSend: NewPartyInput[] = formParties.map((formParty): NewPartyInput => {
    const party = omit(['id'], partyInFormToParty(formParty))

    return {
      ...party,
      label: party.label,
      name: party.name,
      industry: party.industry,
    }
  })

  return {
    ...restOfFields,
    disputeStartedOn: disputeStartedOn?.toISOString(),
    parties: partiesToSend,
    perspectiveIndex,
  }
}
