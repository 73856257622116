import React from 'react'

import {Lifecycle} from '../../graphQLTypes'
import {DraftTag, PublishedTag} from './tags'

export const lifecycleTags = new Map<Lifecycle, React.ReactElement>([
  // eslint-disable-next-line react/jsx-key
  [Lifecycle.Draft, <DraftTag />],
  // eslint-disable-next-line react/jsx-key
  [Lifecycle.Report, <PublishedTag />],
])
