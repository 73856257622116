import * as React from 'react'
import styled from 'styled-components'

import {VSpaceSmall} from '@settleindex/react'
import {chartTestIds} from '@settleindex/testids/src/testIds'

const Box = styled.div`
  width: 100%;
`

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
`

const ChartHeight = styled.div`
  height: 305px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 0px;
`

interface Props {
  /**
   * The section above the chart. Use the `NameAndDescription` component for a
   * uniform look.
   */
  headline?: React.ReactElement
  /**
   * The legend between the headline and the chart
   */
  legend?: React.ReactElement
}

export const ChartSectionNormalLayout: React.FC<React.PropsWithChildren<Props>> = ({children, headline, legend}) => (
  <Box data-test-id={chartTestIds.chartSection}>
    <Inner>
      {headline}
      {legend && (
        <>
          <VSpaceSmall />
          <div>{legend}</div>
        </>
      )}

      <ChartHeight>{children}</ChartHeight>
    </Inner>
  </Box>
)
