import {TableColumn} from '@settleindex/react'

import {CostRecoveryRecord} from './CostRecoveryRecord'

export const costRecoveryTableColumns: TableColumn<CostRecoveryRecord>[] = [
  {
    align: 'left',
    id: 'party',
    label: 'Party',
    width: '50%',
  },
  {
    align: 'right',
    id: 'potential',
    label: 'Recoverable',
  },
  {
    align: 'right',
    id: 'percent',
    label: 'Pay/Recover',
  },
  {
    align: 'right',
    id: 'recoverable',
    label: 'Total',
    width: '16%',
  },
]
