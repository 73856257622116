import {TableColumn} from '@settleindex/react'

import {CaseValuesRecord} from './CaseValuesRecord'

export const makeColumns = (
  perspectiveLabel: string,
  opponentLabel: string = 'Opponent',
): TableColumn<CaseValuesRecord>[] => [
  {
    id: 'stage',
    label: 'Position',
    width: 180,
  },
  {
    id: 'description',
    label: 'Risk-weighted',
  },
  {
    align: 'right',
    id: 'perspective',
    label: perspectiveLabel,
  },
  {
    align: 'right',
    id: 'separator',
    label: '',
    width: 35,
  },
  {
    align: 'right',
    id: 'opponent',
    label: opponentLabel,
    width: Math.max(110, opponentLabel.length * 8),
  },
]
