import {TableColumn} from '@settleindex/react'

import {CostRecord} from './CostRecord'

export const columns: TableColumn<CostRecord>[] = [
  {
    align: 'left',
    id: 'title',
    label: 'Cost',
    width: 280,
  },
  {
    align: 'left',
    id: 'isRecoverable',
    label: 'Recoverability',
    width: 180,
  },
  {
    align: 'right',
    id: 'amount',
    label: 'Case Budget',
  },
  {
    align: 'right',
    id: 'spent',
    label: 'Spent to date',
  },
  {
    align: 'right',
    id: 'forecast',
    label: 'Forecast',
  },
  {
    align: 'right',
    id: 'actions',
    width: 32,
  },
]
