import {selector} from 'recoil'

import {safeDisputeSelector} from '../../../dispute/context/useSafeDispute'
import {versionAtom} from '../../../version/context/versionAtom'
import {AppOutcome} from '../../AppOutcome'
import {toAppOutcome} from '../../toAppOutcome'
import {selectedPartyIdAtom} from '../atoms/selectedPartyIdAtom'

/**
 * Returns the Outcomes for the party
 */
export const selectedPartyOutcomesSelector = selector<AppOutcome[]>({
  key: 'selectedPartyOutcomesSelector',
  get: ({get}) => {
    const dispute = get(safeDisputeSelector)
    const selectedNodePartyId = get(selectedPartyIdAtom) ?? dispute.perspectiveId
    const isPerspective = selectedNodePartyId === dispute.perspectiveId

    return (get(versionAtom)?.outcomes ?? []).map((outcome) =>
      toAppOutcome(outcome, selectedNodePartyId, isPerspective),
    )
  },
})
