import * as React from 'react'

import {calculatePercentChange, HistoryMetrics} from '@settleindex/domain'
import {path} from '@settleindex/fp'
import {PrimaryText} from '@settleindex/react'

import {DisputeHistoryPointFragment} from '../../graphQLTypes'
import {usePageTitle} from '../../pageTitle/usePageTitle'
import {Percent} from '../../percent/Percent'
import {PercentInput} from '../../percent/PercentInput'
import {SumText} from '../../sum'
import {SumChange} from '../../sum/SumChange'
import {useSafeDispute} from '../context/useSafeDispute'
import {getChangeColorCoding} from '../history/disputeHistoryMetricsSelector/getChangeColorCoding'
import {MetricsSelector, titleColumn} from '../history/metricsSelector/MetricsSelector'
import {defaultCoding, metricVisuals} from '../history/metricVisuals'
import {makeExtraColumns} from './makeExtraColumns'
import {MaybeHistoryPoints} from './MaybeHistoryPoint'

interface Props {
  checkedMetrics: HistoryMetrics[]
  historyPoints: MaybeHistoryPoints
  isWeightEnabled: boolean
  modelWeights: number[]
  onCheckboxClick: (metricPath: HistoryMetrics, isChecked: boolean) => void
  onModelWeightChange: (index: number, value: number) => void
  totalWeight: number
  weightedTotalHistoryPoint: DisputeHistoryPointFragment
}

export const VersionCompareMetricsSelector: React.FC<Props> = ({
  checkedMetrics,
  historyPoints,
  isWeightEnabled,
  modelWeights,
  onCheckboxClick,
  onModelWeightChange,
  totalWeight,
  weightedTotalHistoryPoint,
}) => {
  const {dispute} = useSafeDispute()
  usePageTitle(`${dispute.title} - Compare Models`)

  const extraColumns = React.useMemo(
    () => makeExtraColumns(historyPoints, isWeightEnabled, dispute.id),
    [dispute.id, historyPoints, isWeightEnabled],
  )

  const prefixRows = React.useMemo(
    () =>
      isWeightEnabled
        ? [
            {
              id: 'prefixRow1',
              cells: {
                title: <PrimaryText medium>Weight Models</PrimaryText>,
                version1: <PercentInput defaultValue={modelWeights[0]} onSave={(val) => onModelWeightChange(0, val)} />,
                version2: <PercentInput defaultValue={modelWeights[1]} onSave={(val) => onModelWeightChange(1, val)} />,
                version3: <PercentInput defaultValue={modelWeights[2]} onSave={(val) => onModelWeightChange(2, val)} />,
                weighted: <Percent bold percent={totalWeight} />,
              },
            },
          ]
        : [],
    [isWeightEnabled, modelWeights, onModelWeightChange, totalWeight],
  )

  const makeExtraCells = React.useCallback(
    (metricPath: HistoryMetrics) => {
      const version2Change = calculatePercentChange(
        path<number>(metricPath, historyPoints?.[0]),
        path<number>(metricPath, historyPoints?.[1]),
      )
      const version3Change = calculatePercentChange(
        path<number>(metricPath, historyPoints?.[0]),
        path<number>(metricPath, historyPoints?.[2]),
      )

      return {
        version1: <SumText semibold sum={path(metricPath, historyPoints?.[0])} />,
        version2: (
          <SumChange
            change={version2Change}
            color={getChangeColorCoding(
              metricVisuals.get(metricPath)?.coding ?? defaultCoding,
              dispute.perspectiveRole,
              version2Change,
            )}
            sum={path<number>(metricPath, historyPoints?.[1])}
          />
        ),
        version3: (
          <SumChange
            change={version3Change}
            color={getChangeColorCoding(
              metricVisuals.get(metricPath)?.coding ?? defaultCoding,
              dispute.perspectiveRole,
              version3Change,
            )}
            sum={path<number>(metricPath, historyPoints?.[2])}
          />
        ),
        weighted: (
          <SumText semibold sum={path<DisputeHistoryPointFragment, number>(metricPath, weightedTotalHistoryPoint)} />
        ),
      }
    },
    [dispute.perspectiveRole, historyPoints, weightedTotalHistoryPoint],
  )

  const metricsToUse = React.useMemo(
    () => new Map([...metricVisuals.entries()].filter((e) => e[0] !== HistoryMetrics['event.eventValue'])),
    [],
  )

  const leftColumns = React.useMemo(() => [{...titleColumn, label: 'Compare on Chart'}], [])

  return (
    <MetricsSelector
      checkedMetrics={checkedMetrics}
      dataTableStateId="VersionCompareMetricsSelector"
      extraColumns={extraColumns}
      leftColumns={leftColumns}
      makeExtraCells={makeExtraCells}
      metricsToShow={metricsToUse}
      nonInteractive={false}
      onCheckboxClick={onCheckboxClick}
      prefixRows={prefixRows}
      renderMetricCircle={false}
    />
  )
}
