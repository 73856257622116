import * as React from 'react'

import {Classification, isicIndustryClassification, isicIndustryClassificationTree} from '@settleindex/domain'
import {noop, sortByPath} from '@settleindex/fp'
import {Form, FormItem, H1, LeftRight, PrimaryButton, SecondaryButton, TextInput, VSpaceSmall} from '@settleindex/react'

import {tmpId} from '../tmpId'
import {partyFieldNames} from './partyFieldNames'
import {PartyIndustry} from './PartyIndustry'
import {PartyInForm} from './PartyInForm'
import {PartyLabel} from './PartyLabel'
import {PartyLawFirm} from './PartyLawFirm'
import {PartyLeadSolicitor} from './PartyLeadSolicitor'
import {PartyName} from './PartyName'

export const partyFormName = 'party'

interface Props {
  onFinish: () => void
  party?: PartyInForm
  type: PartyInForm['type']
}

export const PartyForm: React.FC<Props> = ({onFinish, party, type}) => {
  const [form] = Form.useForm()
  const [lastIndustrySectionId, setLastIndustrySectionId] = React.useState<string>()
  const [availableIndustryDivisions, setAvailableIndustryDivisions] = React.useState<Classification[]>([])

  React.useEffect(() => {
    const defaultDivision = isicIndustryClassification.find((c) => c.id === party?.industry)
    const defaultSection = isicIndustryClassification.find((c) => c.id === defaultDivision?.parent)
    const divisionsOfSection = defaultSection
      ? isicIndustryClassification.filter((c) => c.parent === defaultSection?.id)
      : []
    form.setFieldsValue({...party, industrySection: defaultSection?.id})

    setLastIndustrySectionId(defaultSection?.id)
    setAvailableIndustryDivisions(divisionsOfSection)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- prevents changing controlled fields otherwise
  }, [])

  const allSectionsAlphabetical = React.useMemo(() => sortByPath('title', isicIndustryClassificationTree), [])

  const handleOnFinish = () => {
    form.validateFields().then(onFinish).catch(noop)
  }

  const handleOnChange = () => {
    const currentSectionIdInForm: string = form.getFieldValue([partyFieldNames.industrySection])
    const sectionIdChanged = currentSectionIdInForm !== lastIndustrySectionId

    if (sectionIdChanged) {
      form.setFieldsValue({[partyFieldNames.industry]: null})
      setLastIndustrySectionId(currentSectionIdInForm)
      const divisionsOfSection = currentSectionIdInForm
        ? isicIndustryClassification.filter((c) => c.parent === currentSectionIdInForm)
        : []
      setAvailableIndustryDivisions(divisionsOfSection)
    }
  }

  return (
    <>
      <Form
        form={form}
        name={partyFormName}
        onChange={handleOnChange}
        style={{width: '100%', margin: '0 auto', padding: '20px 20px'}}
      >
        <H1>Add Party</H1>
        <VSpaceSmall />
        <PartyLabel />
        <PartyName />
        <PartyIndustry
          allSectionsAlphabetical={allSectionsAlphabetical}
          availableIndustryDivisions={availableIndustryDivisions}
          defaultValue={party?.industry}
          onChange={handleOnChange}
        />
        <PartyLawFirm />
        <PartyLeadSolicitor />
        <VSpaceSmall />
        <LeftRight
          left={
            <SecondaryButton medium onClick={onFinish}>
              Cancel
            </SecondaryButton>
          }
          right={
            <PrimaryButton data-test-id={`party-form-done`} htmlType="submit" medium onClick={handleOnFinish}>
              Done
            </PrimaryButton>
          }
        />
        <FormItem hidden initialValue={party?.id ?? tmpId()} name={partyFieldNames.id} noStyle>
          <TextInput />
        </FormItem>
        <FormItem hidden initialValue={type} name={partyFieldNames.type} noStyle>
          <TextInput />
        </FormItem>
      </Form>
    </>
  )
}
