import {Point} from '@nivo/line'
import * as React from 'react'
import styled from 'styled-components'

import {PrimaryText} from '@settleindex/react'

const Key = styled.div`
  display: inline-block;
  background-color: ${(p: any) => p.color};
  width: 12px;
  height: 12px;
  border-radius: 10px;
  margin-right: 7px;
  margin-bottom: -1px;
`

const LineItemBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;

  & [data-value] {
    margin-left: 5px;
  }
`

interface LineItemProps {
  label: string
  point: Point
  value: React.ReactElement
}

export const LineItem: React.FC<LineItemProps> = ({label: partyLabel, point, value}) => {
  return (
    <LineItemBlock>
      <Key color={point.serieColor} />
      <PrimaryText>{`${partyLabel}:`}</PrimaryText>
      <PrimaryText data-value semibold>
        {value}
      </PrimaryText>
    </LineItemBlock>
  )
}
