import {getPath} from '@settleindex/domain/lib/src/nodes/tree/getPath'
import {sum} from '@settleindex/fp'

import {Currency, PartyCumulativeWeightsFragment} from '../../graphQLTypes'
import {AppNode} from '../AppNode'
import {AppOutcome} from '../AppOutcome'
import {NodeDisplayValue} from '../NodeDisplayValue'
import {nodeNamePrefix} from '../nodeNamePrefix'
import {Data} from './Data'
import {sortDataByValue} from './sortDataByValue'

interface Args {
  cumulativeWeights: PartyCumulativeWeightsFragment[]
  currency: Currency
  displayValue: NodeDisplayValue
  outcomeVisibleThreshold: number
  partyNodes: AppNode[]
  partyOutcomes: AppOutcome[]
}

export const makeChartData = ({
  cumulativeWeights,
  currency,
  displayValue,
  outcomeVisibleThreshold,
  partyNodes,
  partyOutcomes,
}: Args): Data[] => {
  const avgValue = sum(partyOutcomes.map((o) => o.valuePresent)) / (partyOutcomes.length * 2)

  return partyOutcomes
    .filter((o) => o.absoluteWeight && o.absoluteWeight > outcomeVisibleThreshold)
    .map((outcome, index) => {
      const originalValueIsZero = outcome[displayValue] === 0
      const value = originalValueIsZero ? avgValue : outcome[displayValue]
      const path = getPath({partyNodes, leafId: outcome.id, prefix: nodeNamePrefix})
      const cumulativeWeight = cumulativeWeights.find((cw) => cw.outcomeId === outcome.id)?.cumulativeWeight || 0

      return {
        absoluteWeight: outcome.absoluteWeight,
        claimsWithInterest: outcome.claimsWithInterest,
        costRecoveryNet: outcome.costRecoveryNet,
        cumulativeWeight,
        currency,
        id: String(index),
        name: outcome.name,
        originalValueIsZero,
        path,
        value,
        valueForecast: outcome.valueForecast,
        valueGross: outcome.valueGross,
        valueNet: outcome.valueNet,
        valuePresent: outcome.valuePresent,
      }
    })
    .sort(sortDataByValue)
}
