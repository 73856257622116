import * as React from 'react'

import {isUndefined} from '@settleindex/fp'
import {PrimaryText} from '@settleindex/react'

import {SumFormat} from './SumFormat'

interface Props {
  bold?: boolean
  medium?: boolean
  placeholder?: string
  semibold?: boolean
  sum?: number | null
  testId?: string
}

/**
 * Same as SumFormat, but displays in a PrimaryText, so you don't need to use
 * two components.
 */
export const SumText: React.FC<Props> = ({bold, medium, placeholder = '-', semibold, sum, testId}) => {
  const textProps = {
    bold,
    ['data-test-id']: testId,
    medium,
    semibold,
  }

  if (isUndefined(sum)) {
    return <PrimaryText {...textProps}>{placeholder}</PrimaryText>
  }

  return (
    <PrimaryText {...textProps}>
      <SumFormat placeholder={placeholder} sum={sum} />
    </PrimaryText>
  )
}

SumText.displayName = 'SumText'
