import * as React from 'react'

import {defaultSettlementStrategyType} from '@settleindex/domain'
import {cond, default0, sortByPath, T} from '@settleindex/fp'
import {PrimaryText, TertiarySelect} from '@settleindex/react'
import {versionReportTestIds} from '@settleindex/testids/src/testIds'

import {
  Currency,
  IndexedPartyResponse,
  OpponentValueFragment,
  SettlementStrategyFragment,
  SettlementStrategyInput,
  SettlementStrategyType,
} from '../../graphQLTypes'
import {SumFormat} from '../../sum'
import {SumInput} from '../../sum/sumInput'
import {settlementStrategyTypeLabels} from './settlementStrategyTypeLabels'

const strategyTypesRequireInput = [
  SettlementStrategyType.contribution,
  SettlementStrategyType.customTarget,
  SettlementStrategyType.judged,
  SettlementStrategyType.settled,
]

interface Props {
  currency: Currency
  opponentStrategyMap: Map<string, SettlementStrategyFragment>
  opponentValues: OpponentValueFragment[]
  perspectiveCaseValuePresent: number
  readonly: boolean
  setSettlementStrategy: (strategy: SettlementStrategyInput) => void
}

export const settlementStrategyRow = ({
  currency,
  opponentStrategyMap,
  opponentValues,
  perspectiveCaseValuePresent,
  readonly,
  setSettlementStrategy,
}: Props) => {
  const typeOptions = sortByPath(
    'weight',
    Object.values(SettlementStrategyType).map((type) => ({
      label: settlementStrategyTypeLabels.get(type)?.[0],
      weight: settlementStrategyTypeLabels.get(type)?.[1],
      value: type,
    })),
  )

  return (opponent: IndexedPartyResponse, opponentIndex: number) => {
    const commonSettlementValue = opponentValues.find((value) => value.partyId === opponent.id)?.commonSettlementValue
    const strategy = opponentStrategyMap.get(opponent.id)
    const selectedType = strategy?.type ?? defaultSettlementStrategyType
    const typeRequiresInput = strategyTypesRequireInput.includes(selectedType)
    const selectedAmountSum = {currency, amount: strategy?.amount ?? 0}

    const typeSelect = readonly ? (
      <PrimaryText>{settlementStrategyTypeLabels.get(selectedType)?.[0]}</PrimaryText>
    ) : (
      <TertiarySelect
        defaultValue={selectedType}
        onChange={(selectedOption: SettlementStrategyType) =>
          setSettlementStrategy({
            partyId: opponent.id,
            type: selectedOption,
            amount: selectedAmountSum.amount,
          })
        }
        options={typeOptions}
        style={{width: '100%', marginLeft: -10}}
      />
    )

    const sum = cond([
      [(t) => t === SettlementStrategyType.none, () => undefined],
      [(t) => t === SettlementStrategyType.commonSettlementValue, () => commonSettlementValue],
      [(t) => t === SettlementStrategyType.perspectiveCaseValuePresent, () => perspectiveCaseValuePresent],
      [T, () => default0(strategy?.amount)],
    ])(selectedType)

    const amountInput =
      !typeRequiresInput || readonly ? (
        <PrimaryText semibold>
          <SumFormat sum={sum} />
        </PrimaryText>
      ) : (
        <SumInput
          data-test-id={versionReportTestIds.strategyAmount(opponentIndex)}
          defaultValue={sum}
          disabled={readonly}
          onSave={(amount: number) =>
            setSettlementStrategy({
              partyId: opponent.id,
              type: selectedType,
              amount,
            })
          }
          placeholder={`0`}
          style={{maxWidth: 145}}
        />
      )

    return {
      id: opponent.id,
      cells: {
        opponentLabel: <PrimaryText medium>{opponent.label}</PrimaryText>,
        type: typeSelect,
        amount: amountInput,
      },
    }
  }
}
