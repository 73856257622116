import * as React from 'react'
import {useParams} from 'react-router-dom'

import {isDefined} from '@settleindex/fp'
import {Ambulance, LoadingData} from '@settleindex/react'

import {useAppIsLoading} from '../../appContext/useAppIsLoading'
import {useDisputeContainer} from '../../dispute/context/useDisputeContainer'
import {DisputeNotFound} from '../../dispute/DisputeNotFound'
import {disputeNotFoundGraphError} from '../../dispute/graph/disputeErrorGuards'
import {DisputeFragment, NewEventInput, useCreateEventMutation, useDisputeQuery} from '../../graphQLTypes'
import {LoadingIndicatorWithNav} from '../../page'
import {useHistory} from '../../routing'
import {paths} from '../../routing/paths'
import {NewEventModal} from './NewEventModal'

const NewEventContainer: React.FC = () => {
  const {id: disputeId} = useParams<{id: string}>()
  const {history} = useHistory()
  const {runAsync} = useAppIsLoading()
  const goToEventList = () => {
    history.push(paths.disputeEvents(disputeId))
  }
  const {data, error, loading} = useDisputeQuery({variables: {id: disputeId}})
  const [createEventMutation, {loading: createLoading}] = useCreateEventMutation()
  const createEvent = (newEvent: NewEventInput) => {
    runAsync(() => createEventMutation({variables: {disputeId, newEvent}}).then(goToEventList))
  }

  const {dispute: disputeInContext} = useDisputeContainer({requiredId: disputeId, setDisputeFrom: data?.dispute})

  const whenAvailable = () => {
    const dispute = disputeInContext as DisputeFragment

    return (
      <NewEventModal
        claimantId={dispute.claimant.id}
        loading={createLoading}
        onCancel={goToEventList}
        onFinish={createEvent}
        parties={dispute.parties}
        perspectiveRole={dispute.perspectiveRole}
      />
    )
  }

  return (
    <LoadingData
      state={{
        available: isDefined(disputeInContext),
        empty: disputeNotFoundGraphError(error),
        error: error && !disputeNotFoundGraphError(error),
        loading,
      }}
      whenAvailable={whenAvailable}
      whenEmpty={<DisputeNotFound />}
      whenError={<Ambulance />}
      whenLoading={<LoadingIndicatorWithNav />}
    />
  )
}

export default NewEventContainer
